import React, { memo, useContext, useEffect, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { useFormikContext } from "formik";

import { FormField } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import styles from "../../styles";

const RouteName = ({
  setRouteNameAlias,
  isLoadingOrPastRoute,
  routeStatus,
  isEditRoute,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { values: { routeNameAlias: routeNameFieldVal = "" } = {} } =
    useFormikContext();

  const disableRouteName = useMemo(
    () =>
      isLoadingOrPastRoute ||
      (isEditRoute &&
        !["DRAFT", "PLANNED", "INPROGRESS"].includes(routeStatus)),
    [routeStatus, isLoadingOrPastRoute]
  );

  useEffect(() => {
    setRouteNameAlias(routeNameFieldVal);
  }, [routeNameFieldVal]);

  return (
    <View
      style={{
        ...tw("flex-row my-1 items-center"),
        zIndex: 35,
      }}
    >
      <View style={styles.labelTextContainer}>
        <Text
          style={{
            ...styles.labelText,
            paddingRight: 15,
          }}
        >
          {Localise(messages, "Route Name")}:
        </Text>
      </View>
      <View style={styles.valueText}>
        {!disableRouteName ? (
          <FormField
            name="routeNameAlias"
            containerStyle={{
              zIndex: 1,
              marginLeft: -5,
              width: isSmallScreen ? "100%" : 230,
              maxWidth: 800,
            }}
            placeholder={Localise(messages, "Route Name")}
            testID="route_name_alias"
            accessibilityLabel="route_name_alias"
            isUpdateOnChange={true}
          />
        ) : (
          <View style={styles.valueText}>
            <Text numberOfLines={2}>{routeNameFieldVal || "-"}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default memo(RouteName);
