import React from "react";
import { View, Text, Platform } from "react-native";

import I18NContext from "library/contexts/i18N";
import { FormField } from "components/elements/forms";
import { ToasterHandler } from "components/elements";
import { formatPrice } from "library/utils/formatter";

import styles from "components/containers/listing-new/styles";
import { fonts } from "styles/theme";

import tw from "tailwind-rn";

const ApplyPayment = ({
  openItemUnpaidOrders = [],
  viewMoreCounts,
  setFieldValue,
  isAccountType = "",
  balanceForwardPaymentPreviewData = [],
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View>
      {openItemUnpaidOrders
        ?.slice(0, viewMoreCounts)
        ?.map((lineItem, index) => {
          const {
            orderId = "",
            orderNumber = "",
            amountReceived = 0,
            orderTotalAmount = 0,
            refunds = [],
            isDebitMemo = false,
            isLateFee = false,
            payments = [],
            houseAccountType = "",
          } = lineItem;

          const mhqOrderNumber = orderNumber || orderId;

          const filteredOrders =
            balanceForwardPaymentPreviewData?.length > 0 &&
            balanceForwardPaymentPreviewData?.filter(
              (item) => item.orderID === mhqOrderNumber
            );

          const paymentAmount =
            filteredOrders.length > 0 ? filteredOrders[0]?.paymentAmount : 0;

          let refundedAmount = 0;
          for (let i = 0; i < refunds?.length; i++) {
            refundedAmount = refundedAmount + refunds[i]?.refundedAmount;
          }

          let paymentReceived = 0;
          for (let i = 0; i < payments?.length; i++) {
            paymentReceived = paymentReceived + payments[i]?.paymentAmount;
          }

          const totalAmountReceived = Number(
            formatPrice(
              (houseAccountType === "Balance_Forward"
                ? paymentReceived
                : amountReceived) + refundedAmount
            )
          );

          const lineItemName = Localise(
            messages,
            isDebitMemo
              ? Localise(messages, "Debit Memo")
              : isLateFee
              ? Localise(messages, "Late Fee")
              : Localise(messages, "Order")
          );

          return (
            <View key={index}>
              <View
                style={[
                  tw(
                    `flex flex-row justify-start pb-2 items-${
                      Platform.OS !== "web" ? "center" : "baseline"
                    }`
                  ),
                ]}
              >
                <Text
                  style={{
                    ...fonts.default,
                    ...styles.labelText,
                    width: "40%",
                  }}
                >
                  {`${lineItemName} #${mhqOrderNumber}`}
                </Text>
                <View style={[tw(`flex flex-row pt-2`)]}>
                  {isAccountType === "Open_Item" ? (
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"paymentAmount"}
                      placeholder=""
                      containerStyle={{ width: 100 }}
                      isPrice={true}
                      keyboardType="numeric"
                      iconType="material-community"
                      iconName="currency-usd"
                      iconSize={14}
                      inputContainerStyle={{
                        padding: 6,
                      }}
                      leftIconContainerStyle={{ paddingRight: 20 }}
                      path={`ordersInfo.${index}`}
                      testID="ordersInfo"
                      handleOnBlur={(balAmount = "") => {
                        if (balAmount?.length) {
                          setFieldValue(
                            `ordersInfo.${index}.orderID`,
                            mhqOrderNumber
                          );
                          if (
                            Number(balAmount) >
                            Number(
                              formatPrice(
                                orderTotalAmount - totalAmountReceived
                              )
                            )
                          ) {
                            ToasterHandler(
                              "uh oh",
                              Localise(
                                messages,
                                `Amount should not be greater than $${formatPrice(
                                  orderTotalAmount - totalAmountReceived
                                )}`
                              )
                            );
                          }
                        } else {
                          setFieldValue(`ordersInfo.${index}.orderID`, ``);
                          setFieldValue(
                            `ordersInfo.${index}.paymentAmount`,
                            ``
                          );
                        }
                      }}
                    />
                  ) : (
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"paymentAmount"}
                      value={formatPrice(paymentAmount)}
                      placeholder=""
                      containerStyle={{ width: 100 }}
                      isPrice={true}
                      keyboardType="numeric"
                      iconType="material-community"
                      iconName="currency-usd"
                      iconSize={14}
                      inputContainerStyle={{
                        padding: 6,
                      }}
                      leftIconContainerStyle={{ paddingRight: 20 }}
                      testID="paymentAmount"
                      editable={false}
                      grayedOutOnDisable={true}
                    />
                  )}
                </View>
                <Text
                  style={{
                    ...fonts.default,
                    ...styles.labelText,
                    paddingBottom: 5,
                    paddingLeft: 10,
                  }}
                >
                  {Localise(
                    messages,
                    `Balance $${formatPrice(
                      orderTotalAmount -
                        (isAccountType === "Open_Item"
                          ? totalAmountReceived
                          : Number(
                              formatPrice(totalAmountReceived + paymentAmount)
                            ))
                    )}`
                  )}
                </Text>
              </View>
            </View>
          );
        })}
    </View>
  );
};

export default ApplyPayment;
