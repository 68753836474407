import React, { useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import { Input, Icon } from "react-native-elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import {
  createTransaction,
  createOrEndSession,
} from "library/sagas/session/slice";
import { ToasterHandler } from "components/elements";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { theme, colors } from "styles/theme";
import { printActivitiesReceipt } from "../activity";
import {
  getValidationSchema,
  getAmount,
  priceKeys,
  priceValues,
} from "./helper";
import AdditionsCalculator from "./additions-calculator";

const options = [];
for (let i = 1; i <= 500; i++) {
  options.push({ label: i, value: i });
}

const BalanceCheck = (props) => {
  const {
    sessionInfo: { currentBalance, memberId, macAddress, sessionId },
    operator,
    createTransaction,
    createOrEndSession,
    setShowBalanceCheck,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const [showCalculator, setShowCalculator] = useState(false);

  return (
    <ScrollView
      style={{
        flexGrow: 0,
        maxHeight: `calc(100vh - 100px)`,
      }}
    >
      <Form
        initialValues={{
          currentBalance: parseFloat(currentBalance).toFixed(2),
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={getValidationSchema(Localise, messages)}
        onSubmit={(values, formikBag) => {
          const diffAmount = parseFloat(
            getAmount(values) - values.currentBalance
          );
          const endSession = () => {
            createTransaction({
              params: {
                amount: 0,
                type: "Other",
                macAddress,
                memberId,
                user: operator,
                userNote: `Ended Session #${sessionId}`,
                orderId: "",
              },
              resolve: (resp) => {
                setShowBalanceCheck(false);
                createOrEndSession({
                  params: {
                    ...props.sessionInfo,
                    status: "inactive",
                  },
                  resolve: (resp) => {
                    printActivitiesReceipt(
                      resp.events,
                      memberId,
                      Localise,
                      messages
                    );
                    ToasterHandler(
                      "success",
                      Localise(messages, `Session ended successfully`)
                    );
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to end Session. Please try again.`
                      )
                    );
                  },
                });
              },
              reject: () => {},
            });
          };

          if (diffAmount !== 0) {
            createTransaction({
              params: {
                amount: diffAmount > 0 ? diffAmount : -1 * diffAmount,
                type: diffAmount > 0 ? "Paid-In" : "Paid-Out",
                macAddress,
                memberId,
                user: operator,
                userNote: "$$BALANCE_CHECK$$",
                orderId: "",
              },
              resolve: () => {
                endSession();
              },
              reject: () => {
                ToasterHandler(
                  "uh oh",
                  Localise(
                    messages,
                    `Failed to create transaction. Please try again.`
                  )
                );
                formikBag.setSubmitting(false);
              },
            });
          } else {
            endSession();
          }
        }}
        render={({ values, setFieldValue }) => {
          const sumOfValues = getAmount(values);
          const overOrUnder = sumOfValues - values.currentBalance;
          const diffValue = parseFloat(
            overOrUnder < 0 ? -1 * overOrUnder : overOrUnder
          ).toFixed(2);
          return (
            <View style={tw("mt-2")}>
              <Text style={tw("p-1 mb-4")}>
                {Localise(messages, "End Session")}
              </Text>
              <FormField
                name="currentBalance"
                label={"Current Balance"}
                testID="current_balance"
                accessibilityLabel="current_balance"
                containerStyle={{
                  width: 120,
                }}
                isPrice={true}
                iconType="material-community"
                iconName="currency-usd"
                iconSize={13}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                keyboardType="numeric"
                placeholder={Localise(messages, "0")}
                editable={false}
                grayedOutOnDisable={true}
              />
              <View style={tw("p-2")}>
                {priceKeys.map((priceLabel, index) => {
                  const isCheck = priceLabel === "Checks";
                  return (
                    <View key={index} style={tw("flex-row items-center")}>
                      <View style={{ width: 200 }}>
                        <Text>{Localise(messages, priceLabel)}</Text>
                      </View>
                      <FormFieldPicker
                        placeholder={{ label: "0", value: 0 }}
                        containerStyle={{ width: 100 }}
                        data={options}
                        name={priceLabel}
                        label=""
                        onChange={(e) => {
                          setFieldValue(
                            `${priceLabel}Value`,
                            parseFloat(
                              Number(e) * priceValues[priceLabel]
                            ).toFixed(2)
                          );
                        }}
                      />
                      <FormField
                        name={`${priceLabel}Value`}
                        label={""}
                        testID={`${priceLabel}Value`}
                        accessibilityLabel={`${priceLabel}Value`}
                        containerStyle={{
                          width: isCheck ? 100 : 120,
                        }}
                        isPrice={true}
                        iconType="material-community"
                        iconName="currency-usd"
                        iconSize={13}
                        inputContainerStyle={{
                          padding: 6,
                        }}
                        leftIconContainerStyle={{ paddingRight: 0 }}
                        keyboardType="numeric"
                        placeholder={Localise(messages, "0")}
                        editable={isCheck}
                        grayedOutOnDisable={true}
                      />
                      {isCheck && (
                        <Icon
                          name={"calculator"}
                          size={20}
                          type="material-community"
                          iconStyle={{
                            color: showCalculator
                              ? colors.highlighter
                              : colors.primary,
                            marginBottom: 5,
                          }}
                          onPress={() => setShowCalculator(!showCalculator)}
                          testID="calendar_left_arrow"
                          accessibilityLabel="calendar_left_arrow"
                        />
                      )}
                    </View>
                  );
                })}
              </View>
              {showCalculator && (
                <AdditionsCalculator setFieldValue={setFieldValue} />
              )}
              <View style={tw("flex-row")}>
                <Input
                  containerStyle={{
                    width: 100,
                    marginTop: 7,
                  }}
                  label="Balance Check"
                  leftIcon={{
                    type: "material-community",
                    name: "currency-usd",
                    size: 13,
                  }}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  value={parseFloat(sumOfValues).toFixed(2)}
                  disabled={true}
                />
                <Input
                  containerStyle={{
                    width: 100,
                    marginTop: 7,
                  }}
                  label="Over/Under"
                  leftIcon={{
                    type: "material-community",
                    name: "currency-usd",
                    size: 13,
                  }}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  style={
                    overOrUnder < 0
                      ? {
                          color: colors.error,
                        }
                      : {}
                  }
                  value={overOrUnder < 0 ? `(${diffValue})` : diffValue}
                  disabled={true}
                />
              </View>

              <View style={tw("flex-row justify-center items-center")}>
                <TouchableOpacity
                  onPress={() => {
                    setShowBalanceCheck(false);
                    createTransaction({
                      params: {
                        amount: "0",
                        type: "Other",
                        memberId,
                        macAddress,
                        user: operator,
                        userNote: `Balance Check Canceled`,
                        orderId: "",
                      },
                      resolve: (resp) => {
                        console.log(`Cancel Balance check is logged`);
                      },
                      reject: () => {
                        console.log(
                          `Failed to create transaction. Please try again.`
                        );
                      },
                    });
                  }}
                  style={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 9,
                    paddingHorizontal: 9,
                    marginVertical: 0,
                    marginHorizontal: 10,
                  }}
                  testID={"cancel"}
                  accessibilityLabel={"cancel"}
                >
                  <Text style={theme.Button.secondaryTitleStyle}>
                    {Localise(messages, "Cancel")}
                  </Text>
                </TouchableOpacity>
                <SubmitButton
                  title={Localise(messages, "Submit")}
                  containerStyle={{
                    width: 85,
                    margin: 0,
                    marginLeft: 10,
                    height: 35,
                  }}
                  testID="Submit"
                  accessibilityLabel="Submit"
                />
              </View>
            </View>
          );
        }}
      />
    </ScrollView>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTransaction,
      createOrEndSession,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BalanceCheck);
