import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";
import I18NContext from "library/contexts/i18N";
import { View, TouchableOpacity, Image } from "react-native";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";
import { Tooltip } from "components/elements";
import { basicOrderInfo } from "./config";
import {
  setActiveOrderItemTab,
  setOrderItemTabs,
} from "library/sagas/views/home/drawer/create-order/slice";
import { selectActiveItemTab } from "library/sagas/views/home/drawer/create-order/selector";

const AddNewOrderItem = ({
  tabNames,
  arrayHelpersRef,
  sendingMemberCode,
  isCopy = false,
  orderItems = [],
}) => {
  const dispatch = useDispatch();
  const activeItemTab = useSelector(selectActiveItemTab);
  const { tax_delivery_fee = false } =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const maxOrderItemsAllowed = Environment.get("MAX_ORDER_ITEMS_ALLOWED", 5);
  const { messages, Localise } = React.useContext(I18NContext);
  const holdTimerRef = useRef(false);
  const testID = `${isCopy ? "copy" : "add"}-new-order-item`;
  return tabNames.length < maxOrderItemsAllowed ? (
    <View style={[tw(`flex flex-row items-center justify-start`)]}>
      <TouchableOpacity
        style={{ paddingHorizontal: 5 }}
        onPress={() => {
          // Restricting immediate clicks for 2 sec
          if (holdTimerRef.current) return;
          holdTimerRef.current = true;
          setTimeout(() => {
            holdTimerRef.current = false;
          }, 2000);

          const newTabNames = tabNames.map((eachTab, index) => {
            return {
              ...eachTab,
              key: index,
              title: eachTab.defaultTitle
                ? `Order ${index + 1}`
                : eachTab.title,
            };
          });

          dispatch(
            setOrderItemTabs([
              ...newTabNames,
              {
                key: tabNames.length,
                title: `Order ${tabNames.length + 1}`,
                isRemovable: true,
                defaultTitle: true,
              },
            ])
          );
          dispatch(setActiveOrderItemTab(tabNames.length));
          const orderInfo = !isCopy
            ? basicOrderInfo
            : { ...orderItems[activeItemTab], isCopy };
          arrayHelpersRef.current?.push({
            ...orderInfo,
            applyTaxOnDF: tax_delivery_fee,
          });
        }}
        testID={testID}
        accessibilityLabel={testID}
      >
        <Tooltip
          text={Localise(messages, `${isCopy ? "Copy" : "Create"} Multi Order`)}
          renderForWebOnly={true}
        >
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            resizeMode="contain"
            source={IMAGES[!isCopy ? `create-order` : `copy-content`]}
          />
        </Tooltip>
      </TouchableOpacity>
    </View>
  ) : null;
};

export default React.memo(AddNewOrderItem);
