import React, { useState } from "react";
import { Platform } from "react-native";
import FeesInputFields from "./input/fees";
import CoverageInputFields from "./input/coverage";
import DeliveryExceptions from "./delivery-exception";
import DeliveryMap from "./delivery-exception/zones-map";
import LatLongSettings from "./lat-long";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";

const FeesCoverage = ({ accordionStatus = false, screenOneRef }) => {
  const canEditLatLong = Environment.get("CAN_EDIT_LAT_AND_LONG", false);
  const operatorRole = UserProfileStorage.getOperatorRole();

  const isEditEligible =
    ["FTD_ADMIN", "FTD_POWER_USER"].includes(operatorRole) && canEditLatLong;

  const [isEdit, setIsEdit] = useState(false);
  if (!accordionStatus) return null;
  return (
    <>
      {Platform.OS === "web" && (
        <>
          <DeliveryMap
            setIsEdit={setIsEdit}
            screenOneRef={screenOneRef}
            isEditEligible={isEditEligible}
          />
          {isEditEligible && <LatLongSettings isEdit={isEdit} />}
        </>
      )}
      <FeesInputFields />
      <CoverageInputFields />
      <DeliveryExceptions />
    </>
  );
};

export default FeesCoverage;
