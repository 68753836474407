import React, { memo } from "react";
import { useSelector } from "react-redux";
import { CustomModal } from "components/elements";
import { Text } from "react-native-elements";
import { Platform } from "react-native";
import tw from "tailwind-rn";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { ORDERS } from "library/constants";
import { useFormikContext } from "formik";
import { selectRouteDetails } from "library/sagas/ongoing/order-details/selector";
import { getRouteDisplayName } from "components/views/drawer/delivery/routes-dashboard/helper";

const RouteAdjustmentWarning = (props) => {
  const {
    onContinue,
    showRouteAdjustmentWarning,
    setShowRouteAdjustmentWarning,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const { route: { routeFriendlyName, routeName } = {} } =
    useSelector(selectRouteDetails);
  const formikBag = useFormikContext();

  const routeDisplayName = getRouteDisplayName(routeFriendlyName, routeName);

  const modalContent = {
    content: (
      <Text
        testID={"route_change_warning"}
        style={[
          tw("p-4 text-center"),
          {
            fontSize: 15,
            color: colors.highlighter,
          },
        ]}
      >
        {Localise(
          messages,
          ORDERS.ADDRESS_OR_DATE_CHANGE_WARNING_TEXT.replace(
            "ROUTE_NAME",
            routeDisplayName
          )
        )}
      </Text>
    ),
    buttons: [{ type: "primary", title: Localise(messages, "Continue") }],
  };

  //Trigger form submit.
  const handlePrimaryButtonClick = () => {
    setShowRouteAdjustmentWarning(false);
    onContinue(formikBag.values, formikBag);
  };

  if (!showRouteAdjustmentWarning) return;

  return (
    <CustomModal
      modalVisible={showRouteAdjustmentWarning}
      modalContent={modalContent}
      primaryhandler={handlePrimaryButtonClick}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
      isReduceBtnSpacing={true}
    ></CustomModal>
  );
};

export default memo(RouteAdjustmentWarning);
