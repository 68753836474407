import React from "react";
import { View, ScrollView, Platform } from "react-native";
import WebView from "react-native-webview";
import Environment from "library/utils/environment";

export const MHQTrainingMaterials = () => {
  const scribeHelpMHQTrainingMaterialsURL = Environment.get(
    "SCRIBE_HELP_MHQ_TRAINING_MATERIALS_URL",
    ""
  );

  return (
    <ScrollView
      contentContainerStyle={{
        paddingHorizontal: 20,
        paddingBottom: 30,
      }}
    >
      <View>
        {Platform.OS === "web" ? (
          <iframe
            src={scribeHelpMHQTrainingMaterialsURL}
            width="100%"
            height="640"
            allowFullScreen
          />
        ) : (
          <View style={{ flex: 1, width: "100%", height: 315 }}>
            <WebView
              source={{
                uri: scribeHelpMHQTrainingMaterialsURL,
              }}
            />
          </View>
        )}
      </View>
    </ScrollView>
  );
};
