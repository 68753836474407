import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Platform, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import tw from "tailwind-rn";

import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";
import { getMHQUIConfig, getMOLUIConfig } from "./ui-config";
import { DeviceContext } from "library/contexts/appSettings";

import { AccordionWithBorder } from "components/elements";
import DownloadLinks from "components/views/drawer/help/download-links";

import { selectActiveHelpSubTab } from "library/sagas/views/home/drawer/help/selector";
import UserAuthContext from "library/contexts/userAuth";

import { MHQTrainingMaterials } from "./training-materials";

const TrainingMaterials = ({ setShowPreview, setContent }) => {
  const { setParams } = useNavigation();

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const { authToken } = React.useContext(UserAuthContext);

  const currentSubTab = useSelector(selectActiveHelpSubTab);

  const initConfig =
    currentSubTab == "mhq-sub-tab"
      ? getMHQUIConfig(isDesktop)
      : getMOLUIConfig();

  useEffect(() => {
    return () => {
      setParams({ search: undefined });
    };
  }, []);

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <>
        {currentSubTab === "mhq-sub-tab" ? (
          <MHQTrainingMaterials />
        ) : (
          initConfig.map(
            ({ title = "", subAccordians = [], defaultOpen = false }, id) => {
              return (!authToken || Platform.OS !== "web") &&
                title !== "Legal" ? null : (
                <AccordionWithBorder
                  title={`${title} (${subAccordians?.length})`}
                  key={id}
                  defaultOpen={defaultOpen}
                >
                  {subAccordians &&
                    subAccordians.map(
                      (
                        {
                          subTitle,
                          content,
                          fileName,
                          needEncoding = false,
                          key,
                        },
                        index
                      ) => (
                        <View key={key}>
                          {title === "Legal" ? (
                            <View style={tw("pt-4")}>
                              <TouchableOpacity
                                onPress={() => {
                                  setShowPreview(true);
                                  setContent(content);
                                }}
                                testID={key}
                                accessibilityLabel={key}
                              >
                                <View style={tw("pt-2 pb-2")}>
                                  <Text style={fonts.link1}>
                                    {Localise(messages, subTitle)}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <View key={index}>
                              <DownloadLinks
                                subTitle={subTitle}
                                fileName={fileName}
                                needEncoding={needEncoding}
                              />
                            </View>
                          )}
                        </View>
                      )
                    )}
                </AccordionWithBorder>
              );
            }
          )
        )}
      </>
    </View>
  );
};

export default TrainingMaterials;
