/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { View, Text, Platform } from "react-native";
import I18NContext from "library/contexts/i18N";
import { Form } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import SettingsHOC from "components/views/drawer/ftd-admin/member-settings/manage-groups/shop-preferences/settings-hoc";
import Environment from "library/utils/environment";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { getValidationSchema, formFields } from "./config";
import UserProfileStorage from "library/storage/userProfile";

const LatLongForm = ({ isEdit }) => {
  const shopCode = useSelector(selectShopCode);
  const { messages, Localise } = React.useContext(I18NContext);

  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const { latitude, longitude } = UserProfileStorage.getShopLocation(shopCode);
  const latitudeRef = useRef(null);

  const initialValues = {
    latitude,
    longitude,
  };

  const saveLatLong = (values, formikBag) => {
    formikBag.setSubmitting(false);
    console.log("Saving Shop Coordinates...");
  };

  useEffect(() => {
    latitudeRef?.current.focus();
  }, [isEdit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        saveLatLong(values, formikBag);
      }}
      validationSchema={getValidationSchema(Localise, messages)}
      validateOnChange={validationOnChange}
      validateOnBlur={validationOnChange}
      render={({ submitCount }) => {
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);

        return (
          <>
            <View style={[tw("flex flex-row justify-between")]}>
              {formFields.map((fieldInfo, index) => {
                const {
                  formFieldElement: Component,
                  title,
                  formFieldProps,
                } = fieldInfo;

                return (
                  <View
                    key={index}
                    style={[
                      tw(
                        `flex flex-row justify-start pb-2 items-${
                          Platform.OS !== "web" ? "center" : "baseline"
                        }`
                      ),
                      { width: "50%" },
                    ]}
                  >
                    <Text
                      style={{
                        height: 40,
                        width: "20%",
                        ...fonts.heading5,
                      }}
                    >
                      {title}
                    </Text>
                    <View style={{ width: "50%" }}>
                      <Component
                        {...formFieldProps}
                        inputRef={index === 0 ? latitudeRef : null}
                      />
                    </View>
                  </View>
                );
              })}
            </View>
            <SaveCancelButtons
              buttonTitle={Localise(messages, "Save")}
              disableOnDirty={true}
              customContainerStyles={{
                width: 100,
                marginLeft: 0,
              }}
              cancelTestId={`cancelLatLong}`}
              saveTestId={`saveLatLong`}
            />
          </>
        );
      }}
    />
  );
};

const LatLongSettings = (props) => {
  const showLatLongSettings = Environment.get("SHOW_LAT_LONG_SETTINGS", true);
  if (!showLatLongSettings) return null;

  return (
    <SettingsHOC
      title={"Shop Coordinates"}
      Child={LatLongForm}
      childProps={props}
      defaultOpen={props.isEdit}
      titleStyle={{ paddingLeft: 0 }}
    />
  );
};

export default LatLongSettings;
