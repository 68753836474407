import { put, call, all, select } from "redux-saga/effects";

import UserProfileStorage from "library/storage/userProfile";
import { request } from "library/utils/request";
import {
  convertToTimeZone,
  timedOrderBuffer,
} from "library/utils/deliveryService";

import {
  setDeliveryDashboardData,
  fetchRouteDetail,
  setZones,
  setDashboardCurrentTabKey,
} from "./slice";
import { selectDeliveryDashboardData, selectZonesData } from "./selector";
import get from "lodash/get";
import set from "lodash/set";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { getRouteDisplayName } from "components/views/drawer/delivery/routes-dashboard/helper";

const getDisplaystatus = (orderStatus) => {
  return ["ACKNOWLEDGED", "ACKNOWLEDGE_PRINT", "PRINTED"].includes(orderStatus)
    ? "Not Started"
    : orderStatus === "DESIGN"
    ? "In Design"
    : ["READY_FOR_DELIVERY", "DESIGNED"].includes(orderStatus)
    ? "Designed"
    : orderStatus === "OUT_FOR_DELIVERY"
    ? "Out for Delivery"
    : orderStatus === "DELIVERED"
    ? "Completed"
    : false;
};

const processDeliveryDashboardResponse = (data, { shopCodes }) => {
  let updatedData = {
    routes: [],
    zones: [],
  };

  orderBy(data, "date", "asc")?.map((eachDate) => {
    const { date, memberOrderDetails = [] } = eachDate;

    //default _nonRouted for shopCode when ordersCount 0 for selected dateRange
    set(updatedData, `routes.allDates`, {
      ...get(updatedData, `routes.allDates`, {}),
      [`allShops_nonRouted`]: {
        ...get(updatedData, `routes.allDates.allShops_nonRouted`, {}),
        displayName: "Not Assigned to Route",
        routeStatus: "nonRouted",
        shopCodes,
        totalOrders: get(
          updatedData,
          `routes.allDates.allShops_nonRouted.totalOrders`,
          0
        ),
      },
    });

    memberOrderDetails.map((eachShop) => {
      const { details = [], memberCode = "" } = eachShop;
      details?.map((eachStatus) => {
        const {
          status,
          nonRoutedOrders: {
            nonRoutedOrdersCount = 0,
            rushOrder: { rushOrdersCount: nonRoutedRushOrders = 0 } = {},
          } = {},
          routes = [],
          zones = [],
        } = eachStatus;

        if (getDisplaystatus(status)) {
          const displayStatus = getDisplaystatus(status);

          //All Non routed Orders for selected date range
          set(updatedData, `routes.allDates`, {
            ...get(updatedData, `routes.allDates`, {}),
            [`allShops_nonRouted`]: {
              ...get(updatedData, `routes.allDates.allShops_nonRouted`, {}),
              [displayStatus]: {
                ...get(
                  updatedData,
                  `routes.allDates.allShops_nonRouted.${displayStatus}`,
                  {}
                ),
                count:
                  get(
                    updatedData,
                    `routes.allDates.allShops_nonRouted.${displayStatus}.count`,
                    0
                  ) + nonRoutedOrdersCount,
                hasRushOrders:
                  get(
                    updatedData,
                    `routes.allDates.allShops_nonRouted.${displayStatus}.hasRushOrders`,
                    false
                  ) || !!nonRoutedRushOrders,
              },
              totalOrders:
                get(
                  updatedData,
                  `routes.allDates.allShops_nonRouted.totalOrders`,
                  0
                ) + nonRoutedOrdersCount,
              additional: {
                rushOrders: {
                  count:
                    get(
                      updatedData,
                      `routes.allDates.allShops_nonRouted.additional.rushOrders.count`,
                      0
                    ) + nonRoutedRushOrders,
                },
              },
            },
          });
          routes.map((eachRoute) => {
            const {
              routeId,
              ordersCount,
              rushOrder: { rushOrdersCount = 0 } = {},
            } = eachRoute;
            updatedData.routes = {
              ...updatedData.routes,
              [date]: {
                ...get(updatedData, `routes.${date}`, {}),
                [routeId]: {
                  ...get(updatedData, `routes.${date}.${routeId}`, {}),
                  totalOrders:
                    get(
                      updatedData,
                      `routes.${date}.${routeId}.totalOrders`,
                      0
                    ) + ordersCount,
                  date,
                  routeId,
                  shopCodes: [
                    ...get(
                      updatedData,
                      `routes.${date}.${routeId}.shopCodes`,
                      []
                    ),
                    memberCode,
                  ],
                  additional: {
                    rushOrders: {
                      count:
                        get(
                          updatedData,
                          `routes.${date}.${routeId}.additional.rushOrders.count`,
                          0
                        ) + rushOrdersCount,
                    },
                  },
                  [displayStatus]: {
                    ...get(
                      updatedData,
                      `routes.${date}.${routeId}.${displayStatus}`,
                      {}
                    ),
                    count:
                      get(
                        updatedData,
                        `routes.${date}.${routeId}.${displayStatus}.count`,
                        0
                      ) + ordersCount,
                    hasRushOrders:
                      get(
                        updatedData,
                        `routes.${date}.${routeId}.${displayStatus}.hasRushOrders`,
                        false
                      ) || !!rushOrdersCount,
                  },
                },
              },
            };
          });
          zones.map((eachZone) => {
            const {
              zoneId,
              ordersCount = 0,
              routedOrdersCount = 0,
              rushOrder: { rushOrdersCount = 0 } = {},
            } = eachZone;
            const nonRoutedOrdersCount = ordersCount - routedOrdersCount;
            updatedData.zones = {
              ...updatedData.zones,
              [memberCode]: {
                ...get(updatedData, `zones.${memberCode}`, {}),
                [zoneId]: {
                  ...get(updatedData, `zones.${memberCode}.${zoneId}`, {}),
                  totalOrders:
                    get(
                      updatedData,
                      `zones.${memberCode}.${zoneId}.totalOrders`,
                      0
                    ) + ordersCount,
                  zoneId,
                  shopCode: memberCode,
                  additional: {
                    rushOrders: {
                      count:
                        get(
                          updatedData,
                          `zones.${memberCode}.${zoneId}.additional.rushOrders.count`,
                          0
                        ) + rushOrdersCount,
                    },
                    nonRoutedOrders: {
                      count:
                        get(
                          updatedData,
                          `zones.${memberCode}.${zoneId}.additional.nonRoutedOrders.count`,
                          0
                        ) + nonRoutedOrdersCount,
                    },
                    routedOrders: {
                      count:
                        get(
                          updatedData,
                          `zones.${memberCode}.${zoneId}.additional.routedOrders.count`,
                          0
                        ) + routedOrdersCount,
                    },
                  },
                  [displayStatus]: {
                    ...get(
                      updatedData,
                      `zones.${memberCode}.${zoneId}.${displayStatus}`,
                      {}
                    ),
                    count:
                      get(
                        updatedData,
                        `zones.${memberCode}.${zoneId}.${displayStatus}.count`,
                        0
                      ) + ordersCount,
                    hasRushOrders:
                      get(
                        updatedData,
                        `zones.${memberCode}.${zoneId}.${displayStatus}.hasRushOrders`,
                        false
                      ) || !!rushOrdersCount,
                  },
                },
              },
            };
          });
        }
      });
    });
  });

  let processedRoutesData = Object.values(
    Object.values(updatedData.routes)
      .map((each) => Object.values(each))
      .flat()
  );

  set(updatedData, "routes", processedRoutesData);

  return updatedData;
};

export function* handleFetchDeliveryDashboardData(action = {}) {
  const { params, setLoading, deliveryDashboardRoutesController } = get(
    action,
    "payload",
    {}
  );
  const { fetchMemberCodes = [] } = params;

  const serviceRequest = (params) =>
    request("get-delivery-dashboard-details", params);

  try {
    setLoading && setLoading(true);
    const response = yield call(serviceRequest, params);

    const updatedResp = processDeliveryDashboardResponse(response, {
      shopCodes: fetchMemberCodes,
    });

    yield put(setDeliveryDashboardData(updatedResp));
    const { routes: routesData = [] } = updatedResp;
    let updatedRoutesData = [...routesData];
    updatedRoutesData?.shift(); //removing "Not Assigned to Route" deatils before fetching Route Details

    if (updatedRoutesData?.length) {
      setLoading && setLoading(true);
      //get route Data for each route
      yield all(
        updatedRoutesData?.map((eachRoute, i) => {
          const { shopCodes = [], routeId } = eachRoute;
          const params = {
            index: i + 1,
            routeId,
            shopCode: shopCodes[0],
            deliveryDashboardRoutesController,
            setLoading:
              updatedRoutesData.length - 1 === i ? setLoading : () => {},
          };
          return put(fetchRouteDetail(params));
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchRouteDetail(action = {}) {
  const params = get(action, "payload", {});
  const { index, shopCode, deliveryDashboardRoutesController, setLoading } =
    params;
  const serviceRequest = (params) =>
    request("get-route-data", params, deliveryDashboardRoutesController);

  try {
    setLoading && setLoading(true);
    const routeResponse = yield call(serviceRequest, params);
    const {
      route: {
        routeFriendlyName = "",
        routeName = "",
        status: routeStatus,
        recommendedStartTime,
        departureTime,
        stops = [],
        memberCodes = [],
      } = {},
    } = routeResponse || {};
    const deliveryDashboardData = yield select(selectDeliveryDashboardData) ||
      {};

    const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    const floristTimeZone = shopTimeZone || "America/Chicago";

    const undeliveredRushOrdersInRoute = stops.filter(
      (ord) =>
        ord.type === "ORDER" && ord.status !== "COMPLETED" && ord.deliverByTime
    );

    const deliverByTimeMap = undeliveredRushOrdersInRoute.map((ord) =>
      moment(ord?.deliverByTime)
    );
    const earliestRushTime = deliverByTimeMap.length
      ? moment.min(deliverByTimeMap)
      : null;
    const deliverBy = earliestRushTime
      ? moment(convertToTimeZone(earliestRushTime, floristTimeZone)).format(
          "h:mm A"
        )
      : "";
    //checking if route may have delayed orders
    //Suggested start in florist timezone
    const localRecommendedStartTime = moment(
      convertToTimeZone(moment(recommendedStartTime).format(), floristTimeZone)
    );
    const currentFloristTimewithBuffer = moment(
      convertToTimeZone(moment.utc().format(), floristTimeZone)
    ).add(timedOrderBuffer || 20, "minutes");
    const localDepartureTime = moment(
      convertToTimeZone(departureTime, floristTimeZone)
    );

    const startTime =
      departureTime && routeStatus === "INPROGRESS"
        ? localDepartureTime
        : currentFloristTimewithBuffer;

    const isDelayed =
      moment(startTime).isAfter(localRecommendedStartTime) &&
      recommendedStartTime &&
      ["DRAFT", "PLANNED", "INPROGRESS"].includes(routeStatus);
    let updatedDashboardData = cloneDeep(deliveryDashboardData);

    let routeData = {
      ...updatedDashboardData.routes[index],
      displayName: getRouteDisplayName(routeFriendlyName, routeName),
      routeName,
      routeStatus,
      deliverBy,
      isDelayed,
      localRecommendedStartTime,
      routeMemberCodes: memberCodes,
    };
    updatedDashboardData.routes[index] = routeData;

    yield put(setDeliveryDashboardData(updatedDashboardData));
  } catch (error) {
    console.log(error);
  } finally {
    setLoading && setLoading(false);
  }
}

export function* handleFetchZones(action = {}) {
  const {
    shopCode,
    isSingleShop = false,
    setLoading,
    deliveryDashboardController,
  } = get(action, "payload", {});

  if (shopCode) {
    const serviceRequest = (params) =>
      request("get-autoroutes-data", params, deliveryDashboardController);
    try {
      const response = yield call(serviceRequest, {
        shopCode,
      });
      const zonesList = yield select(selectZonesData) || {};

      if (response?.length) {
        yield put(
          setZones(
            isSingleShop
              ? { [shopCode]: response }
              : { ...zonesList, [shopCode]: response }
          )
        );
      } else {
        yield put(
          setZones(
            isSingleShop ? { [shopCode]: [] } : { ...zonesList, [shopCode]: [] }
          )
        );
        if (isSingleShop)
          yield put(setDashboardCurrentTabKey("dashboard-routes-tab"));
        setLoading && setLoading(false);
      }
    } catch (error) {
      if (isSingleShop)
        yield put(setDashboardCurrentTabKey("dashboard-routes-tab"));
    } finally {
      setLoading && setLoading(false);
    }
  }
}
