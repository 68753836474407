import { takeLatest, put, call } from "redux-saga/effects";
import { request } from "library/utils/request";
import {
  processStaffListResponse,
  refineStaff,
} from "library/utils/staffListing";
import {
  processFTDStaffListResponse,
  refineUserAccounts,
} from "library/utils/ftdUsersListing";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import {
  setData,
  setLoading,
  fetchData,
  fetchFtdAdminData,
  fetchUserRolesPermissions,
  setRolesPermissions,
  updateUserPreferences,
} from "library/sagas/ongoing/staff-listing/slice";
import UserProfileStorage from "library/storage/userProfile";

const serviceRequest = (params) => request("get-staff-list-by-access", params);

const ftdAdminServiceRequest = (params) => request("get-staff-list", params);

const getUserRolesPermissionsServiceRequest = () =>
  request("get-user-roles-permissions", { roles: "all" });

const updateUserPreferencesServiceRequest = (params) =>
  request("edit-user-profile", params);

const applySearchFilters = (staffList, actions, listingType) => {
  const refinedList = cloneDeep(staffList);
  return listingType === "ftd-user-accounts"
    ? refineUserAccounts(refinedList, actions)
    : refineStaff(refinedList, actions);
};

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleStaffListing(action = {}) {
  try {
    const { actions, listingType, params } = get(action, "payload", {});

    const authGroup = yield UserProfileStorage.getAuthGroup();
    const {
      memberCodes: profileMemberCodes = [],
      roles = [],
      shopGroups = [],
    } = authGroup;

    const proxyUser = yield UserProfileStorage.getProxyUser();

    let selectedMemberCodes = params.selectedMemberCodes;

    let selectedShopGroupName = "";
    if (roles[0] === "ADMIN") {
      if (proxyUser && shopGroups[0] === "FTD_ALL_SHOPS") {
        selectedShopGroupName = "";
        selectedMemberCodes = profileMemberCodes;
      } else {
        selectedShopGroupName = shopGroups[0];
        selectedMemberCodes =
          selectedMemberCodes.length > 1
            ? selectedMemberCodes.join(",")
            : selectedMemberCodes;
      }
    } else {
      selectedMemberCodes =
        selectedMemberCodes.length > 0
          ? selectedMemberCodes.length > 1
            ? selectedMemberCodes.join(",")
            : selectedMemberCodes
          : profileMemberCodes;
    }

    const response = yield call(serviceRequest, {
      selectedMemberCodes,
      shopGroupName: encodeURIComponent(selectedShopGroupName),
      status: "ALL",
    });

    const updatedResp = processStaffListResponse(response);

    yield put(
      setData({
        data: applySearchFilters(updatedResp, actions, listingType),
      })
    );
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFtdAdminListing(action = {}) {
  try {
    const { actions, listingType } = get(action, "payload", {});

    const response = yield call(ftdAdminServiceRequest, {
      memberCode: "FTD",
    });

    const updatedResp = processFTDStaffListResponse(response);

    yield put(
      setData({
        data: applySearchFilters(updatedResp, actions, listingType),
      })
    );
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}

function* handleGetUserRolesPermissions() {
  try {
    const response = yield call(getUserRolesPermissionsServiceRequest);

    const allRolesPermissions = response.filter(
      (rolePermissions) => rolePermissions.role !== "ADMIN"
    );

    yield put(
      setRolesPermissions({
        data: allRolesPermissions,
      })
    );
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}

function* handleUpdateUserPreferences(action = {}) {
  const {
    reqPayload = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});

  try {
    const response = yield call(
      updateUserPreferencesServiceRequest,
      reqPayload
    );
    resolve(response);
  } catch (error) {
    reject(error);
    console.log("updateUserPreferences error:", error);
  }
}

/**
 * WATCHER: Subscribe to Page actions
 */
export default function* watchSaga() {
  yield takeLatest(fetchData.type, handleStaffListing);
  yield takeLatest(fetchFtdAdminData.type, handleFtdAdminListing);
  yield takeLatest(
    fetchUserRolesPermissions.type,
    handleGetUserRolesPermissions
  );
  yield takeLatest(updateUserPreferences.type, handleUpdateUserPreferences);
}
