import { select, put, call, delay } from "redux-saga/effects";

import { InitOrdersData } from "../constants";
import {
  setPageData,
  setApiError,
  setCurrentCustomer,
  setOrderAction,
  fetchOrderDetails,
  setCustomerOrders,
  fetchCustomerDetails,
  fetchAllCustomers,
  // fetchCustomerReceivePayments,
  //fetchCustomerPaidOrders,
  fetchCustomerActivityDetails,
  setHouseAccount,
  setOpenItemUnpaidOrders,
  setBalanceForwardPaymentPreview,
  setFullyPaidOrders,
  setCurrentCustomerViewedAt,
  setCustomerSubscriptions,
} from "../slice";
import {
  selectCurrentCustomerId,
  selectCurrentHouseAccountId,
  selectCustomerOrders,
  selectMeatBallAction,
  selectStoreOrigin,
  selectCustomerSubscriptions,
  selectCustomerActivityInfo,
  selectCustomersToMerge,
  selectCurrentCustomerIdViewedAt,
} from "../selector";
import { PrintIframeAction } from "components/elements";
import { getActionObj } from "components/views/drawer/order-details/helper";
import { getSingleOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";

import {
  processOrderResponse,
  isDSSubmittedOrder,
} from "library/utils/orderListing";
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";
import { isMHQNonCoreMember } from "library/utils/entitlements";

import get from "lodash/get";
import omit from "lodash/omit";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { menuActions, meatBallActions } from "../constants";
import { Platform } from "react-native";
import { processSelectedProductResponse } from "library/utils/productDetails";
import { getRandomInt, checkForNA } from "library/utils/payment-options";
import {
  generateQRCode,
  setShowOrderUnpaidModal,
} from "library/sagas/ongoing/order-details/slice";
import {
  setDconStatus,
  updateAutoPrintOrders,
} from "library/sagas/ongoing/current-orders/slice";
import {
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";
import {
  hasRemotePrintEnabled,
  showPrintToaster,
  getRemotePrinterName,
} from "components/views/drawer/order-details/order-head/take-action/helper";
import { getShopSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";

let addressSuggestionsController;

const mappings = {
  CASH_OR_CHECK: "Paid with Cash/Check for Orders:",
  CASH: "Paid with Cash:",
  CHECK: "Paid with Check:",
  PAID_ELSEWHERE: "Paid with Paid Elsewhere:",
  CREDIT_CARD: "Paid with Credit Card:",
  SAVED_CARD: "Saved Payment",
  CREDIT_MEMO: "Credit Memo Issued",
  DEBIT_MEMO: "Debit Memo Issued",
  GIFT_CARD: "Gift Card",
  AUTO_ADJUSTED: "Order auto adjusted",
  BALANCE_DUE: "Balance due updated with",
};

const paymentTypeMapping = {
  CASH_OR_CHECK: "Cash/Check",
  CASH: "Cash",
  CHECK: "Check",
  PAID_ELSEWHERE: "Paid Elsewhere",
  CREDIT_CARD: "Credit Card",
  SAVED_CARD: "Saved Payment",
  CREDIT_MEMO: "Credit Memo",
};

export function* handleFetchCustomerReceivePayments({ payload = {} }) {
  const { resolve, reject, memberCode } = payload;
  const serviceRequest = (houseAccountId, storeOrigin) =>
    request("get-customer-receive-payments", { houseAccountId, storeOrigin });
  const storeOrigin = yield select(selectStoreOrigin);
  const houseAccountId = yield select(selectCurrentHouseAccountId);
  if (!houseAccountId) return;

  try {
    const response = yield call(serviceRequest, houseAccountId, storeOrigin);
    const receivePayments = processHouseAccountPaymentDetails(
      response,
      memberCode
    );

    yield put(
      setPageData({
        receivePayments,
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setPageData({
        receivePayments: [],
      })
    );
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCustomerSubscriptions({ payload }) {
  const { resolve, reject } = payload;
  const serviceRequest = (customerId) =>
    request("get-customer-subscriptions", { customerId });

  try {
    const customerId = yield select(selectCurrentCustomerId);
    const result = yield call(serviceRequest, customerId);

    const subscriptions = result?.subscriptions?.length
      ? result?.subscriptions
      : [];

    yield put(
      setPageData({
        subscriptions,
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "subscriptions",
        error: "Unable to pull customer subscriptions, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCustomerStatement({ payload }) {
  const { resolve, reject, month, year } = payload;
  const serviceRequest = (month, year, houseAccountId) =>
    request("house-account-statements", { month, year, houseAccountId });

  try {
    const houseAccountId = yield select(selectCurrentHouseAccountId);
    const response = yield call(serviceRequest, month, year, houseAccountId);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleFetchCustomerDetails({ payload }) {
  const { storeOrigin, resolve, reject, isCurrentCustomer = true } = payload;
  const serviceRequest = (customerId) =>
    request("get-customer-details", { customerId, storeOrigin });

  try {
    const id = yield select(selectCurrentCustomerId);
    const customerId = payload.customerId || id || "";
    const response = yield call(serviceRequest, customerId);

    if (!isCurrentCustomer) {
      const customersToMerge = yield select(selectCustomersToMerge);
      yield put(
        setPageData({ customersToMerge: [...customersToMerge, response] })
      );
      resolve && resolve(response);
      return;
    }

    const customerDetails = processCustomerDetailsResponse({ response });
    yield put(
      setPageData({
        customerDetails,
        originalCustomerDetails: response,
      })
    );
    yield put(fetchCustomerActivityDetails());
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(error);
  }
}

export function* handleFetchOpenItemUnpaidOrders({ payload = {} }) {
  const { resolve, reject, storeOrigin, shopCode, houseAccountId } = payload;

  const serviceRequest = (offset) =>
    request("get-open-item-unpaid-orders", {
      storeOrigin,
      shopCode,
      houseAccountId,
    });

  try {
    const response = yield call(serviceRequest);
    yield put(setOpenItemUnpaidOrders(response));
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchBalanceForwardPaymentPreview({ payload = {} }) {
  const { resolve, reject, storeOrigin, params } = payload;

  const serviceRequest = () =>
    request("get-balance-forward-payment-preview", {
      storeOrigin,
      ...params,
    });

  try {
    const response = yield call(serviceRequest);
    yield put(setBalanceForwardPaymentPreview(response));
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCustomerPaidOrders({ payload = {} }) {
  const { resolve, reject, shopCode } = payload;
  const serviceRequest = (houseAccountId, storeOrigin) =>
    request("get-customer-ha-paid-orders", {
      houseAccountId,
      storeOrigin,
      shopCode,
    });
  const storeOrigin = yield select(selectStoreOrigin);
  const houseAccountId = yield select(selectCurrentHouseAccountId);
  if (!houseAccountId) return;

  try {
    const response = yield call(serviceRequest, houseAccountId, storeOrigin);
    yield put(setFullyPaidOrders(response));
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCustomerActivityDetails({ payload = {} }) {
  const {
    resolve,
    reject,
    offset = 0,
    startDate = "",
    endDate = "",
    feedType = "",
  } = payload;

  const serviceRequest = (
    customerId,
    offset,
    feedType,
    startDate,
    endDate,
    userTimeZone
  ) =>
    request("get-customer-activities", {
      customerId,
      offset,
      feedType,
      startDate,
      endDate,
      userTimeZone,
    });
  const userTimeZone = encodeURIComponent(moment.tz.guess());

  try {
    const customerId = yield select(selectCurrentCustomerId);
    const customerActivityInfo = yield select(selectCustomerActivityInfo);

    const response = yield call(
      serviceRequest,
      customerId,
      offset,
      feedType,
      startDate,
      endDate,
      userTimeZone
    );

    yield put(
      setPageData({
        activityInfo: {
          ...response,
          activities:
            offset === 0
              ? response.activities
              : [...customerActivityInfo.activities, ...response.activities],
          startDate,
          endDate,
          feedType,
        },
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCustomerOrders({ payload }) {
  const { resolve, reject } = payload;
  const serviceRequest = (customerId, storeOrigin) =>
    request("get-customer-orders", { customerId, storeOrigin });

  try {
    const customerId = yield select(selectCurrentCustomerId);
    const storeOrigin = yield select(selectStoreOrigin);
    const ordersList = yield call(serviceRequest, customerId, storeOrigin);

    const {
      orderCollection: {
        customerOrders: { orders },
      },
    } = processOrderResponse(ordersList, InitOrdersData, "customerOrders");

    orders.sort(
      (a, b) =>
        new Date(b.displayDeliveryDate) - new Date(a.displayDeliveryDate)
    );

    yield put(
      setPageData({
        orders: orders,
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customers",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleOrderMeatBallAction() {
  try {
    const orders = yield select(selectCustomerOrders);
    const { event, recordId, hasPendingPayLaterPayment, sourceMemberCode } =
      yield select(selectMeatBallAction);
    const { isPickupOrder } = orders.find(
      (item) => item.orderItemId === recordId
    );
    const isMHQNonCoreUser = isMHQNonCoreMember(sourceMemberCode);
    if (
      ["delivery-confirmation", "delivery-exception"].includes(event) &&
      hasPendingPayLaterPayment
    ) {
      yield put(setShowOrderUnpaidModal(true));
    } else if (
      meatBallActions.includes(event) ||
      (isPickupOrder && ["delivery-confirmation"].includes(event)) ||
      ["markAsRead"].includes(event)
    ) {
      const isPrintOrder = event === "accept-print" || event === "printOrder";
      const isFetchOrderDetails = isPrintOrder || event === "copy-order";
      if (isPrintOrder) {
        // fetching latest printer settings for supporting remote print
        yield put(
          getShopSettings({
            params: {
              memberCode: sourceMemberCode,
              ids: isMHQNonCoreUser
                ? PrinterSettingsKeys
                : nonStandardEligiblePrinterSettings,
              updateStorage: true,
            },
          })
        );
      }

      if (isFetchOrderDetails) {
        yield put(fetchOrderDetails());
      } else {
        yield put(setOrderAction());
      }
    } else if (menuActions.includes(event)) {
      //set delivery status as Attempted delivery
      if (["delivery-confirmation", "delivery-exception"].includes(event)) {
        yield put(setDconStatus(event));
      }
      // Set selected menu event & open order details side car
      yield put(
        setCurrentCustomer({
          type: "menuAction",
          value: event + recordId,
        })
      );
      yield put(
        setCurrentCustomer({
          type: "orderId",
          value: recordId,
        })
      );
    }
  } catch (error) {
    yield put(
      setApiError({
        section: "orders",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSetOrderActions() {
  const orders = yield select(selectCustomerOrders);
  const { event, recordId } = yield select(selectMeatBallAction);
  const {
    deliveryDate,
    deliveryMethod,
    sourceMemberCode,
    dsEligible,
    autoSendToDS,
    deliveryType,
  } = orders.find((item) => item.orderItemId === recordId);
  const isDSSubmitEligible =
    dsEligible && autoSendToDS && !isDSSubmittedOrder(deliveryType);
  const action =
    event === "printOrder" ? "print" : event === "markAsRead" ? "read" : event;
  const { firstName: operator } = UserProfileStorage.getUser();
  const requestMethod =
    action === "read" ? "order-message-actions" : "order-actions";
  const isMarkRead = event === "markAsRead";
  const commonParams = {
    recordId,
    deliveryMethod,
    sourceMemberCode,
  };
  let shopTimeZone =
    UserProfileStorage.getZFormatShopTimeZone(sourceMemberCode);
  const reqParams = {
    ...commonParams,
    ...(requestMethod === "order-message-actions"
      ? { markAsRead: isMarkRead, isStructured: true, isUnstructured: true }
      : {
          action,
          ...getActionObj(deliveryDate, action, "", "", shopTimeZone),
          operator,
        }),
  };
  const serviceRequest = () => request(requestMethod, reqParams);

  try {
    yield call(serviceRequest);
    yield delay(1000);

    if (isDSSubmitEligible) {
      yield put(
        setCurrentCustomer({
          type: "recordAction",
          value: `${action}${recordId}`,
        })
      );
      yield put(
        setCurrentCustomer({
          type: "orderId",
          value: recordId,
        })
      );
    }
    yield put(setCustomerOrders({ setEmpty: false }));
  } catch (error) {
    yield put(
      setApiError({
        section: "orders",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchOrderDetails() {
  const orders = yield select(selectCustomerOrders);
  const {
    event,
    recordId,
    messages,
    Localise,
    redirectToCreateOrder,
    dispatch,
  } = yield select(selectMeatBallAction);
  const { deliveryMethod, sourceMemberCode } = orders.find(
    (item) => item.orderItemId === recordId
  );

  const { firstName: operator } = UserProfileStorage.getUser();

  const serviceRequest = () =>
    request("order-details", {
      recordId,
      deliveryMethod,
      sourceMemberCode,
    });

  try {
    const result = yield call(serviceRequest);
    const { orderItems = [], customerInfo = {}, orderId = "" } = result || {};
    const { lineItems, multiProductEligible = false } = orderItems[0];

    const products = multiProductEligible
      ? lineItems.filter((lineItem) => lineItem.type === "Product")
      : lineItems;

    if (result && result.orderItems) {
      if (event === "copy-order") {
        redirectToCreateOrder(result);
      } else {
        const {
          orderSource = "",
          orderItemId = "",
          erosOrderNumber = "",
          deliveryInfo = {},
          receivingMember = {},
          sendingMember = {},
          isPrinted = false,
          direction = "",
        } = orderItems[0];
        const {
          order_details_print_settings = [],
          order_details_outgoing_print_settings = [],
          qr_code_global_enabled = "",
          qr_code_order_details_enabled = "",
        } = UserProfileStorage.getShopPreferences(sourceMemberCode);
        const showQrCode = qr_code_global_enabled === "true";
        const orderDetailQrCode = qr_code_order_details_enabled === "true";
        const showOrderDetailQrCode = showQrCode ? orderDetailQrCode : false;
        let qrImageData = "";

        const isElectronApp =
          Platform.OS === "web" &&
          document.getElementById("isElectronApp").value === "true";

        const macAddress =
          isElectronApp && document.getElementById("macAddress").value;

        // QR Data Payload for generating the QR code
        const qrData = [
          {
            oid: orderItemId || "", // orderItemId
            sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
            dm: deliveryMethod || "", // order deliveryMethod
          },
        ];

        const isInBoundOrder = direction === "INBOUND";
        const isOutBoundOrder = direction === "OUTBOUND";

        const printPreferencesArray = isInBoundOrder
          ? order_details_print_settings
          : isOutBoundOrder
          ? order_details_outgoing_print_settings
          : [];

        const isRemotePrintEnabled = hasRemotePrintEnabled(
          printPreferencesArray
        );

        const remotePrinterName = isRemotePrintEnabled
          ? getRemotePrinterName(printPreferencesArray)
          : "";

        const printPreferences =
          (isElectronApp &&
            (printPreferencesArray || []).find(
              (each) => each.macAddress === macAddress
            )) ||
          {};

        const updateAutoPrint = (remotePrint = false) => {
          if (!remotePrint) {
            if (isElectronApp && !isEmpty(printPreferences)) {
              showPrintToaster({
                remotePrint,
                printerName: printPreferences.printer,
                artifactName: "Order Details",
                Localise,
                messages,
              });
            }
            if (isPrinted) return;
          }
          dispatch(
            updateAutoPrintOrders({
              ...(remotePrint && { remotePrintRequest: true }),
              payload: [
                {
                  memberCode: sourceMemberCode,
                  autoPrintOrders: [
                    {
                      orderId: orderId || orderItemId,
                      orderItemId,
                      orderNumber: erosOrderNumber,
                      isPrinted: true,
                      deliveryMethod: deliveryInfo?.deliveryMethod,
                      sendingMemberCode: sendingMember?.memberCode,
                      fillingMemberCode: receivingMember?.memberCode,
                      isAutoPrint: false,
                    },
                  ],
                },
              ],
              resolve: () => {
                if (remotePrint) {
                  showPrintToaster({
                    remotePrint,
                    printerName: remotePrinterName,
                    artifactName: "Order Details",
                    Localise,
                    messages,
                  });
                  return;
                }
                dispatch(setCustomerOrders({ setEmpty: false }));
              },
            })
          );
        };

        const orderDetailPrintTrigger = () => {
          if (products.length) {
            let promises = [];
            products.forEach((product) => {
              const productId = product?.productFirstChoiceCode || "";
              if (productId) {
                const productResp = request("get-product-details", {
                  productId: productId.toUpperCase(),
                  siteId: sourceMemberCode,
                  orderSource:
                    orderSource === "FLORIST" &&
                    deliveryMethod !== "FLORIST_PARTNER"
                      ? "LOCAL"
                      : orderSource,
                });
                promises.push(productResp);
              }
            });

            Promise.all(promises).then((responses = []) => {
              let productsInfo = [];

              responses.map((productResp) => {
                if (productResp?.products?.length > 0) {
                  const productInfo = processSelectedProductResponse(
                    (productResp && productResp.products) || []
                  );
                  productsInfo.push(productInfo);
                }
              });

              const macAddress =
                Platform.OS === "web"
                  ? document.getElementById("macAddress").value
                  : "";

              const printPreferences =
                (printPreferencesArray || []).find(
                  (each) => each.macAddress === macAddress
                ) || {};

              if (isRemotePrintEnabled) {
                updateAutoPrint(true);
                return;
              }

              PrintIframeAction(
                getSingleOrderPrintHTML({
                  orderDetails: orderItems[0],
                  memberCodes: sourceMemberCode,
                  operatorName: operator,
                  messages,
                  Localise,
                  selectedproducts: productsInfo,
                  customerInfo,
                  qrImageData,
                }),
                {
                  printType: "orderDetails",
                  preferences: printPreferences,
                },
                updateAutoPrint
              );
            });
          }
        };
        showOrderDetailQrCode
          ? yield put(
              generateQRCode({
                params: { qrPayload: qrData },
                type: "SINGLE",
                resolve: (resp) => {
                  qrImageData = resp[orderItemId] || "";
                  orderDetailPrintTrigger();
                },
                reject: () => {
                  orderDetailPrintTrigger();
                },
              })
            )
          : orderDetailPrintTrigger();
      }
    }
  } catch (error) {
    console.log("Error -- ", error);
    yield put(
      setApiError({
        section: "orders",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveCustomerInfo(action = {}) {
  const serviceRequest = (payload) => request("upsert-customer", payload);
  const { resolve, reject, params } = get(action, "payload", {});
  const isCreate = get(params, "isCreate", false);
  const storeOrigin = get(params, "storeOrigin", "");

  try {
    const viewedAt = yield select(selectCurrentCustomerIdViewedAt);
    const payload = prepareSaveCustomerInfoPayload({ ...params, viewedAt });
    const { id } = yield call(serviceRequest, payload);

    if (isCreate) {
      yield put(
        setCurrentCustomer({
          type: "customerId",
          value: id,
          viewedAt: moment().utc().toISOString(), // update viewedAt after successful save
        })
      );
      resolve && resolve(id);
    } else {
      yield put(setCurrentCustomerViewedAt(moment().utc().toISOString())); // update viewedAt after successful save
      yield put(
        fetchCustomerDetails({
          storeOrigin,
          customerId: id,
          resolve: () => resolve && resolve(id),
          reject,
        })
      );
      yield put(fetchAllCustomers());
    }
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject &&
      reject(
        get(error, "errors.0.message", "").includes("CUSTOMER_ALREADY_EXISTS")
          ? "Customer with same email or phone already exists. Please refine your details & try again"
          : get(error, "errors.0.message", "")
      );
  }
}

export function* handleSaveCustomerHouseAccount(action = {}) {
  const createServiceRequest = (payload) =>
    request("create-house-account", payload);

  const updateServiceRequest = (payload) =>
    request("update-house-account", payload);

  const { resolve, reject, params } = get(action, "payload", {});
  const isCreate = params.houseAccountId === "";

  try {
    const response = yield call(
      isCreate ? createServiceRequest : updateServiceRequest,
      isCreate
        ? {
            ...params,
            creditLimit: params?.creditLimit || "500.00",
          }
        : params
    );
    yield put(setHouseAccount(processHouseAccountResponse(response, params)));
    resolve && resolve(isCreate);
  } catch (error) {
    let message = `Failed to ${isCreate ? `create` : `update`} house account.`;
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(error || message);
  }
}

export function* handleSaveCustomerPayment(action = {}) {
  const serviceRequest = (payload) =>
    request("save-customer-credit-card", payload);
  const tokenRequest = (requestPayload) =>
    request("tokenize-credit-card", { requestPayload });
  const { resolve, reject, params } = get(action, "payload", {});
  const storeOrigin = get(params, "storeOrigin", "");
  const isCreate = get(params, "isCreate", false);

  // Generating merchant reference id like below as Heartland is accepting merchantRefId's in this pattern [0-9A-Za-z_-]+
  // If we are sending customerId as merchant reference id from Terminal then Heartland won't accept it as customerId is alpha numeric
  const merchantReferenceId = `${moment()}${getRandomInt(0, 9)}`;

  try {
    const requestPayload = getTokenizePaymentPayload(
      params,
      merchantReferenceId
    );
    const {
      tokenId = "",
      errorMessages = [],
      status = "",
    } = yield call(tokenRequest, requestPayload);

    if (status === "declined") {
      throw { type: "CARD_DECLINED", message: errorMessages[0] };
    } else {
      const payload = prepareSaveCustomerPaymentPayload(
        params,
        tokenId,
        merchantReferenceId
      );
      yield call(serviceRequest, payload);
    }
    if (isCreate) {
      yield put(setCurrentCustomer());
    } else {
      yield put(fetchCustomerDetails({ storeOrigin }));
    }

    resolve && resolve();
  } catch (error) {
    let message = "";

    if (get(error, "errors.0.key", "").includes("INVALID_EXPIRY_DATE")) {
      message = "Invalid expiry date provided";
    } else if (
      get(error, "errors.0.key", "").includes("MISSING_EMAILID_FOR_SAVECC")
    ) {
      message =
        "Please provide an email in customer info to save a credit card";
    } else if (get(error, "type", "").includes("CARD_DECLINED")) {
      message = get(error, "message", "Something went wrong, please try again");
    } else if (error && error === "CREDIT_CARD_PROCESS_FAILURE") {
      message =
        "Unable to save card as credit card payment type is disabled. Please contact administrator";
    }

    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(message);
  }
}

export function* handleSaveCreditDebitMemoHouseAccount(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const serviceRequest = (payload) =>
    request("save-house-account-credit-debit-memo", payload);
  const storeOrigin = yield select(selectStoreOrigin);

  try {
    yield call(serviceRequest, params);
    yield put(fetchCustomerDetails({ storeOrigin }));

    resolve && resolve();
  } catch (error) {
    let message = error || "";

    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(message);
  }
}

export function* handleSaveCustomerReceivePayment(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const serviceRequest = (payload) =>
    request("save-customer-receive-payment", payload);
  const storeOrigin = yield select(selectStoreOrigin);

  try {
    const response = yield call(serviceRequest, params);
    // yield put(fetchCustomerReceivePayments());
    // yield put(fetchCustomerPaidOrders());
    yield put(fetchCustomerDetails({ storeOrigin }));

    resolve && resolve(response);
  } catch (error) {
    let message = error || "";

    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(message);
  }
}

export function* handleFetchAddressSuggestions(action = {}) {
  const { addressLine1 = "", country = "" } = get(action, "payload", {});

  if (
    (country === "US" || country === "CA") &&
    addressLine1.trim().length > 2
  ) {
    const payload = {
      addressLine1,
      aptCode: "",
      cityLocality: "",
      stateProvince: "",
      postalCode: "",
      maxRecords: "10",
      resultDetails: [],
      countryRegion: country,
    };

    addressSuggestionsController && addressSuggestionsController.abort();
    addressSuggestionsController = new AbortController();

    const serviceRequest = (payload) =>
      request("get-address-suggestions", payload, addressSuggestionsController);

    try {
      const result = yield call(serviceRequest, payload);

      if (result?.addresses?.length) {
        yield put(
          setCurrentCustomer({
            type: "addressSuggestions",
            value: result.addresses,
          })
        );
      } else {
        yield put(
          setCurrentCustomer({
            type: "addressSuggestions",
            value: [],
          })
        );
      }
    } catch (error) {
      yield put(
        setApiError({
          section: "customerDetails",
          error: "Something went wrong, please try again",
        })
      );
    }
  }
}

export function* handleDeleteCustomerCreditcard(action = {}) {
  const {
    resolve,
    reject,
    params: { customerId, creditCardId, storeOrigin },
  } = get(action, "payload", {});
  const serviceRequest = () =>
    request("delete-customer-credit-card", {
      customerId,
      creditCardId,
      storeOrigin,
    });
  try {
    yield call(serviceRequest);
    yield put(fetchCustomerDetails({ storeOrigin }));
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleDeleteSubscriptionTemplate(action = {}) {
  const {
    resolve,
    reject,
    params: { shopCode, customerId, subscriptionId },
  } = get(action, "payload", {});

  const serviceRequest = (shopCode, customerId, subscriptionId) =>
    request("delete-subscription-template", {
      shopCode,
      customerId,
      subscriptionId,
    });

  try {
    yield call(serviceRequest, shopCode, customerId, subscriptionId);

    const customerSubscriptions = yield select(selectCustomerSubscriptions);

    let currentSubscriptions = customerSubscriptions.filter(
      (item) => item.id != subscriptionId
    );

    yield put(
      setPageData({
        subscriptions: currentSubscriptions,
      })
    );

    resolve & resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "subscriptions",
        error: "Unable to cancel subscription, please try again",
      })
    );
    reject && reject();
  }
}

export function* handlePauseSubscriptionTemplate(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const pauseSubscriptionRequest = (params) =>
    request("pause-subscription-template", params);

  const updatePauseSubscriptionRequest = (params) => {
    request("update-pause-subscription-template", params);
  };

  const isModifyPause = !isEmpty(params?.pauseId || "");

  try {
    const response = yield call(
      isModifyPause ? updatePauseSubscriptionRequest : pauseSubscriptionRequest,
      params
    );
    yield put(
      setCustomerSubscriptions({
        resolve: () => resolve && resolve(response),
      })
    );
  } catch (error) {
    console.log("error :>> ", error);
    reject && reject(error);
  }
}

export function* handleResumeSubscriptionTemplate(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const resumeSubscriptionRequest = (params) =>
    request("resume-subscription-template", params);

  try {
    const response = yield call(resumeSubscriptionRequest, params);
    yield put(
      setCustomerSubscriptions({
        resolve: () => resolve && resolve(response),
      })
    );
  } catch (error) {
    console.log("error :>> ", error);
    reject && reject(error);
  }
}

export function* handleSendEmailReceipts(action = {}) {
  const { payload = {}, resolve, reject } = get(action, "payload", {});
  const postPaymentReceiptReq = (params) =>
    request("post-payment-receipt-request", params);

  try {
    const res = yield call(postPaymentReceiptReq, payload);
    yield put(fetchCustomerDetails({ storeOrigin: payload?.shopCode }));

    resolve && resolve(res);
  } catch (error) {
    reject && reject();
    console.log(error);
  }
}

const processHouseAccountResponse = (response = {}, params = {}) => {
  const {
    houseAccountId,
    isActive,
    accountType,
    creditLimit = 0,
    autoEmailStatements,
    autoEmailInvoices,
    createdOn,
  } = response;

  return {
    ...omit(params, ["storeOrigin"]),
    houseAccountId,
    isActive,
    accountType,
    creditLimit: parseFloat(creditLimit).toFixed(2),
    autoEmailStatements,
    autoEmailInvoices,
    createdOn,
  };
};

export const processCustomerDetailsResponse = ({
  response = {},
  filterAddresses = true,
}) => {
  const isEmailRequired =
    get(response, "paymentInfo.0.lastFourDigits", "") !== "";
  const isAddressRequired = get(response, "addresses.0.addressId", "") !== "";
  const {
    expirationMonth = "",
    expirationYear = "",
    lastFourDigits = "",
  } = get(response, "paymentInfo.0", {});
  const expiration = expirationMonth + "/" + expirationYear;

  const cardNumber =
    lastFourDigits !== "" ? "**** **** **** " + lastFourDigits : "";

  if (filterAddresses) {
    response.addresses = get(response, "addresses", []).filter(
      (address) => address.isPrimary
    );
  }

  const customerDetails = get(response, "customerDetails");
  return {
    customerId: get(response, "customerId", ""),
    customerType: get(response, "isBusinessProfile", "").toString(),
    firstName: get(response, "firstName", ""),
    lastName: get(response, "lastName", ""),
    businessName: get(response, "businessName", ""),
    phone: phoneNumberFormatter(get(response, "phones.0", "")),
    email: get(response, "email", ""),
    isAddressRequired,
    address: {
      addressId: get(response, "addresses.0.addressId", ""),
      addressType: get(response, "addresses.0.addressType", "Billing"),
      country: get(response, "addresses.0.country", "US"),
      addressLine1: get(response, "addresses.0.addressLine1", ""),
      addressLine2: get(response, "addresses.0.addressLine2", ""),
      city: get(response, "addresses.0.city", ""),
      state: get(response, "addresses.0.state", ""),
      zipcode: get(response, "addresses.0.zipcode", ""),
      isPrimary: get(response, "addresses.0.isPrimary", true),
    },
    customerContacts: get(response, "customerContacts", []),
    customerDetails,
    ...customerDetails,
    taxExemptCode: get(response, "taxExemptCode", ""),
    discountType: get(response, "discountType", ""),
    discountPercentage: get(response, "discountPercentage", "").toString(),
    referralType: get(response, "referralType", ""),
    customerNotes: get(response, "customerNotes", ""),
    emailOptIn: true,
    smsOptIn: get(response, "smsOptIn", false),
    smsProviderOptIn: get(response, "smsProviderOptIn", false),
    smsMarketingOptIn: get(response, "smsMarketingOptIn", false),
    emailMarketingOptIn: get(response, "emailOptIn", true), // emailOptIn from API is mapped to emailMarketingOptIn at UI,
    storeOrigin: get(response, "storeOrigin", ""),
    createdBy: get(response, "createdBy", ""),
    updatedBy: get(response, "updatedBy", ""),
    createdOn: moment(
      get(response, "createdOn", ""),
      "YYYY-MM-DDTHH:mm:ss"
    ).format("MM/DD/YYYY"),
    updatedOn: moment(
      get(response, "updatedOn", ""),
      "YYYY-MM-DDTHH:mm:ss"
    ).format("MM/DD/YYYY"),
    orderNotifications: {
      email: false,
      sms: false,
    },
    marketingNotifications: {
      email: false,
      sms: false,
    },
    isEmailRequired,
    paymentInfo: {
      nameOnCard: get(response, "paymentInfo.0.nameOnCard", ""),
      creditCardId: get(response, "paymentInfo.0.creditCardId", ""),
      cardNumber,
      lastFourDigits,
      cardType: get(response, "paymentInfo.0.cardType", ""),
      cvvNumber: get(response, "paymentInfo.0.cvvNumber", ""),
      expiration: expiration !== "/" ? expiration : "",
      expirationMonth,
      expirationYear,
      sameAsMailingAddress: false,
      tokenId: get(response, "paymentInfo.0.tokenId", ""),
      billingAddress: {
        addressLine1: get(
          response,
          "paymentInfo.0.billingAddress.addressLine1",
          ""
        ),
        addressLine2: get(
          response,
          "paymentInfo.0.billingAddress.addressLine2",
          ""
        ),
        country: get(response, "paymentInfo.0.billingAddress.country", ""),
        city: get(response, "paymentInfo.0.billingAddress.city", ""),
        state: get(response, "paymentInfo.0.billingAddress.state", ""),
        zipcode: get(response, "paymentInfo.0.billingAddress.zipcode", ""),
      },
    },
    houseAccountInfo: response.houseAccountInfo
      ? {
          ...response.houseAccountInfo,
          creditLimit: parseFloat(
            get(response, "houseAccountInfo.creditLimit", 0)
          ).toFixed(2),
        }
      : undefined,
  };
};

const processHouseAccountPaymentDetails = (response = {}, memberCode = "") => {
  const filteredResponse = response?.filter(
    (data) => data?.memberCode === memberCode
  );
  const output = filteredResponse?.map((paymentInfo) => {
    const {
      isCreditBalance,
      paymentReceivedType,
      paymentReceivedDate,
      paymentAmount,
      notes,
      isAutoAdjustPayment,
      ordersInfo = [],
      id = "",
      memberCode = "",
    } = paymentInfo;
    const creditBalancePaymentType =
      paymentReceivedType === "CREDIT_BALANCE" && isCreditBalance
        ? "Credit Balance Added"
        : isCreditBalance
        ? `Credit Balance Added with ${paymentTypeMapping[paymentReceivedType]}`
        : "Paid with Credit Balance for Orders:";

    return {
      date: moment(paymentReceivedDate).format("MM/DD/YYYY"),
      paymentType:
        ordersInfo?.length === 0 && !isCreditBalance
          ? `${mappings["BALANCE_DUE"]} ${paymentTypeMapping[paymentReceivedType]}`
          : paymentReceivedType === "CREDIT_BALANCE" || isCreditBalance
          ? creditBalancePaymentType
          : isAutoAdjustPayment
          ? mappings["AUTO_ADJUSTED"]
          : mappings[paymentReceivedType],
      amountPaid: `${parseFloat(paymentAmount).toFixed(2)}`,
      ordersInfo: ordersInfo,
      notes,
      paymentId: id,
      shopCode: memberCode,
    };
  });
  return output?.reverse();
};

const prepareSaveCustomerInfoPayload = (params) => {
  const {
    customerId,
    customerType,
    firstName,
    lastName,
    businessName,
    phone,
    email,
    isAddressRequired,
    address: {
      addressId,
      addressType,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      zipcode,
      isPrimary,
    } = {},
    customerContacts = [],
    taxExemptCode,
    discountType,
    discountPercentage = "",
    referralType,
    customerNotes,
    storeOrigin,
    smsOptIn = false,
    smsProviderOptIn = false,
    smsMarketingOptIn = false,
    emailMarketingOptIn = true,
    memberCode,
    viewedAt = "",
    deliveryFeeOverride = "",
  } = params;

  const isBusinessProfile = customerType === "true";
  const customerName = isBusinessProfile
    ? { businessName }
    : { firstName, lastName };
  const discountVal = discountPercentage.length ? discountPercentage : "0";
  const customerDetails = { ...params.customerDetails, deliveryFeeOverride };

  const payload = {
    customerId,
    ...customerName,
    phones: [formatPhoneForPayload(phone)],
    storeOrigin,
    memberCode,
    email,
    referralType,
    customerNotes,
    smsOptIn,
    smsProviderOptIn,
    smsMarketingOptIn,
    emailOptIn: emailMarketingOptIn,
    taxExemptCode,
    discountPercentage: discountVal,
    discountType,
    isBusinessProfile,
    ...(isAddressRequired && {
      addresses: [
        {
          addressId,
          firstName,
          lastName,
          addressType,
          addressLine1,
          addressLine2,
          city,
          state,
          country,
          zipcode,
          isPrimary,
        },
      ],
    }),
    ...(customerContacts.length > 0 && { customerContacts }),
    ...(viewedAt && customerId && { viewedAt }),
    customerDetails,
  };

  return JSON.parse(JSON.stringify(payload));
};

const getTokenizePaymentPayload = (values = {}, merchantReferenceId) => {
  let {
    storeOrigin,
    paymentInfo: {
      cardNumber,
      cardType,
      cvvNumber,
      expiration,
      billingAddress: { addressLine1, city, state, country, zipcode },
    },
  } = values;
  const [expirationMonth, expirationYear] = expiration.split("/");

  const tokenizeReqPayload = {
    storePaymentMethod: false,
    currency: "usd",
    isCalyxRoute: true,
    merchantReferenceId,
    processorAccount: "mhq",
    partnerCode: storeOrigin,
    paymentMethod: {
      billTo: {
        address1: checkForNA(addressLine1),
        city: checkForNA(city),
        state: checkForNA(state),
        country: checkForNA(country),
        zipCode: checkForNA(zipcode),
      },
      creditCard: {
        cardNumber: cardNumber.split(" ").join(""),
        cardType,
        cvvNumber,
        expirationMonth,
        expirationYear,
      },
      paymentType: cardType,
    },
  };
  return tokenizeReqPayload;
};

const prepareSaveCustomerPaymentPayload = (
  params,
  tokenId,
  merchantReferenceId
) => {
  const {
    customerId,
    storeOrigin,
    paymentInfo: {
      billingAddress,
      nameOnCard,
      cardType,
      expiration,
      cardNumber,
    },
  } = params;
  const trimmedCardNumber = cardNumber.split(" ").join("");
  const lastFourDigits = trimmedCardNumber.substr(trimmedCardNumber.length - 4);
  const expiry = expiration.split("/");
  const expirationMonth = expiry[0];
  const expirationYear = expiry[1];

  const payload = {
    customerId,
    billingAddress,
    storeOrigin,
    nameOnCard,
    cardType,
    expirationMonth,
    expirationYear,
    lastFourDigits,
    merchantReferenceId,
    tokenId,
  };

  return payload;
};

export function* handleFetchCustomerNotifications({ payload }) {
  const { resolve, reject, fileName } = payload;
  const serviceRequest = (customerId, fileName) =>
    request("customer-notifications", {
      customerId,
      fileName,
    });
  try {
    const customerId = yield select(selectCurrentCustomerId);
    const response = yield call(serviceRequest, customerId, fileName);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleMergeCustomerProfiles(action) {
  const { resolve, reject, params = [] } = get(action, "payload", {});
  const serviceRequest = (customersToMerge) =>
    request("merge-customer", customersToMerge);

  try {
    yield call(serviceRequest, params);
    yield put(
      fetchCustomerDetails({
        storeOrigin: params[0].storeOrigin,
        resolve,
      })
    );
  } catch (error) {
    reject && reject(error);
  }
}

export function* handleFetchContactSubscriptions(action = {}) {
  const { resolve, reject, params = [] } = get(action, "payload", {});
  const serviceRequest = (payload) =>
    request("get-subscriptions-for-contact", payload);

  try {
    const response = yield call(serviceRequest, params);
    resolve && resolve(response);
  } catch (error) {
    reject && reject(error);
  }
}
