import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import moment from "moment";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { isDoordashProvider } from "../helper";
import { setLoadingOnDSrequested } from "library/sagas/ongoing/current-orders/slice";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { ToasterHandler } from "components/elements";
import { Form } from "components/elements/forms";

import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import {
  returnToStoreVal,
  leaveAtLocationVal,
} from "library/utils/deliveryService";
import { DSFormValidationSchema } from "components/views/drawer/order-details/delivery-info/yup";
import { ORDERS } from "library/constants";

import DSFormContent from "./form-content";

//eslint-disable-next-line
const DSForm = React.memo(
  ({
    index = 0,
    actionCallback,
    recordId,
    sourceMemberCode,
    locationType,
    locationName,
    fillerMemberCode,
    triggerAction,
    hasDSFulfillmentError,
    isDSSubmitted,
    timezone,
    deliveryMethod,
    undeliverableAction,
    pickUpDateTime,
    deliveryDate,
    deliveryDetailsToDS,
  }) => {
    const orderDetailResponse = useSelector(selectRecordData) || {};
    const orderDetails = orderDetailResponse.orderItems[index] || {};

    const { messages: locMessages, Localise } = useContext(I18NContext);
    const [onChangeValidation, setOnChangeValidation] =
      useStateIfMounted(false);

    const { setFieldValue: orderSetFieldValue } = useFormikContext();

    const isDoordashInitialVal =
      fillerMemberCode &&
      isDoordashProvider(
        UserProfileStorage.getShopPreferences(fillerMemberCode)
          .delivery_provider
      );

    const [isDoordash, setIsDoordashProvider] = useStateIfMounted(
      isDoordashInitialVal ? "true" : null
    );

    const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));
    const dispatch = useDispatch();

    const deliveryTypeChange = ({ deliveryMethod }) => {
      triggerAction({
        action: "preferred-delivery-type",
        preferredDeliveryType: [
          "FLORIST_DELIVERED",
          "FLORIST_PARTNER",
          "MOL_FLORIST_DELIVERED",
          "FOL_FLORIST_DELIVERED",
        ].includes(deliveryMethod)
          ? "FLORIST_FULFILLED"
          : deliveryMethod,
      });
    };

    //default fulfilmentType to "STANDARD" for order
    const fulfillmentType = "STANDARD";

    return (
      <Form
        initialValues={{
          deliveryMethod: deliveryMethod ?? "",
          newPickUpDate:
            pickUpDateTime.length > 0
              ? `${pickUpDateTime?.split("T")[0]}`
              : isPastDate
              ? `${moment().format("YYYY-MM-DD")}`
              : `${deliveryDate?.split("T")[0]}`,
          pickUpDateTime: pickUpDateTime
            ? isDoordash === "true"
              ? `${moment
                  .utc(pickUpDateTime)
                  .tz(timezone)
                  .format("HH:mm A")} ${startCase(
                  fulfillmentType.toLowerCase()
                )}`
              : `${moment
                  .utc(pickUpDateTime)
                  .tz(timezone)
                  .format("YYYY-MM-DDTHH:mm:ss")}`
            : "",
          deliveryDetailsToDS,
          undeliverableAction:
            undeliverableAction.length > 0
              ? undeliverableAction
              : orderDetails.deliveryInfo.occasion === "FUNERAL" // Sympathy
              ? returnToStoreVal // "Return to Store"
              : leaveAtLocationVal, // "Leave at Location"
          timezone,
          dsPickUpTimings: {},
          injectSpecificTimings: [],
          specialInstructionsCharCount:
            orderDetails.deliveryInfo.occasion === "FUNERAL"
              ? ORDERS.FUNERAL_DS_INSTRUCTIONS?.length
              : 0,
          pickupTimeDidChanged: false,
          pickupTimeDidConfirmed: false,
          fulfillmentType: startCase(fulfillmentType.toLowerCase()),
        }}
        validateOnChange={onChangeValidation}
        validateOnBlur={onChangeValidation}
        validationSchema={DSFormValidationSchema(locMessages, Localise)}
        onSubmit={(values, formikBag) => {
          const {
            newPickUpDate,
            pickUpDateTime,
            deliveryDetailsToDS: deliveryDetails,
            undeliverableAction,
            doordashPickUpTime,
            fulfillmentType,
          } = values;

          const pickUpTime =
            isDoordash === "true"
              ? doordashPickUpTime.trim()
              : moment(pickUpDateTime).format("HH:mm:ss");

          const reqObj = {
            pickUpDateTime: moment
              .tz(`${newPickUpDate} ${pickUpTime}`, timezone)
              .toISOString(),
            fulfillmentType: fulfillmentType.toUpperCase(),
            locationType,
            locationName,
            deliveryDetails,
            undeliverableAction,
          };
          const dsReqPayload = {
            recordId: orderDetails.orderItemId,
            deliveryMethod: orderDetails.deliveryInfo.deliveryMethod,
            ...reqObj,
            sourceMemberCode,
            requestType: "delivery",
          };

          const handleDSError = (error) => {
            const { errors } = error;
            let errorMsg =
              error === "CATCH ALL ERROR"
                ? "Something went wrong, please try again"
                : get(errors, "0.message", "").includes(
                    "Could not opt Delivery Service when route status in Out-for-delivery/ Completed"
                  )
                ? "This order is already out for delivery."
                : "Delivery Service is unavailable for this order.";

            ToasterHandler("oops", Localise(locMessages, errorMsg));
          };

          if (hasDSFulfillmentError) {
            request("order-message-actions", {
              recordId,
              deliveryMethod: orderDetails.deliveryInfo.deliveryMethod,
              markAsRead: true,
              isStructured: true,
              isUnstructured: true,
              sourceMemberCode,
            })
              .then(() => {
                request("order-requests", dsReqPayload)
                  .then(() => {
                    formikBag.setSubmitting(false);
                    deliveryTypeChange({
                      deliveryMethod: values.deliveryMethod,
                    });
                    dispatch(setLoadingOnDSrequested(true));
                    actionCallback("deliveryTypeChange", recordId);
                  })
                  .catch((error) => {
                    handleDSError(error);
                    formikBag.setSubmitting(false);
                  });
              })
              .catch(() => {
                formikBag.setSubmitting(false);
              });
          } else {
            request("order-requests", dsReqPayload)
              .then(() => {
                formikBag.setSubmitting(false);
                deliveryTypeChange({
                  deliveryMethod: values.deliveryMethod,
                });
                dispatch(setLoadingOnDSrequested(true));
                actionCallback("", recordId);
              })
              .catch((error) => {
                handleDSError(error);
                formikBag.setSubmitting(false);
              });
          }
        }}
        render={(props) => (
          <DSFormContent
            {...{
              index,
              props,
              isDoordash,
              setIsDoordashProvider,
              orderSetFieldValue,
              setOnChangeValidation,
              isDSSubmitted,
              deliveryMethod,
            }}
          />
        )}
      />
    );
  }
);

export default DSForm;
