import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, ScrollView, Platform } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Filters from "./filters";
import { fonts, colors } from "styles/theme";
import { fetchCustomerActivityDetails } from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectCustomerActivityInfo } from "library/sagas/views/home/drawer/customer-directory/selector";
import { fetchStatement } from "../statements/statement";

import moment from "moment";
import I18NContext from "library/contexts/i18N";

const WindowHeight = 300;
const MaxCount = 10;

const Activity = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { messages, Localise } = React.useContext(I18NContext);
  const activityInfoDetails = useSelector(selectCustomerActivityInfo);
  const {
    activities = [],
    offset,
    startDate,
    endDate,
    feedType,
    customerId,
  } = activityInfoDetails || {};
  let flag = 0;
  const filterProps = {
    width: "5%",
    icon: "filter",
  };

  const handleScroll = (event) => {
    const { last, offset } = activityInfoDetails?.pageable || {};
    if (last) return;

    const currentOffset = event?.nativeEvent?.contentOffset?.y;
    const contentHeight = event?.nativeEvent?.contentSize?.height;

    if (contentHeight === currentOffset + WindowHeight) {
      dispatch(
        fetchCustomerActivityDetails({
          offset: offset + MaxCount,
          startDate,
          endDate,
          feedType,
        })
      );
    }
  };

  const mapping = {
    ORDER_CONFIRMATION_EMAIL: "Order Confirmation email",
    ORDER_CANCELLATION_EMAIL: "Order Cancellation email",
    ORDER_PREPARATION_EMAIL: "Order preparation email",
    OUT_FOR_DELIVERY_EMAIL: "Out for delivery email",
    READY_FOR_PICKUP_EMAIL: "Ready for pick up email",
    DELIVERY_CONFIRMATION_EMAIL: "Delivery Confirmation email",
    DELIVERY_DATE_CHANGE_EMAIL: "Delivery date change email",
    DELIVERY_ATTEMPT_EMAIL: "Attempted Delivery email",
    HOUSE_ACCOUNT_STATEMENTS_EMAIL: "House account statements email",
    SAVE_CREDIT_CARD_EMAIL: "Save Credit card email",
    DESIGNED_IMAGE_UPLOAD_EMAIL: "Design Preview email",
  };

  const smsMapping = {
    ORDER_CONFIRMATION_EMAIL: "Order Confirmation SMS",
    ORDER_PREPARATION_EMAIL: "Order Preparation SMS",
    OUT_FOR_DELIVERY_EMAIL: "Out for Delivery SMS",
    READY_FOR_PICKUP_EMAIL: "Ready for Pickup SMS",
    DELIVERY_CONFIRMATION_EMAIL: "Delivery Confirmation SMS",
    DELIVERY_DATE_CHANGE_EMAIL: "Delivery Date Change SMS",
    DELIVERY_ATTEMPT_EMAIL: "Attempted Delivery SMS",
    HOUSE_ACCOUNT_STATEMENTS_EMAIL: "House account statements SMS",
    SAVE_CREDIT_CARD_EMAIL: "Save Credit card SMS",
    DESIGNED_IMAGE_UPLOAD_EMAIL: "Design Preview SMS",
  };

  const handleCustomerActivity = (fileNameLocal) => {
    let requestParams;
    requestParams = {
      customerId: customerId,
      fileName: fileNameLocal,
    };
    fetchStatement("customer-notifications", requestParams, Localise, messages);
  };

  if (!activities.length) {
    return (
      <>
        {!!((startDate && endDate) || feedType) && (
          <View style={{ alignItems: "flex-end", marginRight: 10 }}>
            <Filters
              {...filterProps}
              startDate={startDate}
              endDate={endDate}
              offset={offset}
              feedType={feedType}
            />
          </View>
        )}
        <View>
          <Text
            style={{
              alignItems: "center",
            }}
          >
            {Localise(messages, "No Activity")}
          </Text>
        </View>
      </>
    );
  }
  const getLastUpdateDate = (latestDate) => {
    flag++;
    return (
      <View style={{ marginTop: 20 }}>
        <Text
          style={{
            ...fonts.heading5,
            marginLeft: 15,
            alignItems: "center",
          }}
        >
          {latestDate}
        </Text>
      </View>
    );
  };

  const getActivityInfoText = (
    data,
    emailTypeInfo,
    smsType,
    orderItemIdInfo,
    fileNameLocal
  ) => {
    const infoText = data.split(" ");
    return (
      <>
        {infoText?.map((each, idx) => {
          if (each === "EMAIL_TYPE") {
            return emailTypeInfo === "HOUSE_ACCOUNT_STATEMENTS_EMAIL" ? (
              <Text
                style={{
                  color: "#3A7D8D",
                }}
              >
                {mapping[emailTypeInfo]}
              </Text>
            ) : (
              <TouchableOpacity
                key={idx}
                activeOpacity={0.6}
                style={{ color: "#3A7D8D", textDecoration: "underline" }}
                onPress={() => {
                  handleCustomerActivity(fileNameLocal);
                }}
                testID="email_activity"
              >
                <Text
                  style={{
                    textDecorationLine: "underline",
                    color: "#3A7D8D",
                  }}
                >
                  {mapping[emailTypeInfo]}
                </Text>
              </TouchableOpacity>
            );
          } else if (each === "SMS_TYPE") {
            return (
              <Text
                style={{
                  color: "#3A7D8D",
                }}
              >
                {smsMapping[smsType]}
              </Text>
            );
          } else if (each === "ORDER_ITEM_ID") {
            return (
              <TouchableOpacity
                key={idx}
                activeOpacity={0.6}
                style={{ color: "#3A7D8D", textDecoration: "underline" }}
                onPress={() => {
                  navigation.dispatch(
                    CommonActions.navigate({
                      name: "orders",
                      params: {
                        search: {
                          title: orderItemIdInfo,
                          value: orderItemIdInfo,
                        },
                      },
                    })
                  );
                }}
                testID="order_item_activity"
                accessibilityLabel="order_item_activity"
              >
                <Text
                  style={{
                    textDecorationLine: "underline",
                    color: "#3A7D8D",
                  }}
                >
                  {orderItemIdInfo}
                </Text>
              </TouchableOpacity>
            );
          } else {
            return <Text key={idx}> {`${each}`} </Text>;
          }
        })}
      </>
    );
  };

  return (
    <View>
      <View style={{ alignItems: "flex-end", marginRight: 10 }}>
        <Filters
          {...filterProps}
          startDate={startDate}
          endDate={endDate}
          feedType={feedType}
          offset={offset}
        />
      </View>

      <ScrollView style={{ maxHeight: WindowHeight }} onScroll={handleScroll}>
        {activityInfoDetails?.activities?.map((activity, index) => {
          const {
            createdOn,
            activityInfo = "",
            orderItemId,
            emailType,
            smsType,
            fileName,
          } = activity;
          const currDate = moment(
            activityInfoDetails?.activities[index]?.createdOn
          ).format("MMM D, YYYY");
          const prevDate = moment(
            activityInfoDetails?.activities[index - 1]?.createdOn
          ).format("MMM D, YYYY");

          currDate === prevDate ? flag++ : (flag = 0);

          return (
            <View
              key={index}
              style={{
                borderStyle: "solid",
                borderLeftWidth: 1,
                borderLeftColor: colors.light,
                marginLeft: 5,
              }}
            >
              {(flag === 0 || index === 0) &&
                getLastUpdateDate(moment(createdOn)?.format("MMM D, YYYY"))}
              {activityInfo
                .trim("\n")
                .split("\n")
                .map((activityText, idx) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          borderRadius: 50,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: colors.dark,
                          width: 10,
                          height: 10,
                          position: "absolute",
                          left: -5,
                          top: Platform.OS === "web" ? "calc(50% - 5px)" : 5,
                          backgroundColor: colors.secondary,
                          marginBottom: 15,
                        }}
                      ></Text>
                      <Text
                        style={{
                          ...fonts.default,
                          lineHeight: 20,
                          marginLeft: 15,
                        }}
                      >
                        {getActivityInfoText(
                          activityText,
                          emailType,
                          smsType,
                          orderItemId,
                          fileName
                        )}
                      </Text>
                    </View>
                  );
                })}
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default Activity;
