import * as Yup from "yup";
import { FormField } from "components/elements/forms";

export const formFields = [
  {
    title: "Latitude",
    formFieldElement: FormField,
    formFieldProps: {
      name: "latitude",
      keyboardType: "numeric",
      placeholder: "Enter Latitude",
    },
  },
  {
    title: "Longitude",
    formFieldElement: FormField,
    formFieldProps: {
      name: "longitude",
      keyboardType: "numeric",
      placeholder: "Enter Longitude",
    },
  },
];

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    latitude: Yup.string().required(
      Localise(messages, "Please enter Latitude")
    ),
    longitude: Yup.string().required(
      Localise(messages, "Please enter Longitude")
    ),
  });

export const sectionTitle = "Shop Coordinates";
