import React, { forwardRef } from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import { Image, Text } from "react-native-elements";

import { Accordion, ToasterHandler } from "components/elements";
import { SubmitButton, ResetButton } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import { fetchBalanceForwardPaymentPreview } from "library/sagas/views/home/drawer/customer-directory/slice";

import IMAGES from "static/assets/images";
import { colors, fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

export const Header = ({ title, onClose }) => (
  <View
    style={[
      tw("px-3 py-3 flex flex-row items-center justify-between mb-2"),
      { backgroundColor: backgroundColors.primary },
    ]}
  >
    <Text style={{ ...fonts.heading2, ...tw("text-white") }}>{title}</Text>
    {onClose && (
      <View>
        <TouchableOpacity onPress={onClose} testID="close">
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>
    )}
  </View>
);

export const Section = ({ title, children, headerContent }) => {
  const { isMobile } = React.useContext(DeviceContext);
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        marginBottom: 8,
        opacity: 1,
      }}
      pointerEvents={"auto"}
    >
      <Accordion
        title={title}
        defaultOpen={true}
        handleOnPress={() => {}}
        headerContent={headerContent}
        labelStyle={{
          marginTop: 0,
          height: 40,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          fontWeight: "500",
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        testID={title}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: isMobile ? 10 : 20,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};

function SubmitButtons({ disableOnDirty = true, title = "Save" }, ref) {
  // const { dirty, resetForm } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  return (
    <View style={tw("justify-end flex-row m-2")}>
      <ResetButton
        containerStyle={{
          width: isDesktop ? 72 : 87,
          height: 40,
        }}
        title={Localise(messages, "Cancel")}
        disableOnDirty={disableOnDirty}
        testID={"cancel"}
        refFromProp={ref}
      />
      <SubmitButton
        containerStyle={{
          minWidth: isDesktop ? 72 : 80,
          height: 40,
          marginLeft: -1,
        }}
        title={Localise(messages, title)}
        disableOnDirty={disableOnDirty}
        refFromProp={ref}
      />
    </View>
  );
}

export const SaveCancelButtons = forwardRef(SubmitButtons);

export const scrollToSubscriptionSection = ({
  customerDetailsViewRef,
  setsubscriptionYCoordinate,
} = {}) => {
  if (Platform.OS === "web") {
    const subScriptionElement = document.querySelector(
      '[data-testid="Subscriptions"]'
    );
    subScriptionElement?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  } else {
    //Commented to handle it on seperate ticket for Mobile.
    // if (customerDetailsViewRef.current) {
    //   customerDetailsViewRef.current.measure(
    //     (x, y, width, height, pageX, pageY) => {
    //       setsubscriptionYCoordinate(pageY - 200);
    //     }
    //   );
    // }
  }
};

export const getIsOpenItemEligibleShop = (selectedShopPermissions) => {
  return selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
    Entitlements.HOUSE_ACCOUNT_PERMISSIONS.OPEN_ITEM_ACCOUNTING
  );
};

export const getIsBalanceForwardEligibleShop = (selectedShopPermissions) => {
  return selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
    Entitlements.HOUSE_ACCOUNT_PERMISSIONS.BALANCE_FORWARD_ACCOUNTING
  );
};

export const getBalanceForwardPaymentsPreviewList = ({
  memberCodeLocal,
  values,
  paymentDetails,
  isAccountType,
  setIsLoading,
  dispatch,
  storeOrigin,
  houseAccountInfo,
  customerId,
  standaloneCreditBalance,
  messages,
  Localise,
}) => {
  const {
    //  savePayment,
    paymentMethodType,
    paymentMethodDetails,
    amount,
  } = values?.paymentDetails?.paymentMethod[0];
  const { note = "" } = paymentMethodDetails;
  let formPaymentDetails = {};

  if (["CASH", "CHECK", "PAID_ELSEWHERE"].includes(paymentMethodType)) {
    formPaymentDetails = {
      ...paymentDetails,
      paymentMethod: [
        {
          paymentMethodType,
          paymentMethodDetails: {
            amount: amount,
            note: ["CHECK", "PAID_ELSEWHERE"].includes(paymentMethodType)
              ? note
              : values?.optionalNotes,
          },
        },
      ],
    };
  } else if (paymentMethodType === "PAYMENT_TERMINAL") {
    formPaymentDetails = {
      ...paymentDetails,
      paymentMethod: [
        {
          ...values?.paymentDetails?.paymentMethod[0],
          paymentMethodType: "CREDIT_CARD",
          enablePayment: true,
          savePayment: false,
          amount: amount,
          paymentMethodDetails: {
            creditCardType: "VISA",
            authorizationDetails: [
              {
                name: "amount",
                value: amount,
              },
            ],
            note: values?.optionalNotes,
          },
          isPayLaterEnabled: false,
          paymentFailed: false,
          paymentProcessed: false,
        },
      ],
    };
  } else if (
    paymentMethodType === "CREDIT_CARD" ||
    paymentMethodType === "SAVED_CARD"
  ) {
    formPaymentDetails = {
      ...paymentDetails,
      paymentMethod: [
        {
          ...values?.paymentDetails?.paymentMethod[0],
          paymentMethodType: "CREDIT_CARD",
          enablePayment: true,
          savePayment: false,
          amount: amount,
          paymentMethodDetails: {
            creditCardType: "VISA",
            authorizationDetails: [
              {
                name: "amount",
                value: amount,
              },
            ],
            note: values?.optionalNotes,
          },
          isPayLaterEnabled: false,
          paymentFailed: false,
          paymentProcessed: false,
        },
      ],
    };
  }

  // fetching balance frwd unpaid orders amount split preview on selecting shop
  if (isAccountType === "Balance_Forward") {
    setIsLoading(true);
    dispatch(
      fetchBalanceForwardPaymentPreview({
        storeOrigin: storeOrigin,
        params: {
          houseAccountId: houseAccountInfo?.houseAccountId,
          memberCode: memberCodeLocal,
          customerId: customerId,
          storeOrigin: storeOrigin,
          creditLimit: houseAccountInfo?.creditLimit,
          paymentDetails: formPaymentDetails,
          houseAccountType: isAccountType,
          creditBalance: standaloneCreditBalance,
          isCreditBalancePayment: false,
        },
        resolve: () => {
          setIsLoading(false);
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(messages, "Failed to fetch amounts preview")
          );
          setIsLoading(false);
        },
      })
    );
  }
};

export const getPrintReceiptFormattedHtml = (html = "") => {
  return html?.replace(
    "<body>",
    "<body onload=\"window.parent.postMessage({ action: 'iframe-loaded' }, '*');\">"
  );
};

export const isBalFwdAccountType = (accountType = "") => {
  return accountType === "Balance_Forward";
};
