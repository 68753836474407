/* eslint-disable react-hooks/rules-of-hooks */

import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { Text } from "react-native-elements";
import { View, TouchableOpacity, Platform, Image } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import last from "lodash/last";
import filter from "lodash/filter";
import pick from "lodash/pick";
import uniq from "lodash/uniq";

import IMAGES from "static/assets/images";
import {
  backgroundColors,
  colors,
  fonts,
  orderStatusColorCodes,
} from "styles/theme";

import { CountDownTimer, ToasterHandler, Tooltip } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import {
  getTimeLeftInMins,
  getPickupTimeLeftInMins,
} from "library/utils/datetime";
import {
  calcCountDownTimer,
  orderDsRequestRerouting,
  isDSSubmittedOrder,
} from "library/utils/orderListing";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";

import {
  selectRecordData,
  selectOrderLocked,
} from "library/sagas/ongoing/current-orders/selector";
import OrderBreadcrumb from "./order-breadcrumb";
import TakeActionContainer from "./take-action/take-action-container";
import FormContainer from "./take-action/form-container";
import UserProfileStorage from "library/storage/userProfile";
import { setActiveTab } from "library/sagas/ongoing/current-orders/slice";
import {
  setShowPrint,
  setOrderHeadHeight,
  setIsSidecarOpen,
} from "library/sagas/ongoing/order-details/slice";
import {
  selectShowPrint,
  selectOrderViewersInfo,
} from "library/sagas/ongoing/order-details/selector";
import { selectImages } from "library/sagas/ongoing/global-data/selector";
import CancelDSModal from "./take-action/cancelDSModal";
import { OrderDSCancelFailModal } from "library/utils/deliveryService";
import { getViewersNames, getOtherViewersNames } from "../helper";
import { isPartnerPickupOrderSource } from "components/views/drawer/order-details/helper";

const OrderHead = React.memo(
  ({
    index,
    doNotFill,
    recordId,
    scrollViewRef,
    actionTriggered,
    setActionTriggered,
    setRequestTriggered,
    isActionProcessing,
    listingType,
    actionsList,
    subActionsList,
    actionCallback,
    menuAction,
    setMenuAction,
    showDriverAction,
    onComplete,
    orderRefundStatus,
    setOrderRefundStatus,
    isInactiveOrder,
    showPayment,
    setShowDconForm,
    tabDetailScrollRef,
  }) => {
    /**
     * If we opened order details side car from orders listing screen and if recordId is not available then
     * we are returning null here in order to avoid all other actions and unnecessary state changes / calls.
     */
    if (!recordId) return null;

    const imagesData = useSelector(selectImages);
    const orderDetailResponse = useSelector(selectRecordData);
    const isOrderLocked = useSelector(selectOrderLocked);
    const orderViewersInfo = useSelector(selectOrderViewersInfo);
    const orderViewers = uniq(getViewersNames(orderViewersInfo));
    const isRePrintTriggered = useSelector(selectShowPrint);
    const dispatch = useDispatch();

    const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);
    const [cancelType, setCancelType] = useStateIfMounted("");
    const [cancelPayload, setCancelPayload] = useStateIfMounted({});
    const [showReasonForm, setShowReasonForm] = useStateIfMounted(true);
    const [showDSCancelFailModal, setShowDSCancelFailModal] = useStateIfMounted(
      {
        modalVisible: false,
        errorMessage: "",
      }
    );
    const [isDisabled, setIsDisabled] = useStateIfMounted(false); // Added this flag to avoid multiple API calls https://ftdcorp.atlassian.net/browse/MSOL-21371
    const { messages: locMessages, Localise } = useContext(I18NContext);
    const { userProfile } = React.useContext(UserProfileContext);
    const orderOtherViewers = getOtherViewersNames(orderViewers, userProfile);

    const { isDesktop, isMobile } = useContext(DeviceContext);

    const orderDetails = cloneDeep(orderDetailResponse.orderItems[index] || {});
    const cardInfo = orderDetailResponse.cardInfo || {};
    const customerInfo = orderDetailResponse.customerInfo || {};
    const paymentDetails = orderDetailResponse.paymentDetails || {};
    const isSmallScreen = !isDesktop;

    // convert from utc to moment timezone
    orderDetails.createdOn = moment(orderDetails.createdOn).format();

    const {
      createdOn,
      status: orderStatus = "",
      //orderItemId: recordId, // commenting this line bcoz this is causing recordId is available even after closing side car, it should be undefined once we close side car
      direction,
      receivingMember,
      sendingMember = {},
      orderSource = "",
      pickupInfo,
      orderItemId = "",
      messages,
      deliveryInfo: { deliveryType = "", deliveryMethod = "", deliveryDate },
      hasPendingPayLaterPayment = false,
      hasSettlementError = false,
      hasFulfillmentError = false,
      hasDSFulfillmentError = false,
      lineItems,
      recipientInfo: { zip, addressLine1, suite, city, state, country },
      price,
    } = orderDetails;

    const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));
    const { storePickupDateTime = "" } = pickupInfo || {};
    const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";
    const isPartnerPickupOrder = isPartnerPickupOrderSource(orderSource);
    const isPickUpOrder =
      (isPartnerPickupOrder &&
        !isEmpty(storePickupDateTime)) ||
      isStorePickupOrder ||
      isWalkInOrder ||
      (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP");

    const isCompletedOrder = ["CANCELLED", "DELIVERED"].includes(orderStatus);

    const memberTimeZones = UserProfileStorage.getAllShopTimeZones() || {};
    const shopHours = UserProfileStorage.getAllShopHours() || {};
    const fillerMemberCode =
      direction === "INBOUND" ? receivingMember?.memberCode : "";

    const sourceMemberCode =
      direction === "INBOUND"
        ? receivingMember?.memberCode
        : sendingMember?.memberCode;

    const countDownTimer =
      orderStatus === "NEW" || orderStatus === "VIEWED"
        ? calcCountDownTimer(
            createdOn,
            fillerMemberCode,
            memberTimeZones,
            shopHours
          )
        : {
            countDownWithinShopEndTime: undefined,
            countDownEndTime: "",
            countDownPauseTime: "",
          };

    const {
      countDownWithinShopEndTime: targetTime,
      countDownEndTime = "",
      countDownPauseTime = "",
    } = countDownTimer;

    const [timeLeftInMins, setTimeLeftInMins] = useStateIfMounted(
      targetTime
        ? getTimeLeftInMins(targetTime, memberTimeZones[fillerMemberCode])
        : !targetTime
        ? getTimeLeftInMins(countDownEndTime, memberTimeZones[fillerMemberCode])
        : 0
    );

    const [pickupTimeLeftInMins, setPickupTimeLeftInMins] = useStateIfMounted(
      isPickUpOrder ? getPickupTimeLeftInMins(storePickupDateTime) : 0
    );

    const isLocalOrder = [
      "FLORIST_DELIVERED",
      "STORE_PICKUP",
      "WALK_IN",
      "PHONE_OUT",
    ].includes(deliveryMethod);

    const isFormActions =
      (!isPickUpOrder &&
        [
          `delivery-confirmation${orderItemId}`,
          `delivery-exception${orderItemId}`,
          `cancel${orderItemId}`,
          `reject${orderItemId}`,
          `adjustment${orderItemId}`,
        ].includes(menuAction)) ||
      [`assign-to-designer${orderItemId}`].includes(menuAction);

    const onlyMenuAction = menuAction.substring(
      0,
      menuAction.length - orderItemId.length
    );

    const [selectedAction, setSelectedAction] = useStateIfMounted(
      isFormActions || (isLocalOrder && menuAction === `cancel${orderItemId}`)
        ? onlyMenuAction
        : ""
    );
    const isDSSubmitted = isDSSubmittedOrder(deliveryType);

    const relatedMessages = filter(
      messages,
      (message) => message.messageType === "Cancel"
    );
    const latestMessage = last(relatedMessages);
    const {
      direction: messageDirection,
      requestStatus,
      id: askId,
    } = latestMessage || {};

    const isCancelPendingApproval =
      messageDirection === "INBOUND" && requestStatus === "WAITING";

    const isMOLCancelledOrder =
      orderSource === "MOL" && orderStatus === "CANCELLED";

    const isFulfillmentRejectedOrder = useMemo(() => {
      return hasFulfillmentError && orderStatus === "REJECTED";
    }, [orderStatus, hasFulfillmentError]);

    const isFulfillmentErrorOrder = useMemo(() => {
      return hasFulfillmentError && orderStatus === "ERROR";
    }, [orderStatus, hasFulfillmentError]);

    const cancelApprovalInfo = { askId };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scrollToEndOfPage = useCallback(() => {
      // Adding setTimeout here in order to scroll to bottom payment section o/w it is scrolling upto 90% only
      setTimeout(() => {
        scrollViewRef &&
          scrollViewRef?.current?.scrollToEnd({
            animated: true,
          });
        // order details tab structure scroll
        tabDetailScrollRef &&
          tabDetailScrollRef?.current?.scrollToEnd({ animated: true });
      }, 50);
    });

    useEffect(() => {
      if (
        (hasPendingPayLaterPayment &&
          !menuAction.includes("assign-to-designer") &&
          orderStatus !== "CANCELLED") ||
        hasSettlementError
      ) {
        // Open payment tab and Focus to Payment option for paylaterpending and payment errored orders
        dispatch(setActiveTab("payment"));
        scrollToEndOfPage();
      } else if (hasDSFulfillmentError) {
        dispatch(setActiveTab("delivery"));
      } else {
        dispatch(setActiveTab("order-summary"));
        hasFulfillmentError && scrollToEndOfPage();
      }
    }, []);

    useEffect(() => {
      // rePrint trigger in case of bulk scan functionality
      if (isRePrintTriggered) {
        setSelectedAction("print");
        dispatch(setShowPrint(false));
      }
    }, [isRePrintTriggered]);

    const TimerContainer = () => (
      <>
        {!isPickUpOrder && timeLeftInMins > 0 && (
          <View style={tw("flex flex-row items-center py-1 pr-5 pl-1")}>
            <View style={tw("flex flex-row items-center")}>
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                source={IMAGES["accept-deadline"]}
              />
              <Text
                style={{
                  ...fonts.heading5,
                  color: colors.highlighter,
                  ...tw("ml-1"),
                }}
              >
                {Localise(locMessages, "Accept Deadline")}
              </Text>
            </View>
            <View style={tw("ml-2")}>
              <CountDownTimer
                targetTime={
                  !isPickUpOrder
                    ? targetTime || countDownEndTime
                    : moment(storePickupDateTime)
                }
                pauseCountDownTimer={!isPickUpOrder ? !targetTime : false}
                setTimeLeftInMins={setTimeLeftInMins}
                countDownPauseTime={countDownPauseTime}
                isOrderDetailCounter={true}
              />
            </View>
          </View>
        )}
        {isPickUpOrder && pickupTimeLeftInMins < 31 && !isCompletedOrder && (
          <View style={tw("flex flex-row items-center py-1 pr-5")}>
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={IMAGES["accept-deadline"]}
            />
            <View style={tw("ml-1")}>
              <Text
                style={{
                  ...fonts.heading5,
                  color: colors.highlighter,
                }}
              >
                {Localise(locMessages, "Pickup!")}
              </Text>
            </View>
            <View style={tw("ml-2")}>
              {pickupTimeLeftInMins > 0 ? (
                <CountDownTimer
                  targetTime={moment(storePickupDateTime)}
                  pauseCountDownTimer={false}
                  setTimeLeftInMins={setPickupTimeLeftInMins}
                  isPickUpOrder={isPickUpOrder}
                  isOrderDetailCounter={true}
                />
              ) : (
                <Text
                  style={{
                    fontSize: 12,
                    textAlign: "left",
                    fontWeight: "600",
                    color: colors.highlighter,
                  }}
                >
                  0:00:00
                </Text>
              )}
            </View>
          </View>
        )}
      </>
    );

    const InactiveTextBanner = () =>
      isInactiveOrder && (
        <Text
          style={{
            ...fonts.heading4,
            ...(isSmallScreen && {
              fontSize: 12,
              width: 96,
              paddingVertical: 4,
            }),
            color: colors.primary,
          }}
        >
          {Localise(locMessages, "Order")}{" "}
          {Localise(
            locMessages,
            startCase(toLower(orderStatus)).replace("Cancelled", "Canceled")
          )}
        </Text>
      );

    const cancelDSRequestAndOrder = (callBack = () => {}) => {
      const dsCancelPayload = {
        deliveryMethod: orderDetails.deliveryInfo.deliveryMethod,
        memberCode: fillerMemberCode,
        orderItemId,
      };
      request("delivery-service-cancel", dsCancelPayload)
        .then(() => {
          ToasterHandler(
            "success",
            Localise(messages, "The delivery service request is canceled")
          );
          callBack();
          setCancelType("");
        })
        .catch(({ errors = {} }) => {
          const errorMessage = get(
            errors,
            "0.message",
            "Something went wrong, please try again."
          );
          setShowDSCancelFailModal({
            modalVisible: true,
            errorMessage,
          });
        });
    };

    const orderActionInfo = isOrderLocked.isLocked
      ? `${Localise(locMessages, "Order is being modified by")} ${
          isOrderLocked.lockedBy
        }`
      : orderOtherViewers?.length
      ? `${Localise(
          locMessages,
          "Order is being viewed by"
        )} ${orderOtherViewers.join(` ${Localise(messages, "and")} `)}`
      : "";

    return (
      <View
        style={{
          position: Platform.OS === "web" ? "sticky" : "relative",
          top: 0,
          zIndex: 99,
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
          borderRadius: 5,
          paddingHorizontal: isMobile ? 10 : 15,
          paddingVertical: 10,
          backgroundColor: colors.secondary,
        }}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          dispatch(setOrderHeadHeight(height - 5));
        }}
      >
        <View
          style={[
            tw(`flex flex-row items-center justify-between px-1 flex-wrap`),
            {
              backgroundColor: backgroundColors.secondary,
              minHeight: 30,
            },
          ]}
        >
          <Text
            style={{
              ...fonts.heading4,
              ...tw("py-1"),
              color: colors.highlighter,
              ...(isMobile && {
                fontSize: 13,
              }),
            }}
            testID={orderDetails?.orderItemId}
          >
            {`${Localise(locMessages, "Order")} #${
              isStorePickupOrder || isWalkInOrder
                ? orderDetails?.erosOrderNumber
                : orderDetails?.messageNumber || ""
            }`}
          </Text>
          {/* InActive Text for Desktop - Start */}
          {!isMobile && <InactiveTextBanner />}
          {/* InActive Text for Desktop - End */}

          <View style={tw("flex flex-row items-center justify-between")}>
            {isMobile && (
              <TakeActionContainer
                index={index}
                listingType={listingType}
                isSmallScreen={isSmallScreen}
                isMobile={isMobile}
                actionTriggered={actionTriggered}
                triggerAction={setActionTriggered}
                triggerRequest={setRequestTriggered}
                actionsList={actionsList}
                subActionsList={subActionsList}
                actionCallback={actionCallback}
                menuAction={menuAction}
                doNotFill={doNotFill}
                sourceMemberCode={sourceMemberCode}
                isActionProcessing={isActionProcessing}
                isPickUpOrder={isPickUpOrder}
                setMenuAction={setMenuAction}
                showDriverAction={showDriverAction}
                orderRefundStatus={orderRefundStatus}
                setOrderRefundStatus={setOrderRefundStatus}
                scrollViewRef={scrollViewRef}
                tabDetailScrollRef={tabDetailScrollRef}
                paymentDetails={paymentDetails}
                customerInfo={customerInfo}
                cardInfo={cardInfo}
                imagesData={imagesData}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                isCancelDSModal={isCancelDSModal}
                setShowCancelDSModal={setShowCancelDSModal}
                showReasonForm={showReasonForm}
                setShowReasonForm={setShowReasonForm}
                showPayment={showPayment}
                setShowDconForm={setShowDconForm}
              />
            )}
            {!isMobile && (
              <>
                <TimerContainer />
                <View>
                  <TouchableOpacity
                    onPress={() => {
                      onComplete();
                      setMenuAction("");
                      dispatch(setIsSidecarOpen(false));
                    }}
                    testID="close"
                  >
                    <Tooltip
                      text={Localise(messages, "Close sidecar")}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{ width: 20, height: 20 }}
                        resizeMode="cover"
                        source={IMAGES["close"]}
                      />
                    </Tooltip>
                  </TouchableOpacity>
                </View>
              </>
            )}
          </View>
        </View>

        <View
          style={tw(
            `flex flex-row items-center justify-between flex-wrap ${
              isMobile ? "w-full" : ""
            }`
          )}
        >
          <OrderBreadcrumb
            index={index}
            listingType={listingType}
            isSmallScreen={isSmallScreen}
            isMobile={isMobile}
            doNotFill={doNotFill}
            isPickUpOrder={isPickUpOrder}
            orderRefundStatus={orderRefundStatus}
            sourceMemberCode={sourceMemberCode}
            setSelectedAction={setSelectedAction}
            triggerAction={setActionTriggered}
          />

          {!isMobile && (
            <TakeActionContainer
              index={index}
              listingType={listingType}
              isSmallScreen={isSmallScreen}
              isMobile={isMobile}
              actionTriggered={actionTriggered}
              triggerAction={setActionTriggered}
              triggerRequest={setRequestTriggered}
              actionsList={actionsList}
              subActionsList={subActionsList}
              actionCallback={actionCallback}
              menuAction={menuAction}
              doNotFill={doNotFill}
              sourceMemberCode={sourceMemberCode}
              isActionProcessing={isActionProcessing}
              isPickUpOrder={isPickUpOrder}
              setMenuAction={setMenuAction}
              showDriverAction={showDriverAction}
              orderRefundStatus={orderRefundStatus}
              setOrderRefundStatus={setOrderRefundStatus}
              scrollViewRef={scrollViewRef}
              tabDetailScrollRef={tabDetailScrollRef}
              paymentDetails={paymentDetails}
              customerInfo={customerInfo}
              cardInfo={cardInfo}
              imagesData={imagesData}
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
              isCancelDSModal={isCancelDSModal}
              setShowCancelDSModal={setShowCancelDSModal}
              showReasonForm={showReasonForm}
              setShowReasonForm={setShowReasonForm}
              showPayment={showPayment}
              setShowDconForm={setShowDconForm}
            />
          )}
        </View>
        <View>
          {/* Mobile Block for Timer & Inactive Text */}
          {isMobile && (
            <>
              <TimerContainer />
              <InactiveTextBanner />
            </>
          )}

          {/* For Local Order cancel when DS Submitted */}
          {cancelType === "request" ? (
            <CancelDSModal
              isOrderReject={false}
              isLocalDSOrderCancel={cancelType === "request"}
              isCancelDSModal={isCancelDSModal}
              setShowCancelDSModal={setShowCancelDSModal}
              setShowReasonForm={setShowReasonForm}
              setSelectedAction={setSelectedAction}
              setCancelPayload={setCancelPayload}
              triggerAction={() => {
                if (isDSSubmitted && direction === "INBOUND") {
                  cancelDSRequestAndOrder(() =>
                    setRequestTriggered({ ...cancelPayload })
                  );
                } else {
                  setRequestTriggered({ ...cancelPayload });
                  setCancelType("");
                }
              }}
              secondaryhandler={() => setCancelType("")}
            />
          ) : null}

          {showDSCancelFailModal?.modalVisible ? (
            <OrderDSCancelFailModal
              modalVisible={showDSCancelFailModal?.modalVisible}
              errorMessage={showDSCancelFailModal?.errorMessage}
              primaryhandler={() => {
                //cancel order
                if (cancelType === "request") {
                  setRequestTriggered({ ...cancelPayload });
                } else if (cancelType === "action") {
                  setActionTriggered({ ...cancelPayload });
                }
                setShowDSCancelFailModal({
                  modalVisible: false,
                  errorMessage: "",
                });
              }}
              secondaryhandler={() => {
                setShowDSCancelFailModal({
                  modalVisible: false,
                  errorMessage: "",
                });
                setCancelType("");
              }}
              Localise={Localise}
              messages={locMessages}
            />
          ) : null}

          <FormContainer
            index={index}
            triggerRequest={(payload) => {
              if (
                direction === "INBOUND" &&
                selectedAction === "cancel" &&
                (isDSSubmitted ||
                  orderDsRequestRerouting(
                    pick(orderDetails, ["latestDSRequestedStatus"])
                  ))
              ) {
                //local order cancel request when DS/reroute inprogress
                setCancelType("request");
                setCancelPayload({ ...payload });
                setShowCancelDSModal(true);
              } else {
                setRequestTriggered({ ...payload });
              }
            }}
            actionCallback={actionCallback}
            sourceMemberCode={sourceMemberCode}
            setMenuAction={setMenuAction}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            isFormActions={isFormActions}
            showPayment={showPayment}
          />
          {orderActionInfo?.length > 0 && (
            <Text
              style={{ fontStyle: "italic", lineHeight: 20 }}
              testID="order-user-action"
            >
              {orderActionInfo}
            </Text>
          )}
          {isCancelPendingApproval && (
            <>
              <CancelDSModal
                isOrderReject={false}
                isOrderCancelApproval={true}
                isCancelDSModal={isCancelDSModal}
                setShowCancelDSModal={setShowCancelDSModal}
                setShowReasonForm={setShowReasonForm}
                setSelectedAction={setSelectedAction}
                triggerAction={(payload) => {
                  if (isDSSubmitted && direction === "INBOUND") {
                    setCancelType("action");
                    setCancelPayload({ ...payload });
                    cancelDSRequestAndOrder(() =>
                      setActionTriggered({ ...payload })
                    );
                  } else {
                    setActionTriggered({ ...payload });
                  }
                }}
                cancelApprovalInfo={cancelApprovalInfo}
                secondaryhandler={() => setCancelType("")}
              />
              <View style={tw("flex flex-row pt-2")}>
                <Text style={{ fontStyle: "italic", paddingRight: 5 }}>
                  {Localise(locMessages, "Cancellation Approval Pending")}
                  {": "}
                </Text>
                <Text
                  style={{
                    ...fonts.link1,
                    ...(isDisabled && {
                      pointerEvents: "none",
                      opacity: 0.4,
                    }),
                  }}
                  testID="cancel-approval"
                  accessibilityLabel="cancel-approval"
                  onPress={() => {
                    if (isDisabled) return;
                    setIsDisabled(true);
                    if (isDSSubmitted) {
                      setShowCancelDSModal(true);
                    } else {
                      setActionTriggered({
                        action: "cancel-approval",
                        askId: cancelApprovalInfo.askId,
                      });
                    }
                  }}
                >
                  {Localise(locMessages, "Approve")}
                </Text>
                {!isPickUpOrder && (
                  <Text
                    style={{
                      paddingLeft: 10,
                      ...fonts.link1,
                      opacity: isMOLCancelledOrder ? 0.4 : 1,
                      ...(isMOLCancelledOrder ||
                        (isDisabled && {
                          pointerEvents: "none",
                          opacity: 0.4,
                        })),
                    }}
                    testID="cancel-deny"
                    accessibilityLabel="cancel-deny"
                    onPress={() => {
                      setIsDisabled(true);
                      if (isMOLCancelledOrder || isDisabled) return;

                      setSelectedAction("cancel-deny");
                    }}
                  >
                    {Localise(locMessages, "Deny")}
                  </Text>
                )}
              </View>
            </>
          )}
          {(isFulfillmentRejectedOrder ||
            isFulfillmentErrorOrder ||
            hasSettlementError) && (
            <Text
              style={{
                lineHeight: 14,
                fontSize: 12,
                ...tw("pl-1 pb-0 font-semibold"),
                color: orderStatusColorCodes["fulfillment error"],
              }}
            >
              {Localise(
                locMessages,
                isFulfillmentRejectedOrder
                  ? "Filler rejected the order,"
                  : isFulfillmentErrorOrder
                  ? "Filler unavailable,"
                  : hasSettlementError
                  ? "Payment processing error."
                  : ""
              )}{" "}
              <Text
                style={{
                  ...fonts.link1,
                  lineHeight: 14,
                  fontSize: 12,
                  ...tw("pb-0"),
                  color: orderStatusColorCodes["fulfillment error"],
                }}
                onPress={() => {
                  if (!hasSettlementError) {
                    dispatch(setActiveTab("order-summary"));
                    const sideCarInfo = {
                      memberCodes: [sendingMember.memberCode],
                      deliveryInfo: { deliveryDate },
                      lineItems,
                      recipientInfo: {
                        zip,
                        addressLine1,
                        suite,
                        city,
                        state,
                        country,
                      },
                      price,
                    };
                    if (!isPastDate) {
                      setActionTriggered({
                        action: `selectNewFlorist_${JSON.stringify(
                          sideCarInfo
                        )}_`,
                      });
                    } else {
                      ToasterHandler(
                        "uh oh",
                        Localise(
                          messages,
                          "Please change the delivery date before sending to a new florist"
                        )
                      );
                    }
                  } else {
                    // Open payment tab and Focus to Payment option for payment errored orders
                    dispatch(setActiveTab("payment"));
                    scrollToEndOfPage();
                  }
                }}
              >
                {Localise(
                  locMessages,
                  !hasSettlementError
                    ? "Select New Florist"
                    : "Select New Payment Method"
                )}
              </Text>
            </Text>
          )}
          {hasDSFulfillmentError && (
            <Text
              style={{
                lineHeight: 14,
                ...tw("pl-1 pb-0 font-semibold"),
                color: orderStatusColorCodes["fulfillment error"],
              }}
            >
              {Localise(locMessages, "Rejected by delivery service")}
            </Text>
          )}
        </View>
      </View>
    );
  }
);

export default OrderHead;
