import * as Yup from "yup";

export const InitViewMoreCounts = (ordersInRoute, nonRoutedOrders) => {
  return { ordersInRoute, nonRoutedOrders };
};

// Advanced Route Optimization limits.
export const CFR_MAX_COUNT = 60;
export const CFR_MIN_COUNT = 25;

export const ListOfFilters = (minDays, maxDays, routeDeliveryDate) => ({
  "Date Range": [
    {
      title: "Custom Range",
      value: "custom-date-range",
      canSelectDates: true,
      minDays,
      maxDays,
      date: routeDeliveryDate,
    },
  ],
  "Order Status": [
    {
      title: "Designed",
      value: "designed",
    },
  ],
  Categories: [
    {
      title: "Timed Orders",
      value: "timedOrders",
    },
  ],
  "Individual Filters": [
    {
      title: "Hide Flagged Orders",
      value: "hideFlaggedOrders",
    },
    {
      title: "Date Change Pending requests",
      value: "dateChangePending",
    },
  ],
});

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    routeNameAlias: Yup.string().required(
      Localise(messages, "Please enter Route Name")
    ),
  });
