import React, { useEffect, useRef } from "react";
import { Platform, View } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import { CustomModal } from "components/elements";
import { colors, backgroundColors } from "styles/theme";
import Environment from "library/utils/environment";

import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  memberEntitlements,
  isMHQNonCoreMember,
} from "library/utils/entitlements";
import UtilStorage from "library/storage/utilStorage";
import { selectShopDetails } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  UIConfig,
  getInitialValues,
  nonStandardEligiblePrinterSettings,
} from "./config";
import SettingItem from "./setting";
import RemotePrintSettings from "../remote-print-settings";

const PrinterSettings = ({
  shopCode,
  shopSettings,
  saveSettings,
  loadingKey,
}) => {
  const remotePrintIntervalEnabled = Environment.get(
    "REMOTE_PRINT_INTERVAL_ENABLED",
    false
  );

  const macAddressOnLoad =
    Platform.OS === "web" && document.getElementById("macAddress").value;
  const { messages, Localise } = React.useContext(I18NContext);
  const [printers, setPrinters] = useStateIfMounted([]);
  const [macAddress, setMacAddress] = useStateIfMounted(macAddressOnLoad);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [remotePrintModalVisible, setRemotePrintModalVisibile] =
    useStateIfMounted(false);
  const remotePrintArtifactValuesRef = useRef({});

  const printersList = JSON.parse(
    document.getElementById("printers_list").value
  );
  const printersInfo = printersList.printersList || [];
  const initialValues = getInitialValues(shopSettings);
  const shopDetails = useSelector(selectShopDetails);
  const isMHQNonCoreShop = isMHQNonCoreMember(shopCode);

  const isMOLOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.MERCURY_ONLINE;
  const isDeliveryServiceOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.DELIVERY_SERVICE;

  useEffect(() => {
    //If macAddress not found relaod once. still not found, show message to close and open app
    setTimeout(() => {
      const currentRetryCount = UtilStorage.getMacAddressRetryCount(
        "MacAddressRetryCount"
      );
      const currentMacAddress =
        Platform.OS === "web" && document.getElementById("macAddress").value;
      console.log(
        "currentRetryCount and macAddress - ",
        currentRetryCount,
        currentMacAddress
      );
      if (!currentMacAddress) {
        if (currentRetryCount === 0) {
          UtilStorage.setMacAddressRetryCount(currentRetryCount + 1);
          window.location.reload();
        } else {
          setTimeout(() => {
            const macId =
              Platform.OS === "web" &&
              document.getElementById("macAddress").value;
            if (!macId) {
              setModalVisibile(true);
            } else {
              UtilStorage.setMacAddressRetryCount(0);
              setMacAddress(macId);
            }
          }, [5000]);
        }
      } else {
        setMacAddress(currentMacAddress);
      }
    }, [5000]);

    const printerNames = [];
    !isEmpty(printersInfo) &&
      printersInfo.map((each) =>
        printerNames.push({ label: each.printerName, value: each.printerName })
      );
    setPrinters(printerNames);
  }, []);

  const handleRefresh = () => {
    UtilStorage.setMacAddressRetryCount(0); //Reset
    setModalVisibile(false);
  };

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
        }}
      >
        {Localise(
          messages,
          "Mercury HQ encountered an error loading printer settings. Please close and restart the application."
        )}
      </Text>
    ),
    buttons: [{ type: "primary", title: Localise(messages, "Ok") }],
  };

  const remoteModalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(
          messages,
          "Remote printing for this document is already enabled on another device for this shop. Would you like to override this setting?"
        )}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "No") },
      { type: "primary", title: Localise(messages, "Yes") },
    ],
  };

  return (
    <View>
      {remotePrintIntervalEnabled && (
        <RemotePrintSettings
          shopSettings={shopSettings}
          saveSettings={saveSettings}
          loadingKey={loadingKey}
          macAddress={macAddress}
        />
      )}
      {Object.keys(UIConfig).map((key, id) => {
        const { title, id: settingId } = UIConfig[key];
        const savedMacAddress = initialValues[settingId].macAddress;
        if (
          (!isMHQNonCoreShop &&
            ![...nonStandardEligiblePrinterSettings].includes(settingId)) ||
          ((isMOLOnlyUser || isDeliveryServiceOnlyUser) &&
            settingId !== "pickup_manifest_print_settings") ||
          (savedMacAddress && savedMacAddress !== macAddress)
        )
          return null;

        return (
          <View title={title} key={id} style={tw("py-2")}>
            <SettingItem
              title={title}
              settingId={settingId}
              initialValues={initialValues[settingId]}
              loadingKey={loadingKey}
              printers={printers}
              saveSettings={saveSettings}
              shopSettings={shopSettings}
              printersInfo={printersInfo}
              shopCode={shopCode}
              macAddress={macAddress}
              setRemotePrintModalVisibile={setRemotePrintModalVisibile}
              remotePrintArtifactValuesRef={remotePrintArtifactValuesRef}
            />
          </View>
        );
      })}
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={handleRefresh}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
      ></CustomModal>
      {remotePrintModalVisible && (
        // Error popup for cross combination in an order(i.e, gift-card and products)
        <CustomModal
          modalVisible={remotePrintModalVisible}
          modalContent={remoteModalContent}
          primaryhandler={() => {
            const {
              settingId,
              initailPreferences,
              hasMultiplePreferences,
              initialValuesIndex,
            } = remotePrintArtifactValuesRef.current;

            // Clone the initial preferences to avoid modifying the original array
            const clonedInitialPreferences = [...initailPreferences];

            // Update each preference's remote_print based on the initialValuesIndex
            const updatedPreferences = clonedInitialPreferences.map(
              (preference, index) => ({
                ...preference,
                remote_print: index === initialValuesIndex,
              })
            );

            // Apply logic based on the number of preferences
            let newValues;
            if (hasMultiplePreferences) {
              // Use the fully updated preferences array
              newValues = updatedPreferences;
            } else {
              // Only use the entry at initialValuesIndex
              newValues = [updatedPreferences[initialValuesIndex]];
            }

            // Set up preferences for saving
            const preferences = [
              {
                id: settingId,
                values: [JSON.stringify(newValues)],
              },
            ];

            // Save settings with feedback
            saveSettings(
              {
                preferences,
              },
              true,
              false,
              () => {
                remotePrintArtifactValuesRef.current = {};
                setRemotePrintModalVisibile(false);
              },
              `Remote Print has been ${
                updatedPreferences[initialValuesIndex].remote_print
                  ? "enabled"
                  : "disabled"
              }`
            );
          }}
          secondaryhandler={() => {
            remotePrintArtifactValuesRef.current = {};
            setRemotePrintModalVisibile(false);
          }}
          contentStyle={[
            tw("border border-black p-4"),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
          isReduceBtnSpacing={true}
        ></CustomModal>
      )}
    </View>
  );
};

export default PrinterSettings;
