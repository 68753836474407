/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect, useContext } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "react-native-elements";

import {
  setLoading,
  setPageActions,
  fetchPrintDetails,
  triggerMeetBallAction,
  followupOrder,
} from "library/sagas/views/home/drawer/orders-new/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import {
  selectActions,
  selectZones,
} from "library/sagas/views/home/drawer/orders-new/selector";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import UserProfileContext from "library/contexts/userProfile";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import I18NContext from "library/contexts/i18N";
import {
  getOrderInternalStatus,
  orderDsRequestRerouting,
  isDSorder,
} from "library/utils/orderListing";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";
import { getShopSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";
import { getActionObj } from "components/views/drawer/order-details/helper";
import {
  printActions,
  invoicePrintAction,
  designerWorksheetAction,
} from "components/views/drawer/order-details/order-head/take-action/helper";
import { getOrderStatus } from "components/views/drawer/order-details/order-head/order-breadcrumb/helper";
import { isFloristDelivered } from "components/views/drawer/order-details/delivery-info/helper.js";
import { ToasterHandler, Tooltip } from "components/elements";

import { useNavigation, CommonActions } from "@react-navigation/native";
import IMAGES from "static/assets/images";
import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import upperFirst from "lodash/upperFirst";
import capitalize from "lodash/capitalize";

import { printTrigger } from "../../order-details/order-head/take-action/helper";
import {
  needsActionMappings,
  oldPrintActionKeys,
  newPrintActionKeys,
  columnIDs,
  meetBallActionKeys,
  getDeliveryMethod,
} from "../config";
import "../styles.css";

const NeedsActionToolTip = ({ position, actions }) => {
  return ReactDOM.createPortal(
    <div
      className="custom-tooltip"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      {actions.map((e) => {
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 10,
                marginRight: 5,
                paddingHorizontal: 10,
                width: 150,
                height: 26,
                backgroundColor: "white",
                borderWidth: 1,
                borderColor: needsActionMappings[e.type].color,
                borderRadius: 8,
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode={"contain"}
                source={IMAGES[needsActionMappings[e.type].image]}
              />
              <View style={{ marginRight: 5 }} />
              <Text
                style={{
                  flex: 1,
                  textAlign: "center",
                  color: needsActionMappings[e.type].color,
                }}
              >
                {needsActionMappings[e.type].label}
              </Text>
            </View>
          </View>
        );
      })}
    </div>,
    document.body
  );
};

const MeetballActions = ({
  position,
  actions,
  meetBallsPopupRef,
  triggerAction,
}) => {
  return ReactDOM.createPortal(
    <div
      ref={meetBallsPopupRef}
      className="custom-tooltip"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        visibility: position.visibility,
      }}
    >
      <View>
        {actions.map((e, i) => {
          return (
            <TouchableOpacity
              key={i}
              onPress={() => triggerAction(e.key)}
              style={{ padding: 5 }}
            >
              <Text>{e.label}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </div>,
    document.body
  );
};

const PrintActions = ({
  position,
  printActionsPopupRef,
  activePrintActions,
  printDetails,
  triggerPrint,
}) => {
  return ReactDOM.createPortal(
    <div
      ref={printActionsPopupRef}
      className="custom-tooltip"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        visibility: position.visibility,
      }}
    >
      <View style={{ alignItems: "flex-start" }}>
        {activePrintActions.map(({ key, label }, i) => {
          return (
            <TouchableOpacity
              key={i}
              onPress={() => triggerPrint(oldPrintActionKeys[key])}
              style={{ flexDirection: "row", alignItems: "center", padding: 5 }}
            >
              <Text style={{ width: 100 }}>{label}</Text>
              {printDetails[newPrintActionKeys[key]] && (
                <Image
                  style={{ width: 15, height: 15 }}
                  resizeMode={"contain"}
                  source={IMAGES["confirm"]}
                />
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    </div>,
    document.body
  );
};

/* AG-Grid column cells */

export const CustomHeaderComponent = (props) => {
  const isFilterInActive =
    isEmpty(props?.column?.colDef?.appliedFilter ?? {}) &&
    isEmpty(props?.column?.colDef?.appliedSort ?? "") &&
    isEmpty(props?.column?.colDef?.appliedSearch ?? "");
  const filterIconRef = useRef(null);

  const openFilter = () => {
    filterIconRef.current && props.showColumnMenu(filterIconRef.current);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>{props.displayName}</span>
      <div
        ref={filterIconRef}
        onClick={openFilter}
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: 8,
          cursor: "pointer",
          ...(!isFilterInActive
            ? {
                backgroundColor: "#DFE5EA",
                padding: 5,
                borderRadius: 5,
                shadowColor: "#000000",
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowRadius: 5,
                shadowOpacity: 1.0,
              }
            : {}),
        }}
      >
        <Image
          style={{ width: 12, height: 12 }}
          resizeMode={"contain"}
          source={IMAGES["column_filter"]}
        />
        <span
          style={{
            position: "absolute",
            right: 4,
            top: 13,
            width: 8,
            height: 8,
            backgroundColor: isFilterInActive ? "transparent" : "#1F4256",
            borderRadius: "50%",
            marginRight: 4,
          }}
        ></span>
      </div>
    </div>
  );
};

export function CustomFilterComponent(props) {
  const dispatch = useDispatch();
  const { gridState = [] } = useSelector(selectActions);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onDateChange = (dates, filterVal) => {
    const [start, end] = dates;
    const startDateFormatted = moment(start).format("YYYY-MM-DD");
    const endDateFormatted = moment(end).format("YYYY-MM-DD");

    setStartDate(start);
    setEndDate(end);

    start &&
      end &&
      updateFilterState(
        `${filterVal}::${startDateFormatted}::${endDateFormatted}`
      );
  };

  const { filterOptions = [], colDef: { colId = "" } = {} } = props;
  const searchValue =
    gridState?.find((e) => e?.colId === colId)?.appliedSearch?.split("::")[1] ||
    "";

  const updateFilterState = (filter = "", checked = false) => {
    dispatch(
      setPageActions({ type: "saveColumnFilter", value: filter, checked })
    );
  };

  const getFilterStatus = (colId, rowId, label) => {
    const filterState =
      gridState?.find((e) => e?.colId === colId)?.appliedFilter?.[rowId] || [];
    return filterState.includes(label);
  };

  useEffect(() => {
    if (gridState.length && colId === "fulfillment_date") {
      const { startDate = "", endDate = "" } =
        gridState?.find((e) => e?.colId === "fulfillment_date")
          ?.appliedFilter || {};

      if (startDate.length && endDate.length) {
        const start = new Date(moment(startDate).format("MM/DD/YYYY"));
        const end = new Date(moment(endDate).format("MM/DD/YYYY"));

        setStartDate(start);
        setEndDate(end);
      }
    }
  }, []);

  return (
    <View style={{ padding: 10 }}>
      <Input
        testID={`${colId}_search_input`}
        onFocus={() => {
          Platform.OS === "web" &&
            document
              .querySelector(`[data-testid=${colId}_search_input]`)
              .select();
        }}
        containerStyle={{ paddingHorizontal: 0 }}
        inputContainerStyle={{
          height: 30,
          marginVertical: 5,
        }}
        errorStyle={{ paddingBottom: 0 }}
        onChangeText={(val) => {
          dispatch(
            setPageActions({
              type: "saveColumnSearch",
              value: `${colId}::${val}`,
              callback: () => {
                [
                  columnIDs.order_id,
                  columnIDs.customer,
                  columnIDs.products,
                  columnIDs.recipient,
                ].includes(colId) && props.filterChangedCallback();
              },
            })
          );
        }}
        value={searchValue}
        placeholder={"Search.."}
        leftIcon={
          <Image
            style={{ width: 12, height: 12 }}
            resizeMode={"contain"}
            source={IMAGES["search"]}
          />
        }
      />
      {filterOptions.map((e, i) => {
        const refinedOptions = e.filters.filter(
          (e) =>
            e.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            e.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        if (!refinedOptions.length) return null;

        return (
          <View style={{ paddingVertical: 5 }} key={i}>
            <Text style={{ fontSize: 14, fontWeight: 700, marginBottom: 5 }}>
              {e.heading}
            </Text>
            {refinedOptions.map(({ label, name }, j) => {
              const checked = getFilterStatus(colId, e.rowId, label);

              return (
                <React.Fragment key={j}>
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      updateFilterState(
                        `${colId}::${e.rowId}::${label}`,
                        !checked
                      );
                    }}
                  >
                    <div
                      className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
                        checked ? "ag-checked" : ""
                      }`}
                      role="presentation"
                    >
                      <input
                        className="ag-input-field-input ag-checkbox-input"
                        type="checkbox"
                      />
                    </div>
                    <View style={{ marginRight: 5 }} />
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        marginVertical: 5,
                      }}
                    >
                      {name}
                    </Text>
                  </TouchableOpacity>
                  {label === "customDatePicker" && checked && (
                    <View style={{ paddingTop: 10 }}>
                      <DatePicker
                        selected={startDate}
                        onChange={(dates) =>
                          onDateChange(dates, `${colId}::${e.rowId}::${label}`)
                        }
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                    </View>
                  )}
                </React.Fragment>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

export const StatusCellRenderer = (props) => {
  const needsActionRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const {
    id = "",
    group = false,
    status = "",
    actions = [],
  } = props?.data ?? {};
  const editorValues = props?.colDef?.cellEditorParams?.values ?? [];
  const renderValue = editorValues.find(
    (e) => e?.value?.toLowerCase() === status?.toLowerCase()
  );
  const { label = "", value = "", color = "" } = renderValue ?? {};
  const severityOrder = { Error: 1, Warning: 2, Information: 3 };
  const sortedActions = [...actions]
    .sort((a, b) => severityOrder[a.severity] - severityOrder[b.severity])
    .filter((e) => e.type !== "FOLLOW_UP");

  const handleTooltipToggle = () => {
    const rect = needsActionRef.current.getBoundingClientRect();
    setTooltipPosition({
      top: rect.bottom + 10,
      left: rect.x - 75,
    });
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const gridXasisElement = document.querySelector(".ag-center-cols-viewport");
    const gridYaxisElement = document.querySelector(".ag-body-viewport");

    const handleScroll = () => {
      showTooltip && setShowTooltip(false);
    };

    gridXasisElement?.addEventListener("scroll", handleScroll);
    gridYaxisElement?.addEventListener("scroll", handleScroll);

    return () => {
      gridXasisElement?.removeEventListener("scroll", handleScroll);
      gridYaxisElement?.removeEventListener("scroll", handleScroll);
    };
  }, [showTooltip]);

  useEffect(() => {
    const handleClickOutside = () => {
      setShowTooltip(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (group || id.includes("invalid")) return null;

  return (
    <div className="custom-cell">
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 10,
          paddingHorizontal: 10,
          width: 150,
          height: 26,
          backgroundColor: "white",
          borderWidth: 1.2,
          borderColor: color,
          borderRadius: 8,
        }}
      >
        <Image
          style={{ width: 20, height: 20 }}
          resizeMode={"contain"}
          source={IMAGES[label.toLowerCase()]}
        />
        <View style={{ marginRight: 5 }} />
        <Text style={{ flex: 1, textAlign: "center", color }}>{value}</Text>
      </View>
      {sortedActions.length > 0 && (
        <>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 10,
                marginRight: 5,
                paddingHorizontal: 10,
                width: 150,
                height: 26,
                backgroundColor: "white",
                borderWidth: 1.2,
                borderColor: needsActionMappings[sortedActions[0].type].color,
                borderRadius: 8,
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode={"contain"}
                source={
                  IMAGES[needsActionMappings[sortedActions[0].type].image]
                }
              />
              <View style={{ marginRight: 5 }} />
              <Text
                style={{
                  flex: 1,
                  textAlign: "center",
                  color: needsActionMappings[sortedActions[0].type].color,
                }}
              >
                {needsActionMappings[sortedActions[0].type].label}
              </Text>
            </View>
            {(sortedActions?.length ?? 0) > 1 && (
              <TouchableOpacity
                ref={needsActionRef}
                onPress={handleTooltipToggle}
              >
                <Text
                  style={{
                    flex: 1,
                    textAlign: "center",
                    color: needsActionMappings[sortedActions[0].type].color,
                  }}
                >
                  +{(sortedActions?.length ?? 0) - 1}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {showTooltip && (
            <NeedsActionToolTip
              position={tooltipPosition}
              actions={sortedActions}
            />
          )}
        </>
      )}
    </div>
  );
};

export const OrderCellRenderer = (props) => {
  const {
    id = "",
    group = false,
    order_number = 0,
    external_id = "",
    fulfillment_details = {},
  } = props?.data ?? {};

  if (group || id.includes("invalid")) return null;

  const { rush = false } = fulfillment_details || {};

  return (
    <View style={{ flexDirection: "row", marginTop: 5 }}>
      {rush && (
        <Image
          style={{ width: 25, height: 25 }}
          resizeMode={"contain"}
          source={IMAGES["timed-delivery-progress"]}
        />
      )}
      <View style={{ marginRight: 5 }} />
      <View>
        <Text style={{ fontSize: 12 }}>{order_number}</Text>
        <View style={{ marginBottom: 5 }} />
        <Text style={{ fontSize: 12 }}>{external_id}</Text>
      </View>
    </View>
  );
};

export const DateCellRenderer = (props) => {
  const {
    id = "",
    group = false,
    fulfillment_date = "",
    fulfillment_time = "",
    delivery_instructions = "",
    eros_details = {},
  } = props?.data ?? {};

  if (group || id.includes("invalid")) return null;

  const { latest_requested_date = "", latest_date_request_status = "" } =
    eros_details || {};
  const displayDate =
    latest_requested_date.length > 0 ? latest_requested_date : fulfillment_date;
  const color =
    latest_date_request_status === "APPROVED"
      ? "green"
      : latest_date_request_status === "DENIED"
      ? "red"
      : latest_date_request_status
      ? "gray"
      : "black";

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{moment(displayDate).format("MM/DD/YYYY")}</Text>
      {latest_date_request_status.length > 0 && (
        <>
          <View style={{ marginBottom: 5 }} />
          <Text style={{ color }}>
            {latest_date_request_status === "CHECK_RESPONSE"
              ? "Check Response"
              : capitalize(latest_date_request_status)}
          </Text>
        </>
      )}
      {fulfillment_time && (
        <>
          <View style={{ marginBottom: 5 }} />
          <Text style={{ color }}>
            {moment
              .utc(displayDate + " " + fulfillment_time)
              .local()
              .format("hh:mm A")}
          </Text>
        </>
      )}
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{delivery_instructions}</Text>
    </View>
  );
};

export const CustomerCellRenderer = (props) => {
  const { group = false, contact = {} } = props?.data ?? {};
  const {
    id = "",
    first_name = "",
    last_name = "",
    email = "",
    phone = "",
  } = contact || {};

  if (group || !contact || id.includes("invalid")) return null;

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text>{upperFirst(first_name) + " " + upperFirst(last_name)}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text>{email}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text>
        {phone && phone.length === 10
          ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
          : phone}
      </Text>
    </View>
  );
};

export const RecipientCellRenderer = (props) => {
  const { id = "", group = false, recipient = {} } = props?.data ?? {};
  const {
    first_name = "",
    last_name = "",
    phone = "",
    email = "",
    address: {
      address_line1 = "",
      address_line2 = "",
      county = "",
      state = "",
      city = "",
      zip = "",
      avs_confidence = "",
    } = {},
  } = recipient || {};
  const isAddressVerified = avs_confidence === "HIGH";

  if (group || !recipient || id.includes("invalid")) return null;

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text>{upperFirst(first_name) + " " + upperFirst(last_name)}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text>{email}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text>
        {phone && phone.length === 10
          ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
          : phone}
      </Text>
      <View style={{ marginBottom: 5 }} />
      <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
        <Tooltip text={isAddressVerified ? "Verified" : "Not Verified"}>
          <Image
            style={{ width: 18, height: 18 }}
            resizeMode={"contain"}
            source={
              IMAGES[
                isAddressVerified
                  ? "check-mark-green-fill-color"
                  : "address-not-verified-exclamation"
              ]
            }
          />
        </Tooltip>
        <View style={{ marginRight: 2 }} />
        <Text>
          {[address_line1, address_line2, county, state, city, zip]
            .filter(Boolean)
            .join(", ")}
        </Text>
      </View>
      <View style={{ marginBottom: 1 }} />
    </View>
  );
};

export const ProductCellRenderer = (props) => {
  const { id = "", group = false, products = [] } = props?.data ?? {};

  if (group || !products.length || id.includes("invalid")) return null;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingVertical: 5,
      }}
    >
      {products[0].image && (
        <>
          <Image
            style={{ width: 60, height: 60, borderRadius: 10 }}
            resizeMode={"contain"}
            source={products[0].image}
          />
          <View style={{ marginLeft: 10 }} />
        </>
      )}
      <View
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Text>{products[0]?.product_id ?? ""}</Text>
        <View style={{ marginBottom: 5 }} />
        <Text>{products[0]?.product_description ?? ""}</Text>
        {products?.length > 1 && (
          <>
            <View style={{ marginBottom: 5 }} />
            <Text>{`${products?.length ?? 0} Items`}</Text>
          </>
        )}
      </View>
    </View>
  );
};

export const PriceCellRenderer = (props) => {
  const {
    id = "",
    group = false,
    totals: { fees = 0, taxes = 0, fill_total = 0, total = 0 } = {},
    eros_details = {},
  } = props?.data ?? {};

  if (group || id.includes("invalid")) return null;

  const { latest_requested_price = "", latest_price_request_status = "" } =
    eros_details || {};
  const displayTotal =
    latest_requested_price.length > 0 ? latest_requested_price : total;
  const color =
    latest_price_request_status === "APPROVED"
      ? "green"
      : latest_price_request_status === "DENIED"
      ? "red"
      : latest_price_request_status
      ? "gray"
      : "black";

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>${fill_total}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>${(fees + taxes).toFixed(2)}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>${displayTotal}</Text>
      {latest_price_request_status.length > 0 && (
        <>
          <View style={{ marginBottom: 5 }} />
          <Text style={{ color }}>
            {latest_price_request_status === "CHECK_RESPONSE"
              ? "Check Response"
              : capitalize(latest_price_request_status)}
          </Text>
        </>
      )}
    </View>
  );
};

export const ActionsCellRenderer = (props = {}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);
  const { userRole } = useContext(UserProfileContext);
  const { userProfile: { firstName: operator } = {} } =
    useContext(UserProfileContext);
  const meetballsRef = useRef(null);
  const printActionsRef = useRef(null);
  const meetBallsPopupRef = useRef(null);
  const printActionsPopupRef = useRef(null);

  const [showMeetBallActions, setShowMeetBallActions] = useState(false);
  const [showPrintActions, setShowPrintActions] = useState(false);
  const [meetBallActionsPosition, setMeetBallActionsPosition] = useState({
    top: 0,
    left: 0,
    visibility: "hidden",
  });
  const [printActionsPosition, setPrintActionsPosition] = useState({
    top: 0,
    left: 0,
    visibility: "hidden",
  });

  const {
    data: {
      id: rowId = "",
      group = false,
      external_id: recordId = "",
      member_code: sourceMemberCode = "",
      fulfillment_type = "",
      fulfillment_date: deliveryDate = "",
      status = "",
      print_details: printDetails = {},
      designer = {},
      actions = [],
      notes = "",
      source = "",
      eros_details = {},
      fulfillment_details = {},
      products = [],

      latestDSRequestedStatus = "",
      numOfReadChatMessages = 0,
    } = {},
  } = props;

  const { delivery_service_eligibility = "" } = fulfillment_details || {};
  const followUpFlag =
    actions.find((e) => e.type === "FOLLOW_UP") !== undefined;
  const deliveryMethod = getDeliveryMethod(
    source,
    fulfillment_type,
    eros_details
  );
  const isPickupOrder = [
    "STORE_PICKUP",
    "MOL_CUSTOMER_PICKUP",
    "PARTNER_STORE_PICKUP",
  ].includes(deliveryMethod);
  const isLocalOrder = isFloristDelivered(deliveryMethod);
  const dsEligible = delivery_service_eligibility === "ELIGIBLE";
  const autoSendToDS = dsEligible && fulfillment_type === "Delivery Service";
  const isDSSubmitEligible =
    dsEligible &&
    autoSendToDS &&
    !isDSorder(latestDSRequestedStatus, status.toUpperCase());
  const direction = fulfillment_type === "Wire Out" ? "OUTBOUND" : "INBOUND";
  const giftCardType =
    products.find(
      (e) =>
        e.product_type === "GIFT_CARD" || e.product_type === "GIFT_CARD_DIGITAL"
    )?.product_type || "";
  const hasDSFulfillmentError =
    actions.find((e) => e.type === "DS_FULFILLMENT_ERROR") !== undefined;
  const hasSettlementError =
    actions.find((e) => e.type === "SETTLEMENT_ERROR") !== undefined;
  const numOfUnreadChatMessages =
    actions.find((e) => e.type === "CHAT_MESSAGE") !== undefined ? 1 : 0;
  const numOfUnreadStructuredResponses =
    actions.find((e) => e.type === "RESPONSE_MESSAGE") !== undefined ? 1 : 0;

  // Print Actions
  const memberCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.ORDERS in permissions[memberCode])
      memberCodes.push(memberCode);
  });
  const isMHQNonCoreUser = isMHQNonCoreMember(sourceMemberCode);
  const isDesignerWorksheetEnabled = Environment.get(
    "ENABLE_DESIGNER_WORKSHEET",
    true
  );
  const activePrintActions = isMHQNonCoreUser
    ? [
        ...printActions,
        ...invoicePrintAction,
        ...(isDesignerWorksheetEnabled ? designerWorksheetAction : []),
      ]
    : [...printActions];

  // Meetball Actions
  let internalSubStatus = "";
  if (numOfUnreadChatMessages > 0 || numOfUnreadStructuredResponses > 0)
    internalSubStatus = "view-chats";
  else if (numOfReadChatMessages > 0) internalSubStatus = "review-chats";

  const subActions = get(ListOfSubActions, internalSubStatus, []);
  const showAdjustmentaction =
    moment().isBefore(moment(deliveryDate).add(90, "days")) &&
    moment(deliveryDate).month() !== moment().month();
  const adjustmentAction =
    showAdjustmentaction &&
    direction === "OUTBOUND" &&
    deliveryMethod !== "PHONE_OUT" &&
    !["CANCELLED", "REJECTED", "FORFEITED", "ERROR"].includes(
      status.toUpperCase()
    )
      ? [{ key: "adjustment", label: "Adjustment" }]
      : [];
  const internalStatus = getOrderStatus({
    status: status.toUpperCase(),
    isPickUpOrder: isPickupOrder,
  });
  const rejectAction =
    [
      "incoming-pickup",
      "accepted-pickup",
      "design-pickup",
      "designed-pickup",
      "delivery-pickup",
    ].includes(internalStatus) &&
    !isLocalOrder &&
    source !== "Mercury Online"
      ? [{ key: "reject", label: "Reject" }]
      : [];
  const isGiftCard = ["DIGITAL_GIFT_CARDS", "PHYSICAL_GIFT_CARDS"].includes(
    giftCardType
  );
  const cancelAction =
    ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(
      deliveryMethod
    ) &&
    status.toUpperCase() !== "CANCELLED" &&
    isLocalOrder &&
    !isGiftCard
      ? [{ key: "cancel", label: "Cancel" }]
      : [];
  const dsRerouteInProgress = orderDsRequestRerouting({
    latestDSRequestedStatus,
  });
  const dsErrorAction =
    direction === "INBOUND" &&
    (hasDSFulfillmentError || dsRerouteInProgress) &&
    status.toUpperCase() !== "DELIVERED"
      ? [{ key: "delivery-confirmation", label: "Confirm Delivery" }]
      : [];
  const hasDesignCenter = hasDesignCenterFeature(permissions, sourceMemberCode);
  const hasDesigner = !!designer && designer?.id;
  const assignToDesigner =
    direction === "INBOUND" &&
    hasDesignCenter &&
    ["accepted", "in-design", "accepted-pickup", "design-pickup"].includes(
      internalStatus
    ) &&
    !hasDesigner &&
    !hasSettlementError
      ? [{ key: "assign-to-designer", label: "Assign to Designer" }]
      : [];
  const settlementAction =
    hasSettlementError ||
    ["CANCELLED", "REJECTED", "FORFEITED"].includes(status.toUpperCase())
      ? [{ key: "copy-order", label: "Copy Order" }]
      : [];

  const coreActionsList = {};
  if (sourceMemberCode?.length && !hasDesignCenter) {
    // Removing "designed" objects from the each status array
    Object.values(ListOfActions).forEach((value, index) => {
      const updatedArray = value.filter(
        (item) => item.key !== "designed" && item.key !== "designed-pickup"
      );
      coreActionsList[Object.keys(ListOfActions)[index]] = updatedArray;
    });
  }
  const finalActionsList = hasDesignCenter ? ListOfActions : coreActionsList;
  const finalActions = get(
    finalActionsList,
    isPickupOrder
      ? getOrderInternalStatus({ status: status.toUpperCase() }, isPickupOrder)
      : direction === "INBOUND" || deliveryMethod === "PHONE_OUT"
      ? hasDSFulfillmentError || dsRerouteInProgress
        ? "dsfullfillmenterror"
        : status.toUpperCase() !== "FORWARDED"
        ? hasSettlementError
          ? []
          : internalStatus === "in-design"
          ? "design"
          : internalStatus
        : []
      : internalStatus !== "rejected" && internalStatus !== "cancelled"
      ? "outgoing"
      : "",
    []
  );
  const menuOptions = finalActions.concat(
    subActions,
    adjustmentAction,
    rejectAction,
    cancelAction,
    dsErrorAction,
    assignToDesigner,
    settlementAction
  );
  const meetballActions =
    (status.toUpperCase() !== "NEW" && status.toUpperCase() !== "VIEWED") ||
    isPickupOrder
      ? [
          ...menuOptions,
          {
            key: "printOrder",
            label: printDetails.details_printed
              ? "Reprint Order"
              : "Print Order",
          },
        ]
      : menuOptions;

  const triggerAction = (action) => {
    setShowMeetBallActions(false);
    dispatch(setLoading(true));

    if (action === "copy-order") {
      // Timeout to avoid the screen stuck while redirecting to create order
      setTimeout(() => {
        dispatch(setCurrentPage("create-order"));
        dispatch(setLoading(false));
        navigation.dispatch(
          CommonActions.navigate({
            name: "create-order",
            params: {
              id: recordId,
              action: "copy",
              smc: sourceMemberCode,
              dm: deliveryMethod,
            },
          })
        );
      }, 2000);
    } else if (
      [
        "accept-print",
        "printOrder",
        "triCardPrint",
        "invoice",
        "designerWorksheetPrint",
      ].includes(action)
    ) {
      dispatch(setLoading(false));
      triggerPrint(action === "printOrder" ? "print" : action);
    } else {
      const reqObj = getActionObj(deliveryDate, action, "");
      const isMarkRead = action === "markAsRead";
      const requestMethod = isMarkRead
        ? "order-message-actions"
        : "order-actions";
      const params = {
        recordId,
        deliveryMethod,
        sourceMemberCode,
        ...(requestMethod === "order-message-actions"
          ? {
              markAsRead: isMarkRead,
              isStructured: true,
              isUnstructured: true,
            }
          : {
              action,
              ...reqObj,
              operator,
            }),
      };

      dispatch(
        triggerMeetBallAction({
          requestMethod,
          params,
          callback: (error) => {
            if (error) {
              ToasterHandler(
                "uh oh",
                Localise("Something went wrong, Please try again later!")
              );
            } else {
              dispatch(setLoading(false));
              if (Object.keys(meetBallActionKeys).includes(params.action)) {
                props.node.setData({
                  ...props.data,
                  status: {
                    ...props.data.status,
                    order_status: meetBallActionKeys[params.action],
                  },
                });
                props.api.ensureColumnVisible(columnIDs.status);
                props.api.flashCells({
                  rowNodes: [props.node],
                  columns: [columnIDs.status],
                });
              }
            }
          },
        })
      );
    }
  };

  const triggerPrint = (action) => {
    setShowPrintActions(false);
    dispatch(setLoading(true));

    dispatch(
      fetchPrintDetails({
        rowId,
        recordId,
        deliveryMethod,
        sourceMemberCode,
        callback: ({ res, productsInfo }) => {
          const {
            deliveryInfo: { cardMessage = "" },
          } = res.orderItems[0];

          if (
            [
              "accept-print",
              "print",
              "triCardPrint",
              "invoice",
              "designerWorksheetPrint",
            ].includes(action)
          ) {
            // getting the latest printer-settings to support remote print
            dispatch(
              getShopSettings({
                params: {
                  memberCode: sourceMemberCode,
                  ids: isMHQNonCoreUser
                    ? PrinterSettingsKeys
                    : nonStandardEligiblePrinterSettings,
                  updateStorage: true,
                },
                resolve: () => {
                  dispatch(setLoading(false));
                  printTrigger({
                    selectedAction: action,
                    orderDetails: res.orderItems[0],
                    memberCodes,
                    operatorName: operator,
                    messages,
                    Localise,
                    customerInfo: res?.customerInfo,
                    paymentDetails: res?.paymentDetails,
                    dispatch,
                    selectedproducts: productsInfo,
                    cardInfo: res?.cardInfo,
                    imagesData: {},
                    cardMessage,
                    triggerAction,
                    orderDetailResponse: res,
                    ordersList: true,
                    deliveryInfo: get(res?.orderItems[0], `deliveryInfo`, {}),
                    recordId,
                    isDSSubmitEligible,
                    userRole,
                  });
                },
              })
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    if (showMeetBallActions) {
      const rect = meetballsRef.current.getBoundingClientRect();
      const tooltipWidth = meetBallsPopupRef.current.offsetWidth;

      setMeetBallActionsPosition({
        top: rect.bottom + 10,
        left: rect.left - (tooltipWidth + 28) / 2,
        visibility: "visible",
      });
    } else if (showPrintActions) {
      const rect = printActionsRef.current.getBoundingClientRect();
      const tooltipWidth = printActionsPopupRef.current.offsetWidth;

      setPrintActionsPosition({
        top: rect.bottom + 5,
        left: rect.left - (tooltipWidth - 18) / 2,
        visibility: "visible",
      });
    }

    const gridXasisElement = document.querySelector(".ag-center-cols-viewport");
    const gridYaxisElement = document.querySelector(".ag-body-viewport");

    const handleScroll = () => {
      showMeetBallActions && setShowMeetBallActions(false);
      showPrintActions && setShowPrintActions(false);
    };

    gridXasisElement?.addEventListener("scroll", handleScroll);
    gridYaxisElement?.addEventListener("scroll", handleScroll);

    return () => {
      gridXasisElement?.removeEventListener("scroll", handleScroll);
      gridYaxisElement?.removeEventListener("scroll", handleScroll);
    };
  }, [showMeetBallActions, showPrintActions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      meetBallsPopupRef.current &&
        !meetBallsPopupRef.current.contains(event.target) &&
        setShowMeetBallActions(false);
      printActionsPopupRef.current &&
        !printActionsPopupRef.current.contains(event.target) &&
        setShowPrintActions(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (group || rowId.includes("invalid")) return null;

  return (
    <div className="custom-cell">
      <View
        style={{
          flexDirection: "row",
          marginVertical: 5,
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => setShowMeetBallActions(!showMeetBallActions)}
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode={"contain"}
            source={IMAGES["meatballs_new"]}
          />
        </TouchableOpacity>
        <View style={{ marginRight: 5 }} />
        <TouchableOpacity
          ref={meetballsRef}
          onPress={() => triggerPrint("print")}
        >
          <Image
            style={{ width: 30, height: 30 }}
            resizeMode={"contain"}
            source={
              IMAGES[printDetails.details_printed ? "new_print_again" : "print"]
            }
          />
        </TouchableOpacity>
        <View style={{ marginRight: 5 }} />
        <TouchableOpacity
          ref={printActionsRef}
          onPress={() => setShowPrintActions(!showPrintActions)}
        >
          <Image
            style={{ width: 15, height: 15 }}
            resizeMode={"contain"}
            source={IMAGES["vector_new"]}
          />
        </TouchableOpacity>
        <View style={{ marginRight: 5 }} />
        <TouchableOpacity
          onPress={() => {
            dispatch(
              followupOrder({
                rowId,
                recordId,
                sourceMemberCode,
                deliveryMethod: deliveryMethod.replace(" ", "_").toUpperCase(),
                orderUpdates: [
                  {
                    op: "replace",
                    path: "/orderItems/0/isFollowUp",
                    value: !followUpFlag,
                  },
                ],
              })
            );
          }}
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode={"contain"}
            source={
              IMAGES[
                followUpFlag ? "orders_flag_checked" : "orders_flag_unchecked"
              ]
            }
          />
        </TouchableOpacity>
        {notes && notes.length > 0 && (
          <>
            <View style={{ marginRight: 5 }} />
            <Tooltip text={notes}>
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode={"contain"}
                source={IMAGES["order_notes"]}
              />
            </Tooltip>
          </>
        )}
      </View>
      {showMeetBallActions && (
        <MeetballActions
          position={meetBallActionsPosition}
          actions={meetballActions}
          meetBallsPopupRef={meetBallsPopupRef}
          triggerAction={triggerAction}
        />
      )}
      {showPrintActions && (
        <PrintActions
          position={printActionsPosition}
          printActionsPopupRef={printActionsPopupRef}
          activePrintActions={activePrintActions}
          printDetails={printDetails}
          triggerPrint={triggerPrint}
        />
      )}
    </div>
  );
};

export const DeliveryServiceCellRenderer = (props) => {
  const {
    id = "",
    group = false,
    fulfillment_details: {
      is_having_ds_request = false,
      delivery_service_eligibility = "",
    } = {},
  } = props?.data ?? {};

  if (group || id.includes("invalid")) return null;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: 5,
      }}
    >
      {delivery_service_eligibility === "ELIGIBLE" ? (
        <>
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode={"contain"}
            source={IMAGES["delivery-service-delivery"]}
          />
          <View style={{ marginRight: 5 }} />
          <Text>{is_having_ds_request ? "Requested" : "Not Requested"}</Text>
        </>
      ) : (
        <Text>{"Not Eligible"}</Text>
      )}
    </View>
  );
};

export const FullfillmentCellRenderer = (props) => {
  const {
    id = "",
    group = false,
    source = "",
    fulfillment_type = "",
    eros_details = {},
  } = props?.data ?? {};
  const { filler = "", sender = "" } = eros_details || {};

  if (group || id.includes("invalid")) return null;

  return (
    <View
      style={{
        alignItems: "flex-start",
        marginTop: 5,
      }}
    >
      <Text>{source}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text>{fulfillment_type}</Text>
      {!isEmpty(eros_details) && (
        <View>
          <View style={{ marginBottom: 5 }} />
          <Text>{sender}</Text>
          <View style={{ marginBottom: 5 }} />
          <Text>{filler}</Text>
        </View>
      )}
    </View>
  );
};

export const AssignmentsCellRenderer = (props) => {
  const zones = useSelector(selectZones);
  const {
    id = "",
    group = false,
    designer = {},
    fulfillment_details = {},
  } = props?.data ?? {};

  if (group || id.includes("invalid")) return null;

  const { delivery_zone_id = "" } = fulfillment_details || {};

  return (
    <View
      style={{
        alignItems: "flex-start",
        marginTop: 5,
      }}
    >
      <Text>{designer.name}</Text>
      {zones.length > 0 && (
        <>
          <View style={{ marginBottom: 5 }} />
          <Text>
            {zones.find((e) => e?.autoRouteId === delivery_zone_id)?.name || ""}
          </Text>
        </>
      )}
    </View>
  );
};

/* Column hidden cell */

export const DesignerCellRenderer = (props) => {
  const { designer = {} } = props?.data ?? {};

  return <Text>{designer.name}</Text>;
};
