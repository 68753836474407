const COMMON = Object.freeze({
  ACTIVE: "Active",
  AMOUNT: "Amount",
  AMOUNT_INVALID: "Invalid Amount",
  AMOUNT_REQUIRED: "Amount is required",
  CONTACT_SUPPORT:
    "Please try again.  If the issue persists, please contact the MTAC support team.",
  CC_AMERICAN_EXPRESS: "AMERICAN_EXPRESS",
  CC_AMEX: "AMERICANEXPRESS",
  CC_CYBERSOURCE_TOKEN: "CYBERSOURCE_TOKEN",
  CC_DINERS: "DINERS",
  CC_DISCOVER: "DISCOVER",
  CC_MAESTRO: "MAESTRO",
  CC_MASTERCARD: "MASTERCARD",
  CC_UNKNOWN: "UNKNOWN",
  CC_VISA: "VISA",
  DISCOUNT: "Discount",
  DISCOUNT_TYPE: "Discount Type",
  DISCOUNT_TYPE_REQUIRED: "Discount type is required",
  DOLLAR: "Dollar",
  END: "End",
  END_DATE_BEFORE_START: "End date cannot be before start date",
  END_DATE_REQUIRED: "End date is required",
  EXPIRED: "Expired",
  INACTIVE: "Inactive",
  INVALID_PERCENTAGE: "% cannot exceed 100",
  NO: "No",
  PAYMENT_ABORT: "Payment aborted.",
  PAYMENT_FAIL: "Payment failed.",
  PERCENTAGE: "Percentage",
  PT_CONNECTION_FAIL:
    "Connecting to the Payment Terminal failed. Please try again and ensure the Acceptance Devices app is open. If the issue persists, contact us at (800) 788-9000.",
  PT_CONNECTION_TIMEOUT:
    "Timeout..! Connection to the Payment Terminal failed. Please ensure the Acceptance Devices app is open.",
  PT_SERIAL_MISSING: "Terminal serial number is missing.", // Here, 'PT' stands for 'Payment Terminal
  PT_SERVER_ERROR:
    "Connection failed. Please try again. If the issue persists, contact us at (800) 788-9000.",
  PT_SETUP_MISSING: (memberCode) =>
    `The Payment Terminal is unavailable for ${
      memberCode || "member"
    } due to missing or an invalid setup. Please contact us at (800) 788-9000 to resolve the issue.`,
  REFUND_FAIL: "Refund failed.",
  SAVE: "Save",
  SELECT_DISCOUNT: "Select Discount",
  START: "Start",
  START_DATE_REQUIRED: "Start date is required",
  TXN_NOT_FOUND: "Transaction not found for",
  YES: "Yes",
  LANDSCAPE: "landscape",
  UNSAVED_WARNING:
    "Are you sure you want to continue? You will lose the entered data.",
});

export default COMMON;
