import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import { Platform } from "react-native";

const isObjectEmpty = (obj) => {
  // Check if the input is a valid object
  if (!isObject(obj) || isNil(obj)) {
    return true;
  }

  // Check if all values in the object are empty
  return every(
    obj,
    (value) =>
      isNil(value) || // Check for null or undefined
      isEmpty(value) // Check for empty strings, arrays, or objects
  );
};

const groupByMemberCode = (items = []) =>
  items.reduce((accumulator, currentItem) => {
    const { memberCode } = currentItem;

    // Initialize an array for the memberCode if it doesn't exist
    if (!accumulator[memberCode]) {
      accumulator[memberCode] = [];
    }

    // Add the current object to the array for the corresponding memberCode
    accumulator[memberCode].push(currentItem);

    return accumulator;
  }, {});

export const scrollToTopOfPage = ({ scrollRefs = [] }) => {
  scrollRefs?.forEach((scrollViewRef = {}) => {
    scrollViewRef &&
      scrollViewRef?.current?.scrollTo({
        y: 0,
        animated: true,
      });
  });
};

export const scrollToEndOfPage = ({ scrollRefs = [] }) => {
  scrollRefs?.forEach((scrollViewRef = {}) => {
    scrollViewRef &&
      scrollViewRef?.current?.scrollToEnd({
        animated: true,
      });
  });
};

const checkIsElectronApp = () => {
  return (
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true"
  );
};

export default {
  isObjectEmpty,
  groupByMemberCode,
  scrollToTopOfPage,
  scrollToEndOfPage,
  checkIsElectronApp,
};
