import React from "react";
import tw from "tailwind-rn";
import {
  colors,
  backgroundColors,
  theme,
  datePriceChangeColors,
} from "styles/theme";
import { Tooltip } from "components/elements";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import { View, Image, Animated, Platform } from "react-native";
import { Text, Icon } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { Entitlements } from "library/utils/entitlements";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import moment from "moment";
import { isAdhocStop } from "components/views/drawer/delivery/routes-dashboard/helper";
import {
  convertToTimeZone,
  timedOrderBuffer,
} from "library/utils/deliveryService";
import { replaceDeliverPhrase } from "library/utils/orderListing";
import AddressVerificationIndicator from "components/views/drawer/order-details/delivery-info/component/address-indicator";

const TouchableOpacity =
  Platform.OS === "web"
    ? require("react-native").TouchableOpacity
    : require("react-native-gesture-handler").TouchableOpacity;

//eslint-disable-next-line
const ListItem = React.memo(
  ({
    dragOverlay = false,
    enableDND,
    index,
    item,
    itemKey,
    columnsList,
    subColumnsList = [],
    isSelectedRecord,
    isSmallScreen,
    isMobile,
    isDesktop,
    recordId,
    listType,
    page,
    setRouteMoveAction,
    totalOrdersInRoute,
    enableMoves = true,
    enableMoveStop = false,
    enableAddOrder = false,
    enableRemoveStop = false,
    setRecordId,
    styles,
    ordersToHighlight = [],
    routeData,
    loading,
    isClickable = true,
    routeEditAllowed = false,
    localPermissions = {},
  }) => {
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const { permissions } = React.useContext(AppSettingsContext);

    const {
      displayOrderStatus,
      statusColor,
      imageName,
      addressVerificationInfo = {},
      orderItemId = "",
      zoneName = "",
      isRushOrder = false,
      receivingMember: { memberCode: shopCode } = {},
      status: orderStatus,
      messageNumber = "",
      stopType = "",
      orderDeliveryTime = "",
      notes = "",
      isFollowUp = false,
    } = item;

    const enableEditStop =
      localPermissions.adhocStops && ["CUSTOM"].includes(stopType);
    const isRouted = listType === "routed";

    const selectedShopPermissions = get(permissions, shopCode, {});

    const {
      status: routeStatus,
      stops = [],
      recommendedStartTime,
      departureTime,
    } = routeData;
    const { deliverByTime, expectedArrivalTime, delayedDelivery } =
      stops[index] || {};

    const {
      isAvsPerformed = "",
      avsConfidence = "",
      isAvsSuggestedAddress = "",
    } = addressVerificationInfo;

    const isAvsVerified =
      isAvsPerformed === "Y" &&
      isAvsSuggestedAddress === "Y" &&
      avsConfidence === "HIGH";

    const { imageWidth = 20, imageHeight = 20 } = DIMENSIONS[imageName] || {};

    const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    const floristTimeZone = shopTimeZone || "America/Chicago";
    const showAVSAlectIcon = !isAvsVerified && !enableMoves && !isClickable;
    const hasDateChangeNotification = [
      "Approve Date",
      "Date Approved",
      "Date Denied",
    ].includes(displayOrderStatus);

    //checking if timed Order will be delayed for delivery
    const isDelayedTimedOrder = () => {
      const isCFROptimiseEnabled = selectedShopPermissions[
        Entitlements.DELIVERY
      ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CFR_OPTIMIZATION);

      const isOptimizeEnabled =
        isCFROptimiseEnabled ||
        selectedShopPermissions[Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE.ROUTE_OPTIMIZATION
        );

      const currentFloristTimewithBuffer = moment(
        convertToTimeZone(moment.utc().format(), floristTimeZone)
      ).add(timedOrderBuffer, "minutes");
      const localDepartureTime = moment(
        convertToTimeZone(departureTime, floristTimeZone)
      );
      const localRecommendedStartTime = moment(
        convertToTimeZone(recommendedStartTime, floristTimeZone)
      );

      const routeStartTime =
        departureTime && routeStatus === "INPROGRESS"
          ? localDepartureTime
          : currentFloristTimewithBuffer;
      if (
        listType === "routed" &&
        !isEmpty(routeData) &&
        ["DRAFT", "PLANNED", "INPROGRESS"].includes(routeStatus) &&
        !["CANCELLED", "DELIVERED"].includes(orderStatus) &&
        (isRushOrder || !!orderDeliveryTime) &&
        deliverByTime
      ) {
        if (
          isOptimizeEnabled &&
          recommendedStartTime &&
          moment(routeStartTime).isAfter(localRecommendedStartTime)
        ) {
          const routes = get(routeData, "routeMap.routes", []);
          const legs = get(routes, "0.legs", []);
          const totalDuration = legs
            .slice(0, index + 1)
            .reduce(
              (accumulator, item) => accumulator + item.duration.value,
              0
            );

          //calculating estimatedDeliveryTime for order if driver starts in florist timeZone
          const estimatedDeliveryTime = moment(routeStartTime)
            .add(totalDuration, "seconds")
            .format();

          //deliverByTime for timedOrder in florist timeZone
          const timedOrderDeliverByTime = moment(
            convertToTimeZone(deliverByTime, floristTimeZone)
          );

          if (moment(estimatedDeliveryTime).isAfter(timedOrderDeliverByTime)) {
            return true;
          }
        }
        if (expectedArrivalTime) {
          if (moment(expectedArrivalTime).isAfter(deliverByTime)) {
            return true;
          }
        }
        return !!delayedDelivery;
      }

      return false;
    };

    const AVSAlertIcon = () => {
      return (
        <View>
          <Tooltip
            text={Localise(
              locMessages,
              "FTD was not able to verify this address. Please confirm address with the florist partner, customer, or recipient"
            )}
            isSmallScreen={isSmallScreen}
            height={60}
          >
            <View
              style={[
                tw("flex justify-center"),
                {
                  marginTop: !isClickable ? 2 : 0,
                  marginRight: !isClickable ? 8 : 0,
                },
              ]}
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 5,
                  marginBottom: 2,
                }}
                resizeMode="cover"
                source={require("static/assets/exclamation-alert-white-border.png")}
              />
            </View>
          </Tooltip>
        </View>
      );
    };

    const OrderPosition = ({ customStyle = {} } = {}) => {
      return (
        <View
          style={{
            backgroundColor:
              listType === "routed"
                ? backgroundColors.primary
                : !isAvsVerified
                ? "#E5175E"
                : "#b50d81",
            width: 20,
            height: 20,
            borderRadius: 20,
            marginVertical: 5,
            justifyContent: "center",
            alignItems: "center",
            ...customStyle,
          }}
        >
          <Text style={{ color: "#fff", fontWeight: "600" }}>{index + 1}</Text>
        </View>
      );
    };

    const tooltipsConfig = [
      {
        condition: notes !== "",
        text: Localise(locMessages, notes),
        imageStyle: { width: 18, height: 18 },
        imageSource: IMAGES["message"],
      },
      {
        condition: isFollowUp,
        text: Localise(locMessages, "Order flagged for follow up"),
        imageStyle: { width: 18, height: 23, marginTop: isSmallScreen ? 0 : 1 },
        imageSource: IMAGES["followup-flag-on"],
      },
    ];

    const OrderContainer = () => {
      return (
        <View style={[tw("flex flex-row items-center"), { paddingBottom: 5 }]}>
          {page === "routeDetails" &&
          !isMobile &&
          (dragOverlay || !enableDND) ? (
            <OrderPosition customStyle={{ marginRight: 10 }} />
          ) : null}
          <View
            style={[
              styles.recordContainer,
              isSelectedRecord && styles.activeRecordContainer,
              !isMobile
                ? { flex: 1 }
                : { flex: isRouted ? 1 : routeEditAllowed ? 0.95 : 1 },
              { backgroundColor: backgroundColors.secondary },
              ordersToHighlight.includes(orderItemId) && {
                borderColor: backgroundColors.primary,
                backgroundColor: "#fafafa",
              },
            ]}
            fsClass="fs-unmask"
          >
            <View
              style={[
                styles.record,
                {
                  borderColor: statusColor || "transparent",
                  alignItems: "flex-start",
                },
                {
                  width: isRouted ? "85%" : "90%",
                },
              ]}
              fsClass="fs-unmask"
            >
              {displayOrderStatus && (
                <View
                  style={[
                    tw("justify-between items-center"),
                    styles.recordItemView,
                    styles.status,
                  ]}
                >
                  <View
                    style={tw(
                      `flex flex-row items-center w-full flex-wrap ${
                        isSmallScreen ? "justify-between" : "justify-start"
                      }`
                    )}
                  >
                    <View style={styles.statusText}>
                      <Text
                        numberOfLines={2}
                        style={{
                          ...styles.recordItem,
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "600",
                          width: isMobile || isClickable ? "auto" : 75,
                          color: [
                            "#e5175e",
                            "#b50d81",
                            "#23a587",
                            "#037c77",
                            "#6c0057",
                            "#4aa779",
                          ].includes(statusColor)
                            ? ["Designed", "In Design"].includes(
                                displayOrderStatus
                              )
                              ? colors.primary
                              : statusColor
                            : colors.primary,
                        }}
                      >
                        {Localise(locMessages, displayOrderStatus)}
                      </Text>
                    </View>
                    <View style={styles.statusIcon}>
                      <Image
                        style={{
                          width: imageWidth,
                          height: imageHeight,
                        }}
                        containerStyle={{
                          marginHorizontal: isSmallScreen ? 5 : 10,
                          marginVertical: 5,
                        }}
                        source={IMAGES[imageName]}
                      />
                    </View>
                  </View>
                </View>
              )}
              <View
                style={[
                  tw("flex flex-col justify-between items-center"),
                  styles.recordItemView,
                  styles.notesFlag,
                ]}
              >
                {tooltipsConfig.map(
                  (config, index) =>
                    config.condition && (
                      <Tooltip
                        key={index}
                        text={config.text}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={config.imageStyle}
                          containerStyle={{
                            marginHorizontal: isSmallScreen ? 5 : 10,
                            marginVertical: 5,
                          }}
                          source={config.imageSource}
                        />
                      </Tooltip>
                    )
                )}
              </View>
              {columnsList.map((column) => {
                const customItemStyle = {
                  ...(((hasDateChangeNotification &&
                    column === "displayDeliveryDate") ||
                    item[`${subColumnsList[column]}`] === "Pending") && {
                    color:
                      datePriceChangeColors[item[`${subColumnsList[column]}`]],
                  }),
                  fontWeight: isSelectedRecord ? "600" : "500",
                };
                return (
                  <React.Fragment key={column}>
                    <View
                      style={[
                        styles.recordItemView,
                        styles[column],
                        {
                          ...(column === "zoneName" &&
                            !zoneName?.length && { display: "none" }),
                          ...(column === "messageNumber" &&
                            zoneName?.length && { width: "10%" }),
                          ...(["recipientName", "displayDeliveryDate"].includes(
                            column
                          ) &&
                            zoneName?.length && { width: "15%" }),
                          ...(!item[column]?.length &&
                            !item[`${subColumnsList[column]}`]?.length &&
                            page === "driverDashboard" && {
                              paddingVertical: 0,
                              display: "none",
                            }),
                        },
                      ]}
                    >
                      <View style={[tw("flex flex-row items-center")]}>
                        <Text
                          numberOfLines={2}
                          style={{
                            ...styles.recordItem,
                            textAlign:
                              isMobile && column === "displayPrice"
                                ? "right"
                                : "left",
                            ...(isSelectedRecord && {
                              color: colors.highlighter,
                            }),
                            ...(column === "deliveryInstructions" &&
                              isDelayedTimedOrder() && { color: colors.red }),
                            ...customItemStyle,
                          }}
                          title={item[column]}
                          fsClass={
                            toLower(column) === "phone"
                              ? "fs-exclude"
                              : "fs-unmask"
                          }
                        >
                          {column === "deliveryInstructions"
                            ? replaceDeliverPhrase(item[column])
                            : item[column]}
                        </Text>
                        {!isAvsVerified &&
                        !enableMoves &&
                        isClickable &&
                        column === "recipientCity" ? (
                          <AVSAlertIcon />
                        ) : null}
                      </View>
                      {Array.isArray(subColumnsList[column]) &&
                      subColumnsList[column].length > 0 ? (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {subColumnsList[column].map((subItem, index) => {
                            const isZip = subItem === "zip";

                            return (
                              <React.Fragment
                                key={`${column}_${subItem}_${index}`}
                              >
                                {isZip && (
                                  <Text
                                    style={{
                                      ...styles.subRecordItem,
                                      ...(isSelectedRecord && {
                                        color: backgroundColors.primary,
                                      }),
                                      ...(column === "displayDeliveryDate" &&
                                        isDelayedTimedOrder() && {
                                          color: colors.red,
                                        }),
                                      fontWeight: isSelectedRecord
                                        ? "600"
                                        : "500",
                                      marginRight: 2,
                                    }}
                                  >
                                    {item[`${subItem}`]}
                                  </Text>
                                )}

                                {isZip &&
                                  subColumnsList[column].includes(
                                    "isAvsVerified"
                                  ) && (
                                    <AddressVerificationIndicator
                                      containerStyle={{
                                        zIndex: -5,
                                        marginRight: 2,
                                      }}
                                      isVerified={isAvsVerified}
                                      isSmallScreen={!isDesktop}
                                      isShowLable={false}
                                      verifiedTooltipText={
                                        "Address FTD Verified"
                                      }
                                      unVerifiedTooltipText={
                                        "Address Not verified"
                                      }
                                      width={16}
                                      height={13}
                                    />
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </View>
                      ) : (
                        <Text
                          numberOfLines={2}
                          style={{
                            ...styles.subRecordItem,
                            ...(isSelectedRecord && {
                              color: colors.highlighter,
                            }),
                            ...(column === "displayDeliveryDate" &&
                              isDelayedTimedOrder() && { color: colors.red }),
                            ...customItemStyle,
                          }}
                        >
                          {column === "displayDeliveryDate"
                            ? replaceDeliverPhrase(
                                item[`${subColumnsList[column]}`]
                              )
                            : item[`${subColumnsList[column]}`]}
                        </Text>
                      )}
                      {column === "displayDeliveryDate" &&
                      isAdhocStop(stopType) ? (
                        <Text
                          numberOfLines={2}
                          style={{
                            ...styles.subRecordItem,
                            ...(isSelectedRecord && {
                              color: backgroundColors.primary,
                            }),
                            fontWeight: isSelectedRecord ? "600" : "500",
                          }}
                        >
                          {item["deliveredDateTime"]}
                        </Text>
                      ) : null}
                    </View>
                  </React.Fragment>
                );
              })}
            </View>
            <View style={styles.menuContainer}>
              {enableMoves && itemKey === "orderItemId" && (
                <>
                  {enableAddOrder && listType === "nonRouted" && (
                    <TouchableOpacity
                      testID="plusOrder"
                      accessibilityLabel="plusOrder"
                      disallowInterruption={true}
                      onPress={() => {
                        setRouteMoveAction({
                          action: "add",
                          id: item[itemKey],
                        });
                      }}
                      style={{
                        ...theme.Button.secondaryButtonStyle,
                        paddingVertical: 5,
                        paddingHorizontal: 5,
                        minWidth: 25,
                        margin: 5,
                      }}
                    >
                      <Icon
                        name={"plus"}
                        size={12}
                        type="font-awesome"
                        iconStyle={{ color: colors.primary }}
                      />
                    </TouchableOpacity>
                  )}
                  {listType === "routed" && (
                    <>
                      {!loading && (
                        <React.Fragment>
                          {enableEditStop && (
                            <TouchableOpacity
                              testID="editOrder"
                              accessibilityLabel="editOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                setRecordId(item[itemKey]);
                              }}
                            >
                              <Image
                                style={{
                                  width: 17,
                                  height: 25,
                                }}
                                source={IMAGES["edit-dark"]}
                              />
                            </TouchableOpacity>
                          )}
                          {enableRemoveStop ? (
                            <TouchableOpacity
                              testID="minusOrder"
                              accessibilityLabel="minusOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                setRouteMoveAction({
                                  action: "remove",
                                  id: item[itemKey],
                                });
                              }}
                              style={{
                                ...theme.Button.secondaryButtonStyle,
                                paddingVertical: 5,
                                paddingHorizontal: 5,
                                minWidth: 25,
                                margin: 5,
                              }}
                            >
                              <Icon
                                name={"minus"}
                                size={12}
                                type="font-awesome"
                                iconStyle={{ color: colors.primary }}
                              />
                            </TouchableOpacity>
                          ) : null}
                        </React.Fragment>
                      )}
                      {enableMoveStop ? (
                        <>
                          {index !== 0 && (
                            <TouchableOpacity
                              testID="moveUpOrder"
                              accessibilityLabel="moveUpOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                if (index === 0) return;
                                setRouteMoveAction({ action: "moveUp", index });
                              }}
                              style={{
                                ...theme.Button.secondaryButtonStyle,
                                paddingVertical: 5,
                                paddingHorizontal: 5,
                                minWidth: 25,
                                margin: 5,
                              }}
                            >
                              <Icon
                                name={"arrow-up"}
                                size={12}
                                type="font-awesome"
                                iconStyle={{ color: colors.primary }}
                              />
                            </TouchableOpacity>
                          )}
                          {index !== totalOrdersInRoute - 1 && (
                            <TouchableOpacity
                              testID="moveDownOrder"
                              accessibilityLabel="moveDownOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                if (index === totalOrdersInRoute - 1) return;
                                setRouteMoveAction({
                                  action: "moveDown",
                                  index,
                                });
                              }}
                              style={{
                                ...theme.Button.secondaryButtonStyle,
                                paddingVertical: 5,
                                paddingHorizontal: 5,
                                minWidth: 25,
                                margin: 5,
                              }}
                            >
                              <Icon
                                name={"arrow-down"}
                                size={12}
                                type="font-awesome"
                                iconStyle={{ color: colors.primary }}
                              />
                            </TouchableOpacity>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {showAVSAlectIcon ? <AVSAlertIcon /> : null}
              {page === "driverDashboard" || isMobile ? (
                <OrderPosition />
              ) : null}
            </View>
          </View>
        </View>
      );
    };

    return (
      <Animated.View key={recordId}>
        {isClickable ? (
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => {
              setRecordId(item[itemKey]);
            }}
            style={{ minWidth: 300 }}
            testID={`${messageNumber}_${displayOrderStatus}`}
            accessibilityLabel={`${messageNumber}_${displayOrderStatus}`}
          >
            <OrderContainer />
          </TouchableOpacity>
        ) : (
          <View
            style={{ minWidth: 300 }}
            testID={`${messageNumber}_${displayOrderStatus}`}
            accessibilityLabel={`${messageNumber}_${displayOrderStatus}`}
          >
            <OrderContainer />
          </View>
        )}
      </Animated.View>
    );
  }
);

export default ListItem;
