import React, { useContext, useState, useEffect } from "react";
import { View, ActivityIndicator, Image } from "react-native";
import { Text } from "react-native-elements";
import { Picker, Tooltip } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { remotePrintIntervalOptions } from "../printer-settings/config";
import { colors } from "styles/theme";
import { getPreferences, updatePreferences } from "../../helper";
import SHOP_SETTINGS from "library/constants/shopSettings";

const settingsKey = "remote_print_interval";
const defultInterval =
  remotePrintIntervalOptions[remotePrintIntervalOptions.length - 1]?.value;

const RemotePrintSettings = ({
  shopSettings,
  saveSettings,
  loadingKey,
  macAddress,
}) => {
  const remotePrintPreferences = getPreferences(
    shopSettings,
    macAddress,
    settingsKey
  );

  const [selectedInterval, setSelectedInterval] = useState(
    remotePrintPreferences?.print_interval
  );
  const { messages, Localise } = useContext(I18NContext);

  useEffect(() => {
    if (selectedInterval === remotePrintPreferences?.print_interval) return;
    const updatedPreferences = updatePreferences(
      shopSettings,
      macAddress,
      settingsKey,
      "print_interval",
      selectedInterval
    );

    saveSettings({
      preferences: [
        {
          id: settingsKey,
          values: [JSON.stringify(updatedPreferences)],
        },
      ],
    });
  }, [selectedInterval]);

  return (
    <View style={tw("flex flex-row items-center")}>
      <Text>{Localise(messages, SHOP_SETTINGS.REMOTE_PRINT_INTERVAL)} </Text>
      <Tooltip
        text={Localise(messages, SHOP_SETTINGS.REMOTE_PRINT_INTERVAL_HELP_TEXT)}
        height={40}
      >
        <Image
          style={{
            width: 15,
            height: 15,
            marginLeft: 5,
          }}
          source={require("static/assets/help.png")}
        />
      </Tooltip>
      <Picker
        containerStyle={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginLeft: 20,
        }}
        items={remotePrintIntervalOptions.map((item) => {
          return {
            label: item.label,
            value: item.value,
          };
        })}
        placeholder={{}}
        value={selectedInterval || defultInterval}
        onValueChange={setSelectedInterval}
      ></Picker>
      {loadingKey === settingsKey && (
        <ActivityIndicator
          color={colors.activityIndicator}
          testID={"loader"}
          style={{ marginLeft: 10 }}
        />
      )}
    </View>
  );
};

export default React.memo(RemotePrintSettings);
