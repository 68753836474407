import React, { useEffect } from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import moment from "moment";
import toLower from "lodash/toLower";
import get from "lodash/get";

import I18NContext from "library/contexts/i18N";
import {
  selectOrderModifying,
  selectRecordData,
} from "library/sagas/ongoing/current-orders/selector";
import UserProfileStorage from "library/storage/userProfile";

import { generateDetailContent } from "../helper";
import { fonts } from "styles/theme";
import IMAGES from "static/assets/images";

const PickupInfo = ({ index, isSmallScreen, doNotFill, isPreview }) => {
  const isOrderModifying = useSelector(selectOrderModifying);
  const { messages, Localise } = React.useContext(I18NContext);
  const orderDetailResponse = useSelector(selectRecordData);
  const { values, setFieldValue } = useFormikContext();

  const orderDetails = orderDetailResponse.orderItems[index];
  const parentPath = `orderItems.${index}.deliveryInfo`;
  const {
    pickupDate = "",
    pickUpDateTime = "",
    // shopDayTimings,
    deliveryDate = "",
  } = get(values, parentPath, {});
  const {
    // recipientInfo: { firstName = "", lastName = "" },
    sendingMember: { memberCode: sendingMemberCode } = {},
    // pickupInfo,
    orderSource = "",
    hasPendingPayLaterPayment = false,
  } = orderDetails;
  const {
    deliveryInfo: {
      deliveryInstructions = "",
      deliveryMethod,
      pickUpBy = "",
      recipientInfo: { firstName = "", lastName = "" } = {},
    },
    pickupInfo,
  } = get(values, "orderItems.0", {});
  const shopOpenHours = UserProfileStorage.getShopHours(sendingMemberCode);
  const { storePickupDateTime = "" } = pickupInfo || {};
  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
  const dateTime = storePickupDateTime || pickUpDateTime;

  const pickupData = {
    RecipientData: {
      label: "Recipient",
      value: `${firstName}${firstName !== "" ? " " : ""}${lastName}`,
    },
    DeliveryType: {
      label: "Delivery Type",
      value: hasPendingPayLaterPayment ? "Pickup - Unpaid" : "Pickup",
    },
    PickupDate: {
      label: "Pickup Date",
      value: deliveryDate ? moment(deliveryDate).format("MM/DD/YY") : "",
    },
    PickupTime: {
      label: "Pickup Time",
      value: dateTime ? moment(dateTime).format("h:mm A") : "",
    },
  };

  // rounding time to nearest 15min to show it in pickup time drop down
  const roundUpTime = (intervalMilliseconds, datetime) => {
    datetime = datetime || new Date();
    var modTicks = datetime.getTime() % intervalMilliseconds;
    var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
    delta += intervalMilliseconds;
    return new Date(delta);
  };

  const getShopHoursForSelectedDate = (date) => {
    let dayOfWeek = moment(date).format("dddd").toUpperCase();

    let shopHours = shopOpenHours.filter(
      (dayHours) => dayHours.day.toUpperCase() === dayOfWeek
    );

    return shopHours?.length ?? 0 > 0
      ? shopHours[0]
      : {
          openTime: "00:00:00",
          closeTime: "24:00:00",
        };
  };

  useEffect(() => {
    if (isPreview) return;

    let shopHours = getShopHoursForSelectedDate(pickupDate);

    let shopOpenTime = moment(pickupDate)
      .set({
        hour: parseInt(shopHours?.openTime?.split(":")[0]) || 0,
        minute: parseInt(shopHours?.openTime?.split(":")[1]) || 0,
        second: parseInt(shopHours?.openTime?.split(":")[2]) || 0,
      })
      .toDate();

    let shopCloseTime = moment(pickupDate)
      .set({
        hour: parseInt(shopHours?.closeTime?.split(":")[0]) || 0,
        minute: parseInt(shopHours?.closeTime?.split(":")[1]) || 0,
        second: parseInt(shopHours?.closeTime?.split(":")[2]) || 0,
      })
      .toDate();

    let shopTimings = {
      open: shopOpenTime,
      close: shopCloseTime,
    };

    let isToday = moment(pickupDate).isSame(moment(), "day");

    if (isToday) {
      if (moment().isAfter(shopOpenTime)) {
        if (moment().isBefore(shopCloseTime)) {
          shopTimings.open = moment(
            roundUpTime(15 * 60 * 1000, new Date())
          ).toDate();
        } else {
          shopTimings.open = moment().startOf("day").toDate();
          shopTimings.close = moment().startOf("day").toDate();
        }
      }
    }

    // Updating shop timings & Pickup Time with selected pickupdate in Formik in order to access them while performing Yup validations
    let pickUpTimeOnly =
      pickUpDateTime &&
      moment(pickUpDateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss");

    setTimeout(() => {
      setFieldValue(`${parentPath}.shopDayTimings`, shopTimings);
      pickupDate &&
        pickUpTimeOnly &&
        setFieldValue(
          `${parentPath}.pickUpDateTime`,
          `${moment(pickupDate).format("YYYY-MM-DD")}T${pickUpTimeOnly}`
        );
    }, 0);
  }, [pickupDate, isOrderModifying]);

  useEffect(() => {
    if (pickUpDateTime && pickupDate) {
      let pickUpTimeOnly = moment(pickUpDateTime, "YYYY-MM-DDTHH:mm:ss").format(
        "HH:mm:ss"
      );
      let formattedPickupTime = `${moment(pickupDate).format(
        "YYYY-MM-DD"
      )}T${pickUpTimeOnly}`;

      setTimeout(() => {
        setFieldValue(`${parentPath}.pickUpDateTime`, formattedPickupTime);
      }, 0);
    }
  }, [pickUpDateTime]);

  return (
    <View
      style={{
        // opacity: doNotFill ? 0.4 : 1,
        zIndex: 6,
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View>
        <View>{generateDetailContent(pickupData, true)}</View>
        <View style={tw("flex flex-row")}>
          <Text style={[fonts.style1, { fontSize: 14, paddingVertical: 2 }]}>
            {Localise(messages, "Pickup By")}:{" "}
          </Text>
          <View style={!isSmallScreen ? tw("flex flex-row") : {}}>
            <View style={[tw("flex flex-row")]}>
              {isStorePickupOrder || orderSource === "GrubHub"  ? ( // Will remove after adding grubhub logo
                <Text
                  style={[fonts.style1, { fontSize: 14, paddingVertical: 2 }]}
                >
                  {orderSource === "GrubHub" ? orderSource : pickUpBy}
                </Text>
              ) : (
                <Image
                  style={{
                    width: orderSource === "DoorDash" ? 120 : 90,
                    height: 15,
                  }}
                  resizeMode="cover"
                  source={IMAGES[`${toLower(orderSource)}`]}
                />
              )}
            </View>
          </View>
        </View>
        {isStorePickupOrder && deliveryInstructions.length > 0 && (
          <View>
            <Text style={[fonts.style1, { fontSize: 14, paddingVertical: 2 }]}>
              {Localise(messages, "Pickup Notes")}: {deliveryInstructions}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default PickupInfo;
