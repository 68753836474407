import React, { useContext, useMemo } from "react";
import { View, Platform } from "react-native";
import { Text, Icon } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { DeviceContext } from "library/contexts/appSettings";
import tw from "tailwind-rn";
import get from "lodash/get";
import moment from "moment";
import { colors, backgroundColors } from "styles/theme";
import { CustomModal, ToasterHandler } from "components/elements";
import {
  frequency,
  subscriptionEnds,
} from "components/views/drawer/create-order/subscription-helper";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

import { deleteSubscriptionTemplate } from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import { setNavParams } from "library/sagas/views/home/drawer/create-order/slice";
import ItemActions from "./item-actions";
import RecurrenceText from "./recurrence-text";

const SubscriptionItem = ({ data }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const customerDetails = useSelector(selectCustomerDetails);
  const { isDesktop } = useContext(DeviceContext);

  const [showSubscriptionModal, setShowSubscriptionModal] =
    useStateIfMounted("");
  const { messages, Localise } = useContext(I18NContext);

  const { pause = [] } = data || {};
  const activePause = pause.find(({ status }) => status === "ACTIVE");

  const cancelSubscription = () => {
    const { memberCode: shopCode, customerId, id: subscriptionId } = data;

    dispatch(
      deleteSubscriptionTemplate({
        params: { shopCode, customerId, subscriptionId },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Your subscription has been canceled")
          );
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  };

  const redirectToSubscriptionScreen = (action) => {
    // setting the navParams prior to actual redirection so that i can decide the flow from first render itself.
    const isModify = action === "Modify";
    dispatch(
      setNavParams({ action: isModify ? "edit" : "copy", type: "subscription" })
    );
    navigation.dispatch(
      CommonActions.navigate({
        name: "create-order",
        params: {
          customerDetails,
          subscriptionData: { ...data, isModify },
          type: "subscription",
          action: isModify ? "edit" : "copy",
        },
      })
    );
  };

  const ShowSubscriptionModal = ({ testId }) => {
    const modalContent = {
      content: (
        <View style={tw("flex items-center justify-center")}>
          <Text
            style={{
              ...tw("p-2"),
              fontSize: 18,
              fontWeight: "bold",
              color: colors.dark,
            }}
          >
            {Localise(
              messages,
              `${
                showSubscriptionModal === "Cancel" ? "Cancel" : "Edit"
              } Subscription`
            )}
          </Text>

          <Text
            style={{
              ...tw("p-4"),
              fontSize: 15,
              color: colors.dark,
              textAlignVertical: "center",
              textAlign: "center",
            }}
          >
            {Localise(
              messages,
              `${
                showSubscriptionModal === "Cancel"
                  ? "Are you sure you want to cancel your subscription? This will stop all future deliveries."
                  : "You're about to edit your subscription, and changes will apply to all future deliveries."
              } Do you wish to proceed?`
            )}
          </Text>
        </View>
      ),
      buttons: [
        { type: "primary", title: Localise(messages, "Nevermind") },
        { type: "secondary", title: Localise(messages, "Continue") },
      ],
    };

    return (
      <CustomModal
        modalVisible={showSubscriptionModal?.length > 0}
        modalContent={modalContent}
        testID={`${testId}_modal`}
        primaryhandler={() => {
          setShowSubscriptionModal("");
        }}
        secondaryhandler={() => {
          setShowSubscriptionModal("");

          if (showSubscriptionModal === "Cancel") cancelSubscription();

          if (showSubscriptionModal === "Modify")
            redirectToSubscriptionScreen(showSubscriptionModal);
        }}
        contentStyle={[
          tw("p-4"),
          {
            backgroundColor: colors.buttonText,
            width: "100%",
            borderWidth: 2,
            borderColor: colors.button,
            borderRadius: 4,
          },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: backgroundColors.secondary,
              }
            : { width: "35%" }
        }
        isReduceBtnSpacing
      ></CustomModal>
    );
  };

  const onAction = (menuKey) => {
    if (["Cancel", "Modify"].includes(menuKey)) {
      setShowSubscriptionModal(menuKey);
    } else {
      redirectToSubscriptionScreen(menuKey);
    }
  };

  const subscriptionInfo = get(data, "schedule.repeat.0");
  const deliveryInfo = get(data, "orderTemplate.orderItems.0.deliveryInfo", {});
  const { occasion, deliveryMethod } = deliveryInfo;

  let occasionLabel = (occasion && occasion.replace("_", " ")) || "";
  occasionLabel =
    occasionLabel?.charAt(0).toUpperCase() +
    occasionLabel?.slice(1).toLowerCase();

  const statusColor =
    data.status === "PAUSED"
      ? "#6c0057"
      : data.status === "INACTIVE"
      ? "#c4c4c4"
      : "#17aee5";

  const config = useMemo(
    () => [
      { label: Localise(messages, "Occasion"), value: occasionLabel },
      {
        label: Localise(
          messages,
          `First ${
            deliveryMethod === "STORE_PICKUP" ? "Pickup" : "Delivery"
          } Date`
        ),
        value: moment(subscriptionInfo.firstDeliveryDate).format("MM/DD/YYYY"),
      },
      {
        label: Localise(messages, "Frequency"),
        value:
          frequency.find((each) => each.value === subscriptionInfo.frequency)
            ?.label || "",
      },
      {
        label: Localise(messages, "Ends"),
        value: subscriptionInfo.ends
          ? subscriptionEnds.find(
              (each) => each.value === subscriptionInfo.ends
            )?.label || "Never"
          : moment(subscriptionInfo.endDate).format("MM/DD/YYYY"),
      },
    ],
    [Localise, messages, occasionLabel, subscriptionInfo, deliveryMethod]
  );

  return (
    <View
      key={data.id}
      style={{
        flexDirection: "row",
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        marginVertical: 7,
        padding: 4,
        width: "100%",
      }}
      testID={data?.id}
    >
      <View
        style={{
          width: 8,
          backgroundColor: statusColor,
          marginRight: isDesktop ? 10 : 8,
        }}
      />
      <View
        style={{
          flexDirection: "column",
          paddingVertical: 7,
          zIndex: 10,
          width: "90%",
        }}
        pointerEvents={"none"}
      >
        <View style={tw(`flex flex-row flex-wrap w-full justify-between`)}>
          {config.map((detail, index) => (
            <View
              key={index}
              style={tw(`flex flex-col w-${isDesktop ? "1/4" : "1/2"}`)}
            >
              <Text testID={`column-${index}`} style={tw("font-semibold pb-1")}>
                {detail.label}
              </Text>
              <Text style={tw("text-sm pb-1")}>{detail.value}</Text>
            </View>
          ))}
        </View>
        <RecurrenceText
          {...subscriptionInfo}
          containerStyle={{ paddingTop: 5 }}
          variant="listing"
          deliveryMethod={deliveryMethod}
        />
        {activePause && (
          <View
            style={{
              ...tw("flex flex-row mt-2"),
              width: "100%",
            }}
            testID="suspension_msg_container"
          >
            <Icon
              name="info"
              type="simple-line-icon"
              color={colors.primary}
              size={12}
            />
            <Text
              style={{
                paddingLeft: 5,
              }}
              testID="suspension_msg"
            >
              {`Your subscription ${
                moment().isBefore(activePause.startDate)
                  ? "will pause"
                  : "is paused"
              } from ${moment(activePause.startDate).format(
                "MM/DD/YYYY"
              )} to ${moment(activePause.endDate).format("MM/DD/YYYY")}`}
            </Text>
          </View>
        )}
      </View>
      <ItemActions onSelect={onAction} data={data} />
      <ShowSubscriptionModal testId={data?.id} />
    </View>
  );
};

export default SubscriptionItem;
