import * as Yup from "yup";

export const priceKeys = [
  "Pennies",
  "Nickels",
  "Dimes",
  "Quarters",
  "$1",
  "$5",
  "$10",
  "$20",
  "$50",
  "$100",
  "Checks",
];

export const priceValues = {
  Pennies: 0.01,
  Nickels: 0.05,
  Dimes: 0.1,
  Quarters: 0.25,
  $1: 1.0,
  $5: 5.0,
  $10: 10.0,
  $20: 20.0,
  $50: 50.0,
  $100: 100.0,
  Checks: 0,
};

export const getAmount = (values) => {
  const valueKeys = Object.keys(values).filter((key) => key.includes("Value"));
  const sumOfValues = valueKeys
    .map((each) => {
      const num = parseFloat(values[each]);
      // returning 0, in case of num is NaN & negative number
      return isNaN(num) || num < 0 ? 0 : num;
    })
    .reduce((a, b) => a + b, 0);
  return sumOfValues;
};

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    ChecksValue: Yup.string().matches(
      /^\d+\.?\d*$/,
      Localise(messages, "Invalid Amount")
    ),
  });
};
