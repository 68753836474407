import React, { useEffect, useContext, useRef } from "react";
import { View, Platform, TouchableOpacity } from "react-native";
import { Button, Text, Icon } from "react-native-elements";
import { FieldArray, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import findLastIndex from "lodash/findLastIndex";
import sum from "lodash/sum";
import isEqual from "lodash/isEqual";

import { fonts, colors, theme, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

import { ToasterHandler, Tooltip } from "components/elements";
import {
  FormField,
  FormFieldPicker,
  FormFieldCheckBox,
} from "components/elements/forms";
import BillingInfo from "components/views/drawer/create-order/billing-info";
import HouseAccountPayment from "components/views/drawer/create-event/proposal-and-payment/house-account-payment";
import { basicPaymentInfo } from "components/views/drawer/create-order/config";
import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";

import * as Yup from "yup";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { request } from "library/utils/request";
import {
  formatPrice,
  //getMaskedCardNumber, // commented this as part of this ticket: MSOL-18277
  getFormattedCreditCardExpiry,
  isMasked,
  getStringBetweenChars,
} from "library/utils/formatter";
import {
  inEligibleSplitPaymentOptions,
  hasSplitAmountError,
  voidCCPayment,
  createOrder,
} from "library/utils/createOrder";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeleteCardModal } from "../delete-card";

import { fetchCustomerDetails } from "../../sagas/views/home/drawer/customer-directory/slice";
import { selectRedeemedGiftCardsData } from "library/sagas/views/home/drawer/create-order/selector";
import {
  setPaymentDetails,
  upsertSubscription,
} from "library/sagas/views/home/drawer/create-order/slice";
import { setDeleteCardModal } from "library/sagas/ongoing/global-data/slice";
import { selectDeletCardModal } from "library/sagas/ongoing/global-data/selector";
import UserProfileStorage from "library/storage/userProfile";
import {
  createTerminalTxnRequest,
  handleTerminalTxnErrors,
  handleTerminalTxnResponse,
} from "./helper";
import { COMMON } from "library/constants";
import Environment from "library/utils/environment";

export const phoneRegEx = "^(+d{1,2}s)?(?d{3})?[s.-]?d{3}[s.-]?d{4}$";
export const cvvRegEx = "^[0-9]{3,4}$";

export const ccdErrors = {
  visa: "Visa requires 13 or 16 digits",
  mastercard: "Mastercard requires 16 digits",
  americanexpress: "AMEX requires 15 digits",
  discover: "Discover requires 15 or 16 digits",
  diners: "Diners cards should have 14 digits",
};

export const ccdTypeRegex = {
  visa: /^(?:4[0-9]{3})$/,
  mastercard:
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|2720|27[01][0-9])$/,
  americanexpress: /^(?:3[47][0-9]+)$/,
  discover: /^(6011)$/,
  diners: /^(?:3(?:0[0-5]|[68][0-9])[0-9])$/,
};

export const ccdRegex = {
  visa: "^(?:4[0-9]{12})(?:[0-9]{3})?$",
  mastercard:
    "^(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})",
  americanexpress: "^(?:3[47][0-9]{13})",
  discover: "^(6011[0-9]{12})",
  diners: "^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})",
};

export const getCardType = (value) => {
  const firstFourChar = value.slice(0, 4);
  let ccdType;

  Object.keys(ccdTypeRegex).forEach((key) => {
    if (firstFourChar.match(ccdTypeRegex[key])) {
      ccdType = key;
    }
  });

  return ccdType;
};
export const isOrderTypeWired = (value) => {
  return value?.toLowerCase() === "wired";
};
export const isMolOrder = (value) => {
  return value?.toLowerCase() === "mol";
};
const isCash = (value) => value === "CASH";
const isCreditCard = (value) => ["CREDIT_CARD", "SAVED_CARD"].includes(value);
const isTerminalPayment = (value) => value === "PAYMENT_TERMINAL";
export const isCvvRequired = (paymentMethod, value) => {
  if (paymentMethod === "SAVED_CARD") return true;
  return !!value;
};

export const getPaymentValidationSchema = (
  redeemedGiftCardsData,
  enableSplitPayment = false,
  customerDetails = {},
  houseAccountCreditLimit = ""
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = React.useContext(I18NContext);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isDesktop } = React.useContext(DeviceContext);

  Yup.addMethod(Yup.string, "isValidCard", function (errorMessage) {
    return this.test(`is-valid-card`, errorMessage, function (value = "") {
      const { actualCardNumber = "" } = this.parent;
      if (!actualCardNumber) return true;
      const trimmedValue = actualCardNumber.split(" ").join("");
      if (trimmedValue.substring(0, 3) === "***") return true;
      if (isNaN(trimmedValue)) return false;
      var cardType = getCardType(trimmedValue);
      if (!cardType) return false;
      const { path, createError } = this;
      var regEx = new RegExp(ccdRegex[cardType]);

      return (
        regEx.test(trimmedValue) ||
        createError({
          path,
          message:
            Localise(messages, ccdErrors[cardType]) ||
            Localise(messages, "Invalid Card Number"),
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "isValidExpDate", function (errorMessage) {
    return this.test("is-valid-expDate", errorMessage, function (value) {
      const { path, createError } = this;

      if (!value) {
        return createError({ path, message: errorMessage });
      }
      const [expMonth, expYear] = value.split("/") || [];
      const month = parseInt(expMonth, 10);
      const year =
        expYear?.length === 2
          ? 2000 + parseInt(expYear, 10)
          : parseInt(expYear, 10);
      if (
        !expMonth ||
        !expYear ||
        isNaN(month) ||
        month < 1 ||
        month > 12 ||
        isNaN(year)
      ) {
        return createError({ path, message: errorMessage });
      }
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1;
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.string, "isPaymentTypeSelected", function (errorMessage) {
    return this.test(
      "is-payment-type-selected",
      errorMessage,
      function (paymentType) {
        const { path, createError, parent } = this;
        const {
          isGrandTotalZero = false,
          paymentMethodType,
          isPayLaterEnabled = false,
          amount = 0,
        } = parent;
        const index = getStringBetweenChars(path, "[", "]");

        const paymentStatus = get(
          this,
          "from.2.value.paymentDetails.paymentStatus",
          ""
        );
        const isEditOrder = get(this, "from.2.value.isEditOrder", false);
        const orderType = get(this, "from.2.value.orderType", "");

        // Making PaymentType field not require if we are editing wire orders
        if (isEditOrder && isOrderTypeWired(orderType)) {
          return true;
        } else if (
          // Making PaymentType field disable and removing validation if grandTotal = 0 on gc applied
          redeemedGiftCardsData?.length === 0 &&
          paymentType === undefined &&
          paymentStatus?.toLowerCase() === "pending"
        ) {
          return false;
        } else if (redeemedGiftCardsData?.length > 0 && isGrandTotalZero) {
          return true;
        } else if (!isPayLaterEnabled && paymentType === "PAY_LATER") {
          return createError({
            path,
            message: "This order is not eligible for Pay Later",
          });
        } else if (
          redeemedGiftCardsData?.length > 0 &&
          paymentType === "PAY_LATER"
        ) {
          return createError({
            path,
            message: "Gift Card not applicable with this payment type",
          });
        } else if (paymentType === "INVOICE") {
          const {
            houseAccountInfo: {
              houseAccountId,
              creditLimit,
              isActive,
              houseAccountBalance: { totalBalance = 0 } = {},
            } = {},
          } = customerDetails;
          const updatedCreditLimit = parseFloat(
            houseAccountCreditLimit || creditLimit
          );

          const showHouseAccountError =
            !isActive || amount > updatedCreditLimit - totalBalance;

          if (showHouseAccountError)
            return createError({
              path,
              message: houseAccountId
                ? !isActive
                  ? "This House Account is inactive."
                  : `Credit Limit has been exceeded.`
                : "Please Enable House account from Customer Directory",
            });

          // if (houseAccountId && showHouseAccountError)
          //   return createError({
          //     path,
          //     message: !isActive
          //       ? "This House Account is inactive."
          //       : `Credit Limit has been exceeded.`,
          //   });
          //return true;
        } else if (
          paymentType === undefined &&
          paymentMethodType === undefined
        ) {
          return createError({
            path,
            message:
              index > 0 ? "Select Payment for Remaining Balance" : errorMessage,
          });
        }
        return true;
      }
    );
  });

  // const amountValidation = Yup.string().matches(
  //   /^(?!0(\.0+)?$)\d+(\.\d+)?$/,
  //   Localise(messages, "Invalid Price")
  // );

  Yup.addMethod(Yup.string, "isAmountValid", function (errorMessage) {
    var regEx = new RegExp("^(?!0(\\.0+)?$)\\d+(\\.\\d+)?$");

    return this.test(`is-amount-valid`, errorMessage, function (value) {
      const { isGrandTotalZero } = this.parent;
      const paymentMethods = get(this, "from.1.value.paymentMethod", []);
      if (redeemedGiftCardsData?.length > 0 && isGrandTotalZero) {
        return true;
      } else {
        return paymentMethods.length ? regEx.test(value) : true;
      }
    });
  });

  return Yup.object().shape({
    paymentMethod: Yup.array().of(
      Yup.object().shape({
        paymentMethodType: Yup.string()
          .label("paymentMethodType")
          .when("enablePayment", {
            is: (value) => !!value,
            then: Yup.string().isPaymentTypeSelected(
              Localise(messages, "Select Payment Type")
            ),
          }),
        ...(enableSplitPayment && {
          amount: Yup.string().isAmountValid(
            Localise(messages, "Invalid Price")
          ),
        }),
        paymentMethodDetails: Yup.object().when("paymentMethodType", {
          is: (value) => isCreditCard(value),
          then: Yup.object({
            name: Yup.string()
              .label("name")
              .matches(
                new RegExp("^[-\\sa-zA-Z.]+$"),
                Localise(messages, "Invalid Name")
              )
              .required(Localise(messages, "Enter Name")),
            cardNumber: Yup.string()
              .label("cardNumber")
              .required(Localise(messages, "Please enter Card Number"))
              .isValidCard(Localise(messages, "Invalid Card Number")),
            expDate: Yup.string()
              .label("expDate")
              .matches(
                new RegExp("^(0[1-9]|1[0-2])(/)?([0-9]{4}|[0-9]{2})$"),
                Localise(
                  messages,
                  isDesktop ? "Invalid Expiration Date" : "Invalid Expiration"
                )
              )
              .required(
                Localise(
                  messages,
                  isDesktop ? "Enter Expiration Date" : "Enter Expiration"
                )
              )
              .isValidExpDate(
                Localise(
                  messages,
                  isDesktop ? "Invalid Expiration Date" : "Invalid Expiration"
                )
              ),
            cvv: Yup.string()
              .label("cvv")
              .matches(
                new RegExp("^[0-9]{3,4}$"),
                Localise(messages, "Invalid CVV")
              )
              .when("isSwipedCard", {
                is: (value) => !value,
                then: Yup.string().test(
                  "is-cvv-required",
                  Localise(messages, "Enter CVV"),
                  function (cvv) {
                    const paymentMethodType = get(
                      this,
                      "from.1.value.paymentMethodType",
                      ""
                    );
                    return isCvvRequired(paymentMethodType, cvv);
                  }
                ),
              }),
          }),
          otherwise: Yup.object().when("paymentMethodType", {
            is: (value) => isCash(value),
            then: Yup.object({
              tenderedAmount: Yup.string()
                .label("tenderedAmount")
                .required(Localise(messages, "Enter Tendered amount"))
                .isValidAmount(Localise(messages, "Invalid Tendered amount")),
            }),
          }),
        }),
        billingInformation: Yup.object().when(
          ["paymentMethodType", "savePayment"],
          {
            is: (paymentMethodType, savePayment) =>
              isCreditCard(paymentMethodType) ||
              (isTerminalPayment(paymentMethodType) && savePayment),
            then: Yup.object({
              zip: Yup.string()
                .label("zip")
                // Removing below validation as per this comment https://ftdcorp.atlassian.net/browse/MSOL-11948?focusedCommentId=239150
                /*.when("country", {
                    is: (value) => value && value === "US",
                    then: Yup.string()
                      .matches(
                        new RegExp("^[0-9]{5}([-][0-9]{4})?\\s*$"),
                        Localise(messages, "Invalid Billing Zip")
                      )
                      .required(Localise(messages, "Enter Billing Zip")),
                    otherwise: Yup.string().when("country", {
                      is: (value) => value && value === "CA",
                      then: Yup.string()
                        .matches(
                          new RegExp(
                            "^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"
                          ),
                          Localise(messages, "Invalid Billing Zip")
                        )
                        .required(Localise(messages, "Enter Billing Zip")),
                      otherwise: Yup.string().required(
                        Localise(messages, "Enter Billing Zip")
                      ),
                    }),
                  })*/
                .required(Localise(messages, "Enter Billing Zip"))
                .matches(
                  new RegExp("^[a-zA-Z0-9][a-zA-Z0-9 -]{0,10}[a-zA-Z0-9]$"),
                  Localise(messages, "Invalid Billing Zip")
                ),
            }).when("savePayment", {
              is: (value) => !!value,
              then: Yup.object({
                addressLine1: Yup.string()
                  .label("addressLine1")
                  .matches(
                    new RegExp("^[-\\sa-zA-Z0-9./]+$"),
                    Localise(messages, "Invalid Billing Address")
                  )
                  .required(Localise(messages, "Enter Billing Address")),

                city: Yup.string()
                  .label("city")
                  .required(Localise(messages, "Enter City name")),

                state: Yup.string()
                  .label("state")
                  .when("country", {
                    is: (value) => (value && value === "US") || value === "CA",
                    then: Yup.string()
                      .matches(
                        new RegExp("^[a-zA-Z]{2}$"),
                        Localise(messages, "Invalid State")
                      )
                      .required(Localise(messages, "State required")),
                  }),

                country: Yup.string()
                  .label("country")
                  .required(Localise(messages, "Please select country")),
              }),
            }),
          }
        ),
      })
    ),
  });
};

export const getAutherizationDetails = (authResp = {}) => {
  const { creditCardResponse, merchantReferenceId, route } =
    authResp.creditCardAuthorizeResponse || {};
  let authDetails = Object.keys(creditCardResponse).map((key) => {
    return {
      name: key,
      value:
        authResp?.creditCardAuthorizeResponse?.creditCardResponse[key] || "",
    };
  });
  authDetails.push({ name: "merchantReferenceId", value: merchantReferenceId });
  authDetails.push({ name: "route", value: route });
  return authDetails;
};

export const getAuthorizePayload = (
  refId,
  paymentType,
  paymentAmount,
  cvv,
  partnerCode
) => {
  return {
    isTestOrder: "false",
    giftCodesUpdateRequest: null,
    payPalAuthRequest: null,
    creditCardAuthorizeRequest: {
      paymentType,
      merchantReferenceId: refId,
      currency: "usd",
      isRetry: false,
      amount: paymentAmount,
      cardinalCommerce: null,
      authorizationRequestDate: moment().format("YYYY-MM-DD"),
      partnerCode,
      processorAccount: "mhq",
      cvvNumber: cvv,
    },
    digitalWalletRequest: null,
  };
};

// Some of the customers have billing address as NA due to data migration issue. If you pass the same to tokenization call it will fail.
export const checkForNA = (val) => {
  return val === "NA" ? "" : val;
};

export const getTokenizePaymentPayload = (
  values,
  refId = "",
  partnerCode,
  index = 0
) => {
  let {
    paymentMethodDetails: { actualCardNumber = "", cvv: cvvNumber, expDate },
    billingInformation: { addressLine1, city, state, country, zip: zipCode },
    paymentMethodType,
  } = get(values, `paymentDetails.paymentMethod.${index}`, {});

  const {
    firstPaymentInfo: { tokenId = "", cardType = "", lastFourDigits = "" } = {},
  } = get(values, "customerInfo", {});

  const [expirationMonth, expirationYear] = expDate.split("/");

  const tokenizeReqPayload = {
    currency: "usd",
    isCalyxRoute: true,
    merchantReferenceId: refId,
    processorAccount: "mhq",
    partnerCode,
    paymentMethod: {
      // ToDo: Need to clear based on billing API chages to make the fields optional.
      billTo: {
        address1: checkForNA(addressLine1),
        city: checkForNA(city),
        state: checkForNA(state),
        country: checkForNA(country),
        zipCode: checkForNA(zipCode),
      },
    },
    storePaymentMethod: false,
  };

  if (paymentMethodType === "SAVED_CARD" && tokenId) {
    set(tokenizeReqPayload, "paymentMethod.tokenId", tokenId);
    set(tokenizeReqPayload, "paymentMethod.paymentType", cardType);
    set(tokenizeReqPayload, "paymentMethod.creditCard", {
      cardNumber: lastFourDigits,
      cardType,
      expirationMonth,
      expirationYear,
    });
  } else {
    const cardType = getCardType(actualCardNumber);
    set(tokenizeReqPayload, "paymentMethod.paymentType", cardType);
    set(tokenizeReqPayload, "paymentMethod.creditCard", {
      cardNumber: actualCardNumber.split(" ").join(""),
      cardType,
      cvvNumber,
      expirationMonth,
      expirationYear: expirationYear,
    });
  }
  return tokenizeReqPayload;
};

export const TokenizeAndAuthorizePayment = (
  values,
  orderId,
  partnerCode,
  orderTotal,
  successCallback,
  errorCallback,
  onlyTokenize = false,
  index = 0,
  retryCount = 0
) => {
  const getTokenApiRetryCount = Environment.get("GET_TOKEN_API_RETRY_COUNT", 3);

  const tokenizePayload = getTokenizePaymentPayload(
    values,
    orderId,
    partnerCode,
    index
  );

  request("tokenize-credit-card", {
    requestPayload: tokenizePayload,
  })
    .then((tokenizeResp = {}) => {
      if (!tokenizeResp.tokenId) {
        if (tokenizeResp.status === "declined") {
          const newErrors = {};
          set(
            newErrors,
            `paymentDetails.paymentMethod.${index}.paymentMethodDetails.cardNumber`,
            tokenizeResp.errorMessages[0]
          );
          errorCallback(newErrors, true);
          return;
        } else {
          if (retryCount < getTokenApiRetryCount - 1) {
            TokenizeAndAuthorizePayment(
              values,
              orderId,
              partnerCode,
              orderTotal,
              successCallback,
              errorCallback,
              onlyTokenize,
              index,
              retryCount + 1
            );
            return;
          }
        }
      }

      if (onlyTokenize) {
        successCallback(tokenizeResp.tokenId);
        return;
      }

      const {
        paymentMethod: { paymentType },
        partnerCode,
        merchantReferenceId,
      } = tokenizePayload;

      const {
        paymentMethodDetails: { cvv: cvvNumber },
      } = get(values, `paymentDetails.paymentMethod.${index}`, {});

      const authPayload = getAuthorizePayload(
        merchantReferenceId,
        paymentType.toUpperCase(),
        orderTotal,
        cvvNumber,
        partnerCode
      );

      request("authorize-payment", { requestPayload: authPayload })
        .then((authResp) => {
          const authDetails = getAutherizationDetails(authResp);
          successCallback(authDetails, tokenizeResp.tokenId);
        })
        .catch((error) => {
          errorCallback(error);
        });
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const isAllPaymentsProcessed = (paymentMethods, excludeIndex) => {
  const allCreditCardPaymentsProcessed = paymentMethods
    .filter(({ paymentMethodType }, index) => {
      if (excludeIndex !== "") {
        return paymentMethodType === "CREDIT_CARD" && index !== excludeIndex;
      } else {
        return paymentMethodType === "CREDIT_CARD";
      }
    })
    .every(({ paymentProcessed }) => paymentProcessed);
  return allCreditCardPaymentsProcessed;
};

//For split payments - Credit Card(CC) payments
export const TokenizeAndAuthorizePaymentV2 = async ({
  values = {},
  orderId,
  paymentMethods = [],
  successCallback = () => {},
  errorCallback = () => {},
  onlyTokenize = false,
  setCurrentPaymentMethod = () => {},
  formikBag,
}) => {
  const getTokenApiRetryCount = Environment.get("GET_TOKEN_API_RETRY_COUNT", 3);

  const { sendingMember: partnerCode } = values;
  let hasCCError = false;
  {
    /** recursive method to handle CC payments 
    by calling tokenize and authorize API's in sequence for each CC payment */
  }
  const TokenizeAndAuthorizePayment = async ({ index, retryCount = 0 }) => {
    const { paymentMethodType, paymentProcessed = false } = get(
      paymentMethods,
      `${index}`,
      {}
    );

    // "SAVED_CARD" will also be "CREDIT_CARD" paymentMethodType
    if (!["CREDIT_CARD"].includes(paymentMethodType) || paymentProcessed) {
      //Proceed with next payments if payment is not CC or if payment already processed
      if (index < paymentMethods?.length - 1) {
        TokenizeAndAuthorizePayment({ index: index + 1 });
      } else {
        setCurrentPaymentMethod("");
      }
      return;
    }

    setCurrentPaymentMethod(paymentMethodType);

    const isLastCCPayment = (index) => {
      return (
        index ===
        findLastIndex(
          paymentMethods,
          (payment) => ["CREDIT_CARD"].includes(payment.paymentMethodType) // "SAVED_CARD" will also be "CREDIT_CARD" paymentMethodType
        )
      );
    };

    console.log("tokenize - start", index);

    const tokenizePayload = getTokenizePaymentPayload(
      values,
      orderId,
      partnerCode,
      index
    );

    request("tokenize-credit-card", { requestPayload: tokenizePayload })
      .then((tokenizeResponse) => {
        if (!tokenizeResponse.tokenId) {
          if (
            tokenizeResponse.status === "declined" &&
            !["ERROR", "CATCH ALL ERROR"].includes(tokenizeResponse)
          ) {
            const newErrors = {};
            set(
              newErrors,
              `paymentDetails.paymentMethod.${index}.paymentMethodDetails.cardNumber`,
              get(tokenizeResponse, "errorMessages.0", "")
            );
            hasCCError = true;
            errorCallback({
              error: newErrors,
              isFormikError: true,
              stopPaymentProcessing: isLastCCPayment(index),
              formikBag,
            });
            console.log("tokenize - fail", index);
            //Proceed with next payments even if tokenize fails
            if (index < paymentMethods?.length - 1) {
              TokenizeAndAuthorizePayment({ index: index + 1 });
            }
            return;
          } else {
            if (retryCount < getTokenApiRetryCount - 1) {
              TokenizeAndAuthorizePayment({
                index,
                retryCount: retryCount + 1,
              });
            }
          }
        }
        if (onlyTokenize) {
          successCallback({
            orderId,
            formikBag,
            tokenizePayload,
            authorizedPaymentDetails: {
              tokenId: tokenizeResponse?.tokenId,
            },
          });
          return;
        }
        console.log("tokenize - success", index);
        const {
          paymentMethod: { paymentType },
          merchantReferenceId,
        } = tokenizePayload;

        const {
          paymentMethodDetails: { cvv: cvvNumber },
          amount: paymentAmount,
        } = get(values, `paymentDetails.paymentMethod.${index}`, {});

        const authPayload = getAuthorizePayload(
          merchantReferenceId,
          paymentType.toUpperCase(),
          Number(paymentAmount),
          cvvNumber,
          values.sendingMember
        );
        console.log("authorize - start", index);
        request("authorize-payment", {
          requestPayload: authPayload,
        })
          .then((authResp) => {
            const authDetails = getAutherizationDetails(authResp);
            console.log("authorize - success", index);

            successCallback({
              index,
              orderId,
              authorizedPaymentDetails: {
                authDetails,
                tokenId: tokenizeResponse?.tokenId,
              },
              createOrder:
                (isLastCCPayment(index) ||
                  isAllPaymentsProcessed(paymentMethods, index)) &&
                !hasCCError,
              stopPaymentProcessing: isLastCCPayment(index),
              formikBag,
            });
          })
          .catch((error) => {
            console.log("authorize - failed", index);
            hasCCError = true;
            errorCallback({
              error,
              index,
              stopPaymentProcessing: isLastCCPayment(index),
              formikBag,
            });
          })
          .finally(() => {
            //Proceed with next payments even if autorize fails
            if (index < paymentMethods?.length - 1) {
              TokenizeAndAuthorizePayment({ index: index + 1 });
            }
          });
      })
      .catch((error) => {
        console.log("tokenize - fail", index);
        hasCCError = true;
        errorCallback({
          error,
          index,
          stopPaymentProcessing: isLastCCPayment(index),
          formikBag,
        });
        //Proceed with next payments even if tokenize fails
        if (index < paymentMethods?.length - 1) {
          TokenizeAndAuthorizePayment({ index: index + 1 });
        }
      });
  };

  //initial trigger recursive method for first payment
  TokenizeAndAuthorizePayment({ index: 0 });
};

export const TerminalPayment = ({
  values = {},
  orderId,
  paymentMethods,
  successCallback = () => {},
  errorCallback = () => {},
  setCurrentPaymentMethod = () => {},
  createOrderReqObj,
  formikBag,
  showError,
  isGcFlow = false,
  redeemedGiftCardsData,
  setPaymentStatusDetails,
  isSubscription,
  dispatch,
  subscriptionData,
}) => {
  let hasTerminalPaymentFailed = false;
  const handleTerminalPayment = ({ index }) => {
    const { paymentMethodType, paymentProcessed = false } = get(
      paymentMethods,
      `${index}`,
      {}
    );

    if (!["CREDIT_CARD"].includes(paymentMethodType) || paymentProcessed) {
      //Proceed with next payments if payment is not Payment_Terminal or if payment already processed
      if (index < paymentMethods?.length - 1) {
        handleTerminalPayment({ index: index + 1 });
      } else {
        setCurrentPaymentMethod("");
      }
      return;
    }

    setCurrentPaymentMethod(`PAYMENT_TERMINAL*${index}`);

    const isSavePaymentSelected = get(
      values,
      `paymentDetails.paymentMethod.${index}.savePayment`,
      false
    );

    const isLastTerminalPayment = (index) => {
      return (
        index ===
        findLastIndex(
          paymentMethods,
          (payment) => ["CREDIT_CARD"].includes(payment.paymentMethodType) // "PAYMENT_TERMINAL" will also be "CREDIT_CARD" paymentMethodType
        )
      );
    };

    sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.AUTHORIZATION,
      sendingMember: createOrderReqObj.sendingMember,
      merchantReferenceId: orderId,
      amount: paymentMethods[index].amount,
      requestMultiUseToken: isSavePaymentSelected,
      callback: ({ terminalResponse, paymentMethodDetails = {} }) => {
        if (isLastTerminalPayment(index)) {
          setCurrentPaymentMethod("");
        }
        console.log("terminalResponse :>> ", terminalResponse);
        if (terminalResponse.ResponseCode === "00") {
          set(
            createOrderReqObj,
            `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
            paymentMethodDetails
          );
          formikBag.setFieldValue(
            `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
            paymentMethodDetails
          );

          formikBag.setFieldValue(
            `paymentDetails.paymentMethod.${index}.paymentProcessed`,
            true
          );
          formikBag.setFieldValue(
            `paymentDetails.paymentMethod.${index}.paymentFailed`,
            false
          );

          //store processed payments in state to warn when user navigates away before creating order
          const updatedValues = cloneDeep(values);
          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
            paymentMethodDetails
          );

          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.paymentProcessed`,
            true
          );
          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.paymentFailed`,
            false
          );

          setPaymentStatusDetails({
            ...updatedValues,
            merchantReferenceId: orderId,
          });

          if (
            (isLastTerminalPayment(index) ||
              isAllPaymentsProcessed(paymentMethods, index)) &&
            !hasTerminalPaymentFailed
          ) {
            if (!isSubscription) {
              createOrder(
                createOrderReqObj,
                successCallback,
                errorCallback,
                formikBag
              );
            } else {
              dispatch(
                upsertSubscription({
                  params: {
                    createOrderReqObj,
                    subscriptionData,
                  },
                  resolve: (response) => {
                    setCurrentPaymentMethod("");
                    successCallback(response, formikBag, createOrderReqObj);
                  },
                  reject: (err) => {
                    errorCallback({
                      err,
                      formikBag,
                      createOrderReqObj,
                    });
                  },
                })
              );
            }
          }

          if (!isLastTerminalPayment(index)) {
            if (index < paymentMethods?.length - 1) {
              handleTerminalPayment({ index: index + 1 });
            }
          }
        } else {
          // incase of gcflow if any one payment failed then we are reinstate the gc.
          if (isGcFlow)
            redeemedGiftCardsData?.length > 0 &&
              authorizeGiftCardPayment({
                values: createOrderReqObj,
                redeemedGiftCardsData,
                action: "REINSTATE",
              });
          hasTerminalPaymentFailed = true;
          showError(terminalResponse.ResponseText, formikBag);
          //Proceed with next payments even if autorize fails
          if (index < paymentMethods?.length - 1) {
            handleTerminalPayment({ index: index + 1 });
          }
        }
      },
      cancelBtnHandler: ({ txnStatus }) => {
        setCurrentPaymentMethod(`${txnStatus}*${index}`);
      },
    });
  };

  handleTerminalPayment({ index: 0 });
};

export const authorizeGiftCardPayment = ({
  values,
  redeemedGiftCardsData,
  gcSuccessCallback,
  gcErrorCallback,
  validateResp,
  orderTotal,
  action,
  onEditGcSuccessCallback,
  onEditGcErrorCallback,
  isEditOrder,
  hasCreditCardPayment,
  hasTerminalPayment,
  CCSuccessCallback,
  CCErrorCallback,
}) => {
  let requestPayloadArr = [];
  const staticData = {
    status: action, //REINSTATE ; To unlog amount redeemed from gc.
    channel: "MHQ",
  };

  for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
    requestPayloadArr.push({
      ...staticData,
      giftCodeId: redeemedGiftCardsData[i]?.giftCodeId,
      totalRedemptionAmount: redeemedGiftCardsData[i]?.gcAmount,
    });
  }
  request("authorize-gift-card-payment", {
    requestPayload: requestPayloadArr,
    shopCode: values?.sendingMember || values?.member,
  })
    .then(() => {
      if (action !== "REINSTATE") {
        isEditOrder
          ? onEditGcSuccessCallback(orderTotal)
          : gcSuccessCallback({
              validateResp,
              hasCreditCardPayment,
              hasTerminalPayment,
              CCSuccessCallback,
              CCErrorCallback,
            });
      }
    })
    .catch((error) => {
      if (action !== "REINSTATE") {
        isEditOrder ? onEditGcErrorCallback(error) : gcErrorCallback({ error });
      }
    });
};

export const getCreditCardTypeFromHLTxnRes = (paymentType) => {
  let creditCardType = paymentType;

  if (creditCardType === "AMEX") creditCardType = "AMERICANEXPRESS";

  // We are getting payment type as DiSCOVER in Heartland auth response so converting it to all uppercase
  if (creditCardType?.toLowerCase() === "discover") creditCardType = "DISCOVER";

  return creditCardType;
};

export const getPaymentMethodDetailsFromHLTxnRes = (
  serialNumber,
  sendingMember,
  merchantReferenceId,
  terminalResponse
) => {
  let cardHolderName = "Card Holder Name";

  if (terminalResponse?.CardHolderName) {
    // removing special characters except space, dot and hyphen as we have form validation for saved card payment option
    cardHolderName = terminalResponse?.CardHolderName.replace(
      /[^\\sa-zA-Z.-]/g,
      " "
    ).trim();
  }

  const cardNumber = terminalResponse?.MaskedCardNumber;
  const expirationDate = terminalResponse?.ExpirationDate;

  const paymentMethodDetails = {
    nameOnCard: cardHolderName,
    creditCardType: getCreditCardTypeFromHLTxnRes(
      terminalResponse?.PaymentType || ""
    ),
    creditCardNumber: cardNumber.substring(cardNumber.length - 4),
    creditCardExpireMonth: expirationDate.substring(0, 2),
    creditCardExpireYear: `20${expirationDate.substring(2)}`,
    tokenId: terminalResponse?.Token || "",
    authorizationDetails: [
      {
        name: "currency",
        value: "usd",
      },
      {
        name: "authCode",
        value: terminalResponse?.AuthorizationCode || "",
      },
      {
        name: "authorizationTransactionId",
        value: terminalResponse?.TransactionId || "",
      },
      {
        name: "transactionDetailsId",
        value: terminalResponse?.TransactionId || "",
      },
      {
        name: "amount",
        value: terminalResponse?.TransactionAmount || 0.0,
      },

      {
        name: "status",
        value: "approved",
      },
      {
        name: "merchantReferenceId",
        value: merchantReferenceId,
      },
      {
        name: "partnerCode",
        value: sendingMember,
      },
      {
        name: "entryMode",
        value: terminalResponse?.EntryMethod || "",
      },
      {
        name: "terminalSerialNumber",
        value: serialNumber,
      },
    ],
  };

  return paymentMethodDetails;
};

export const TransactionTypes = {
  SALE: "sale",
  AUTHORIZATION: "authorization",
  TOKENIZATION: "tokenization",
  VOID: "void",
  REFUND: "refund",
  CANCEL: "cancel",
  TRANSACTION_LOOKUP: "transaction_lookup",
  MTLS_CONNECTION: "mtls_connection",
};

/**
 * This method is useful for Cybersource integration in Cloud Mode
 *
 * @param {string} sendingMember - shop code
 * @param {string} transactionType - values - authorization, tokenization and refund
 * @param {string} merchantReferenceId - merchantReferenceId
 * @param {string} amount - amount that we need to charge for an order
 * @param {string} transactionId - that we received when we did authorization
 * @param {boolean} requestMultiUseToken - to decide whether we need to generate a token or not while doing authorization
 * @param {function} callback - callback function that will trigger once we received response from terminal
 */
export const sendCommandToPAXTerminalV1 = async ({
  transactionType,
  sendingMember,
  merchantReferenceId,
  amount = 0.0,
  transactionId = "",
  //requestMultiUseToken = false,
  callback,
  cancelBtnHandler,
}) => {
  const { terminal_settings = [] } =
    UserProfileStorage.getShopPreferences(sendingMember);

  const { currentPOSSettings = {} } = await getCurrentPOSSettings(
    terminal_settings
  );

  const serialNumber = currentPOSSettings?.serial_number || "";

  if (!serialNumber) {
    const terminalResponse = {
      ResponseCode: "1000",
      ResponseText: COMMON.PT_SERIAL_MISSING,
    };

    callback && callback({ terminalResponse });
    return;
  }

  const requestObj = createTerminalTxnRequest({
    transactionType,
    merchantReferenceId,
    amount,
    transactionId,
  });

  try {
    const txnResponse = await request(
      "command-to-terminal",
      {
        sendingMember,
        serialNumber,
        requestObj,
      },
      undefined,
      undefined,
      true
    );

    handleTerminalTxnResponse({
      txnResponse,
      transactionId,
      merchantReferenceId,
      serialNumber,
      sendingMember,
      callback,
      cancelBtnHandler,
    });
  } catch (error) {
    console.log(
      "Error while sending a request to the terminal via cloud :>>",
      error
    );
    handleTerminalTxnErrors({
      error,
      sendingMember,
      merchantReferenceId,
      callback,
    });
  }
};

export const doTransactionLookup = ({
  sendingMember,
  merchantReferenceId,
  transactionId = "",
  callback,
}) => {
  sendCommandToPAXTerminalV1({
    transactionType: TransactionTypes.TRANSACTION_LOOKUP,
    sendingMember,
    merchantReferenceId,
    transactionId,
    callback: ({ terminalResponse }) => {
      callback && callback({ txnLookupResponse: terminalResponse });
    },
  });
};

export const doRefund = ({ sendingMember, transactionId, callback }) => {
  if (transactionId !== "") {
    let message = "Credit card processing error.";

    sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.REFUND,
      sendingMember,
      transactionId,
      callback: ({ terminalResponse: refundResponse = {} }) => {
        if (refundResponse.ResponseCode === "00") {
          message += " Refund initiated.";
        } else {
          message += " Refund failed.";
        }

        message += " Contact us at (800) 788-9000 to resolve this issue.";

        callback && callback({ message });
      },
    });
  } else {
    callback &&
      callback({
        message: "Transaction id cannot be empty while doing a refund.",
      });
  }
};

export const initiateRefundThroughTerminal = ({
  sendingMember,
  merchantReferenceId,
  transactionId = "",
  doLookup = false,
  resolve,
}) => {
  if (doLookup) {
    doTransactionLookup({
      sendingMember,
      merchantReferenceId,
      callback: ({ txnLookupResponse }) => {
        if (txnLookupResponse.ResponseCode === "00") {
          const txnStatus =
            txnLookupResponse?.ResponseText?.processingDetails?.status || "";

          let txnId =
            txnLookupResponse?.ResponseText?.transactionDetails?.id || "";

          if (txnStatus === "APPROVED" && txnId !== "") {
            doRefund({
              sendingMember,
              transactionId: txnId,
              callback: ({ message }) => {
                resolve && resolve({ message });
              },
            });
          } else {
            resolve &&
              resolve({
                message: `Transaction with id - ${txnId} is not approved and refund not initiated.`,
              });
          }
        } else {
          resolve && resolve({ message: txnLookupResponse.ResponseText });
        }
      },
    });
  } else {
    doRefund({
      sendingMember,
      transactionId,
      callback: ({ message }) => {
        resolve && resolve({ message });
      },
    });
  }
};

export const getCancelTerminalTxnMessage = (message) => {
  return typeof message === "string" ? message : "Payment aborted";
};

/**
 *
 * @param {integer} min
 * @param {integer} max
 * @returns a random integer between the specified values. The value is no lower than min (or the next integer
 * greater than min if min isn't an integer), and is less than (but not equal to) max.
 */
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

const paypalOptions = [
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];

export const PAID_ELSEWHERE = (props) => {
  const {
    isSmallScreen,
    path,
    values,
    orderTotal,
    hideFields = false,
    labelStyle,
    setFieldValue,
    enableSplitPayment = false,
  } = props;
  const detailsPath = `${path}.paymentMethodDetails`;
  const { messages, Localise } = React.useContext(I18NContext);

  const note = get(values, `${detailsPath}.note`, "");
  const { paymentMethodType } = get(values, `${path}`, "");

  const amount = enableSplitPayment
    ? get(values, `${path}.amount`, orderTotal)
    : orderTotal;

  useEffect(() => {
    const data = get(values, detailsPath);
    setTimeout(() => {
      setFieldValue(detailsPath, {
        ...data,
        amount: formatPrice(amount),
        note: note,
        orderTotal,
      });
    }, 300);
  }, [orderTotal, note, amount, paymentMethodType]);

  if (hideFields) return null;

  return (
    <>
      {!enableSplitPayment ? (
        <FormField
          name="amount"
          label={Localise(messages, "Amount")}
          labelStyle={labelStyle}
          containerStyle={{
            width: isSmallScreen ? "100%" : "18%",
            marginTop: 2,
          }}
          errorStyle={{ paddingBottom: 0 }}
          iconType="material-community"
          iconName="currency-usd"
          iconSize={14}
          inputContainerStyle={{
            padding: 6,
          }}
          leftIconContainerStyle={{ paddingRight: 0 }}
          path={detailsPath}
          isPrice={true}
          editable={false}
          grayedOutOnDisable={true}
        />
      ) : null}
      <FormField
        name="note"
        label={Localise(messages, "Notes")}
        labelStyle={labelStyle}
        placeholder={Localise(messages, "Enter optional notes or check #")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "47%",
          marginTop: 2,
        }}
        errorStyle={{ paddingBottom: 0 }}
        maxLength={50}
        path={detailsPath}
      />
    </>
  );
};

export const PAY_LATER = (props) => {
  const {
    isSmallScreen,
    path,
    values,
    orderTotal,
    hideFields = false,
    labelStyle,
    setFieldValue,
  } = props;
  const detailsPath = `${path}.paymentMethodDetails`;
  const { messages, Localise } = React.useContext(I18NContext);

  const note = get(values, `${detailsPath}.note`, "");
  useEffect(() => {
    const data = get(values, detailsPath);
    setTimeout(() => {
      setFieldValue(detailsPath, {
        ...data,
        amount: formatPrice(orderTotal),
        note: note,
        orderTotal,
      });
    }, 300);
  }, [orderTotal, note]);

  if (hideFields) return null;

  return (
    <>
      <FormField
        name="amount"
        label={Localise(messages, "Amount")}
        labelStyle={labelStyle}
        containerStyle={{
          width: isSmallScreen ? "100%" : "35%",
          marginTop: 2,
        }}
        errorStyle={{ paddingBottom: 0 }}
        iconName={"usd"}
        iconSize={12}
        path={detailsPath}
        isPrice={true}
        editable={false}
        iconType="font-awesome"
        grayedOutOnDisable={true}
      />
      <FormField
        name="note"
        label={Localise(messages, "Note")}
        labelStyle={labelStyle}
        placeholder={Localise(messages, "Enter note as needed")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "30%",
          marginTop: 2,
        }}
        errorStyle={{ paddingBottom: 0 }}
        maxLength={50}
        path={detailsPath}
      />
    </>
  );
};

export const CASH = (props) => {
  const {
    isSmallScreen,
    path,
    values,
    orderTotal,
    labelStyle,
    enableSplitPayment = false,
    setFieldValue,
  } = props;
  const detailsPath = `${path}.paymentMethodDetails`;
  const { messages, Localise } = React.useContext(I18NContext);

  const tenderedAmount = get(values, `${detailsPath}.tenderedAmount`, 0);
  const amount = enableSplitPayment
    ? get(values, `${path}.amount`, orderTotal)
    : orderTotal;

  useEffect(() => {
    if (isNaN(tenderedAmount)) return;
    const data = get(values, detailsPath);
    const diff = tenderedAmount - amount;
    const changeDueAmount = diff >= 0 ? diff : 0;
    setFieldValue(detailsPath, {
      ...data,
      changeDueAmount: parseFloat(changeDueAmount).toFixed(2),
      orderTotal: Number(formatPrice(orderTotal)),
      amount,
    });
  }, [tenderedAmount, orderTotal, amount]);

  return (
    <>
      <FormField
        name="tenderedAmount"
        label={Localise(messages, "Tendered")}
        labelStyle={labelStyle}
        placeholder={Localise(messages, "Enter Amount (e.g. $100.00)")}
        containerStyle={{
          width: isSmallScreen ? "100%" : enableSplitPayment ? "25%" : "35%",
          marginTop: 2,
        }}
        errorStyle={{ paddingBottom: 0 }}
        path={detailsPath}
        iconType="material-community"
        iconName="currency-usd"
        iconSize={14}
        inputContainerStyle={{
          padding: 6,
        }}
        leftIconContainerStyle={{ paddingRight: 0 }}
        isPrice={true}
      />
      <FormField
        name="changeDueAmount"
        label={Localise(messages, "Change Due")}
        labelStyle={labelStyle}
        placeholder={Localise(messages, "Due Amount")}
        containerStyle={{
          width: isSmallScreen ? "100%" : enableSplitPayment ? "22%" : "30%",
          marginTop: 2,
        }}
        errorStyle={{ paddingBottom: 0 }}
        iconType="material-community"
        iconName="currency-usd"
        iconSize={14}
        inputContainerStyle={{
          padding: 6,
        }}
        leftIconContainerStyle={{ paddingRight: 0 }}
        path={detailsPath}
        editable={false}
        grayedOutOnDisable={true}
      />
    </>
  );
};

export const CREDIT_CARD = ({
  isSmallScreen,
  path,
  values,
  enableCPS = true,
  mandatorySave,
  labelStyle,
  index = 0,
  enableSplitPayment = false,
  setFieldValue,
}) => {
  const dispatch = useDispatch();

  const { messages, Localise } = React.useContext(I18NContext);
  const { showDeleteCardModal, isLoading } = useSelector(selectDeletCardModal);

  const {
    paymentDetails = {},
    customerInfo = {},
    isQuickSaleEnabled = false,
  } = values;

  const paymentMethodInfo = paymentDetails.paymentMethod[index] || {};
  const {
    savePayment,
    paymentMethodType,
    paymentProcessed = false,
    paymentMethodDetails: { actualCardNumber, isSwipedCard = false } = {},
  } = paymentMethodInfo;

  const detailsPath = `${path}.paymentMethodDetails`;
  const billingPath = `${path}.billingInformation`;

  const isSavedCard = paymentMethodType === "SAVED_CARD";
  const isTerminalPayment = paymentMethodType === "PAYMENT_TERMINAL";

  const {
    storeOrigin = "",
    customerId = "",
    firstPaymentInfo = {},
  } = customerInfo;
  const { creditCardId = "" } = firstPaymentInfo;

  const readCardData = () => {
    const cardContentDiv = document.getElementById("card_content_div");
    const cardData = cardContentDiv.innerHTML;
    cardContentDiv.innerHTML = ""; // Clear the data;

    const cardDataArr = cardData.split("_");
    const data = get(values, detailsPath);
    setFieldValue(detailsPath, {
      ...data,
      cardNumber: cardDataArr[0].split("/")[1],
      actualCardNumber: cardDataArr[0].split("/")[0],
      expDate: cardDataArr[1],
      name: cardDataArr[2],
      isSwipedCard: true,
    });
  };
  useEffect(() => {
    if (Platform.OS !== "web") return;

    const swipeButtonElement = document.getElementById("swipe_click_button");
    swipeButtonElement.addEventListener("click", readCardData);

    // remove the event listener when component unmounts
    return () => {
      swipeButtonElement.removeEventListener("click", readCardData);
    };
  }, []);

  const handleDeleteCard = () => {
    request("delete-customer-credit-card", {
      customerId,
      creditCardId,
      storeOrigin,
    })
      .then(() => {
        dispatch(
          setDeleteCardModal({ showDeleteCardModal: false, isLoading: false })
        );
        setFieldValue("customerInfo.firstPaymentInfo", {});
        setFieldValue("paymentDetails.paymentMethod.0.paymentMethodType", "");
        ToasterHandler(
          "success",
          Localise(messages, "Saved Payment deleted successfully")
        );
        dispatch(fetchCustomerDetails({ storeOrigin, customerId }));
      })
      .catch((error) => {
        console.log("Error: " + JSON.stringify(error));
        ToasterHandler(
          "error",
          Localise(
            messages,
            "Failed to delete Saved Payment. Please try again."
          )
        );
        dispatch(
          setDeleteCardModal({ showDeleteCardModal: false, isLoading: false })
        );
      });
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {!isTerminalPayment && (
          <>
            <FormField
              name="name"
              label={Localise(messages, "Name on Card")}
              labelStyle={labelStyle}
              placeholder={Localise(messages, "Enter Name on Card")}
              containerStyle={{
                width: isSmallScreen
                  ? "100%"
                  : enableSplitPayment
                  ? "47%"
                  : "65%",
                marginTop: 2,
              }}
              errorStyle={{ paddingBottom: 0 }}
              path={detailsPath}
              editable={!isSavedCard && !paymentProcessed}
              grayedOutOnDisable={true}
            />

            <FormField
              name="cardNumber"
              label={Localise(messages, "Card Number")}
              labelStyle={labelStyle}
              placeholder={Localise(messages, "Enter Card Number")}
              fsClass="fs-exclude"
              containerStyle={{
                width: isSmallScreen ? "100%" : "40%",
                marginTop: 2,
              }}
              errorStyle={{ paddingBottom: 0 }}
              path={detailsPath}
              editable={!isSavedCard && !paymentProcessed}
              grayedOutOnDisable={true}
              iconName="credit-card"
              iconPosition={false}
              keyboardType="numeric"
              maxLength={19}
              transformText={(text = "") => {
                let trimmed = text.split(" ").join("");
                if (trimmed.length < 4 || isNaN(trimmed)) return text;
                let ccnumspaced = trimmed.match(/.{1,4}/g) || [];
                return ccnumspaced.join(" ");
              }}
              autoComplete={!isSwipedCard ? "new-password" : ""} //hack for autoComplete off
              handleOnBlur={(cardNumber) => {
                if (isMasked(cardNumber)) return;
                setFieldValue(
                  `${path}.paymentMethodDetails.cardNumber`,
                  cardNumber // removed masked code sas part of this ticket: MSOL-18277
                );
                setFieldValue(
                  `${path}.paymentMethodDetails.actualCardNumber`,
                  cardNumber
                );
              }}
              onKeyPress={(e) => {
                if (isSwipedCard) {
                  const keyCode = e.which || e.keyCode || e.nativeEvent.key;
                  //For backspace and delete keys
                  if (
                    [8, 46, "Backspace"].includes(keyCode) &&
                    actualCardNumber
                  ) {
                    const data = get(values, detailsPath);
                    const updatedValues = {
                      ...data,
                      cardNumber: "",
                      actualCardNumber: "",
                      expDate: "",
                      cvv: "",
                      name: "",
                      isSwipedCard: false,
                    };

                    if (Platform.OS === "ios") {
                      setTimeout(() => {
                        setFieldValue(detailsPath, updatedValues);
                      }, 500);
                    } else {
                      setFieldValue(detailsPath, updatedValues);
                    }
                  }
                }
              }}
            />

            <FormField
              name="expDate"
              label={Localise(
                messages,
                isSmallScreen ? "Expiration" : "Expiration Date"
              )}
              labelStyle={labelStyle}
              placeholder="MM/YYYY"
              containerStyle={{
                width: isSmallScreen ? "30%" : "20%",
                marginTop: 2,
              }}
              errorStyle={{ paddingBottom: 0 }}
              maxLength={7}
              path={detailsPath}
              editable={!isSavedCard && !paymentProcessed}
              grayedOutOnDisable={true}
              transformText={(text) => getFormattedCreditCardExpiry(text)}
              autoComplete={!isSwipedCard ? "new-password" : ""} //hack for autoComplete off
            />

            <FormField
              name="cvv"
              label={Localise(messages, "CVV")}
              labelStyle={labelStyle}
              placeholder={Localise(messages, "Enter Code")}
              containerStyle={{
                width: isSmallScreen ? "30%" : "20%",
                marginTop: 2,
              }}
              errorStyle={{ paddingBottom: 0 }}
              maxLength={4}
              path={detailsPath}
              editable={!isSwipedCard && !paymentProcessed}
              grayedOutOnDisable={true}
              secureTextEntry={true}
              autoComplete={!isSwipedCard ? "new-password" : ""} //hack for autoComplete off
            />
          </>
        )}

        {(!isTerminalPayment || (isTerminalPayment && savePayment)) && (
          <>
            <FormField
              name="zip"
              label={Localise(messages, "Billing Zip")}
              labelStyle={labelStyle}
              placeholder={Localise(messages, "Enter Zip")}
              containerStyle={{
                width: isSmallScreen ? "40%" : "20%",
                marginTop: 2,
              }}
              errorStyle={{ paddingBottom: 0 }}
              maxLength={10}
              path={billingPath}
              editable={!isSavedCard && !paymentProcessed}
              grayedOutOnDisable={true}
              autoComplete={!isSwipedCard ? "new-password" : ""} //hack for autoComplete off
            />
          </>
        )}
      </React.Fragment>

      {enableCPS && !isQuickSaleEnabled && !isSavedCard && (
        <View style={[tw("flex flex-row justify-end"), { width: "100%" }]}>
          <FormFieldCheckBox
            iconRight={false}
            name="savePayment"
            size={20}
            path={path}
            containerStyle={{
              marginLeft: isTerminalPayment && !savePayment ? 20 : "auto",
              marginTop: isTerminalPayment ? 20 : 0,
            }}
            title={Localise(
              messages,
              "Save Payment (requires customer consent)"
            )}
            textStyle={{ fontWeight: "normal" }}
            errorStyle={{ paddingBottom: 0 }}
            defaultChecked={mandatorySave}
            isEditable={!mandatorySave && !paymentProcessed}
          />
        </View>
      )}

      {(savePayment || isSavedCard) && (
        <BillingInfo
          isSmallScreen={isSmallScreen}
          path={billingPath}
          values={values}
          isSavedCard={isSavedCard}
          paymentProcessed={paymentProcessed}
          labelStyle={labelStyle}
        />
      )}

      {isSavedCard && (
        <Button
          title={Localise(messages, "Delete Card")}
          titleStyle={theme.Button.secondaryTitleStyle}
          buttonStyle={{
            ...theme.Button.secondaryButtonStyle,
            paddingVertical: 5,
            paddingHorizontal: 10,
          }}
          containerStyle={{
            marginBottom: 0,
          }}
          onPress={() => {
            dispatch(setDeleteCardModal({ showDeleteCardModal: true }));
          }}
          testID={"Delete_Card"}
          accessibilityLabel={"Delete_Card"}
        />
      )}

      {showDeleteCardModal && (
        <DeleteCardModal
          modalVisible={showDeleteCardModal}
          onConfirm={() => {
            handleDeleteCard();
            dispatch(
              setDeleteCardModal({ showDeleteCardModal: true, isLoading: true })
            );
          }}
          closeModal={() =>
            dispatch(setDeleteCardModal({ showDeleteCardModal: false }))
          }
          loading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export const DIGITAL_INVOICE = ({ isSmallScreen, path }) => {
  return (
    <>
      <FormField
        name="email"
        label="Email"
        placeholder="Enter Email"
        containerStyle={{
          width: isSmallScreen ? "100%" : "40%",
          marginTop: 2,
        }}
        path={path}
        editable={true}
      />
      <FormField
        name="phone"
        label="Phone"
        placeholder="Enter Number"
        containerStyle={{
          width: isSmallScreen ? "100%" : "30%",
          marginTop: 2,
        }}
        path={path}
        editable={true}
      />
    </>
  );
};

export const MAIL_INVOICE = ({ isSmallScreen, path }) => {
  return <></>;
};

export const PhoneField = ({ isSmallScreen, path }) => {
  return (
    <FormField
      name="phone"
      label="Phone"
      placeholder="Enter Number"
      containerStyle={{
        width: isSmallScreen ? "100%" : "30%",
        marginTop: 2,
      }}
      path={path}
      editable={true}
    />
  );
};

export const EmailField = ({ isSmallScreen, path }) => {
  return (
    <FormField
      name="email"
      label="Email"
      placeholder="Enter Email"
      containerStyle={{
        width: isSmallScreen ? "100%" : "30%",
        marginTop: 2,
      }}
      path={path}
      editable={true}
    />
  );
};

export const PAY_PAL = ({ isSmallScreen, path, data }) => {
  const { paypalMethod } = data;

  return (
    <>
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "50%" : "30%",
        }}
        placeholder={{ label: "Select Method" }}
        data={paypalOptions}
        name="paypalMethod"
        label="Select Method"
        path={path}
      />

      {paypalMethod === "phone" ? (
        <PhoneField isSmallScreen={isSmallScreen} path={path} />
      ) : (
        paypalMethod === "email" && (
          <EmailField isSmallScreen={isSmallScreen} path={path} />
        )
      )}
    </>
  );
};

export const KeysToComponentMap = {
  CASH,
  CHECK: PAID_ELSEWHERE,
  CREDIT_CARD,
  PAID_ELSEWHERE,
  PAY_LATER,
  DIGITAL_INVOICE,
  MAIL_INVOICE,
  PAY_PAL,
  SAVED_CARD: CREDIT_CARD,
  PAYMENT_TERMINAL: CREDIT_CARD,
  CREDIT_BALANCE: PAID_ELSEWHERE,
};

export const PaymentComponent = ({
  paymentKey,
  paymentOptions = [],
  enableCPS,
  hideFields,
  mandatorySave = false,
  labelStyle = {},
  grandTotal,
  isPayLaterEnabled = false,
  isOrderTotalZero = [],
  index = 0,
  enableSplitPayment = false,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  removePaymentRef = useRef(false),
}) => {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const dispatch = useDispatch();
  const { isDesktop } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);
  const redeemedGiftCardsData = useSelector(selectRedeemedGiftCardsData);

  // Adding this state in order to re-render payment options dropdown with updated payment types
  const [paymentTypes, setPaymentTypes] = useStateIfMounted([]);

  const isSmallScreen = !isDesktop;
  const {
    paymentMethodType = "",
    paymentProcessed = false,
    paymentFailed = false,
    amount,
  } = values.paymentDetails.paymentMethod[index];
  const Component =
    KeysToComponentMap[
      values.paymentDetails.paymentMethod[index].paymentMethodType
    ];
  const { paymentDetails } = values;
  const path = `paymentDetails.paymentMethod.${index}`;

  const multiplePayments = paymentDetails?.paymentMethod?.length > 1;
  const showRemovePayment = multiplePayments && !paymentProcessed;

  useEffect(() => {
    let updatedBillingInfo = {};
    let updatedPaymentMethodDetails = {};
    setFieldValue(`paymentDetails.paymentMethod.${index}.paymentFailed`, false);
    setFieldValue(
      `paymentDetails.paymentMethod.${index}.paymentProcessed`,
      false
    );

    if (paymentMethodType === "SAVED_CARD") {
      const { firstPaymentInfo } = values.customerInfo;
      const {
        lastFourDigits,
        expirationMonth,
        expirationYear = "",
        nameOnCard,
        billingAddress = {},
      } = firstPaymentInfo;

      const {
        addressLine1,
        addressLine2: suite,
        city,
        state,
        zipcode: zip,
        country,
      } = billingAddress;

      updatedBillingInfo = {
        addressLine1,
        suite,
        city,
        state,
        zip,
        country,
      };

      updatedPaymentMethodDetails = {
        name: nameOnCard,
        cardNumber: "***" + lastFourDigits,
        cvv: "",
        expDate: expirationMonth + "/" + expirationYear,
      };
    } else {
      updatedPaymentMethodDetails =
        basicPaymentInfo.paymentMethod[0].paymentMethodDetails;
      updatedBillingInfo = basicPaymentInfo.paymentMethod[0].billingInformation;

      if (["CREDIT_CARD", "PAYMENT_TERMINAL"].includes(paymentMethodType)) {
        const { billingInformation = {} } = values.customerInfo;
        if (!isEmpty(billingInformation))
          updatedBillingInfo = billingInformation;
      }
    }
    // don't update these below 2, incase of removePayment scenario.
    if (!removePaymentRef.current) {
      setFieldValue(
        `paymentDetails.paymentMethod.${index}.billingInformation`,
        { ...updatedBillingInfo }
      );

      setFieldValue(
        `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
        { ...updatedPaymentMethodDetails }
      );
    }

    // unset the removePaymentRef incase of last paymentMethod
    if (paymentDetails?.paymentMethod?.length - 1 === index)
      removePaymentRef.current = false;

    if (
      mandatorySave &&
      ["CREDIT_CARD", "PAYMENT_TERMINAL"].includes(paymentMethodType)
    ) {
      setFieldValue(
        `paymentDetails.paymentMethod.${index}.savePayment`,
        mandatorySave
      );
    } else {
      setFieldValue(`paymentDetails.paymentMethod.${index}.savePayment`, false);
    }

    set(
      values,
      `paymentDetails.paymentMethod.${index}.isPayLaterEnabled`,
      isPayLaterEnabled
    );
  }, [paymentMethodType]);

  useEffect(() => {
    set(
      values,
      `paymentDetails.paymentMethod.${index}.isPayLaterEnabled`,
      isPayLaterEnabled
    );
  }, [isPayLaterEnabled]);

  useEffect(() => {
    if (grandTotal === "0.00" || isOrderTotalZero?.length > 0) {
      setFieldValue(
        `paymentDetails.paymentMethod.${index}.paymentMethodType`,
        ""
      );
    }
    grandTotal === "0.00"
      ? setFieldValue(
          `paymentDetails.paymentMethod.${index}.isGrandTotalZero`,
          true
        )
      : setFieldValue(
          `paymentDetails.paymentMethod.${index}.isGrandTotalZero`,
          false
        );
  }, [grandTotal, isOrderTotalZero?.length]);

  useEffect(() => {
    if (!isEqual(paymentTypes, paymentOptions)) {
      setPaymentTypes(paymentOptions);
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (enableSplitPayment) {
      setFieldValue(
        `paymentDetails.paymentMethod.${index}.paymentFailed`,
        false
      );
    }
  }, [amount]);

  const RemovePaymentLink = () => {
    return (
      <TouchableOpacity
        style={{
          opacity: 1,
          pointerEvents: "auto",
          marginTop: paymentMethodType ? 0 : isSmallScreen ? 0 : 32,
          marginHorizontal: 5,
          marginLeft: !paymentMethodType && isSmallScreen ? "auto" : 5,
        }}
        onPress={() => {
          const removePayment = () => {
            setFieldValue(
              `paymentDetails.paymentMethod.${index}.paymentFailed`,
              false
            );
            setFieldValue(
              `paymentDetails.paymentMethod.${index}.paymentProcessed`,
              false
            );
            let updatedPaymentMethods = cloneDeep(paymentDetails.paymentMethod);
            updatedPaymentMethods.splice(index, 1);
            const hasProcessedPayments = updatedPaymentMethods.filter(
              (eachPayment) => {
                return (
                  !!["CREDIT_CARD", "SAVED_CARD"].includes(
                    eachPayment.paymentMethodType
                  ) && !!eachPayment.paymentProcessed
                );
              }
            );
            setFieldValue(
              `paymentDetails.paymentMethod`,
              updatedPaymentMethods
            );
            removePaymentRef.current = true;
            if (hasProcessedPayments.length > 0) {
              dispatch(
                setPaymentDetails({
                  status: "pending",
                  values: {
                    ...values,
                    paymentDetails: {
                      ...paymentDetails,
                      paymentMethod: updatedPaymentMethods,
                    },
                  },
                })
              );
            } else {
              dispatch(setPaymentDetails({ status: "", values: {} }));
            }

            setFieldError(
              `paymentDetails.paymentMethod.${index}.paymentMethodType`,
              ""
            );
          };

          if (paymentProcessed) {
            voidCCPayment({ values, index })
              .then(() => {
                removePayment();
              })
              .catch((error) => {
                console.log(error);
                ToasterHandler(
                  "error",
                  Localise(
                    messages,
                    "Failed to Remove Payment. Please try again."
                  )
                );
              });
          } else {
            removePayment();
          }
        }}
        testID={"remove_payment"}
        accessibilityLabel={"remove_payment"}
      >
        <Text style={{ ...fonts.link1, fontWeight: "400" }}>
          {Localise(messages, "Remove Payment")}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View
      key={paymentKey}
      style={{
        ...(multiplePayments && {
          paddingVertical: 10,
        }),
        ...(multiplePayments &&
          index < paymentDetails?.paymentMethod?.length - 1 && {
            borderBottomWidth: 1,
            borderBottomColor: colors.grayScaleLight,
          }),
      }}
    >
      {multiplePayments && (
        <View style={tw("flex flex-row items-center justify-between")}>
          <Text style={{ ...fonts.heading4, color: colors.sectionTitle }}>
            {Localise(messages, "Payment")} {index + 1}
          </Text>
          {showRemovePayment && paymentMethodType ? RemovePaymentLink() : null}
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginLeft: -5,
          width: "100%",
          marginTop: 10,
        }}
        pointerEvents={paymentProcessed ? "none" : "auto"}
      >
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "35%",
            paddingTop: 2,
          }}
          placeholder={{
            label: Localise(messages, "Select Payment"),
          }}
          data={paymentTypes}
          name="paymentMethodType"
          label={Localise(messages, "Payment Type")}
          labelStyle={labelStyle}
          path={path}
          disabledFieldTouch={true}
          disabled={
            !!(redeemedGiftCardsData?.length > 0 && grandTotal === "0.00") ||
            !!paymentProcessed
          }
        />

        {enableSplitPayment &&
        (!paymentMethodType ||
          !inEligibleSplitPaymentOptions.includes(paymentMethodType)) ? (
          <FormField
            name="amount"
            label={Localise(messages, "Amount")}
            labelStyle={labelStyle}
            containerStyle={{
              width: isSmallScreen ? "100%" : "18%",
              marginTop: 2,
            }}
            errorStyle={{ paddingBottom: 0 }}
            iconName={"usd"}
            iconSize={12}
            path={path}
            isPrice={true}
            editable={
              !!paymentMethodType &&
              !inEligibleSplitPaymentOptions.includes(paymentMethodType) &&
              !paymentProcessed
            }
            iconType="font-awesome"
            grayedOutOnDisable={true}
          />
        ) : null}

        {Component && (
          <Component
            index={index}
            isSmallScreen={isSmallScreen}
            path={path}
            values={values}
            orderTotal={grandTotal}
            enableCPS={enableCPS}
            hideFields={hideFields}
            mandatorySave={mandatorySave}
            labelStyle={labelStyle}
            setFieldValue={setFieldValue}
            enableSplitPayment={enableSplitPayment}
          />
        )}

        {!!paymentMethodType && paymentFailed ? (
          <View style={[tw("mt-1"), { width: "100%" }]}>
            <Text
              style={{
                ...theme.SubmitButton.errorStyle,
                paddingLeft: 5,
                fontSize: 12,
                marginRight: 10,
              }}
            >
              *{" "}
              {Localise(
                messages,
                "There was a credit card processing error. Contact us at (800) 788-9000 to resolve the issue."
              )}
            </Text>
          </View>
        ) : null}
        {enableSplitPayment && showRemovePayment && !paymentMethodType
          ? RemovePaymentLink()
          : null}
      </View>
    </View>
  );
};

export const PaymentSection = ({
  paymentDetailsRef,
  paymentOptions = [],
  enableCPS,
  hideFields,
  mandatorySave = false,
  labelStyle = {},
  grandTotal,
  isPayLaterEnabled = false,
  isOrderTotalZero = [],
  enableSplitPayment = false,
  house_account_invoice = false,
  setValidationOnChange = () => {},
  houseAccountFormRef,
  onHouseAccountUpdateSuccess,
  onHouseAccountUpdateReject,
  onHouseAccountCreditLimitChange,
  setInnerFormLoading,
  showInvoiceOnInactive,
}) => {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const { messages, Localise } = useContext(I18NContext);
  const { paymentDetails } = values;
  const firstPaymentMethodType =
    paymentDetails?.paymentMethod[0]?.paymentMethodType || "";
  const firstPaymentPath = `paymentDetails.paymentMethod.0`;
  const removePaymentRef = useRef(false);

  //split payment amounts
  const amounts = paymentDetails?.paymentMethod.map((payment) =>
    Number(parseFloat(payment.amount || 0).toFixed(2))
  );

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const paymentMethods = get(values, "paymentDetails.paymentMethod", []);
  const hasProcessedPayments = paymentMethods.filter((eachPayment) => {
    return (
      !!["CREDIT_CARD", "SAVED_CARD", "PAYMENT_TERMINAL"].includes(
        eachPayment.paymentMethodType
      ) && !!eachPayment.paymentProcessed
    );
  });
  const showWarningInfo =
    hasProcessedPayments.length > 0 && paymentMethods.length > 1;

  const removeSplitPayments = () => {
    setFieldValue("showError", false);
    let updatedPaymentMethods = paymentDetails.paymentMethod;
    updatedPaymentMethods.splice(1);
    updatedPaymentMethods = updatedPaymentMethods.map((payment) => {
      return { ...payment, paymentFailed: false, paymentProcessed: false };
    });
    setFieldValue(`paymentDetails.paymentMethod`, updatedPaymentMethods);
  };

  useEffect(() => {
    if (
      enableSplitPayment &&
      inEligibleSplitPaymentOptions.includes(firstPaymentMethodType)
    ) {
      removeSplitPayments();
      setFieldValue(`${firstPaymentPath}.amount`, formatPrice(grandTotal));
    }
    if (paymentDetails?.paymentMethod?.length === 1)
      setFieldValue(`${firstPaymentPath}.amount`, formatPrice(grandTotal));
  }, [firstPaymentMethodType]);

  useEffect(() => {
    if (paymentDetails?.paymentMethod?.length === 1) {
      setFieldValue(`${firstPaymentPath}.amount`, formatPrice(grandTotal));
    }
    if (enableSplitPayment) {
      setFieldValue(
        "showError",
        hasSplitAmountError({ values, amounts, grandTotal })
      );
    }
  }, [grandTotal]);

  useEffect(() => {
    if (enableSplitPayment) {
      setFieldValue(
        "showError",
        hasSplitAmountError({ values, amounts, grandTotal })
      );
    } else {
      setFieldValue("showError", false);
    }
  }, [JSON.stringify(amounts)]);

  const SplitPaymentButton = () => (
    <Button
      titleStyle={{
        ...theme.Button.secondaryTitleStyle,
        color: colors.highlighter,
        fontSize: 12,
      }}
      buttonStyle={{
        ...theme.Button.cancelButtonStyle,
        paddingVertical: 8,
        paddingHorizontal: 20,
      }}
      containerStyle={{
        justifyContent: "center",
        alignItems: "flex-start",
        marginHorizontal: 0,
      }}
      onPress={() => {
        let paymentTerminalIndex = paymentOptions.findIndex(
          (paymentOption) => paymentOption.value === "PAYMENT_TERMINAL"
        );
        paymentDetailsRef.current.push({
          ...basicPaymentInfo.paymentMethod[0],
          ...(isElectronApp &&
            paymentTerminalIndex >= 0 && {
              paymentMethodType: "PAYMENT_TERMINAL",
            }),
          enablePayment: paymentDetails?.paymentMethod[0].enablePayment,
          amount: formatPrice(
            grandTotal - formatPrice(sum(amounts)) < 0
              ? 0
              : grandTotal - formatPrice(sum(amounts))
          ),
          savePayment: mandatorySave,
        });
      }}
      title={Localise(messages, "Split Payment")}
      disabled={
        Number(sum(amounts).toFixed(2)) > Number(grandTotal) ||
        Number(sum(amounts).toFixed(2)) === Number(grandTotal)
      }
      disabledStyle={{
        backgroundColor: backgroundColors.secondary,
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
      }}
      testID="split_payment"
      accessibilityLabel="split_payment"
    />
  );

  return (
    <View>
      <FieldArray
        name="paymentDetails.paymentMethod"
        render={(arrayHelpers) => {
          paymentDetailsRef.current = arrayHelpers;
          const selectedPaymentMethods = [];
          paymentDetails?.paymentMethod.map((each) =>
            selectedPaymentMethods.push(each.paymentMethodType)
          );

          return (
            <>
              {paymentDetails?.paymentMethod.map(
                ({ paymentMethodType } = {}, index) => {
                  const paymentKey = `payment-${index}`;
                  let filteredPaymentOptions = cloneDeep(paymentOptions);
                  const savedCardIndex =
                    selectedPaymentMethods.indexOf("SAVED_CARD");
                  const terminalIndex =
                    selectedPaymentMethods.indexOf("PAYMENT_TERMINAL");
                  const creditCardIndex =
                    selectedPaymentMethods.indexOf("CREDIT_CARD");
                  const cashIndex = selectedPaymentMethods.indexOf("CASH");

                  //hide inEligibleSplitPaymentOptions for split payments
                  if (index > 0) {
                    filteredPaymentOptions = paymentOptions.filter(
                      (paymentOption) =>
                        !inEligibleSplitPaymentOptions.includes(
                          paymentOption.value
                        )
                    );

                    // if credit card selected then remove the terminal as a payment method
                    if (creditCardIndex !== -1 || savedCardIndex !== -1) {
                      filteredPaymentOptions = filteredPaymentOptions.filter(
                        (paymentOption) =>
                          !["PAYMENT_TERMINAL"].includes(paymentOption.value)
                      );
                    } else if (terminalIndex !== -1) {
                      // if terminal selected then remove credit card & saved card as a payment methods.
                      filteredPaymentOptions = filteredPaymentOptions.filter(
                        (paymentOption) =>
                          !["CREDIT_CARD", "SAVED_CARD"].includes(
                            paymentOption.value
                          )
                      );
                    }
                  }

                  if (savedCardIndex !== -1 && index !== savedCardIndex) {
                    filteredPaymentOptions = filteredPaymentOptions.filter(
                      (paymentOption) =>
                        !["SAVED_CARD"].includes(paymentOption.value)
                    );
                  }

                  // Exclude CASH if it's already selected
                  if (cashIndex !== -1 && index !== cashIndex) {
                    filteredPaymentOptions = filteredPaymentOptions.filter(
                      (paymentOption) => paymentOption.value !== "CASH"
                    );
                  }

                  const customProps = {
                    paymentMethodType,
                    grandTotal,
                    house_account_invoice,
                    setFieldError,
                    houseAccountFormRef,
                    onHouseAccountUpdateSuccess,
                    onHouseAccountUpdateReject,
                    onHouseAccountCreditLimitChange,
                    setInnerFormLoading,
                  };

                  return (
                    <View key={paymentKey}>
                      <PaymentComponent
                        paymentOptions={filteredPaymentOptions}
                        {...{
                          index,
                          paymentKey,
                          paymentDetailsRef,
                          grandTotal,
                          enableCPS,
                          mandatorySave,
                          hideFields,
                          labelStyle,
                          isPayLaterEnabled,
                          isOrderTotalZero,
                          enableSplitPayment,
                          setValidationOnChange,
                          removePaymentRef,
                        }}
                      />
                      {showInvoiceOnInactive && (
                        <HouseAccountPayment {...customProps} />
                      )}
                    </View>
                  );
                }
              )}
            </>
          );
        }}
      />

      {enableSplitPayment &&
      amounts?.length > 0 &&
      amounts?.length < 5 &&
      !isNaN(sum(amounts)) &&
      grandTotal != 0.0 &&
      firstPaymentMethodType &&
      !inEligibleSplitPaymentOptions.includes(firstPaymentMethodType) ? (
        Number(sum(amounts).toFixed(2)) > Number(grandTotal) ||
        Number(sum(amounts).toFixed(2)) === Number(grandTotal) ? (
          <Tooltip
            text={Localise(
              messages,
              `Adjust this payment amount in order to split this payment`
            )}
            height={70}
            otherPropsForWeb={{ style: { width: "fit-content" } }}
          >
            <SplitPaymentButton />
          </Tooltip>
        ) : (
          <SplitPaymentButton />
        )
      ) : null}

      {get(values, "showError", false) && firstPaymentMethodType ? (
        <Text
          numberOfLines={3}
          style={{
            ...theme.Input.errorStyle,
            fontSize: 12,
            width: "100%",
          }}
        >
          {Localise(
            messages,
            "The total amount of split payments does not match the order total. Please adjust the payment amounts to match the exact total."
          )}
        </Text>
      ) : null}
      {showWarningInfo ? (
        <View
          style={tw("flex flex-row items-center pt-3")}
          testID="payments-processed-warning"
        >
          <Icon
            name={"information-outline"}
            size={13}
            type="material-community"
            iconStyle={{ color: colors.primary, marginRight: 3 }}
            testID="info-icon"
            accessibilityLabel="info-icon"
          />
          <Text
            numberOfLines={3}
            style={{
              width: "100%",
              fontStyle: "italic",
              opacity: 0.7,
            }}
          >
            {Localise(
              messages,
              `There is still a remaining balance. Please correct the split payment or cancel the transaction by clicking 'Cancel'.`
            )}
          </Text>
        </View>
      ) : null}
    </View>
  );
};
