import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Text } from "react-native-elements";
import { View } from "react-native";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import HouseAccount from "components/views/drawer/customer-directory/upsert-screen/customer/house-account";
import CustomerInfo from "components/views/drawer/customer-directory/upsert-screen/customer/customer-info";
import {
  HouseAccountCreditFields,
  AddressField,
  PhoneField,
  EmailField,
} from "components/views/drawer/customer-directory/upsert-screen/ui-config";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import { DeviceContext } from "library/contexts/appSettings";

const HouseAccountPayment = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const customerDetails = useSelector(selectCustomerDetails);
  const { isMobile } = React.useContext(DeviceContext);

  const {
    address: { addressId } = {},
    email,
    phone,
    houseAccountInfo: {
      houseAccountId,
      creditLimit,
      isActive,
      houseAccountBalance: { totalBalance = 0 } = {},
    } = {},
  } = customerDetails;

  const {
    paymentMethodType,
    grandTotal,
    house_account_invoice,
    setFieldError,
    houseAccountFormRef,
    onHouseAccountUpdateSuccess,
    onHouseAccountUpdateReject,
    onHouseAccountCreditLimitChange,
    setInnerFormLoading,
  } = props;

  useEffect(() => {
    const showHouseAccountError =
      paymentMethodType === "INVOICE" &&
      (!isActive || grandTotal > creditLimit - totalBalance);

    if (showHouseAccountError) {
      const errorMsg = houseAccountId
        ? !isActive
          ? "This House Account is inactive."
          : `Credit Limit has been exceeded.`
        : "Please create House Account.";
      setFieldError(
        "paymentDetails.paymentMethod.0.paymentMethodType",
        Localise(messages, errorMsg)
      );
    } else {
      setFieldError(
        "paymentDetails.paymentMethod.0.paymentMethodType",
        Localise(messages, "")
      );
    }
  }, [
    paymentMethodType,
    houseAccountId,
    isActive,
    creditLimit,
    totalBalance,
    grandTotal,
  ]);

  const isUpdateHouseAccount =
    houseAccountId && (!isActive || grandTotal > creditLimit - totalBalance);

  let eligibleInputFields = [];
  if (!phone) eligibleInputFields.push(PhoneField(Localise, messages));
  if (!email) eligibleInputFields.push(EmailField(Localise, messages));
  if (!addressId)
    eligibleInputFields.push(AddressField(Localise, messages, true, false));

  return (
    <>
      {paymentMethodType === "INVOICE" && (
        <>
          {(isUpdateHouseAccount || eligibleInputFields.length > 0) && (
            <Text
              style={{
                ...fonts.heading4,
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              {Localise(messages, "Update House Account")}
            </Text>
          )}

          {!houseAccountId && eligibleInputFields.length > 0 ? (
            <View style={{ marginLeft: 0 }}>
              <CustomerInfo
                UIConfig={{
                  InputFields: eligibleInputFields,
                }}
                redirectToCustomerDirectoryOnSave={false}
                createHouseAccount={true}
                orderTotal={grandTotal}
                formRef={houseAccountFormRef}
                hideSaveCancel={true}
                onSuccess={onHouseAccountUpdateSuccess}
                onReject={onHouseAccountUpdateReject}
                setFormSubmitting={setInnerFormLoading}
              />
            </View>
          ) : (
            isUpdateHouseAccount && (
              <>
                <HouseAccount
                  UIConfig={{
                    InputFields: HouseAccountCreditFields(Localise, messages),
                  }}
                  showAccountingType={false}
                  enableHouseAccount={!isActive}
                  disableOnDirty={false}
                  preferences={{
                    house_account_invoice: house_account_invoice,
                  }}
                  hideSaveCancel={true}
                  formRef={houseAccountFormRef}
                  onSuccess={onHouseAccountUpdateSuccess}
                  onReject={onHouseAccountUpdateReject}
                  onCreditLimitChange={onHouseAccountCreditLimitChange}
                  setFormSubmitting={setInnerFormLoading}
                />
                {houseAccountId && isActive && (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={{
                        ...fonts.heading5,
                        marginBottom: 0,
                        width: isMobile ? "25%" : "30%",
                      }}
                    >
                      {`${Localise(messages, `Available Balance`)}`}
                    </Text>
                    <Text style={{ marginLeft: 5 }}>
                      ${parseFloat(creditLimit - totalBalance).toFixed(2)}
                    </Text>
                  </View>
                )}
              </>
            )
          )}
        </>
      )}
    </>
  );
};

export default HouseAccountPayment;
