import omit from "lodash/omit";
import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

import { compare as generateJSONPatch } from "fast-json-patch";

import Environment from "library/utils/environment";
import {
  getTotalPrice,
  getDiscount,
  getPrice,
} from "library/utils/createOrder";
import {
  formatPrice,
  isValidTime,
  cardMessageFormatter,
} from "library/utils/formatter";

import moment from "moment";
import { fetchAddonInfo } from "components/views/drawer/order-details/product-details/helper";

import {
  basicPaymentInfo,
  customerBasicFields,
} from "components/views/drawer/create-order/config";
import UserProfileStorage from "library/storage/userProfile";
import {
  isWireOrder,
  isPhoneOutOrder,
  isFloristDelivered,
  isStorePickUpOrder,
  isWalkinOrder,
} from "../order-details/delivery-info/helper";
import {
  isSameAddress,
  getTaxAmounts,
  getFeesInfo,
  getLineItemAmounts,
  getPaymentInfo,
  getRecipientState,
  isSameInfo,
} from "library/utils/createOrder";
import {
  giftCardProducts,
  physicalGiftCardProducts,
} from "library/utils/giftCardProducts";
import { formatPhoneForPayload } from "library/utils/formatter";
import shopPermissions from "library/utils/featureAvailability";

export const DELIVER_BY_REGEX =
  /^Deliver\s+by\s+(\d{1,2}:\d{2})\s*([ap]m)\.?\s*/i;
import { request } from "library/utils/request";
import { COMMON } from "library/constants";
import { getSortedChargesList } from "components/views/drawer/order-details/payment-details/order-details/charges-summary";

const funeralFields = [
  "firstName",
  "lastName",
  "deliveryDate",
  "addressLine1",
  "addressLine2",
  "city",
  "state",
  "country",
  "zip",
];

const getOrderAmounts = (
  totalAmount,
  orderSubTotal,
  totalDiscount,
  totalTax,
  totalFee,
  deliveryFee,
  retailDeliveryFee,
  relayFee,
  serviceFee
) => {
  return [
    {
      name: "orderTotal",
      value: formatPrice(totalAmount),
    },
    {
      name: "discountAmount",
      value: formatPrice(totalDiscount),
    },
    {
      name: "savings",
      value: "0.00",
    },
    {
      name: "amountChargedToCustomer",
      value: formatPrice(totalAmount),
    },
    {
      name: "taxAmount",
      value: formatPrice(totalTax),
    },
    {
      name: "subtotal",
      value: formatPrice(orderSubTotal),
    },
    {
      name: "feeTotal",
      value: formatPrice(totalFee),
    },
    {
      name: "deliveryFeeTotal",
      value: formatPrice(deliveryFee),
    },
    {
      name: "relayFeeTotal",
      value: formatPrice(relayFee),
    },
    {
      name: "retailDeliveryFeeTotal",
      value: formatPrice(retailDeliveryFee),
    },
    {
      name: "serviceFeeTotal",
      value: formatPrice(serviceFee),
    },
  ];
};

export const editOrderObject = (
  redeemedGiftCardsData,
  createReq,
  orderDetailsResponse,
  values,
  tokenId,
  isSummaryObj = false
) => {
  const updatedOrderDetailsRes = cloneDeep(orderDetailsResponse);
  const createReqObj = cloneDeep(createReq);

  const {
    customerInfo: createCustomerInfo = {},
    orderItems = [],
    orderAmounts = {},
  } = createReqObj;

  const { customerInfo = {} } = values;

  const orderItem = orderItems[0] || {};
  const {
    recipientInfo = {},
    deliveryInfo = {},
    specialInstructions = "",
    lineItems = {},
    price = {},
    fees = {},
    taxAmounts = {},
    totalTax = {},
    taxation = "",
    deliveryFee = 0,
    retailDeliveryFee = 0,
    relayFee = 0,
    retransFee = 0,
    serviceFee = 0,
    isRushOrder = false,
    externalPartnerBusinessName = "",
    orderSource = "",
    promoCode = "",
    promoValue = "",
    notes = "",
  } = orderItem;

  const { deliveryMethod = "" } = deliveryInfo;
  const isStorePickupOrder = isStorePickUpOrder(deliveryMethod);
  const isWalkInOrder = isWalkinOrder(deliveryMethod);
  const isPhoneOutOrder = deliveryMethod === "PHONE_OUT";
  const isMOLOrder = orderSource === "MOL";

  // This function used to set some default values to fetch the refund summary, without these default values summary call fails.
  const getSummaryUpdates = (updatedOrderDetailsRes) => {
    const { pickUpDateTime = "", pickUpBy = "" } = get(
      updatedOrderDetailsRes,
      "orderItems.0.deliveryInfo",
      {}
    );
    if (isStorePickupOrder) {
      pickUpDateTime === "Invalid date" &&
        set(
          updatedOrderDetailsRes,
          "orderItems.0.deliveryInfo.pickUpDateTime",
          moment().add(24, "hours").toISOString() // adding default value
        );
      pickUpBy === "" &&
        set(
          updatedOrderDetailsRes,
          "orderItems.0.deliveryInfo.pickUpBy",
          "User" // adding default value
        );
    }
  };

  /* ---------- customer info setting to Order Details Response ---------- */
  /* ---------- We are not allowed to update customer Info for Mol Orders so excluding customer check ---------- */
  if (!isMOLOrder) {
    if (!isEmpty(customerInfo) && customerInfo.customerType) {
      const {
        customerId,
        customerType,
        firstName,
        lastName,
        businessName,
        phone,
        email,
        customerNotes,
        taxExemptCode,
        smsOptIn,
        smsProviderOptIn,
      } = customerInfo;

      let updatedCustomerInfo = get(updatedOrderDetailsRes, "customerInfo", {});
      const updatedRecipientInfo = get(
        updatedOrderDetailsRes,
        "orderItems.0.recipientInfo",
        {}
      );

      const formikRecipientInfo = get(values, "orderItems.0.recipientInfo", {});
      formikRecipientInfo.addressLine2 = formikRecipientInfo.suite;
      const { suite, ...formikRecipient } = formikRecipientInfo;

      if (!isEmpty(updatedCustomerInfo)) {
        updatedCustomerInfo = {
          ...updatedCustomerInfo,
          isModified: !isSameAddress(updatedRecipientInfo, formikRecipient),
          customerId,
          isBusinessProfile: customerType === "Business",
          firstName,
          lastName,
          businessName,
          email,
          customerNotes,
          taxExemptCode,
          smsOptIn,
          smsProviderOptIn,
          ...(!isEmpty(phone) || !isEmpty(updatedCustomerInfo?.phones)
            ? { phones: [formatPhoneForPayload(phone)] }
            : {}),
        };

        if (customerType === "Business") {
          delete updatedCustomerInfo.firstName;
          delete updatedCustomerInfo.lastName;
        } else {
          delete updatedCustomerInfo.businessName;
        }

        // To send the updated/modified customer contacts when modifying an order.
        if (createCustomerInfo?.customerContacts) {
          updatedCustomerInfo.customerContacts =
            createCustomerInfo.customerContacts;
        } else {
          // When modifying an order and selecting another customer who does not have any contacts.
          delete updatedCustomerInfo.customerContacts;
        }
      } else {
        updatedCustomerInfo = {
          ...omit(createCustomerInfo, [
            "averageSpendByOccasion",
            "totalAverageSpend",
            "updatedBy",
            "updatedOn",
            "createdBy",
            "createdOn",
            "referralType",
          ]),
        };
      }

      set(updatedOrderDetailsRes, `customerInfo`, updatedCustomerInfo);
    } else {
      //clearning existing customer scenario.
      const updatedCustomerInfo = get(
        updatedOrderDetailsRes,
        "customerInfo",
        {}
      );

      if (!isEmpty(updatedCustomerInfo)) {
        delete updatedOrderDetailsRes.customerInfo;
      }
    }
  } else {
    /* Retaining the existing customer Info from the Existing order details for MOL orders */
    let updatedCustomerInfo = {
      ...omit(orderDetailsResponse?.customerInfo, [
        "averageSpendByOccasion",
        "totalAverageSpend",
        "updatedBy",
        "updatedOn",
        "createdBy",
        "createdOn",
        "referralType",
      ]),
    };
    set(updatedOrderDetailsRes, `customerInfo`, updatedCustomerInfo);
  }
  /* ---------- Customer info setting to Order Details Response ---------- */

  /* -------- Recipient info setting to Order Details Response --------- */
  const orderRecipientInfo = get(
    orderDetailsResponse,
    "orderItems.0.recipientInfo"
  );

  // omitting non necessary attributes.
  const updatedRecipientInfo = omit(recipientInfo, [
    "hasRecipientInfo",
    "deliveryMethod",
  ]);

  // if addressLine2 is null/empty in original & if not modified anything in edit order then removing addressLine2 from patch
  if (
    !orderRecipientInfo?.addressLine2 &&
    isEmpty(updatedRecipientInfo.addressLine2)
  ) {
    delete updatedRecipientInfo.addressLine2;
  }
  // if county is null/empty in original & if not modified anything in edit order then removing county from patch
  if (!orderRecipientInfo?.county && isEmpty(updatedRecipientInfo.county)) {
    delete updatedRecipientInfo.county;
  }
  // if locationType is null/empty in original & if not modified anything in edit order then removing locationType from patch
  if (
    !orderRecipientInfo?.locationType &&
    isEmpty(updatedRecipientInfo.locationType)
  ) {
    delete updatedRecipientInfo.locationType;
  }

  // if locationName is null/empty in original & if not modified anything in edit order then removing locationName from patch
  if (
    !orderRecipientInfo?.locationName &&
    isEmpty(updatedRecipientInfo.locationName)
  ) {
    delete updatedRecipientInfo.locationName;
  }

  set(updatedOrderDetailsRes, "orderItems.0.recipientInfo", {
    ...orderRecipientInfo,
    ...updatedRecipientInfo,
  });

  if (updatedRecipientInfo.state) {
    set(
      updatedRecipientInfo,
      "state",
      getRecipientState(
        updatedRecipientInfo?.state,
        updatedRecipientInfo?.country
      )
    );
  }

  /* ------- Delivery info setting to Order Details Response ---------- */

  let updatedDeliveryInfo = omit(deliveryInfo, [
    "floristInfo",
    "shopDayTimings",
    "timeZone",
  ]);

  if (isWalkInOrder) {
    set(updatedOrderDetailsRes, `orderItems.0.autoForward`, "N");
  }
  if (isPhoneOutOrder) {
    set(
      updatedOrderDetailsRes,
      `orderItems.0.externalPartnerBusinessName`,
      externalPartnerBusinessName
    );
  } else {
    delete updatedOrderDetailsRes.orderItems[0].externalPartnerBusinessName;
  }

  // Removing orderSource from deliveryInfo as there is significance for this
  delete updatedDeliveryInfo.orderSource;

  if (deliveryMethod === "MOL_FLORIST_DELIVERED") {
    const previousDeliveryDate = get(
      updatedOrderDetailsRes,
      "orderItems.0.deliveryInfo.previousDeliveryDate",
      ""
    );

    updatedDeliveryInfo.previousDeliveryDate = previousDeliveryDate;
  }

  set(updatedOrderDetailsRes, "orderItems.0.deliveryInfo", updatedDeliveryInfo);
  set(updatedOrderDetailsRes, "orderItems.0.isRushOrder", isRushOrder);
  set(updatedOrderDetailsRes, "orderItems.0.promoCode", promoCode);
  set(updatedOrderDetailsRes, "orderItems.0.promoValue", promoValue);
  set(updatedOrderDetailsRes, "orderItems.0.notes", notes);

  // if notes is null/empty in original & if not modified anything in edit order then removing notes from patch
  const orderDetailsNotes = get(orderDetailsResponse, "orderItems.0.notes");
  if (!orderDetailsNotes && isEmpty(notes)) {
    delete updatedOrderDetailsRes.orderItems[0].notes;
  }

  /* ----------Setting special instructions if we have any..-------------- */
  if (!isEmpty(specialInstructions)) {
    set(
      updatedOrderDetailsRes,
      "orderItems.0.specialInstructions",
      specialInstructions
    );
  }

  let isRefundDue = get(values, "paymentDetails.isRefundDue");

  const amountDue = get(values, "paymentDetails.amountDue");
  const isPaymentPending =
    values?.paymentDetails?.paymentStatus?.toLowerCase() === "pending";

  if (amountDue > 0 && isPaymentPending) {
    isRefundDue = false;
  }

  if (isRefundDue !== undefined) {
    /* ----------Setting lineItems start-------------- */
    const orderRespLineItems =
      get(updatedOrderDetailsRes, "orderItems.0.lineItems") || [];

    const updatedLineItems = [];
    lineItems.forEach((eachItem, index) => {
      const newDesignNotes = eachItem?.designNotes || "";
      const oldDesignNotes = orderRespLineItems[index]?.designNotes;

      const updatedEachItem = omit(eachItem, [
        "img",
        "itemPrice",
        "productFirstChoiceRefNumberId",
        "productSecondChoiceCode",
        "productSecondChoiceDescription",
      ]);

      let refundedAmount = { name: "refundedAmount", value: "0.00" };

      const newLineItemAmounts = eachItem?.lineItemAmounts || [];

      newLineItemAmounts.push(refundedAmount);

      updatedLineItems.push({
        ...updatedEachItem,
        quantity: parseInt(updatedEachItem.quantity),
        totalQuantity: parseInt(updatedEachItem.quantity),
        lineItemAmounts: newLineItemAmounts,
        ...(((!oldDesignNotes && newDesignNotes.trim() !== "") ||
          (oldDesignNotes && oldDesignNotes !== newDesignNotes.trim())) && {
          designNotes: newDesignNotes,
        }),
      });
    });
    set(updatedOrderDetailsRes, "orderItems.0.lineItems", updatedLineItems);
    /* ----------setting lineItems end-------------- */

    /* ------------------ setting Fees start -------------------- */
    const {
      applicableCharges: {
        deliveryCharges = [],
        surCharges = [],
        totalApplicableCharges = 0,
      },
      discountedCharges,
    } = fees;

    const updatedFees = {
      applicableCharges: {
        surCharges: [...surCharges],
        deliveryCharges: [...deliveryCharges],
        totalApplicableCharges: totalApplicableCharges,
      },
      discountedCharges,
    };

    set(updatedOrderDetailsRes, "orderItems.0.fees", updatedFees);
    /* --------------- setting Fees end ------------------- */

    /* ----------setting taxAmounts start-------------- */
    if (!isEmpty(taxAmounts)) {
      set(updatedOrderDetailsRes, "orderItems.0.taxAmounts", taxAmounts);

      if (!isEmpty(taxation))
        set(updatedOrderDetailsRes, "orderItems.0.taxation", taxation);
    }

    if (!isEmpty(totalTax)) {
      set(updatedOrderDetailsRes, "orderItems.0.totalTax", totalTax);
    }
    /* ----------setting taxAmounts end-------------- */

    /* ----------setting price start-------------- */
    const amountChargedToCustomer =
      orderAmounts.find((obj) => obj.name === "amountChargedToCustomer") || {};
    set(updatedOrderDetailsRes, "orderItems.0.price", [
      price[0],
      amountChargedToCustomer,
    ]);
    /* ----------setting price end-------------- */

    /* ----------setting orderAmounts start-------------- */
    if (!isEmpty(orderAmounts)) {
      // conletst orderAmountKeys = {};
      let newOrderAmounts = {};

      orderAmounts.forEach((each) => {
        newOrderAmounts[each["name"]] = each.value;
      });

      newOrderAmounts = {
        ...newOrderAmounts,
        feeTotal: parseFloat(
          Number(deliveryFee) + Number(retailDeliveryFee)
        ).toFixed(2),
        feeDiscountTotal: "0",
        feeSummary: `{"standardDelivery":${formatPrice(deliveryFee)},${
          Number(retailDeliveryFee) > 0
            ? `"retailDeliveryFee":${parseFloat(retailDeliveryFee)},`
            : `"retailDeliveryFee":0,`
        }"relayFee":${formatPrice(
          relayFee
        )},"morning":0,"retransFee":${formatPrice(
          retransFee
        )},"serviceFee":${formatPrice(serviceFee)}}`,
      };

      const mergeOrderAmounts = updatedOrderDetailsRes.orderAmounts.map(
        (item) => {
          if (newOrderAmounts[item.name]) {
            item.value = newOrderAmounts[item.name];
          }
          return item;
        }
      );
      set(updatedOrderDetailsRes, "orderAmounts", mergeOrderAmounts);
    }

    const amountDue = get(values, "paymentDetails.amountDue", 0);
    const paymentStatus = get(values, "paymentDetails.paymentStatus", "");
    const isPaymentPending = paymentStatus?.toLowerCase() === "pending";

    if (!isRefundDue && (parseFloat(amountDue) > 0 || isPaymentPending)) {
      const paymentInfo = get(createReqObj, "paymentDetails", {});
      const originalPayment = get(
        updatedOrderDetailsRes,
        "paymentDetails.paymentMethod",
        []
      );
      const { currencyCode, paymentMethod } = paymentInfo;
      let newPaymentMethod = omit(paymentMethod[0], ["enablePayment"]);
      const { paymentMethodType } = newPaymentMethod || {};

      const isSavePaymentSelected = get(
        values,
        "paymentDetails.paymentMethod.0.savePayment",
        false
      );

      if (isSavePaymentSelected && paymentMethodType === "CREDIT_CARD") {
        const { paymentMethodDetails } = newPaymentMethod || {};
        const newPaymentMethodDetails = { ...paymentMethodDetails, tokenId };
        newPaymentMethod = {
          ...newPaymentMethod,
          paymentMethodDetails: { ...newPaymentMethodDetails },
        };
      }

      const newPaymentDetails = {
        currencyCode,
        paymentMethod: [...originalPayment, { ...newPaymentMethod }],
      };
      set(updatedOrderDetailsRes, "paymentDetails", newPaymentDetails);

      // Setting gcPaymentObj if giftcard applied
      if (redeemedGiftCardsData?.length > 0) {
        const previousValues = get(
          values,
          "paymentDetails.paymentMethod.0",
          {}
        );

        let gcPaymentObj = [];

        for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
          gcPaymentObj.push({
            ...previousValues,
            paymentMethodDetails: {
              gcAmount: redeemedGiftCardsData[i]?.gcAmount,
              gcNumber: redeemedGiftCardsData[i]?.gcNumber,
            },
            paymentMethodType: "GIFT_CARD",
          });
        }

        let newPaymentDetailsArr =
          newPaymentMethod?.paymentMethodType === "GIFT_CARD"
            ? [...originalPayment]
            : [...originalPayment, { ...newPaymentMethod }];
        for (let i = 0; i < gcPaymentObj?.length; i++) {
          newPaymentDetailsArr.push(gcPaymentObj[i]);
        }

        const newPaymentDetails = {
          currencyCode,
          paymentMethod: newPaymentDetailsArr,
        };
        set(updatedOrderDetailsRes, "paymentDetails", newPaymentDetails);
      }
    }
  } else {
    // Add bill and Refund cases are not applicable when there is no change in price but lineItem info updated like Product description, price, notes, quantity. So, passing in the patch request. MSOL- 19212

    const orderRespLineItems = get(
      updatedOrderDetailsRes,
      "orderItems.0.lineItems"
    );

    const updatedLineItems = [];

    lineItems.forEach((eachLineItem, index) => {
      const eachItem = omit(eachLineItem, [
        "productSecondChoiceCode",
        "productSecondChoiceDescription",
      ]);

      const discountAmount = formatPrice(
        eachItem?.lineItemAmounts.find((item) => item.name === "discountAmount")
          ?.value
      );
      const newQuantity = Number(eachItem?.quantity);
      const newAmountChargedToCustomer =
        discountAmount > 0
          ? formatPrice(
              eachItem?.lineItemAmounts.find(
                (item) => item.name === "amountChargedToCustomer"
              )?.value
            )
          : formatPrice(Number(eachItem?.itemPrice) * newQuantity);

      const newProductFCD = eachItem?.productFirstChoiceDescription;
      const newDesignNotes = eachItem?.designNotes || "";

      const oldAmountChargedToCustomer = formatPrice(
        orderRespLineItems[index]?.lineItemAmounts.find(
          (item) => item.name === "amountChargedToCustomer"
        )?.value
      );
      const oldProductFCD =
        orderRespLineItems[index]?.productFirstChoiceDescription;
      const oldQuantity = Number(orderRespLineItems[index]?.quantity);
      const oldDesignNotes = orderRespLineItems[index]?.designNotes;

      const pushItem =
        newAmountChargedToCustomer !== oldAmountChargedToCustomer ||
        newQuantity !== oldQuantity ||
        newProductFCD !== oldProductFCD
          ? eachItem
          : orderRespLineItems[index];

      updatedLineItems.push({
        ...pushItem,
        ...(((!oldDesignNotes && newDesignNotes.trim() !== "") ||
          (oldDesignNotes && oldDesignNotes !== newDesignNotes.trim())) && {
          designNotes: newDesignNotes,
        }),
      });
    });

    set(updatedOrderDetailsRes, "orderItems.0.lineItems", updatedLineItems);
  }

  /*-- Due to difference between html text and normal text, card msg is being passed in the patch req even though there is no change. So, setting card msg back to html , if there is no edit --*/

  const originalCardMessage = get(
    orderDetailsResponse,
    "orderItems.0.deliveryInfo.cardMessage"
  );
  const updatedCardMessage = get(
    updatedOrderDetailsRes,
    "orderItems.0.deliveryInfo.cardMessage"
  );

  if (cardMessageFormatter(originalCardMessage) === updatedCardMessage) {
    set(
      updatedOrderDetailsRes,
      `orderItems.0.deliveryInfo.cardMessage`,
      originalCardMessage
    );
  }
  const { refundTransactions = [] } = values;

  if (refundTransactions.length > 0) {
    const originalPayment = get(
      updatedOrderDetailsRes,
      "paymentDetails.paymentMethod",
      []
    );

    const updatedPayment = originalPayment.map((each) => {
      const authId =
        each?.paymentMethodDetails?.authorizationDetails?.find(
          (e) => e.name === "authorizationTransactionId"
        ) || {};
      const getTransaction = refundTransactions.filter(
        (obj) => obj?.authorizationTransactionId === authId?.value
      );
      const { refundAmount = "0.00" } = getTransaction[0];
      if (parseFloat(refundAmount) > 0) {
        const refundDetails = get(
          each,
          `paymentMethodDetails.refundDetails`,
          []
        );
        refundDetails.push({ amount: refundAmount });
        return {
          ...each,
          paymentMethodDetails: { ...each.paymentMethodDetails, refundDetails },
        };
      } else {
        return each;
      }
    });
    set(updatedOrderDetailsRes, "paymentDetails.paymentMethod", updatedPayment);
  }

  if (isSummaryObj) getSummaryUpdates(updatedOrderDetailsRes);

  /* ----------setting orderAmounts end-------------- */

  var orderUpdatePatches = generateJSONPatch(
    orderDetailsResponse,
    updatedOrderDetailsRes
  );

  return orderUpdatePatches;
};

export const createOrderObject = ({
  memberCodes,
  orderValues,
  addressVerificationInfo,
  memberTimezone,
  floristTechnology,
  redeemedGiftCardsData = [],
  allowMultipleProducts = true,
  supportEdit = false,
  isSubscriptionPage = false,
  isDraftOrder = false,
}) => {
  const values = cloneDeep(orderValues);
  const isPaymentEnabled = get(
    values,
    "paymentDetails.paymentMethod.0.enablePayment",
    false
  );
  const sendingMember = get(values, "hasMultipleShops", false)
    ? get(values, "sendingMember")
    : memberCodes[0];
  let { orderItems = [] } = values;
  const { orderTotal, orderSubTotal } = getTotalPrice(orderItems);

  // QA Demo Accounts => 12-3737AA, 07-0016AA and Prod Demo Accounts => 90-0134AP, 90-0134AT, 90-0795AY, 90-0795AZ, 90-0795AA, 90-0795AB, 90-0795AC, 90-0795AD, 90-0795AE, 90-0795AF, 90-0795AG, 90-0795AH
  const demoAccounts = Environment.get(
    "DEMO_ACCOUNTS",
    "90-0134AP:90-0134AT:90-0795AY:90-0795AZ:90-0795AA:90-0795AB:90-0795AC:90-0795AD:90-0795AE:90-0795AF:90-0795AG:90-0795AH"
  );
  const isDemoAccount = demoAccounts.split(":").includes(sendingMember);

  let totalDiscount = 0;
  let totalTax = 0;
  let totalFee = 0;
  let totalDeliveryFee = 0;
  let totalRetailDeliveryFee = 0;
  let totalRelayFee = 0;
  let totalServiceFee = 0;

  orderItems.forEach((orderItem, index) => {
    let orderItemTotal = 0;
    let orderItemDiscount = 0;
    let accessoryAmount = 0;
    let accessoryDiscountAmount = 0;
    let orderItemTotalFee = 0;

    const {
      deliveryFee = 0,
      recipientInfo,
      retailDeliveryFee = 0,
      relayFee = 0,
      serviceFee = 0,
      retransFee = 0,
      taxInfo: taxes = [],
      promoCode = "",
    } = orderItem;
    let { lineItems = [], price = [] } = orderItem;

    if (supportEdit) {
      // Create a shallow copy of lineItems to iterate on
      let lineItemsCopy = lineItems.slice();

      // Iterate through the copy in reverse order to prevent index shifting
      for (let i = lineItemsCopy.length - 1; i >= 0; i--) {
        if (lineItemsCopy[i].isRemoved === true) {
          // Remove the corresponding index from both original arrays
          lineItems.splice(i, 1);
          price.splice(i, 1);
        }
      }
    }

    const { deliveryMethod = "" } = orderItem.deliveryInfo;
    const isProductGC =
      lineItems?.length > 0 &&
      giftCardProducts.includes(lineItems[0]?.productFirstChoiceCode);

    const isLocalDelivery = deliveryMethod === "DeliveryService";
    const isLocalOrder = isFloristDelivered(deliveryMethod ?? "");
    const isStorePickupOrder = isStorePickUpOrder(deliveryMethod);
    const isWalkInOrder = isWalkinOrder(deliveryMethod);

    if (isStorePickupOrder || isWalkInOrder) {
      set(orderItem, "deliveryFee", 0);
      set(orderItem, "retailDeliveryFee", 0);
    }
    if (!isWireOrder(deliveryMethod) && !isPhoneOutOrder(deliveryMethod)) {
      set(orderItem, "relayFee", 0);
    }

    //If product is giftcard, florist entered recipient email and didn't enter any recipient info, we are passing country = "" in recipientInfo object
    if (recipientInfo?.firstName?.length === 0 && isProductGC) {
      set(orderItem, "recipientInfo.country", "");
    }

    if (isProductGC) set(orderItem, "recipientInfo.giftCardId", "");

    let productInfo = {};
    const formattedDeliveryFee = getPrice(deliveryFee);
    const formattedRetailDeliveryFee = getPrice(retailDeliveryFee);
    const formattedRelayFee = getPrice(relayFee);
    const formattedServiceFee = getPrice(serviceFee);
    const formattedRetransFee = getPrice(retransFee);

    orderItemTotalFee =
      parseFloat(formattedDeliveryFee) +
      parseFloat(formattedRetailDeliveryFee) +
      parseFloat(formattedRelayFee) +
      parseFloat(formattedServiceFee) +
      parseFloat(formattedRetransFee);

    if (!allowMultipleProducts) {
      productInfo = lineItems.shift();
      productInfo = omit(productInfo, [
        "newlyAdded",
        "isImageLoaded",
        "originalQuantity",
        "originalPrice",
        "canRemove",
        "isRemoved",
      ]);
      const { value, discount, discountType } = price[0];
      const discountAmount = parseFloat(
        getDiscount(discount, value, discountType, productInfo?.quantity)
      );

      orderItemDiscount += discountAmount;
      orderItemTotal += parseFloat(getPrice(value) - discountAmount);

      productInfo.productFirstChoiceCode =
        productInfo.productFirstChoiceRefNumberId;
      productInfo.itemPrice = value;
      productInfo.accessories = [];

      if (discountType) {
        productInfo.discountType = discountType;

        const key =
          discountType === "Percentage" ? "PercentDiscount" : "DollarDiscount";

        productInfo.discountValue = { [key]: discount };
      }

      price.shift();
      lineItems.map((item, idx) => {
        const { value, discount, discountType } = orderItems[index].price[idx];
        const discountAmount = parseFloat(
          getDiscount(discount, value, discountType, item?.quantity)
        );

        accessoryAmount += parseFloat(getPrice(value));
        orderItemDiscount += discountAmount;
        accessoryDiscountAmount += discountAmount;
        orderItemTotal += parseFloat(getPrice(value) - discountAmount);

        const key =
          discountType === "Percentage" ? "PercentDiscount" : "DollarDiscount";

        const accessory = {
          accessoryId: item.productFirstChoiceCode,
          accessoryDesc: item.productFirstChoiceDescription,
          accessoryPrice: value,
          itemPrice: value,
          discountedPrice: formatPrice(value - discountAmount),
          designNotes: item.designNotes,
          accessoryQty: 1,
          ...(discountType && {
            discountType,
            discountValue: {
              [key]: discount,
            },
          }),
          lineItemId: item?.lineItemId || "",
        };
        productInfo.accessories.push(accessory);
      });

      productInfo.lineItemAmounts = [
        {
          name: "retailProductAmount",
          value: formatPrice(value),
        },
        {
          name: "saleProductAmount",
          value: formatPrice(value),
        },
        {
          name: "discountedProductAmount",
          value: formatPrice(value - discountAmount),
        },
        {
          name: "accessoryAmount",
          value: formatPrice(accessoryAmount),
        },
        {
          name: "discountedAccessoryAmount",
          value: formatPrice(accessoryAmount - accessoryDiscountAmount),
        },
        {
          name: "taxAmount",
          value: "0",
        },
        {
          name: "discountAmount",
          value: formatPrice(orderItemDiscount),
        },
        {
          name: "productDiscountAmount",
          value: formatPrice(discountAmount),
        },
        {
          name: "accessoryDiscountAmount",
          value: formatPrice(accessoryDiscountAmount),
        },
        {
          name: "savings",
          value: "0.00",
        },
        {
          name: "amountChargedToCustomer",
          value: formatPrice(orderItemTotal),
        },
        ...(isSubscriptionPage
          ? [
              {
                name: "subtotal",
                value: formatPrice(orderSubTotal),
              },
              {
                name: "feeTotal",
                value: formatPrice(orderItemTotalFee),
              },
            ]
          : []),
      ];
    } else {
      const items = [];
      lineItems.map((item, idx) => {
        const { value, discount, discountType } = price[idx];
        const discountAmount = parseFloat(
          getDiscount(discount, value, discountType, item?.quantity)
        );

        orderItemDiscount += discountAmount;
        orderItemTotal += parseFloat(
          getPrice(value * item.quantity) - discountAmount
        );

        const key =
          discountType === "Percentage" ? "PercentDiscount" : "DollarDiscount";

        const lineItemAmounts = getLineItemAmounts(
          value,
          item.quantity,
          discountAmount
        );

        const finalLineItemAmounts = [
          ...lineItemAmounts,
          ...(isSubscriptionPage
            ? [
                {
                  name: "subtotal",
                  value: formatPrice(
                    parseFloat(value * item.quantity) - discountAmount
                  ),
                },
                {
                  name: "feeTotal",
                  value: formatPrice(orderItemTotalFee),
                },
              ]
            : []),
        ];

        const isProductPhyGC = physicalGiftCardProducts.includes(
          item.productFirstChoiceCode
        ) && {
          giftCardId: item.giftCardId,
        };

        items.push({
          productFirstChoiceCode: item.productFirstChoiceCode,
          productFirstChoiceDescription: item.productFirstChoiceDescription,
          productSecondChoiceCode: item.productSecondChoiceCode,
          productSecondChoiceDescription: item.productSecondChoiceDescription,
          productFirstChoiceRefNumberId: item.productFirstChoiceRefNumberId,
          category: item.category,
          itemPrice: value,
          lineItemId: item?.lineItemId || "",
          ...(discountType && {
            discountType: discountType,
            discountValue: { [key]: discount },
          }),
          accessories: [],
          quantity: item.quantity,
          type: item.type,
          img: item.img,
          lineItemAmounts: finalLineItemAmounts,
          designNotes: item.designNotes,
          ...isProductPhyGC,
        });
      });
      productInfo = items;
    }

    const fees = getFeesInfo(
      formattedRetailDeliveryFee,
      formattedRelayFee,
      formattedDeliveryFee,
      orderItemTotalFee
    );

    if (formattedServiceFee > 0 || formattedRetransFee > 0) {
      const serviceFeeAttribute = {
        id: 0,
        name: "serviceFee",
        type: "Additional",
        feeType: "SC",
        value: parseFloat(formattedServiceFee),
        valueType: "FIXED",
        promotionId: 1,
        adjustmentId: 1,
        adjustmentCode: "",
        category: "",
      };
      const retransFeeAttribute = {
        id: 0,
        name: "retransFee",
        type: "Additional",
        feeType: "SC",
        value: parseFloat(formattedRetransFee),
        valueType: "FIXED",
        promotionId: 1,
        adjustmentId: 1,
        adjustmentCode: "",
        category: "",
      };
      fees.applicableCharges.surCharges.push(
        serviceFeeAttribute,
        retransFeeAttribute
      );
    }

    const shopPreferences =
      UserProfileStorage.getShopPreferences(sendingMember);

    const { taxAmount = 0.0, taxRate = 0.0 } = taxes;
    const taxParam = values?.customerInfo?.taxExemptCode ? [] : taxes;
    const { taxAmounts, totalDeliveryFeeTax, totalRelayFeeTax } = getTaxAmounts(
      taxParam,
      orderItemTotal,
      formattedDeliveryFee,
      formattedRelayFee,
      deliveryMethod,
      shopPreferences
    );

    if (recipientInfo) {
      set(recipientInfo, "addressLine1", `${recipientInfo.addressLine1 || ""}`);
      set(recipientInfo, "addressLine2", `${recipientInfo.suite || ""}`);
    }

    if (recipientInfo.phone) {
      set(recipientInfo, "phone", formatPhoneForPayload(recipientInfo.phone));
    }

    if (recipientInfo.state) {
      set(
        recipientInfo,
        "state",
        getRecipientState(recipientInfo?.state, recipientInfo?.country)
      );
    }

    if (
      !isWalkInOrder &&
      !isStorePickupOrder &&
      !supportEdit &&
      !isDraftOrder
    ) {
      const { locationType = "", locationName = "" } = recipientInfo;

      if (locationType.trim() === "" && locationName.trim() === "") {
        set(recipientInfo, "locationType", "Residence");
      } else if (locationType.trim() === "" && locationName.trim() !== "") {
        set(recipientInfo, "locationType", "Office");
      }
    }
    const avsInfo = addressVerificationInfo[index];
    if (avsInfo) {
      const { timeStamp, ...other } = avsInfo;
      set(orderItem, "addressVerificationInfo", other);
    }
    set(orderItem, "createdOn", new Date().toISOString());
    if (allowMultipleProducts) {
      set(orderItem, "lineItems", [...productInfo]);
    } else {
      set(orderItem, "lineItems", [productInfo]);
    }

    set(orderItem, "fees", fees);
    set(orderItem, "taxAmounts", taxAmounts);

    const productTaxAmount = parseFloat((taxRate * orderItemTotal).toFixed(2));

    set(orderItem, "totalTax", {
      amount: taxAmount,
      rate: taxRate,
      productTaxAmount: productTaxAmount,
      feeTaxAmount: parseFloat((taxAmount - productTaxAmount).toFixed(2)),
      deliveryFeeTaxAmount: totalDeliveryFeeTax,
      relayFeeTaxAmount: totalRelayFeeTax,
    });

    let updateRecipientInfo = omit(recipientInfo, ["suite"]);

    let deliveryInfo = get(orderItem, `deliveryInfo`);

    if (isStorePickupOrder) {
      let pickUpDateTimeInUTC = moment(deliveryInfo.pickUpDateTime)
        .utc()
        .format();
      deliveryInfo.pickUpDateTime = pickUpDateTimeInUTC;
      deliveryInfo = omit(deliveryInfo, ["shopDayTimings"]);
      if (deliveryInfo?.orderDeliveryTime) {
        deliveryInfo = omit(deliveryInfo, ["orderDeliveryTime"]);
      }
    } else if (isWalkInOrder) {
      deliveryInfo.deliveryDate = moment
        .tz(memberTimezone)
        .format("YYYY-MM-DD");
      deliveryInfo.pickUpDateTime = moment().utc().format();
      deliveryInfo = omit(deliveryInfo, ["shopDayTimings"]);
      if (deliveryInfo?.orderDeliveryTime) {
        deliveryInfo = omit(deliveryInfo, ["orderDeliveryTime"]);
      }
    } else {
      if (deliveryInfo?.orderDeliveryTime) {
        const formattedDeliveryTime = moment(
          deliveryInfo?.orderDeliveryTime
        ).format("h:mm A");

        const timedDeliveryInstructions = `Deliver by ${formattedDeliveryTime}.`;

        deliveryInfo.orderDeliveryTime = formattedDeliveryTime;

        deliveryInfo.deliveryInstructions = `${timedDeliveryInstructions}${
          deliveryInfo?.deliveryInstructions
            ? `\n${deliveryInfo?.deliveryInstructions?.trim()}`
            : ""
        }`;
      }
      if (!deliveryInfo.deliveryMethod) {
        deliveryInfo = omit(deliveryInfo, ["deliveryMethod"]);
      }
      const fieldsToCheck = [
        "deliveryNotes",
        "deliveryTime",
        "deliveredDate",
        "operator",
      ];
      fieldsToCheck.forEach((field) => {
        if (!deliveryInfo?.[field]) {
          deliveryInfo = omit(deliveryInfo, [field]);
        }
      });
    }

    //Reset delivery related properties based on delivery method.
    if (!(isStorePickupOrder || isWalkInOrder)) {
      deliveryInfo = omit(deliveryInfo, ["pickUpDateTime", "pickUpBy"]);
    } else {
      // incase of storepickup & walkin - remove unncessary fields in the patch
      const fieldsToCheck = ["deliveryNotes", "deliveryTime", "deliveredDate"];
      fieldsToCheck.forEach((field) => {
        if (!deliveryInfo?.[field]) {
          deliveryInfo = omit(deliveryInfo, [field]);
        }
      });
    }

    deliveryInfo.timeZone = memberTimezone || "America/Chicago";

    set(orderItem, "deliveryInfo", deliveryInfo);
    let finalValues = set(orderItem, "recipientInfo", updateRecipientInfo);

    if (isWalkInOrder || isStorePickupOrder) {
      const { country, deliveryMethod, hasRecipientInfo, ...other } =
        recipientInfo;

      const isDefaultInfo =
        Object.values(other).filter((val) => !!val).length === 0;

      if (isDefaultInfo) delete orderItem.recipientInfo;
    }

    const actualReceivingMember = get(
      orderItem,
      "deliveryInfo.floristInfo.memberCode",
      ""
    );

    // We are not depending on orderItemAmounts in Subscription related APIs so added below check
    if (!supportEdit && !isSubscriptionPage) {
      // setting order item amounts as per multi order payload structuring
      const { orderTotal, orderSubTotal } = getTotalPrice(
        values.orderItems,
        index
      );

      const orderAmounts = getOrderAmounts(
        orderTotal,
        orderSubTotal,
        orderItemDiscount,
        taxAmount,
        orderItemTotalFee,
        deliveryFee,
        retailDeliveryFee,
        relayFee,
        serviceFee
      );

      set(orderItem, "orderItemAmounts", orderAmounts);
    }

    set(finalValues, "price", [
      {
        value: formatPrice(
          orderItemTotal + orderItemDiscount + parseFloat(formattedDeliveryFee)
        ),
        name: "orderTotal",
      },
    ]);

    // we are not using below attribute at API end so removing it after confirming
    delete orderItem.taxInfo;

    const shopLocation = cloneDeep(
      UserProfileStorage.getShopLocation(sendingMember)
    );

    // API is expecting country instead of countryCode as part of shop address so renaming it
    shopLocation["country"] = shopLocation["countryCode"];
    delete shopLocation["countryCode"];

    set(finalValues, "sendingMember", {
      memberCode: sendingMember,
      ...shopLocation,
    });

    const local_order_tax_settings = get(
      shopPreferences,
      "local_order_tax_settings",
      "point_of_origin"
    );

    const wired_order_tax_settings = get(
      shopPreferences,
      "wired_order_tax_settings",
      "point_of_origin"
    );

    const taxation =
      isStorePickupOrder || isWalkInOrder
        ? "point_of_origin"
        : isWireOrder(deliveryMethod)
        ? wired_order_tax_settings
        : local_order_tax_settings;

    set(finalValues, "taxation", taxation);

    set(
      finalValues,
      "receivingMember.memberCode",
      isLocalOrder ||
        isStorePickupOrder ||
        isWalkInOrder ||
        isPhoneOutOrder(deliveryMethod)
        ? sendingMember
        : !isLocalDelivery
        ? actualReceivingMember
        : sendingMember
    );

    const noAutoForward =
      isDemoAccount || isStorePickupOrder || isWalkInOrder
        ? true
        : get(orderItem, "noAutoForward", false);
    set(finalValues, "autoForward", !noAutoForward ? "Y" : "N");
    set(finalValues, "forwardedMember", {});

    finalValues = omit(finalValues, [
      "buyerSpend",
      "buyerPhone",
      "buyerEmail",
      "billingZip",
      "cardNumber",
      "coupon",
      "createHouseAccount",
      "expDate",
      "isBillingAndCutomerAdddressSame",
      "nameOnCard",
      "paymentType",
      "savePayment",
      "securityCode",
      "orders",
    ]);

    totalDiscount += orderItemDiscount;
    totalTax += taxAmount;
    totalFee += orderItemTotalFee;

    totalDeliveryFee = parseFloat(formattedDeliveryFee);
    totalRetailDeliveryFee = parseFloat(formattedRetailDeliveryFee);
    totalRelayFee = parseFloat(formattedRelayFee);
    totalServiceFee = parseFloat(formattedServiceFee);

    if (promoCode && !isEmpty(orderItem?.selectedPromoCodeInfo)) {
      const { promoCodeID, discountValue, discountType } =
        orderItem?.selectedPromoCodeInfo;

      const promoValue =
        discountType === COMMON.DOLLAR
          ? `$${discountValue}`
          : `${discountValue}%`;

      set(orderItem, "promoValue", promoValue);

      //Storing the promoCodeId in case of subscription
      if (isSubscriptionPage) set(orderItem, "promoCodeId", promoCodeID);
    } else if (!promoCode) {
      delete orderItem?.promoValue;
      delete orderItem?.promoCodeId;
    }

    delete orderItem?.selectedPromoCodeInfo;
  });

  // To identify multi orderitem has wire order or not
  const hasWireOutOrder = orderItems.some((each) =>
    isWireOrder(each?.deliveryInfo?.deliveryMethod)
  );
  // To identify multi orderitem has local order or not
  const hasLocalOrder = orderItems.some((each) =>
    ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(
      each?.deliveryInfo?.deliveryMethod
    )
  );

  // checks to send orderType to API
  if (hasLocalOrder && !hasWireOutOrder) {
    set(values, "orderType", "Local");
  } else if (hasWireOutOrder && !hasLocalOrder) {
    set(values, "orderType", "Wired");
  } else if (hasLocalOrder && hasWireOutOrder) {
    set(values, "orderType", "Local and Wired");
  }

  const newTotalAmount = get(values, "paymentDetails.newTotalAmount", 0);
  if (
    !supportEdit
      ? isPaymentEnabled
      : isPaymentEnabled || formatPrice(getPrice(newTotalAmount)) !== 0
  ) {
    const orderAmounts = getOrderAmounts(
      orderTotal,
      orderSubTotal,
      totalDiscount,
      totalTax,
      totalFee,
      totalDeliveryFee,
      totalRetailDeliveryFee,
      totalRelayFee,
      totalServiceFee
    );
    set(values, "orderAmounts", orderAmounts);

    const amountDue = get(values, "paymentDetails.amountDue", 0);

    const amountChargedToCustomer =
      orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
        ?.value || 0;

    let gcRedeemedAmt = 0;
    if (redeemedGiftCardsData?.length > 0) {
      for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
        gcRedeemedAmt += Number(redeemedGiftCardsData[i]?.gcAmount);
      }
    }
    const grandTotal = Number(amountChargedToCustomer) - gcRedeemedAmt;
    const amountDueTotal = Number(amountDue) - gcRedeemedAmt;

    const paymentMethods = get(values, "paymentDetails.paymentMethod", []);
    paymentMethods.map((paymentMethod, index) => {
      const { paymentMethodDetails, paymentMethodType } = getPaymentInfo(
        values,
        paymentMethod,
        index
      );
      const { paymentProcessed = false } = paymentMethod;

      const isSavePaymentSelected = get(
        values,
        `paymentDetails.paymentMethod.${index}.savePayment`,
        false
      );
      if (paymentMethodType === "CREDIT_CARD" && !isSavePaymentSelected) {
        const billingZip = get(
          values,
          `paymentDetails.paymentMethod.${index}.billingInformation.zip`,
          ""
        );
        set(
          values,
          `paymentDetails.paymentMethod.${index}.billingInformation`,
          {
            ...basicPaymentInfo.paymentMethod[0].billingInformation,
            zip: billingZip,
          }
        );
      }
      if (["SAVED_CARD", "PAYMENT_TERMINAL"].includes(paymentMethodType)) {
        set(
          values,
          `paymentDetails.paymentMethod.${index}.paymentMethodType`,
          "CREDIT_CARD"
        );
      }

      set(
        values,
        `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
        paymentMethodType === "INVOICE"
          ? {
              amount: formatPrice(supportEdit ? amountDueTotal : grandTotal),
              notes: "",
            }
          : paymentMethodDetails
      );

      if (paymentMethodType === "PAYMENT_TERMINAL") {
        set(
          values,
          `paymentDetails.paymentMethod.${index}.isHardwareTerminal`,
          true
        );
        if (!paymentProcessed)
          delete values.paymentDetails.paymentMethod[index]
            .paymentMethodDetails;
      }
    });

    // Setting paymentMethodArray if giftcard applied
    if (redeemedGiftCardsData?.length > 0) {
      const previousValues = get(values, "paymentDetails.paymentMethod.0", {});
      const isGrandTotalZero = get(
        values,
        `paymentDetails.paymentMethod.0.isGrandTotalZero`,
        false
      );
      const paymentMethodArray = isGrandTotalZero
        ? []
        : get(values, "paymentDetails.paymentMethod", []);
      for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
        paymentMethodArray.push({
          ...previousValues,
          paymentMethodDetails: {
            gcAmount: redeemedGiftCardsData[i]?.gcAmount,
            gcNumber: redeemedGiftCardsData[i]?.gcNumber,
          },
          paymentMethodType: "GIFT_CARD",
        });
      }
      set(values, "paymentDetails.paymentMethod", paymentMethodArray);
    }
  } else {
    delete values.paymentDetails;
  }

  if (values.hasCustomerInfo) {
    const {
      phone,
      customerType,
      firstPaymentInfo,
      addresses,
      selectContact,
      selectedContact,
      ...other
    } = values.customerInfo;

    let updatedCustomerContacts;
    if (selectContact) {
      //To find out the selected contact from all existing customer contacts while creating an order.
      const selectedIndex = other?.customerContacts?.findIndex((contact) =>
        isEqual(contact, JSON.parse(selectContact))
      );

      // To remove formatting from the contact's phone number
      let updatedSelectedContact = {
        ...selectedContact,
        phone: formatPhoneForPayload(selectedContact.phone) || "",
      };

      updatedCustomerContacts =
        selectedIndex !== -1 && selectedIndex !== undefined
          ? other?.customerContacts?.map(
              (contact, index) =>
                index === selectedIndex ? updatedSelectedContact : contact // To update the existing contact
            )
          : [...(other.customerContacts || []), updatedSelectedContact]; // To creat a new contact
    }

    const custInfo = omit(values.customerInfo, [
      "phone",
      "customerType",
      "firstPaymentInfo",
      "selectContact",
      "selectedContact",
      "billingInformation",
      "initialCustomerId",
      "savedPaymentInfo",
    ]);

    const isModified = values.actualCustomerInfo
      ? !isEqual(
          values.actualCustomerInfo,
          isSubscriptionPage ? custInfo : other
        )
      : false;
    set(values, "customerInfo", {
      ...other,
      ...(updatedCustomerContacts
        ? { customerContacts: updatedCustomerContacts }
        : {}),
      isModified,
      isBusinessProfile: customerType === "Business",
      phones: [formatPhoneForPayload(phone)],
    });
  } else {
    delete values.customerInfo;
  }
  delete values.actualCustomerInfo;
  delete values.hasCustomerInfo;
  delete values.showError; // Formik value used to show order amounts validation error to user for split payments
  values.isCopyOrder && delete values.isCopyOrder; // This flag is used to prefill the selected contact details in CustomerProfileInfo while copying an order

  values.technology = floristTechnology;

  return values;
};

export const getPriceDetails = (createOrderResponse) => {
  const { orderAmounts, orderItems } = createOrderResponse;
  const { fees = {} } = orderItems[0];

  const deliveryFees =
    get(fees, "applicableCharges.deliveryCharges", []).find(
      (obj) => obj.type === "Standard"
    )?.value || 0;

  const retailDeliveryFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retailDeliveryFee"
    )?.value || 0;

  const relayFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "relayFee"
    )?.value || 0;

  const totalTax =
    orderAmounts?.find((obj) => obj.name === "taxAmount")?.value || 0;
  const amountChargedToCustomer =
    orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
      ?.value || 0;

  return {
    tax: totalTax,
    amountChargedToCustomer: amountChargedToCustomer,
    deliveryFees: formatPrice(deliveryFees),
    retailDeliveryFee: formatPrice(retailDeliveryFee),
    relayFee: formatPrice(relayFee),
  };
};

export const getNoOfReceiptPrints = (createOrderResponse) => {
  const hasCreditCardPayment = get(
    createOrderResponse,
    "paymentDetails.paymentMethod",
    []
  )?.some((eachPayment) => {
    const { paymentMethodType } = eachPayment;
    return ["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType);
  });

  return hasCreditCardPayment ? 2 : 1;
};

export const getPrintReceiptContent = (
  createOrderResponse,
  createOrderReqObj,
  printType,
  receiptType = ""
) => {
  const {
    orderItemId,
    sendingMember,
    createdOn,
    lineItems,
    promoCode,
    erosOrderNumber,
    messageNumber,
    deliveryInfo: { deliveryMethod = "" },
  } = createOrderResponse.orderItems[0];

  const paymentMethods = get(
    createOrderResponse,
    "paymentDetails.paymentMethod",
    []
  );

  const chargesList =
    receiptType !== "orderCreation" && getSortedChargesList(paymentMethods);

  const hasCreditCardPayment = paymentMethods?.some((eachPayment) => {
    const { paymentMethodType } = eachPayment;
    return ["CREDIT_CARD"].includes(paymentMethodType); // "SAVED_CARD" will also be "CREDIT_CARD" paymentMethodType
  });

  const isPOS = printType === "POS";
  const orderDate = moment(createdOn).format("MM/DD/YYYY");
  const orderTime = moment(createdOn).format("hh:mm A");
  const shopNames = UserProfileStorage.getAllShopNames();
  const shopLocations = UserProfileStorage.getAllShopLocation();
  const shopName = shopNames[sendingMember.memberCode];
  const shopLocation = shopLocations[sendingMember.memberCode];
  const totalsData = getPriceDetails(createOrderReqObj);

  const { firstName: operator = "" } = UserProfileStorage.getUser();

  const accessories = lineItems.filter((lineItem) => lineItem.type === "Addon");
  const products = lineItems.filter((lineItem) => lineItem.type === "Product");

  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryMethod === "WALK_IN";

  const orderNumber =
    (isStorePickupOrder || isWalkInOrder ? erosOrderNumber : messageNumber) ||
    orderItemId;

  let subTotal = 0;
  let totalDiscountAmount = 0;

  const displayLineItems = (lineItems) => {
    return lineItems.length > 0
      ? lineItems
          .map(
            ({ productFirstChoiceDescription, lineItemAmounts, quantity }) => {
              const retailPrice = lineItemAmounts.find(
                (amount) => amount.name === "retailProductAmount"
              ).value;
              const discountAmount = lineItemAmounts.find(
                (amount) => amount.name === "discountAmount"
              ).value;
              subTotal += parseFloat(retailPrice);
              totalDiscountAmount += parseFloat(discountAmount);
              return `<tr class="productName fullCol">
                <td class="leftCol">${productFirstChoiceDescription}  Qty: ${quantity}</td>
                <td class="rightCol">$${parseFloat(retailPrice).toFixed(2)}</td>
                </tr>`;
            }
          )
          .join("")
      : "";
  };

  const getTerminalReceiptHTMLData = (index) => {
    // As we won't have terminal receipts data in create order API response so we are depending on create order request object
    const paymentMethodInfo = get(
      createOrderReqObj,
      `paymentDetails.paymentMethod.${index}`,
      {}
    );

    const isTerminalPayment = paymentMethodInfo?.isHardwareTerminal ?? false;
    const receiptsData = isTerminalPayment
      ? get(paymentMethodInfo, "paymentMethodDetails.receiptsData", {})
      : {};

    // Added new css class in order to reuse same function to show terminal receipts data at top or bottom
    return `<table>
              <tr class="terminal_receipt">
                <td class="leftCol">Txn Id</td>
                <td class="rightCol breakWord">${
                  receiptsData?.transactionId || ""
                }</td>
              </tr>
              <tr class="terminal_receipt">
                <td class="leftCol breakWord">AID</td>
                <td class="rightCol">${receiptsData?.applicationId || ""}</td>
              </tr>
              <tr class="terminal_receipt">
                <td class="leftCol">Entry Mode</td>
                <td class="rightCol">${
                  receiptsData?.paymentEntryMode || ""
                }</td>
              </tr>
              <tr class="terminal_receipt">
                <td class="leftCol">Authorization</td>
                <td class="rightCol">${
                  receiptsData?.authorizationCode || ""
                }</td>
              </tr>
            </table>`;
  };

  const displayPaymentInfo = () => {
    return paymentMethods?.length > 0
      ? paymentMethods
          ?.map((eachPayment, index) => {
            const {
              paymentMethodType = "",
              isHardwareTerminal = false,
              paymentMethodDetails: {
                tenderedAmount,
                changeDueAmount,
                creditCardType = "",
                creditCardNumber = "",
                authorizationDetails = [],
                amount,
              } = {},
            } = eachPayment;

            const ccAuthAmount =
              authorizationDetails?.find((obj) => obj.name === "amount")
                ?.value || 0;

            return `${
              paymentMethodType === "CASH" && tenderedAmount
                ? `<table><tr class="fullCol">
              <td class="leftCol">Cash Tendered</td>
              <td class="rightCol">$${parseFloat(tenderedAmount).toFixed(
                2
              )}</td></tr></table>`
                : ""
            }${
              paymentMethodType === "CASH" && changeDueAmount
                ? `<table><tr class="fullCol"><td class="leftCol">Change Due</td>
          <td class="rightCol">$${parseFloat(changeDueAmount).toFixed(
            2
          )}</td></tr></table>`
                : ""
            }${
              paymentMethodType === "CREDIT_CARD"
                ? `<table><tr class="fullCol"><td class="leftCol">${creditCardType} ****${creditCardNumber}</td>
         <td class="rightCol">$${parseFloat(ccAuthAmount).toFixed(
           2
         )}</td></tr></table>`
                : ""
            }
            ${isHardwareTerminal ? getTerminalReceiptHTMLData(index) : ``}
            ${
              ["PAID_ELSEWHERE", "CHECK", "INVOICE"].includes(paymentMethodType)
                ? `<table><tr class="fullCol"><td class="leftCol">${startCase(
                    toLower(paymentMethodType)
                  )}</td>
         <td class="rightCol">$${parseFloat(amount).toFixed(
           2
         )}</td></tr></table>`
                : ""
            }`;
          })
          .join("")
      : "";
  };

  const displayNewPaymentInfo = () => {
    return chargesList?.length > 0
      ? chargesList
          ?.map((eachPayment, index) => {
            const {
              paymentMethodType,
              creditCardType,
              creditCardNumber = "",
              transactionAmount,
              chargeType,
              tenderedAmount,
              isHardwareTerminal,
            } = eachPayment;

            console.log("eachPayment :>> ", eachPayment);

            return `${
              paymentMethodType === "CASH"
                ? `<table><tr class="fullCol">
              <td class="leftCol">Cash ${
                tenderedAmount !== 0 && chargeType !== "refund"
                  ? `- Tendered Amount: $${formatPrice(tenderedAmount)}`
                  : ``
              }</td>
              <td class="rightCol">${
                chargeType === "refund"
                  ? `($${formatPrice(transactionAmount || 0)})`
                  : `$${formatPrice(transactionAmount || 0)}`
              }</td></tr></table>`
                : ""
            }${
              paymentMethodType === "CREDIT_CARD"
                ? `<table><tr class="fullCol"><td class="leftCol">${creditCardType} ****${creditCardNumber}</td>
         <td class="rightCol">${
           chargeType === "refund"
             ? `($${formatPrice(transactionAmount || 0)})`
             : `$${formatPrice(transactionAmount || 0)}`
         }</td></tr></table>`
                : ""
            }
            ${isHardwareTerminal ? getTerminalReceiptHTMLData(index) : ``}
            ${
              ["PAID_ELSEWHERE", "CHECK", "INVOICE"].includes(
                paymentMethodType
              ) && chargeType !== "receivePayment"
                ? `<table><tr class="fullCol"><td class="leftCol">${startCase(
                    toLower(paymentMethodType)
                  )}</td>
         <td class="rightCol">${
           chargeType === "refund"
             ? `($${formatPrice(transactionAmount || 0)})`
             : `$${formatPrice(transactionAmount || 0)}`
         }</td></tr></table>`
                : ""
            }`;
          })
          .join("")
      : "";
  };

  return `<!DOCTYPE html>
    <html lang="en">
     <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Order Receipt</title>
        <style>
          @page {
            margin: 0;
          }
           * {
           font-size: 12px;
           font-family: Helvetica, sans-serif;
          }
           body {
           margin: 15px 0 0 0;
           padding: 15px 0 0 0;
           ${!isPOS ? `display: flex; justify-content: center;` : ""}
           }
           td,
           th,
           tr,
           table {
           border-collapse: collapse;
           }
           div table tbody {
            border: none;
           }
           div.ticket table td {
            padding: 4px 2px;
           }
           td.description,
           th.description {
           width: 60%;
           max-width: 60%;
           }
           td.price,
           th.price {
           width: 40%;
           max-width: 40%;
           word-break: break-all;
           }
           .centered {
           text-align: center;
           align-content: center;
           }
           .font {
           margin-left: 0;
           }
           .ticket {
           width: ${isPOS ? "100%" : "300px"};
           max-width: ${isPOS ? "300px" : "100%"};
           margin: 0 auto;
           }
           img {
           max-width: inherit;
           width: inherit;
           }
           .receiptHeader .fullCol {
           width: 100%;
           border-bottom: none;
           }
           .receiptHeader .leftCol {
           width: 40%;
           text-align: left;
           }
           .receiptHeader .rightCol {
           width: 60%;
           text-align: right;
           }
           .receiptBody table {
            width: 100%;
           }
           .receiptBody .fullCol {
            width: 100%;
            border-bottom: none;
            } 
           .receiptBody .productName .leftCol {
            text-align: left;
           }
           .receiptBody .leftCol {
            width: 75%;
            text-align: right;
           }
           .receiptBody .rightCol {
            width: 25%;
            text-align: right;
           }
           .receiptBody .totalRow {
            border-bottom: 10px solid;
            border-color: transparent;
           }
           .receiptBody .subTotalRow {
            border-top: 10px solid;
            border-color: transparent;
           }
           .terminal_receipt {
            width: 100%;
            border-bottom: none;
            padding: 0px;
            }
            .terminal_receipt .leftCol {
            width: 35%;
            text-align: right;
            padding: 1px;
            font-size: 9px;
            }
            .terminal_receipt .rightCol {
            width: 65%;
            text-align: right;
            padding: 1px;
            font-size: 9px;
            }
            .breakWord {
            word-break: break-all;
            }
           .bold {
            font-weight: bold;
           }
           hr {
            border: 1px solid #000;
           }
           @media print {
           .hidden-print,
           .hidden-print * {
           display: none !important;
           }
           }
        </style>
     </head>
      <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
        <div class="ticket">
           <p class="centered">
              <span style="font-weight: bold">${shopName}</span>
              <br>${shopLocation.addressLine1}
              <br>${shopLocation.city}, ${shopLocation.state} ${
    shopLocation.zip
  }
           </p>
           <div class="receiptHeader">
              <table style="width: 100%">
                 <tbody>
                    <tr class="fullCol bold">
                       <td class="leftCol">Date</td>
                       <td class="rightCol">${orderDate}</td>
                    </tr>
                    <tr class="fullCol bold">
                       <td class="leftCol">Time</td>
                       <td class="rightCol">${orderTime}</td>
                    </tr>
                    <tr class="fullCol">
                       <td class="leftCol">Cashier</td>
                       <td class="rightCol">${operator}</td>
                    </tr>
                    <tr class="fullCol bold">
                      <td class="leftCol">Order #</td>
                      <td class="rightCol">${orderNumber}</td>
                    </tr>
                 </tbody>
              </table>
              <hr/>
           </div>
           <div class="receiptBody">
               <table>
                  <thead>
                     <tr class="productName fullCol">
                        <th class="leftCol">Item</th>
                        <th class="rightCol">Price</th>
                     </tr>
                  </thead>
                  <tbody>
                     ${displayLineItems(products)}
                     ${displayLineItems(accessories)}
                     <tr class="fullCol subTotalRow">
                        <td class="leftCol">Subtotal</td>
                        <td class="rightCol">$${parseFloat(subTotal).toFixed(
                          2
                        )}</td>
                     </tr>
                     <tr class="fullCol">
                        <td class="leftCol">Discount</td>
                        <td class="rightCol">$${parseFloat(
                          totalDiscountAmount
                        ).toFixed(2)}</td>
                     </tr>
                     <tr class="fullCol">
                        <td class="leftCol">Delivery Charge</td>
                        <td class="rightCol">$${parseFloat(
                          totalsData.deliveryFees
                        ).toFixed(2)}</td>
                     </tr>
                     ${
                       Number(totalsData.retailDeliveryFee) > 0
                         ? `<tr class="fullCol">
                      <td class="leftCol">Retail Delivery Fee</td>
                      <td class="rightCol">$${parseFloat(
                        totalsData.retailDeliveryFee
                      ).toFixed(2)}</td>
                   </tr>`
                         : ``
                     }
                     ${
                       Number(totalsData.relayFee) > 0
                         ? `<tr class="fullCol">
                        <td class="leftCol">Relay Fee</td>
                        <td class="rightCol">$${parseFloat(
                          totalsData.relayFee
                        ).toFixed(2)}</td>
                    </tr>`
                         : ``
                     }
                     <tr class="fullCol">
                        <td class="leftCol">Sales Tax</td>
                        <td class="rightCol">$${parseFloat(
                          totalsData.tax
                        ).toFixed(2)}</td>
                     </tr>
                     <tr class="fullCol bold totalRow">
                        <td class="leftCol">Total</td>
                        <td class="rightCol">$${parseFloat(
                          totalsData.amountChargedToCustomer
                        ).toFixed(2)}</td>
                     </tr>
                  </tbody>
               </table>
                ${
                  receiptType === "orderCreation"
                    ? displayPaymentInfo()
                    : displayNewPaymentInfo()
                }
            </div>
           <p style="margin-top:40px"></p>
           ${
             hasCreditCardPayment
               ? `<hr/>
                  <p class="centered">Signature</p>`
               : ``
           }
           <p class="centered">Thanks for your order!</p>
           ${
             promoCode
               ? `<p class="centered bold">Applied Promo code - ${promoCode}</p>`
               : ``
           }
           <p class="centered" style="margin-bottom:10px">Printed ${moment().format(
             "MM/DD/YYYY"
           )} at ${moment().format("hh:mm A")}</p>
        </div>
     </body>
  </html>`;
};

export const getSessionReceiptContent = (sessionInfo = {}) => {
  const { sessionId = "", memberId = "", events = [] } = sessionInfo;
  const {
    amount: transAmount = "",
    timeStamp,
    type,
    user,
    userNote: notes = "",
    orderId = "",
  } = events[events.length - 1];

  const shopNames = UserProfileStorage.getAllShopNames();
  const shopLocations = UserProfileStorage.getAllShopLocation();
  const shopName = shopNames[memberId];
  const shopLocation = shopLocations[memberId];

  const eventDate = moment(timeStamp).format("MM/DD/YYYY");
  const eventTime = moment(timeStamp).format("hh:mm A");

  const content = `<!DOCTYPE html>
    <html lang="en">
     <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <style>
           * {
           font-size: 12px;
           font-family: Helvetica, sans-serif;
          }
           td,
           th,
           tr,
           table {
           border-collapse: collapse;
           }
           div table tbody {
            border: none;
           }
           div.ticket table td {
            padding: 4px 2px;
           }
           td.description,
           th.description {
           width: 60%;
           max-width: 60%;
           }
           td.price,
           th.price {
           width: 40%;
           max-width: 40%;
           word-break: break-all;
           }
           .centered {
           text-align: center;
           align-content: center;
           }
           .ticket {
           width: 100%;
           max-width: 100%;
           }
           img {
           max-width: inherit;
           width: inherit;
           }
           .receiptHeader .fullCol {
           width: 100%;
           border-bottom: none;
           }
           .receiptHeader .leftCol {
           width: 60%;
           text-align: left;
           }
           .receiptHeader .rightCol {
           width: 40%;
           text-align: right;
           }
           .receiptBody table {
            width: 100%;
           }
           .receiptBody .fullCol {
            width: 100%;
            border-bottom: none;
            } 
           .receiptBody .productName .leftCol {
            text-align: left;
           }
           .receiptBody .leftCol {
            width: 75%;
            text-align: right;
           }
           .receiptBody .rightCol {
            width: 25%;
            text-align: right;
           }
           .receiptBody .totalRow {
            border-bottom: 10px solid;
            border-color: transparent;
           }
           .receiptBody .subTotalRow {
            border-top: 10px solid;
            border-color: transparent;
           }
           .bold {
            font-weight: bold;
           }
           hr {
            border: 1px solid #000;
           }
           @media print {
           .hidden-print,
           .hidden-print * {
           display: none !important;
           }
           }
        </style>
     </head>
     <body>
        <div class="ticket">
           <p class="centered">
              <span style="font-weight: bold">${shopName}</span>
              <br>${shopLocation.addressLine1}
              <br>${shopLocation.city}, ${shopLocation.state} ${
    shopLocation.zip
  }
           </p>
           <div class="receiptHeader">
              <table style="width: 100%">
                 <tbody>
                    <tr class="fullCol bold">
                       <td class="leftCol">Date</td>
                       <td class="rightCol">${eventDate}</td>
                    </tr>
                    <tr class="fullCol bold">
                       <td class="leftCol">Time</td>
                       <td class="rightCol">${eventTime}</td>
                    </tr>
                    <tr class="fullCol">
                       <td class="leftCol">Cashier</td>
                       <td class="rightCol">${user}</td>
                    </tr>
                    <tr class="fullCol">
                       <td class="leftCol">Session</td>
                       <td class="rightCol">${sessionId}</td>
                    </tr>
                    ${
                      orderId
                        ? `<tr class="fullCol bold">
                          <td class="leftCol">Order Number</td>
                          <td class="rightCol">${orderId}</td>
                        </tr>`
                        : ``
                    }
                 </tbody>
              </table>
              <hr/>
           </div>
           <div class="receiptBody">
               <table>
                  <thead>
                     <tr class="productName fullCol">
                        <th class="leftCol">Description</th>
                        <th class="rightCol">Price</th>
                     </tr>
                  </thead>
                  <tbody>
                    
                     <tr class="productName fullCol">
                        <td class="leftCol">${type.replace("-", " ")}</td>
                        <td class="rightCol">$${parseFloat(transAmount).toFixed(
                          2
                        )}</td>
                     </tr>
                     <tr class="productName fullCol">
                        <td class="leftCol" colspan="2">${
                          notes && notes !== "$$BALANCE_CHECK$$"
                            ? `Notes: ${notes}`
                            : ``
                        }</td>
                     </tr>
                  </tbody>
               </table>
            </div>
           <p style="margin-top:40px"></p>
           <p class="centered" style="margin-bottom:10px">Printed ${moment().format(
             "MM/DD/YYYY"
           )} at ${moment().format("hh:mm A")}</p>
           <p class="centered" style="margin-bottom:10px">*Merchant Copy*</p>
        </div>
     </body>
  </html>`;
  //console.log("content-- ", content);
  return content;
};

export const getEligibleDeliveryMethods = (permissions, memberCode) => {
  const isFloristDeliveredEntEnabled =
    shopPermissions.isFloristDeliveryAvailable(permissions, memberCode);

  const isPickUpEntEnabled = shopPermissions.isPickupDeliveryAvailable(
    permissions,
    memberCode
  );

  const isWalkInEntEnabled = shopPermissions.isWalkInDeliveryAvailable(
    permissions,
    memberCode
  );

  const isPhoneOutEntEnabled = shopPermissions.isPhoneOutDeliveryAvailable(
    permissions,
    memberCode
  );

  let deliveryMethods = [
    {
      label: "Wire Out",
      value: "FLORIST_PARTNER",
    },
  ];

  isFloristDeliveredEntEnabled &&
    deliveryMethods.unshift({
      label: "Florist Delivered",
      value: "FLORIST_DELIVERED",
    });

  isPickUpEntEnabled &&
    deliveryMethods.push({
      label: "Pickup",
      value: "STORE_PICKUP",
    });

  isWalkInEntEnabled &&
    deliveryMethods.push({ label: "Walk-In", value: "WALK_IN" });

  isPhoneOutEntEnabled &&
    deliveryMethods.push({
      label: "Phone Out",
      value: "PHONE_OUT",
    });
  return deliveryMethods;
};

export const fetchAddonImage = (addOnId, global, sourceMemberCode) => {
  let addOnInfo = fetchAddonInfo(addOnId, global) || {};

  const imageURL =
    (!isEmpty(addOnInfo) && addOnInfo?.img) ||
    (!isEmpty(addOnInfo) &&
      addOnInfo?.media &&
      addOnInfo?.media[sourceMemberCode]?.[0]?.url) ||
    "";

  return imageURL;
};

export const getDetailsFromOrder = (orderItem = {}) => {
  const {
    lineItems = [],
    fees,
    totalTax,
    totalDiscountAmount,
    price,
    hasSettlementError = false,
  } = orderItem;

  const deliveryFees =
    get(fees, "applicableCharges.deliveryCharges", []).find(
      (obj) => obj.type === "Standard"
    )?.value || 0;

  const retailDeliveryFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retailDeliveryFee"
    )?.value || 0;

  const relayFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "relayFee"
    )?.value || 0;

  const serviceFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "serviceFee"
    )?.value || 0;

  const retransFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retransFee"
    )?.value || 0;

  let subTotal = 0;

  lineItems.forEach((lineItem, idx) => {
    const { lineItemAmounts = {} } = lineItem || {};
    const lineItemAmount =
      lineItemAmounts?.find((item) => item.name === "amountChargedToCustomer")
        ?.value || 0;
    subTotal += parseFloat(lineItemAmount);
  });

  const orderTotalAmount =
    price?.find((obj) => obj?.name === "amountChargedToCustomer")?.value || 0;

  return {
    totalTax: formatPrice(totalTax?.amount || 0),
    totalDeliveryFee: formatPrice(getPrice(deliveryFees)),
    retailDeliveryFee: formatPrice(retailDeliveryFee),
    relayFee: formatPrice(relayFee),
    serviceFee: formatPrice(serviceFee),
    retransFee: formatPrice(retransFee),
    totalDiscount: formatPrice(totalDiscountAmount),
    subTotal: formatPrice(subTotal),
    orderTotal: hasSettlementError
      ? formatPrice(0)
      : formatPrice(orderTotalAmount),
  };
};

export const formatLineItems = (
  lineItemDetails,
  global,
  isSubscriptionPage = false,
  updateDiscounts = false, // Based on this flag, we are updating discount type and it's value in Formik to display in UI. Ex - In case of subscription modify
  isCoreUser,
  sourceMemberCode,
  isEditOrder = false,
  multiProductEligible = false,
  isWiredOrder = false,
  customerInfo = []
) => {
  //Updating Line Items
  let updatedLineItems = [];
  let updatedLineItemAmounts = [];
  // const { deliveryMethod = "" } = deliveryInfo;

  let isProductAdded = false;
  let productType = "";

  const lineItems = cloneDeep(lineItemDetails);

  lineItems.map((lineItem) => {
    productType = lineItem?.type || "";

    if (isSubscriptionPage && productType === "") productType = "Product";

    const isProduct = productType === "Product";

    if (
      !isEditOrder &&
      !isSubscriptionPage &&
      isCoreUser &&
      isProduct &&
      isProductAdded
    ) {
      return;
    }

    let productFirstChoiceDescription =
      lineItem?.productFirstChoiceDescription?.split("\\n")?.join("\n") ?? "";

    const retailProductAmount = formatPrice(
      lineItem?.lineItemAmounts?.find(
        (item) => item.name == "retailProductAmount"
      )?.value || 0
    );

    const productDiscountPrice = formatPrice(
      lineItem?.lineItemAmounts?.find((item) => item.name == "discountAmount")
        ?.value || 0
    );

    const productQuantity = lineItem?.quantity || 1;
    const productRefundedPrice = formatPrice(
      lineItem?.lineItemAmounts?.find((item) => item.name == "refundedAmount")
        ?.value || 0
    );
    const productItemPrice = formatPrice(
      parseFloat(retailProductAmount) / productQuantity
    );
    const discountedProductAmount = formatPrice(
      lineItem?.lineItemAmounts?.find(
        (item) => item.name == "discountedProductAmount"
      )?.value ||
        retailProductAmount ||
        0
    );

    const productRefundedItemPrice = formatPrice(
      parseFloat(productRefundedPrice)
    );

    let selectedProduct = [];
    let primaryProductImg = "";

    // Added isSubscriptionPage check here as we are facing re-rendering issues with below code
    if (!isSubscriptionPage) {
      if (
        isProduct &&
        !isEmpty(global.selectedproducts) &&
        lineItem.productFirstChoiceCode
      ) {
        selectedProduct = global.selectedproducts.filter(
          (each) => each.productId === lineItem.productFirstChoiceCode
        );
        primaryProductImg = selectedProduct[0]?.primaryImg || "";
      } else {
        primaryProductImg = fetchAddonImage(
          lineItem.productFirstChoiceCode,
          global,
          sourceMemberCode
        );
      }
    }

    const productLineItem = {
      isProduct: true,
      productFirstChoiceCode: lineItem.productFirstChoiceCode,
      productFirstChoiceRefNumberId: lineItem.productFirstChoiceCode,
      productFirstChoiceDescription:
        productFirstChoiceDescription || selectedProduct[0]?.productName,
      productSecondChoiceCode: "NONE",
      productSecondChoiceDescription: "NONE",
      actualPrice: retailProductAmount,
      itemPrice: productItemPrice,
      img: primaryProductImg || "",
      designNotes: lineItem.designNotes || "",
      quantity: productQuantity.toString(),
      type: productType || "",
      isImageLoaded: primaryProductImg !== "",
      isRemoved: false,
      newlyAdded: true,
      category: lineItem.category,
      ...(isEditOrder && {
        originalPrice: discountedProductAmount,
        originalQuantity: productQuantity.toString(),
        canRemove: multiProductEligible,
        refundedAmount: productRefundedItemPrice,
        newlyAdded: false,
        lineItemId: lineItem?.lineItemId || "",
        discountedAmount: productDiscountPrice,
      }),
    };

    const productDiscountKey =
      lineItem?.discountType === "Percentage"
        ? "PercentDiscount"
        : "DollarDiscount";

    const productDiscountValue =
      lineItem?.discountValue?.[productDiscountKey] || 0;

    const discountType = updateDiscounts
      ? lineItem?.discountType ||
        (customerInfo?.discountPercentage ? "Percentage" : null)
      : null;

    const discount = updateDiscounts
      ? productDiscountValue ||
        (customerInfo?.discountPercentage
          ? formatPrice(customerInfo?.discountPercentage)
          : "0.00")
      : "0.00";

    const productLineItemAmount = {
      name: "orderTotal",
      value: productItemPrice,
      discountedValue: discountedProductAmount,
      discountType,
      initialDiscountType: updateDiscounts ? lineItem?.discountType : null,
      discount,
      initialDiscount: updateDiscounts ? productDiscountValue : "0.00",
    };

    updatedLineItems.push(productLineItem);
    updatedLineItemAmounts.push(productLineItemAmount);

    if (isProduct) isProductAdded = true;

    const accessories = cloneDeep(lineItem.accessories) || [];
    accessories.map((accessory) => {
      const {
        accessoryId,
        accessoryDesc,
        designNotes = "",
        type: accessoryType = "Addon",
        accessoryPrice,
        accessoryQty = 1,
        discountedPrice,
      } = accessory;

      let retailAccessoryAmount = formatPrice(getPrice(accessoryPrice));
      const accessoryQuantity = accessoryQty;
      const accessoryItemPrice = formatPrice(
        parseFloat(retailAccessoryAmount) / accessoryQuantity
      );
      let discountedAccessoryAmount = accessory?.discountType
        ? formatPrice(getPrice(discountedPrice?.discountedPrice))
        : retailAccessoryAmount;

      const addOnImage = fetchAddonImage(accessoryId, global, sourceMemberCode);

      const accessoryLineItem = {
        isProduct: false,
        productFirstChoiceCode: accessoryId,
        productFirstChoiceRefNumberId: accessoryId,
        productFirstChoiceDescription: accessoryDesc,
        productSecondChoiceCode: "NONE",
        productSecondChoiceDescription: "NONE",
        actualPrice: retailAccessoryAmount,
        itemPrice: accessoryItemPrice,
        img: addOnImage,
        designNotes: designNotes,
        quantity: accessoryQuantity.toString(),
        type: accessoryType,
        isImageLoaded: addOnImage !== "",
        isRemoved: false,
        ...(isEditOrder && {
          originalPrice: discountedAccessoryAmount,
          originalQuantity: accessoryQuantity.toString(),
          canRemove: multiProductEligible,
          newlyAdded: false,
          lineItemId: accessory?.lineItemId || "",
        }),
      };

      const accessoryDiscountKey =
        accessory?.discountType === "Percentage"
          ? "PercentDiscount"
          : "DollarDiscount";

      const accessoryDiscountValue =
        accessory?.discountValue?.[accessoryDiscountKey] || 0;

      const accessoryLineItemAmount = {
        name: "orderTotal",
        value: accessoryItemPrice,
        discountedValue: discountedAccessoryAmount,
        discountType: updateDiscounts ? accessory?.discountType || null : null,
        initialDiscountType: updateDiscounts ? accessory?.discountType : null,
        discount: updateDiscounts ? accessoryDiscountValue : "0.00",
        initialDiscount: updateDiscounts ? accessoryDiscountValue : "0.00",
      };

      updatedLineItems.push(accessoryLineItem);
      updatedLineItemAmounts.push(accessoryLineItemAmount);
    });
  });

  if (!isSubscriptionPage && isCoreUser && !isProductAdded && !isWiredOrder) {
    updatedLineItems = [];
    updatedLineItemAmounts = [];
  }

  return { updatedLineItems, updatedLineItemAmounts };
};

export const getDiscountedValue = (
  price,
  quantity = "1",
  discountType,
  discount
) => {
  const productPrice = parseFloat(
    formatPrice(getPrice(price) * parseInt(quantity))
  );

  const productDiscount = getDiscount(discount, price, discountType, quantity);

  const priceAfterDiscount = parseFloat(
    formatPrice(productPrice - productDiscount)
  );

  return priceAfterDiscount;
};

export const getProductObj = (
  pid,
  name,
  refNumberId,
  img,
  price,
  quantity,
  type,
  siteId,
  productCategory
) => {
  return {
    actualPrice: price,
    img: img || "",
    isImageLoaded: false,
    isRemoved: false,
    newlyAdded: true,
    category: productCategory,
    productFirstChoiceCode: pid,
    productFirstChoiceDescription: name,
    productFirstChoiceRefNumberId: refNumberId || pid,
    productSecondChoiceCode: "NONE",
    productSecondChoiceDescription: "NONE",
    quantity,
    siteId,
    type,
    // newPrice: price,
  };
};

export const getPriceObj = (price, quantity, discountPercentage) => {
  const priceObj = {
    name: "orderTotal",
    value: price,
    discount: "0.00",
    discountType: null,
    discountedValue: price,
  };

  if (discountPercentage) {
    const discount = formatPrice(discountPercentage);

    priceObj.discount = discount;
    priceObj.discountType = "Percentage";
    priceObj.discountedValue = getDiscountedValue(
      price,
      quantity,
      "Percentage",
      discount
    );
  }

  return priceObj;
};

export const getBillingInfo = (billingAddress = {}) => {
  const {
    addressLine1 = "",
    addressLine2: suite = "",
    city = "",
    state = "",
    zipcode: zip = "",
    country = "",
  } = billingAddress;

  return {
    addressLine1,
    suite,
    city,
    state,
    zip,
    country,
  };
};

export const getDefaultDI = (deliveryInstructions, deliverBy) => {
  const rushDeliverBy = deliverBy
    ? moment(deliverBy, "HH:mm").format("hh:mma")
    : "";

  const deliveryTime = deliveryInstructions
    ?.split("Deliver by ")[1]
    ?.split(".")[0];

  if (
    deliveryTime &&
    isValidTime(deliveryTime) &&
    deliveryTime !== rushDeliverBy
  ) {
    return deliveryInstructions.replace(deliveryTime, rushDeliverBy);
  }
  return deliveryInstructions;
};

export const extractTimeFromDI = (deliveryInstructions = "") => {
  let formattedTime = "";
  const match = deliveryInstructions.match(DELIVER_BY_REGEX);

  if (match) {
    // Remove leading zero if present in hour
    const time = match[1].replace(/^0/, "");
    const period = match[2].toUpperCase();

    // Formatting using moment
    formattedTime = moment(`${time} ${period}`, "h:mm A").format("h:mm A");
  }
  return formattedTime;
};

// Check order is mapped with any Funeral.
// Yes - Check order funeral is modified - Yes, unlik OrderId. Check new Funeral exists - link OrderId to new Funeral.
// No - Check new Funeral exists - link New OrderId.
export const updateFuneralMapping = async (props) => {
  const {
    sendingMember,
    orderItems,
    response,
    selectedFuneralEvents,
    availableFuneralEvents,
    onError,
  } = props;
  //SelectedFunerals doesn't hold funeral info, if funeral created on create order.
  const funeralEvents = [...selectedFuneralEvents, ...availableFuneralEvents];
  //response.orderItems will be available only in create-order.
  const funeralInfo = !response.orderItems
    ? await getFuneralMappedToOrder({
        orderId: response.orderId,
      })
    : undefined;

  //If Funeral Exists - Edit Order with Funeral. Otherwise, Create order OR Edit Order without funeral.
  if (funeralInfo) {
    const isFuneralInfoModified = isOrderFuneralInfoModified({
      funeralInfo,
      orderItem: orderItems[0],
    });

    //If Funeral modified, unlink with Funeral log.
    isFuneralInfoModified &&
      updateFuneralWithOrderIds({
        funeralInfo,
        orderIdToUnLink: response?.orderId,
        onError,
      });
  }

  //Check if orders holds any new funeral, updated Funeral log orderIds and update funeral log.
  const funeralsWithOrdersIds = getFuneralWithOrderIds({
    sendingMember,
    orderItems,
    response,
    funeralEvents,
  });

  Object.keys(funeralsWithOrdersIds).forEach((eventName) => {
    const { funeralInfo, orderIds = [] } = funeralsWithOrdersIds[eventName];
    updateFuneralWithOrderIds({
      funeralInfo,
      orderIds,
      onError,
    });
  });
};

export const getFuneralMappedToOrder = async (props) => {
  const { orderId } = props;
  const funeralEvents = await request("get-search-events", {
    offset: 0,
    limit: Environment.get("EVENTS_LIMIT", 200),
    searchText: orderId,
    includeTotalCount: true,
    eventType: "FUNERAL_LOG",
  });

  return funeralEvents?.data?.find((each) =>
    each.customer?.details?.orderIds.join().includes(orderId)
  );
};

//Checks whether Funeral Info updated in an order.
export const isOrderFuneralInfoModified = (props) => {
  const { funeralInfo, orderItem } = props;
  const {
    celebrant: { firstName, lastName },
    locations,
  } = funeralInfo;
  const { deliveryInfo, recipientInfo } = orderItem;
  const { address = {}, deliveryDate } = locations[0];

  return !isSameInfo(
    {
      firstName,
      lastName,
      ...address,
      deliveryDate: moment(deliveryDate).format("YYYY-MM-DD"),
    },
    { ...recipientInfo, ...deliveryInfo },
    funeralFields
  );
};

//get Funeral data for multiple orders if funeral info not modified.
// {"funeralName": { oderIds: ["orderId1","orderId2"], funeralInfo: {} }}
export const getFuneralWithOrderIds = (props) => {
  const {
    sendingMember,
    orderItems = [],
    response = {},
    funeralEvents,
  } = props;
  const eventsWithOrderIds = {};
  orderItems.forEach((orderItem, index) => {
    const orderItemId = response?.orderItems
      ? response?.orderItems[index]?.orderItemId
      : response?.orderId;
    const {
      deliveryInfo: { deliveryMethod },
    } = orderItem;

    const orderId =
      deliveryMethod === "FLORIST_DELIVERED"
        ? orderItemId
        : `${orderItemId}$${deliveryMethod}`;

    const funeralInfo = funeralEvents?.find(
      (each) =>
        each?.name === orderItem?.eventName &&
        each?.memberCode === sendingMember
    );
    if (!funeralEvents || !funeralInfo) return;
    if (isOrderFuneralInfoModified({ funeralInfo, orderItem })) return;

    if (eventsWithOrderIds[orderItem?.eventName]) {
      eventsWithOrderIds[orderItem.eventName]["orderIds"].push(orderId);
    } else {
      eventsWithOrderIds[orderItem.eventName] = {
        funeralInfo,
        orderIds: [orderId],
      };
    }
  });
  return eventsWithOrderIds;
};

export const updateFuneralWithOrderIds = (props) => {
  const { funeralInfo, orderIds = [], onError, orderIdToUnLink = "" } = props;
  const eventPayload = omit(funeralInfo, [
    "_id",
    "createdOn",
    "updatedOn",
    "__v",
    "startAndEndDate",
    "startTime",
    "customerInfo",
  ]);

  let clonedCustomer = cloneDeep(eventPayload.customer) || {
    details: {},
  };

  let existingOrderIds = get(clonedCustomer, "details.orderIds", []);
  if (orderIdToUnLink) {
    let index = existingOrderIds?.findIndex((x) => x.includes(orderIdToUnLink));
    if (index !== -1) {
      existingOrderIds.splice(index, 1);
    }
  } else
    set(clonedCustomer, "details.orderIds", existingOrderIds.concat(orderIds));

  request("update-event", {
    reqObj: {
      ...eventPayload,
      customer: {
        ...clonedCustomer,
      },
    },
  }).catch((error) => {
    console.log(`Failed to update Event with Order Info - ${error}`);
    onError && onError();
  });
};

export const getLocationTypeBasedOnRecipientInfo = (
  recipientInfo = {},
  isDraftOrder
) => {
  const { country, deliveryMethod, locationType, ...other } = recipientInfo;

  const hasRecipientInfo = !isDraftOrder
    ? true
    : Object.values(other).filter((val) => !!val).length > 0;

  return hasRecipientInfo ? recipientInfo?.locationType : "";
};

export const isDeliveryDateModified = (
  initialOrderItems,
  currentOrderItems
) => {
  const initialDeliveryDate = get(
    initialOrderItems[0],
    "deliveryInfo.deliveryDate",
    ""
  );
  const currentDeliveryDate = get(
    currentOrderItems[0],
    "deliveryInfo.deliveryDate",
    ""
  );

  return initialDeliveryDate !== currentDeliveryDate;
};

export const hasCreditCardPaymentMethod = (paymentMethods) => {
  paymentMethods?.some((eachPayment) => {
    const { paymentMethodType } = eachPayment;
    return ["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType);
  });
};

//Exclude to compare unnecessary props to check unsaved changes.
const excludePropsToCompare = [
  "hasMultipleShops",
  "hasCustomerInfo",
  "sendingMemberField",
  "orderChannel",
  "orderType",
  "orderOrigin",
  "savePayment",
  "createHouseAccount",
  "nameOnCard",
  "cardNumber",
  "expDate",
  "securityCode",
  "billingZip",
  "coupon",
  "isBillingAndCutomerAdddressSame",
  "refundAvailable",
  "feeRefundedAmount",
  "taxRefundedAmount",
  "isSubscription",
  "showError",
  "isEditOrder",
  "isCopyOrder",
  "isQuickSaleEnabled",
];

//Compares major properties which are modified by user for Crate/Edit order/subscription
export const checkHasUnsavedChanges = (initialVals, values) => {
  const updatedInitialVals = omit(initialVals, excludePropsToCompare);
  const updatedFormVals = omit(values, excludePropsToCompare);

  const {
    customerInfo: initialCustomerInfo,
    orderItems: initialOrderItems,
    paymentDetails: { paymentMethod: initialPaymentMethod },
    subscriptionInfo: initialSubscriptionInfo,
    ...otherInitialVals
  } = updatedInitialVals;

  const {
    lineItems: initialLineItems,
    recipientInfo: {
      county: initialCounty,
      latitude: initialLatitude,
      longitude: initialLongitude,
      deliveryMethod: initialDeliveryMethod,
      hasRecipientInfo: initialHasRecipientInfo,
      ...initialRecipientInfo
    },
    deliveryInfo: {
      deliveryZoneId: initialDeliveryZoneId,
      pickUpDateTime: initialPickUpDateTime,
      shopDayTimings: initialShopDayTimings,
      ...initialDeliveryInfo
    },
    deliveryFee: initialDeliveryFee,
  } = initialOrderItems[0];

  const {
    paymentMethodType: initialPaymentMethodType,
    paymentMethodDetails: initialPaymentMethodDetails,
  } = initialPaymentMethod[0];

  const {
    customerInfo,
    orderItems,
    paymentDetails: { paymentMethod },
    subscriptionInfo,
    ...otherFormVals
  } = updatedFormVals;

  const {
    lineItems,
    recipientInfo: {
      county,
      latitude,
      longitude,
      deliveryMethod,
      hasRecipientInfo,
      ...otherRecipientInfo
    },
    deliveryInfo: {
      deliveryZoneId,
      pickUpDateTime,
      shopDayTimings,
      ...otherDeliveryInfo
    },
    deliveryFee,
  } = orderItems[0];
  const { paymentMethodType, paymentMethodDetails } = paymentMethod[0];

  const updatedInitialLineItems = initialLineItems.map((lineItemInfo) => {
    const { isImageLoaded, category, suggestedRetailPrice, ...other } =
      lineItemInfo;
    return other;
  });

  const updatedLineItems = lineItems.map((lineItemInfo) => {
    const { isImageLoaded, category, suggestedRetailPrice, ...other } =
      lineItemInfo;
    return other;
  });

  //Returns true if anything modified.
  return (
    !isEqual(otherInitialVals, otherFormVals) ||
    Number(initialDeliveryFee) !== Number(deliveryFee) ||
    initialPaymentMethodType !== paymentMethodType ||
    !isSameInfo(initialCustomerInfo, customerInfo, customerBasicFields) ||
    !isEqual(updatedInitialLineItems, updatedLineItems) ||
    !isEqual(initialRecipientInfo, otherRecipientInfo) ||
    !isEqual(initialDeliveryInfo, otherDeliveryInfo) ||
    !isEqual(initialPaymentMethodDetails, paymentMethodDetails) ||
    !isEqual(initialSubscriptionInfo, subscriptionInfo)
  );
};

//Check is address modified or not.
export const checkIsAddressModified = (initialValues, values) => {
  const { recipientInfo: initialRecipientInfo } = get(
    initialValues,
    "orderItems.0",
    {}
  );
  const { recipientInfo } = get(values, "orderItems.0", {});
  return !isSameInfo(initialRecipientInfo, recipientInfo, [
    "addressLine1",
    "suite",
    "city",
    "state",
    "zip",
    "country",
  ]);
};

//If Date or Address is changed, show route adjustment warning
export const shouldShowRouteAdjustmentWarning = (initialValues, values) => {
  const { deliveryDate } = get(values, "orderItems.0.deliveryInfo", {});

  const { deliveryDate: initialDeliveryDate } = get(
    initialValues,
    "orderItems.0.deliveryInfo",
    {}
  );

  return (
    initialDeliveryDate !== deliveryDate ||
    checkIsAddressModified(initialValues, values)
  );
};
