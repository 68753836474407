import React, { useState, useEffect, useContext } from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { ToasterHandler, Spinner } from "components/elements";

import GoogleMapReact from "google-map-react";
import { backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import moment from "moment";

import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import Environment from "library/utils/environment";
import UserProfileStorage from "library/storage/userProfile";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  selectCitiesZipcodes,
  selectCitiesPlaceIds,
  selectReloadDeliveryZoneMap,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { setDeliveryZoneMapReload } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";

const DeliveryMap = ({ setIsEdit, screenOneRef, isEditEligible }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const citiesZipcodes = useSelector(selectCitiesZipcodes);
  const citiesplaceIds = useSelector(selectCitiesPlaceIds);
  const reloadDeliveryZoneMap = useSelector(selectReloadDeliveryZoneMap);
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const { messages, Localise } = useContext(I18NContext);

  const enableStyleOptions = {
    strokeColor: "#519342",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#519342",
    fillOpacity: 0.2,
  };
  const disableStyleOptions = {
    strokeColor: "#808080",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#808080",
    fillOpacity: 0.5,
  };
  const requiredGoogleInfo = [
    "street_number",
    "route",
    "country",
    "administrative_area_level_1",
    "locality",
    "postal_code",
  ];
  const { latitude: lat, longitude: lng } =
    UserProfileStorage.getShopLocation(shopCode);
  const googleMapsAPIKey = Environment.get(
    "GOOGLE_MAPS_API_KEY",
    "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU"
  );
  const googleMapsAPPID = Environment.get(
    "GOOGLE_MAPS_APP_ID",
    "ae81b3064704ddac"
  );
  const activeZipcodes = citiesZipcodes
    .map((item) => item.status === "Y" && item.zipCode)
    .filter(Boolean);
  const loaderHtml =
    "<style>@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }</style>" +
    "<div style='border: 2px solid #edf0f0; -webkit-animation: spin 1s linear infinite; animation: spin 1s linear infinite; border-top: 2px solid #1976d2; border-radius: 50%; width: 20px; height: 20px; margin: 5px;'></div>";
  let infoWindow = null;
  let featureLayer = null;

  useEffect(() => {
    if (mapReference && mapsReference) {
      // Create a marker on the map
      const marker = new mapsReference.Marker({
        position: { lat: Number(lat), lng: Number(lng) },
        map: mapReference,
        // title: "Click me!", We can use if we want to show some text
        icon: {
          url: IMAGES["map-shop"],
          size: new mapsReference.Size(35, 35), // Optional: Size of the marker
          scaledSize: new mapsReference.Size(35, 35), // Optional: Scale the image
          anchor: new mapsReference.Point(20, 20),
        },
      });

      // Marker click listener
      marker.addListener("click", (event) => {
        // Prevent triggering the feature layer click
        event.stop();
        const shopLocation = { latLng: new mapsReference.LatLng(lat, lng) };
        handlePlaceClick(shopLocation, true);
      });

      reloadFeatureLayer();
      featureLayer.addListener("click", handlePlaceClick);
      infoWindow = new mapsReference.InfoWindow({});
    }
  }, [mapReference, mapsReference]);

  useEffect(() => {
    mapsReference &&
      mapReference &&
      citiesZipcodes.length > 0 &&
      reloadFeatureLayer();
  }, [citiesZipcodes]);

  // Timeout is required to force unmount & mount the map to pick updated data
  useEffect(() => {
    reloadDeliveryZoneMap &&
      setTimeout(() => {
        dispatch(setDeliveryZoneMapReload(false));
      }, 2000);
  }, [reloadDeliveryZoneMap]);

  const reloadFeatureLayer = () => {
    featureLayer = mapReference.getFeatureLayer("POSTAL_CODE");
    featureLayer.style = (options) => {
      return citiesplaceIds.includes(options.feature.placeId)
        ? enableStyleOptions
        : disableStyleOptions;
    };
  };

  const updateInfoWindow = ({
    showLoader = false,
    center,
    requestParams = {},
    mapInfo = {},
    deliveryFeeResponse = {},
    showLatLng = false,
  }) => {
    const zipcode = requestParams.zip || "";
    const country = requestParams.country || "";
    const state = requestParams.state || "";
    const latitude = requestParams.latitude || "";
    const longitude = requestParams.longitude || "";

    const latLongText = (isEditEligible) => {
      return `Latitude: ${latitude} ${
        isEditEligible
          ? `<img id="editIcon" src="${IMAGES["edit"]}" width="20" height="20">`
          : ""
      } <br/> Longitude: ${longitude} <br/>`;
    };

    const content = showLoader
      ? loaderHtml
      : `<span style="font-size:small">${
          showLatLng ? latLongText(isEditEligible) : ""
        }Zip: ${zipcode}` +
        `<br/> Coverage: ${
          activeZipcodes.includes(zipcode) ? "Active" : "Inactive"
        }` +
        `<br/> Delivery Fee: $${
          activeZipcodes.includes(zipcode)
            ? deliveryFeeResponse?.deliveryFee?.localFee ?? 0
            : deliveryFeeResponse?.deliveryFee?.outsideLocalFee ?? 0
        }` +
        `<br/> City: ${mapInfo?.locality ?? ""}, ${state} ${country}` +
        "</span>";

    infoWindow.setContent(content);
    infoWindow.setPosition(center);
    infoWindow.open({
      map: mapReference,
      shouldFocus: false,
    });

    // Attach event listener to the button after the InfoWindow is opened
    mapsReference.event.addListenerOnce(infoWindow, "domready", function () {
      const editIcon = document.getElementById("editIcon");
      if (editIcon) {
        editIcon.addEventListener("click", () => {
          setIsEdit(true);
          infoWindow?.close();
          screenOneRef?.current?.scrollTo({ x: 0, y: 500 });
        });
      }
    });
  };

  const handlePlaceClick = (event, showLatLng) => {
    // Showing loader until delivery fee is fetched
    updateInfoWindow({
      showLoader: true,
      center: event.latLng,
    });
    request("google-geocode", {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
      .then((res) => {
        let mapInfo = {};
        res?.results &&
          res?.results.length > 0 &&
          res?.results[0]?.address_components.map((item) => {
            item?.types?.map((type) => {
              if (requiredGoogleInfo.includes(type)) {
                mapInfo[type] = [
                  "country",
                  "administrative_area_level_1",
                ].includes(type)
                  ? item.short_name
                  : item.long_name;
              }
            });
          });

        const requestParams = {
          latitude: lat,
          longitude: lng,
          zip: mapInfo?.postal_code,
          city: encodeURIComponent(mapInfo?.locality ?? ""),
          state: mapInfo?.administrative_area_level_1 ?? "",
          country: mapInfo?.country ?? "",
          deliveryDate: moment().utc().format("YYYY-MM-DD"),
          selectedMemberCode: shopCode,
          streetAddress:
            (mapInfo?.street_number ?? "") + " " + (mapInfo?.route ?? ""),
        };
        request("get-delivery-fee", requestParams)
          .then((resp) => {
            updateInfoWindow({
              showLoader: false,
              deliveryFeeResponse: resp,
              requestParams,
              mapInfo,
              center: event.latLng,
              showLatLng,
            });
          })
          .catch((error) => {
            infoWindow?.close();
            console.log("Failed to get delivery fee. Try again. ", error);
            ToasterHandler(
              "error",
              Localise(messages, "Something went wrong, please try again.")
            );
          });
      })
      .catch((error) => {
        infoWindow?.close();
        console.log("error while calling the google maps node api :>> ", error);
        ToasterHandler(
          "error",
          Localise(messages, "Something went wrong, please try again.")
        );
      });
  };

  return reloadDeliveryZoneMap ? (
    <View style={{ minHeight: 150 }}>
      <Spinner size="large" />
    </View>
  ) : (
    <View
      style={[
        tw("my-5"),
        {
          height: 500,
          marginHorizontal: 40,
          borderColor: backgroundColors.medium,
          borderWidth: 1,
          borderStyle: "solid",
        },
      ]}
    >
      {lat && lng ? (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: googleMapsAPIKey,
            version: "beta",
            region: "US",
          }}
          defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
          defaultZoom={13}
          onGoogleApiLoaded={({ map, maps }) => {
            setMapReference(map);
            setMapsReference(maps);
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          options={{
            mapId: googleMapsAPPID,
            clickableIcons: false,
          }}
        ></GoogleMapReact>
      ) : (
        <Text style={{ textAlign: "center" }}>
          {Localise(messages, "Invalid latitude & longitude of florist!")}
        </Text>
      )}
    </View>
  );
};

export default DeliveryMap;
