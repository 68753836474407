/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, memo } from "react";
import { View, ScrollView, Dimensions, Platform } from "react-native";
import { Text, CheckBox, Divider, Badge, Button } from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { useDispatch } from "react-redux";
import tw from "tailwind-rn";
import { theme, fonts } from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import { ListOfFilters } from "./config";
import { updateUserPreferences } from "library/sagas/ongoing/staff-listing/slice";

const ProductCatalogFilterComponent = ({
  filterOptions = ListOfFilters,
  title = "Filters",
  filterIcon,
  menuOptionWidth,
  appliedFilters,
  setAppliedFilters,
  memberCodes,
  selectedShopCode,
  isFromSubscription = false,
}) => {
  const dispatch = useDispatch();

  const selectedShop =
    selectedShopCode === "all" ? memberCodes[0] : selectedShopCode;
  const isNonCoreMember = isMHQNonCoreMember(selectedShop);
  if (!isNonCoreMember) return null;

  const { userType, proxyUser, userProfile, setUserProfile } =
    useContext(UserProfileContext);
  const { isDesktop, isSmallScreen } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);
  const [filters, setFilters] = useStateIfMounted([]);
  const isFTDUser = userType === "FTD";
  const {
    firstName: userFirstName,
    lastName: userLastName,
    email: userEmailId,
    phone: userPhone = "",
    preferences: userPreferences = [],
  } = userProfile;

  const onSelectCheckBox = (value) => {
    if (filters.includes(value)) {
      const updatedFilters = filters.filter((each) => each !== value);
      setFilters(updatedFilters);
    } else {
      setFilters([...filters, value]);
    }
  };

  const menuRef = React.useRef();
  var skipCloseEvent = false;

  const closeFilters = () => {
    skipCloseEvent = true;
    menuRef?.current?.close();
  };
  const onOpen = () => {
    skipCloseEvent = false;
  };
  const onApply = (filters) => {
    setAppliedFilters(filters);
    if (!isFTDUser && !proxyUser) {
      // Skip saving user preferences for MTAC users
      const reqPayload = {
        firstName: userFirstName,
        lastName: userLastName,
        phone: userPhone,
        email: userEmailId,
        preferences: [
          ...userPreferences.filter(
            (pref) => pref.name !== "productFiltersUserPreferences"
          ),
          {
            name: "productFiltersUserPreferences",
            value: filters.join(","),
          },
        ],
      };
      dispatch(
        updateUserPreferences({
          reqPayload,
          resolve: () => {
            setUserProfile({ ...userProfile, ...reqPayload });
          },
        })
      );
    }
  };

  useEffect(() => {
    if (appliedFilters.length !== 0) {
      setFilters(appliedFilters);
    }
  }, [appliedFilters]);

  return (
    <View style={{ marginRight: 20 }}>
      <Menu
        ref={menuRef}
        onClose={() => !skipCloseEvent && setFilters(appliedFilters)}
        onOpen={onOpen}
      >
        <MenuTrigger testID={title} accessibilityLabel={title}>
          <View
            style={tw(
              `${
                !isDesktop ? "flex-col" : "flex-row"
              } justify-around items-center`
            )}
          >
            {filterIcon}
            {isDesktop && <Text>{Localise(messages, title)} </Text>}
            {appliedFilters && appliedFilters.length > 0 && (
              <Badge
                containerStyle={[
                  {
                    right: isDesktop ? -18 : -10,
                    ...tw("mb-4"),
                    position: "absolute",
                  },
                  Platform.OS !== "web" && { top: -10 },
                ]}
                badgeStyle={{ backgroundColor: "#e5175e" }}
                value={appliedFilters.length}
                testID={"ProductBadge"}
              />
            )}
          </View>
        </MenuTrigger>
        <MenuOptions
          optionsContainerStyle={{
            width: menuOptionWidth,
            padding: 5,
            maxHeight: !isDesktop ? Dimensions.get("window").height : "80%",
            maxWidth: !isDesktop && "80%",
            marginTop: 20,
          }}
          customStyles={{
            optionsWrapper: {
              flex: 1,
            },
            optionWrapper: {
              padding: 5,
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            },
          }}
        >
          <View style={{ flex: 1 }}>
            <ScrollView
              style={{
                flexGrow: 0,
                maxHeight: !isDesktop
                  ? Dimensions.get("window").height / 2
                  : "80%",
              }}
            >
              {filters && filters.length > 0 && (
                <MenuOption
                  onSelect={() => {
                    setFilters([]);
                    setAppliedFilters([]);
                  }}
                  disableTouchable={false}
                  testID={"ClearProductFilters"}
                >
                  <View style={tw("flex flex-col w-full")}>
                    <Text style={{ ...fonts.heading4, marginVertical: 3 }}>
                      {Localise(messages, "Clear")} {Localise(messages, title)}
                    </Text>
                    <Divider />
                  </View>
                </MenuOption>
              )}
              {Object.keys(filterOptions).map((optionKey) => {
                let filteredOptions = filterOptions[optionKey];
                if (optionKey === "Filter By" && isFromSubscription) {
                  filteredOptions = filteredOptions.filter(
                    (option) => option.value !== "globalAddons"
                  );
                }
                return (
                  <CustomCheckBoxFilter
                    key={optionKey}
                    section={optionKey}
                    options={filteredOptions}
                    onPress={onSelectCheckBox}
                    isSmallScreen={isSmallScreen}
                    filters={filters}
                  />
                );
              })}
            </ScrollView>

            <View
              style={{
                flexDirection: "row",
                margin: 10,
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={[fonts.link1, tw("m-2")]}
                  onPress={() => {
                    closeFilters();
                    setFilters(appliedFilters);
                  }}
                >
                  {Localise(messages, "Close")}
                </Text>
              </View>
              <Button
                title={Localise(messages, "Apply")}
                onPress={() => {
                  closeFilters();
                  onApply(filters);
                }}
                testID={"Apply"}
                accessibilityLabel={"Apply"}
              />
            </View>
          </View>
        </MenuOptions>
      </Menu>
    </View>
  );
};

const CustomCheckBoxFilter = ({ section, options, onPress, filters }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <>
      <MenuOption onSelect={() => false} disableTouchable={true}>
        <View style={tw("flex flex-col w-full")}>
          <Text
            style={{
              ...fonts.heading4,
              marginVertical: 3,
            }}
          >
            {Localise(messages, section)}
          </Text>
          <Divider />
        </View>
      </MenuOption>

      {options.map((e, index) => {
        const selectedFilterIndex =
          (filters && filters.includes(e.value)) || false;
        return (
          <View key={index} style={{ zIndex: 0 }}>
            <MenuOption key={index} onSelect={() => false}>
              <View
                style={[
                  tw("flex flex-1 flex-row items-center"),
                  { paddingRight: 20 },
                ]}
              >
                <>
                  <CheckBox
                    containerStyle={[
                      theme.CheckBox.inputContainerStyle,
                      { paddingRight: 3 },
                    ]}
                    size={18}
                    checked={selectedFilterIndex}
                    onPress={() => onPress(e.value)}
                    testID={e.title}
                    accessibilityLabel={e.title}
                  />
                  <Text onPress={() => onPress(e.value)}>
                    {Localise(messages, e.title)}
                  </Text>
                </>
              </View>
            </MenuOption>
          </View>
        );
      })}
    </>
  );
};

export default memo(ProductCatalogFilterComponent);
