import React from "react";
import { View, Text, Platform } from "react-native";
import { Button } from "react-native-elements";

import I18NContext from "library/contexts/i18N";
import { FormField } from "components/elements/forms";
import { Accordion } from "components/elements";

import { formatPrice } from "library/utils/formatter";

import styles from "components/containers/listing-new/styles";
import { fonts, colors, theme } from "styles/theme";
import { CUSTOMER_DIRECTORY } from "library/constants";

import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";

const FullyPaidOrders = ({ fullyPaidOrders }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(5);

  return (
    <View>
      <Accordion
        title={Localise(messages, CUSTOMER_DIRECTORY.INVOICES_PAID_IN_FULL)}
        defaultOpen={false}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: "white",
          paddingRight: 2,
          paddingLeft: 2,
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        titleStyle={{
          color: colors.primary,
          ...fonts.default,
          paddingLeft: 3,
        }}
        iconStyle={{
          color: colors.primary,
        }}
      >
        {fullyPaidOrders?.length > 0 ? (
          <>
            {fullyPaidOrders
              ?.slice(0, viewMoreCounts)
              ?.map((lineItem, index) => {
                const {
                  orderId = "",
                  orderNumber = "",
                  orderTotalAmount = 0,
                } = lineItem;

                return (
                  <View key={index}>
                    <View
                      style={[
                        tw(
                          `flex flex-row justify-start pb-2 items-${
                            Platform.OS !== "web" ? "center" : "baseline"
                          }`
                        ),
                        { paddingHorizontal: 5 },
                      ]}
                    >
                      <Text
                        style={{
                          ...fonts.default,
                          ...styles.labelText,
                          width: "40%",
                        }}
                      >
                        {`Order #${orderNumber || orderId}`}
                      </Text>
                      <View style={[tw(`flex flex-row pt-2`)]}>
                        <FormField
                          autoCapitalize="none"
                          autoCorrect={false}
                          name={"orderTotalAmount"}
                          value={formatPrice(orderTotalAmount)}
                          placeholder=""
                          containerStyle={{ width: 100 }}
                          isPrice={true}
                          keyboardType="numeric"
                          iconType="material-community"
                          iconName="currency-usd"
                          iconSize={14}
                          inputContainerStyle={{
                            padding: 6,
                          }}
                          leftIconContainerStyle={{ paddingRight: 20 }}
                          testID="orderTotalAmount"
                          editable={false}
                          grayedOutOnDisable={true}
                        />
                      </View>
                      <Text
                        style={{
                          ...fonts.default,
                          ...styles.labelText,
                          paddingLeft: 10,
                        }}
                      >
                        {Localise(messages, `Balance $0.00`)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            {viewMoreCounts < fullyPaidOrders?.length && (
              <Button
                titleStyle={theme.Button.secondaryTitleStyle}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingHorizontal: 10,
                  paddingVertical: 6,
                  width: 95,
                  height: 35,
                }}
                containerStyle={{
                  alignItems: "flex-end",
                  marginHorizontal: 0,
                }}
                title={Localise(messages, "View More")}
                onPress={() => {
                  setViewMoreCounts(viewMoreCounts + 5);
                }}
                testID="view_more_paid_orders"
                accessibilityLabel="view_more_paid_orders"
              />
            )}
          </>
        ) : (
          <Text
            style={{
              paddingVertical: 10,
              paddingHorizontal: 5,
              ...fonts.default,
            }}
          >
            {Localise(messages, CUSTOMER_DIRECTORY.NO_RESULTS_FOUND)}
          </Text>
        )}
      </Accordion>
    </View>
  );
};

export default FullyPaidOrders;
