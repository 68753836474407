import React, { useContext, useEffect } from "react";
import { View, Text, Image, ActivityIndicator, Platform } from "react-native";
import { fonts, colors } from "styles/theme";
import { ToasterHandler, UploadImage } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { AppSettingsContext } from "library/contexts/appSettings";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { scrollToEndOfPage } from "library/utils/common";
import Environment from "library/utils/environment";

// eslint-disable-next-line react/display-name
const UploadImageButton = React.memo(
  ({
    index,
    recordId,
    scrollViewRef,
    tabDetailScrollRef,
    selectedActionRef,
    triggerAction,
  }) => {
    const [loading, setLoading] = useStateIfMounted(false);
    const { permissions } = useContext(AppSettingsContext);
    const orderDetailResponse = useSelector(selectRecordData);
    const orderDetails = orderDetailResponse.orderItems[index];
    const { messages: locMessages, Localise } = useContext(I18NContext);

    const {
      orderItemId,
      status: orderStatus = "",
      deliveryInfo: { deliveryMethod = "" } = {},
      designedImageUrls: uploaddesignedImageUrls = [],
    } = orderDetails;

    const designedImageDefaultValue = uploaddesignedImageUrls[0] || null;
    const isPhoneOutOrder = deliveryMethod === "PHONE_OUT";

    const hideUploadImageButton = [
      "FORFEITED",
      "CANCELLED",
      "REJECTED",
      "NEW",
    ].includes(orderStatus) || isPhoneOutOrder ;


    const fillerMemberCode = get(
      orderDetails,
      "receivingMember.memberCode",
      ""
    );
    const showUploadDesignedImage = Environment.get(
      "SHOW_UPLOAD_DESIGNED_IMAGE",
      false
    );
    const hasDesignCenter =
      hasDesignCenterFeature(permissions, fillerMemberCode) &&
      showUploadDesignedImage;

    const hasDesignedImage = !isEmpty(designedImageDefaultValue);
    const designedImageLabel = !hideUploadImageButton || hasDesignedImage;
    const showUploadButton =
      hasDesignCenter &&
      !hideUploadImageButton &&
      (orderStatus !== "DELIVERED" || !hasDesignedImage);
      

    const uploadDesignedImage = (base64Image) => {
      setLoading(true);
      const reqObj = {
        imageType: "DESIGNED",
        deliveryMethod,
        isReplaceImage: hasDesignedImage,
        images: [
          {
            imageContentType: "image/jpeg",
            imageName: "designedimage",
            imageContent: base64Image,
          },
        ],
      };

      request("upload-designed-image", {
        orderId: orderItemId,
        shopCode: fillerMemberCode,
        deliveryMethod,
        body: reqObj,
      })
        .then(() => {
          setLoading(false);
          const successMessage = hasDesignedImage
            ? Localise(locMessages, "Designed Image was replaced successfully")
            : Localise(locMessages, "Designed Image was uploaded successfully");
          ToasterHandler("success", successMessage);
          triggerAction({ action: "upload-designed-image", recordId });
        })
        .catch(() => {
          setLoading(false);
          ToasterHandler(
            "error",
            Localise(locMessages, "Upload failed. Please try again.")
          );
        });
    };

    const scrollRefs = [scrollViewRef, tabDetailScrollRef];

    useEffect(() => {
      // scrolling to end of the page after uploading designed image
      if (selectedActionRef.current === "upload-designed-image") {
        scrollToEndOfPage({ scrollRefs });
        selectedActionRef.current = "";
      }
    }, [selectedActionRef.current]);

    return (
      <>
        {hasDesignCenter || hasDesignedImage ? (
          <View>
            <View style={tw("flex flex-row flex-wrap my-1 items-center")}>
              {designedImageLabel && (
                <Text style={{ ...fonts.heading5, width: 125 }}>
                  {Localise(locMessages, "Designed Image")}
                </Text>
              )}
              <View style={tw("flex-row items-center")}>
                {showUploadButton ? (
                  <UploadImage
                    title={!hasDesignedImage ? "Upload Photo" : "Replace Photo"}
                    testID="upload_designed_image"
                    onImageSelect={(resp) => {
                      if (resp) {
                        const base64Image =
                          Platform.OS === "web"
                            ? resp
                            : resp?.assets && resp?.assets[0]?.base64;
                        base64Image && uploadDesignedImage(base64Image);
                      }
                    }}
                  />
                ) : null}
              </View>

              {hasDesignedImage && (
                <View style={{ width: 100 }}>
                  <Image
                    style={{
                      width: 104,
                      height: 104,
                      marginTop: 10,
                    }}
                    source={{ uri: designedImageDefaultValue }}
                  />
                </View>
              )}
              {loading && (
                <ActivityIndicator
                  style={{ marginLeft: 10 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
          </View>
        ) : null}
      </>
    );
  }
);

UploadImageButton.displayName = UploadImageButton;
export default UploadImageButton;
