import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Tooltip } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { fetchDistanceInMiles } from "library/sagas/ongoing/global-data/slice";
import { selectDistanceInMiles } from "library/sagas/ongoing/global-data/selector";
import UserProfileStorage from "library/storage/userProfile";

import { colors } from "styles/theme";
import tw from "tailwind-rn";

export const Distance = ({
  memberCode = "",
  recipientInfo = {},
  distanceText = "",
  isAvsVerified = false,
  customStyles = {},
  customContainerStyles = {},
  calculateDistance = false,
  deliveryMethod = "",
  isCopy = false,
}) => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const isSelectDistanceInMiles = useSelector(selectDistanceInMiles);

  const shopLocations = UserProfileStorage.getAllShopLocation();
  const shopLocation = shopLocations[memberCode] || {};

  const isFetchGoogleDistance = deliveryMethod === "FLORIST_DELIVERED";
  const isDisplayZeroMiles = ["WALK_IN", "STORE_PICKUP"].includes(
    deliveryMethod
  );

  const distanceInMiles = isFetchGoogleDistance
    ? isSelectDistanceInMiles
    : isDisplayZeroMiles
    ? "0.0 miles"
    : "";

  const {
    addressLine1 = "",
    city = "",
    country = "",
    state = "",
    zip = "",
  } = recipientInfo;

  const getDistanceInMiles = () => {
    dispatch(
      fetchDistanceInMiles({
        memberCode: memberCode,
        destination: `${addressLine1} ${country} ${city} ${state} ${zip}`,
        origin: `${shopLocation.addressLine1} ${shopLocation.city} ${shopLocation.countryCode} ${shopLocation.state} ${shopLocation.zip}`,
      })
    );
  };

  useEffect(() => {
    !isCopy && calculateDistance && getDistanceInMiles();
  }, [
    addressLine1,
    city,
    country,
    state,
    zip,
    shopLocation,
    calculateDistance,
  ]);

  return (
    <>
      {isAvsVerified && (distanceText || distanceInMiles) ? (
        <View
          style={[
            tw("flex flex-wrap items-start my-3 pr-3"),
            customContainerStyles,
          ]}
        >
          <Tooltip text={Localise(messages, "Drive Miles")}>
            <Text
              style={{
                color: colors.primary2,
                borderColor: colors.primary2,
                borderWidth: 1,
                paddingLeft: 5,
                padding: 5,
                borderRadius: 10,
                fontSize: 14,
                ...customStyles,
              }}
            >
              {Localise(messages, `${distanceText || distanceInMiles}`)}
            </Text>
          </Tooltip>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};
