import React, { useContext, useEffect } from "react";
import { View, ScrollView, Platform, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import { Text, Image, Icon } from "react-native-elements";
import tw from "tailwind-rn";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { fonts, backgroundColors, colors } from "styles/theme";
import {
  Form,
  SubmitButton,
  FormFieldPicker,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { Accordion, PrintIframeRenderer } from "components/elements";
import {
  initialValues,
  validationSchema,
  rangeTypes,
  salesByPromoReportTypes,
  businessSnapshotReportTypes,
  getEligibleMonths,
  getYears,
  filterTypes,
  printBy,
  paymentHistoryReportOrStatments,
  supportedFileFormats,
} from "./config";
import { onFormSubmit, showDurationReportsList } from "./helper";
import IMAGES from "static/assets/images";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import moment from "moment";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import Environment from "library/utils/environment";
import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";

const OccasionsField = (props) => {
  const { values, setValues, Localise, messages, allLabel } = props;
  const finalValues = cloneDeep(values);
  const [searchQuery, setSearchQuery] = useStateIfMounted("");
  return (
    <FormFieldAutoComplete
      name="selectedOccasions"
      data={values.occasions.filter((code) =>
        searchQuery
          ? code.value.includes(searchQuery?.toUpperCase())
          : code.value
      )}
      initialDataLength={values.occasions.length}
      showOnFocus={true}
      setFocusBack={true}
      placeholder={
        values.selectedOccasions.length
          ? `${values.selectedOccasions.length} ${Localise(
              messages,
              Localise(messages, "Selected")
            )}`
          : Localise(messages, "Select an Occasion")
      }
      listDisplayValues={["label"]}
      outerContainerStyle={{
        marginTop: 5,
      }}
      isMultiSelect={true}
      onSelect={(selectedValue) => {
        const { selectedOccasions } = finalValues;

        if (selectedValue.label === allLabel) {
          if (selectedOccasions.length === selectedValue.value.length) {
            set(finalValues, "selectedOccasions", []);
          } else {
            set(finalValues, "selectedOccasions", [...selectedValue.value]);
          }
        } else {
          const index = selectedOccasions.indexOf(selectedValue.value);
          const isSelected = index >= 0;
          isSelected
            ? selectedOccasions.splice(index, 1)
            : selectedOccasions.push(selectedValue.value);
        }
        searchQuery && setSearchQuery("");
        setValues(finalValues);
      }}
      onChangeText={(text) => {
        const arr = text.split(",");
        setSearchQuery(arr[arr.length - 1]);
      }}
    />
  );
};
//eslint-disable-next-line
const UserAlert = React.memo(({ message }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 5,
      }}
    >
      <Icon
        name="information-outline"
        type="material-community"
        size={14}
        color={colors.button}
      />
      <Text
        style={{
          marginLeft: 5,
        }}
        testID="information-report"
        accessibilityLabel="information-report"
      >
        {Localise(messages, message)}
      </Text>
    </View>
  );
});
/* eslint-disable react-hooks/rules-of-hooks */
export default function ReportsScreen() {
  const selectedShopCode = useSelector(selectDashboardShopCode);
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const [reportTypes, setReportTypes] = useStateIfMounted([]);
  const [memberQuery, setMemberQuery] = useStateIfMounted("");
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const isMHQNonCoreUser = isMHQNonCoreMember(selectedShopCode);

  const currentYear = Number(
    Environment.get("CURRENT_YEAR", "") || moment().format("YYYY")
  );

  const minYearsOfOccasions = Number(Environment.get("MIN_YEARS", "") || 2);

  const { permissions } = React.useContext(AppSettingsContext);
  const allPermissionsShopCodes = Object.keys(permissions);

  const allLabel = Localise(messages, "All");

  //TODO: make config for all reports permissions
  const entitlementKeywordConfig = {
    "advance-optimization-usage-report":
      Entitlements.REPORTS_PAGE.ADVANCE_OPTIMIZATION_USAGE_REPORT,
    "business-snapshot-report":
      Entitlements.REPORTS_PAGE.BUSINESS_SNAPSHOT_REPORT,
    "business-snapshot-report-by-type":
      Entitlements.REPORTS_PAGE.BUSINESS_SNAPSHOT_REPORT_BY_TYPE,
  };

  const hasReportPermission = (entitlementKeyword) => {
    return allPermissionsShopCodes.some((memberCode) =>
      permissions[memberCode][Entitlements.REPORTS]?.includes(
        entitlementKeyword
      )
    );
  };

  useEffect(() => {
    request("get-report-types", {}, undefined, true).then((res) => {
      const result =
        res &&
        res.report_types.map((obj) => {
          if (
            [
              "order-detail-report",
              "customer-report",
              "house-account-report",
              "sales-tax-report",
              "occasion-reminder",
              "sales-by-promo-code",
              "product-sales-report",
              "sales-summary-report",
              "product-comparison-report",
              "aged-analysis-report",
              "credit-card-settlement-report",
            ].includes(obj.value)
          ) {
            if (isMHQNonCoreUser) {
              return {
                ...obj,
                label: Localise(messages, obj.label),
              };
            }
          } else if (
            ["advance-optimization-usage-report"].includes(obj.value) ||
            ["business-snapshot-report"].includes(obj.value) ||
            ["business-snapshot-report-by-type"].includes(obj.value)
          ) {
            /* Show Report only if atleast 1 shop has permission for reportType */
            /* We can implement same for other reports */
            if (hasReportPermission(entitlementKeywordConfig[obj.value])) {
              return {
                ...obj,
                label: Localise(messages, obj.label),
              };
            }
          } else {
            return {
              ...obj,
              label: Localise(messages, obj.label),
            };
          }
        });
      setReportTypes(result.filter(Boolean));
    });
  }, []);

  return (
    <>
      <ScrollView>
        <View style={tw(`${isDesktop ? "w-6/12" : "w-full"} mt-2`)}>
          <View
            style={{
              borderColor: colors.light,
              borderWidth: 1,
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.1,
              shadowRadius: 5,
              borderRadius: 5,
            }}
          >
            <View style={[tw("flex flex-row justify-between p-2 mr-2 ")]}>
              <Accordion
                defaultOpen={true}
                labelStyle={{
                  backgroundColor: backgroundColors.navBar,
                  borderColor: colors.grayScaleLight,
                  borderWidth: 1,
                  marginRight: 5,
                  paddingVertical: 10,
                }}
                handleOnPress={() => {}}
                contentStyle={{
                  borderColor: colors.grayScaleLight,
                  borderWidth: 1,
                  borderTopWidth: 1,
                  marginRight: 5,
                }}
                openStyle={{
                  borderWidth: 1,
                  borderBottomWidth: 0,
                }}
                containerStyle={{
                  width: "100%",
                  margin: 5,
                }}
                headerContent={(isAccordionOpen) => (
                  <>
                    <Text style={fonts.heading3}>
                      {Localise(messages, "Create Report")}
                    </Text>
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      source={
                        IMAGES[
                          isAccordionOpen
                            ? "expanded-section"
                            : "collapsed-section"
                        ]
                      }
                    />
                  </>
                )}
              >
                <Form
                  initialValues={initialValues()}
                  onSubmit={(values, formikBag) =>
                    onFormSubmit(
                      values,
                      formikBag,
                      shopNames,
                      messages,
                      Localise,
                      setValidationOnChange,
                      reportTypes
                    )
                  }
                  formReinitialize={false}
                  validateOnChange={validationOnChange}
                  validateOnBlur={validationOnChange}
                  validationSchema={validationSchema(messages, Localise)}
                  render={({
                    values,
                    setValues,
                    submitCount,
                    setFieldValue,
                  }) => {
                    const [printByOptions, setPrintByOptions] =
                      useStateIfMounted(printBy);
                    const [filterByOptions, setFilterByOptions] =
                      useStateIfMounted(filterTypes);

                    useEffect(() => {
                      if (submitCount > 0) {
                        setValidationOnChange(true);
                      }
                    }, [submitCount]);

                    useEffect(() => {
                      setFilterByOptions(
                        values.printBy !== "Cash"
                          ? filterTypes
                          : filterTypes.filter(
                              (obj) => obj.value === "orderDate"
                            )
                      );
                    }, [values.printBy]);

                    useEffect(() => {
                      setPrintByOptions(
                        values.filterBy !== "deliveryDate"
                          ? printBy
                          : printBy.filter((obj) => obj.value === "Accrual")
                      );
                    }, [values.filterBy]);

                    const eligibleMonths = getEligibleMonths();
                    const { reportType } = values;
                    const showDurationText =
                      showDurationReportsList.includes(reportType);
                    const finalValues = cloneDeep(values);
                    const selectedCount = values.selectedMemberCodes.length;
                    const dateRangeType =
                      values?.rangeType === "orderDate"
                        ? "Sale Date Range"
                        : values?.rangeType === "deliveryDate"
                        ? "Delivery Date Range"
                        : "";

                    const filterRangeType =
                      values?.filterBy === "orderDate"
                        ? "Order Date Range"
                        : values?.filterBy === "deliveryDate"
                        ? "Delivery Date Range"
                        : "";

                    useEffect(() => {
                      setMemberQuery("");
                    }, [values.selectedMemberCodes]);

                    useEffect(() => {
                      if (reportType !== "") {
                        // clearing selected member codes when user changed report type as member codes may change on report type change
                        setFieldValue("selectedMemberCodes", []);
                        setFieldValue("startDate", "");
                        setFieldValue("endDate", "");
                        setFieldValue("comparisonStartDate", "");
                        setFieldValue("comparisonEndDate", "");
                        setFieldValue("selectedOccasions", []);
                        setFieldValue(
                          "minDate",
                          moment("2021-08-01", "YYYY-MM-DD").toDate()
                        );
                        setFieldValue("reportFormat", "xlsx");
                        setFieldValue("detailOrSummaryReport", "");
                        setFieldValue("businessAnalysisType", "");
                        setFieldValue("paymentHistoryReportOrStatments", "");
                        setFieldValue("month", "");
                        setFieldValue("year", "");

                        let entitlementKeyword = "";
                        if (reportType === "order-summary-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.ORDER_SUMMARY;

                          setFieldValue("maxDate", "");
                          setFieldValue("rangeType", "deliveryDate");
                        } else if (reportType === "customer-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.CUSTOMER_DIRECTORY;
                        } else if (reportType === "product-sales-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.PRODUCT_SALES;

                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        } else if (reportType === "product-comparison-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.PRODUCT_COMPARISON;

                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        } else if (reportType === "order-detail-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.ORDER_DETAIL;

                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        } else if (reportType === "house-account-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.HOUSE_ACCOUNT_STATEMENTS;
                        } else if (reportType === "aged-analysis-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.AGED_ANALYSIS_REPORT;
                        } else if (reportType === "sales-tax-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.SALES_TAX_REPORTS;
                        } else if (reportType === "occasion-reminder") {
                          setFieldValue(
                            "minDate",
                            moment()
                              .subtract(minYearsOfOccasions, "years")
                              .toDate()
                          );
                          if (values.occasions.length) return;
                          request("get-occasions", {}, undefined, true).then(
                            (res) => {
                              if (res) {
                                const eligibleOccasions = res.occasions.filter(
                                  (each) =>
                                    !["Sympathy", "Other"].includes(each.label)
                                );
                                setFieldValue("occasions", [
                                  {
                                    label: Localise(messages, "All"),
                                    value: eligibleOccasions.map(
                                      (occ) => occ.value
                                    ),
                                  },
                                  ...eligibleOccasions,
                                ]);
                              }
                            }
                          );
                        } else if (reportType === "sales-by-promo-code") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.SALES_BY_PROMO_CODE;
                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                          setFieldValue(
                            "minDate",
                            moment()
                              .subtract(minYearsOfOccasions, "years")
                              .toDate()
                          );
                        } else if (reportType === "sales-summary-report") {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE.SALES_SUMMARY;

                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        } else if (
                          reportType === "advance-optimization-usage-report"
                        ) {
                          entitlementKeyword =
                            entitlementKeywordConfig[reportType];
                          setFieldValue("maxDate", null);
                        } else if (
                          reportType === "credit-card-settlement-report"
                        ) {
                          entitlementKeyword =
                            Entitlements.REPORTS_PAGE
                              .CREDIT_CARD_SETTLEMENT_REPORT;
                          setFieldValue("maxDate", moment().toDate());
                        } else if (reportType === "business-snapshot-report") {
                          entitlementKeyword =
                            entitlementKeywordConfig[reportType];
                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        } else if (
                          reportType === "business-snapshot-report-by-type"
                        ) {
                          entitlementKeyword =
                            entitlementKeywordConfig[reportType];
                          setFieldValue(
                            "maxDate",
                            moment().subtract(1, "days").toDate()
                          );
                        }

                        let shopCodes = [];
                        allPermissionsShopCodes.map((memberCode) => {
                          if (
                            permissions[memberCode][
                              Entitlements.REPORTS
                            ]?.includes(entitlementKeyword) ||
                            reportType === "sales-by-promo-code"
                          ) {
                            shopCodes.push(memberCode);
                          }
                        });

                        let shopsList = [];
                        if (shopCodes.length > 1) {
                          if (reportType !== "product-comparison-report")
                            shopsList = [
                              {
                                label: Localise(messages, "All"),
                                value: shopCodes,
                              },
                            ];
                        } else {
                          setFieldValue(
                            "selectedMemberCodes",
                            shopCodes.length > 0 ? [shopCodes[0]] : []
                          );
                        }

                        shopCodes.map((code) =>
                          shopsList.push({
                            label: `${code} ${shopNames[code] || ""}`,
                            value: code,
                          })
                        );

                        setFieldValue("allShopCodes", shopsList);
                      }

                      if (reportType !== "order-summary-report")
                        setFieldValue("rangeType", "");

                      setFieldValue("filterBy", "");
                    }, [reportType]);

                    return (
                      <>
                        {isDesktop ? (
                          <View
                            style={[
                              tw("flex flex-col mb-2 p-4"),
                              {
                                width: "75%",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <View
                              style={{
                                ...tw("flex-row"),
                                width: "100%",
                                marginBottom: 4,
                                zIndex: -1,
                              }}
                            >
                              <View
                                style={{
                                  width: "30%",
                                  justifyContent: "center",
                                }}
                              >
                                <Text style={[fonts.heading4]}>
                                  {Localise(messages, "Report Type")}
                                </Text>
                              </View>
                              <View
                                style={{
                                  ...tw("flex-row"),
                                  width: "70%",
                                }}
                              >
                                <FormFieldPicker
                                  placeholder={{ label: "Select Report Type" }}
                                  containerStyle={{
                                    width: "100%",
                                  }}
                                  data={reportTypes}
                                  name="reportType"
                                  testID="reportType"
                                  accessibilityLabel="reportType"
                                />
                                <PrintIframeRenderer testID="reports" />
                              </View>
                            </View>
                            {reportType?.length ? (
                              <>
                                {values.allShopCodes.length > 1 &&
                                  [
                                    "order-summary-report",
                                    "order-detail-report",
                                    "house-account-report",
                                    "sales-tax-report",
                                    "sales-by-promo-code",
                                    "product-sales-report",
                                    "sales-summary-report",
                                    "aged-analysis-report",
                                    "advance-optimization-usage-report",
                                    "credit-card-settlement-report",
                                    "business-snapshot-report",
                                    "business-snapshot-report-by-type",
                                  ].includes(reportType) && (
                                    <View
                                      style={{
                                        ...tw("flex-row"),
                                        marginBottom: 4,
                                        width: "100%",
                                        zIndex: 1,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "30%",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Text style={[fonts.heading4]}>
                                          {Localise(messages, "Select Shop")}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "70%",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <FormFieldAutoComplete
                                          name="selectedMemberCodes"
                                          data={values.allShopCodes.filter(
                                            (code) =>
                                              code.label
                                                .toLowerCase()
                                                .includes(
                                                  memberQuery.toLowerCase()
                                                )
                                          )}
                                          initialDataLength={
                                            values.allShopCodes.length
                                          }
                                          showOnFocus={true}
                                          setFocusBack={true}
                                          placeholder={
                                            selectedCount
                                              ? `${selectedCount} ${Localise(
                                                  messages,
                                                  "Selected"
                                                )}`
                                              : "Select Shop Codes"
                                          }
                                          listDisplayValues={["label"]}
                                          outerContainerStyle={{
                                            zIndex: 1,
                                            width: isMobile ? "100%" : "100%",
                                          }}
                                          isMultiSelect={true}
                                          onSelect={(selectedValue) => {
                                            const { selectedMemberCodes } =
                                              finalValues;

                                            if (
                                              selectedValue.label === allLabel
                                            ) {
                                              if (
                                                selectedMemberCodes.length ===
                                                selectedValue.value.length
                                              ) {
                                                set(
                                                  finalValues,
                                                  "selectedMemberCodes",
                                                  []
                                                );
                                              } else {
                                                set(
                                                  finalValues,
                                                  "selectedMemberCodes",
                                                  [...selectedValue.value]
                                                );
                                              }
                                            } else {
                                              const index =
                                                selectedMemberCodes.indexOf(
                                                  selectedValue.value
                                                );
                                              const isSelected = index >= 0;
                                              isSelected
                                                ? selectedMemberCodes.splice(
                                                    index,
                                                    1
                                                  )
                                                : selectedMemberCodes.push(
                                                    selectedValue.value
                                                  );
                                            }
                                            setValues(finalValues);
                                          }}
                                          onChangeText={(text) => {
                                            const arr = text.split(",");
                                            setMemberQuery(arr[arr.length - 1]);
                                          }}
                                        />
                                      </View>
                                    </View>
                                  )}
                                {values.allShopCodes.length > 1 &&
                                  ["product-comparison-report"].includes(
                                    reportType
                                  ) && (
                                    <View
                                      style={{
                                        ...tw("flex-row"),
                                        marginBottom: 4,
                                        width: "100%",
                                        zIndex: 3,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "30%",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Text style={[fonts.heading4]}>
                                          {Localise(messages, "Select Shop")}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "70%",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <FormFieldPicker
                                          name="selectedMemberCodes"
                                          data={values.allShopCodes}
                                          containerStyle={{
                                            width: "100%",
                                          }}
                                          placeholder={{
                                            label: "Select Shop Code",
                                          }}
                                        />
                                      </View>
                                    </View>
                                  )}

                                {[
                                  "order-summary-report",
                                  "order-detail-report",
                                  "product-sales-report",
                                  "sales-summary-report",
                                  "product-comparison-report",
                                  "business-snapshot-report",
                                  "business-snapshot-report-by-type",
                                ].includes(reportType) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Range Type")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Range Type",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={rangeTypes}
                                        name="rangeType"
                                        testID="rangeType"
                                        accessibilityLabel="rangeType"
                                        disabled={
                                          reportType === "order-summary-report"
                                        }
                                        onChange={(val) => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");

                                          if (val === "orderDate") {
                                            setFieldValue(
                                              "maxDate",
                                              moment()
                                                .subtract(1, "days")
                                                .toDate()
                                            );
                                          } else if (val === "deliveryDate") {
                                            setFieldValue("maxDate", "");
                                          }
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}

                                {[
                                  "sales-by-promo-code",
                                  "sales-tax-report",
                                  "credit-card-settlement-report",
                                  "aged-analysis-report",
                                ].includes(reportType) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(
                                          messages,
                                          "Detail or Summary Report"
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label:
                                            "Select Detail or Summary Report",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={salesByPromoReportTypes}
                                        name="detailOrSummaryReport"
                                        testID="detailOrSummaryReport"
                                        accessibilityLabel="detailOrSummaryReport"
                                        onChange={() => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {["business-snapshot-report-by-type"].includes(
                                  reportType
                                ) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Analysis Type")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Analysis Type",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={businessSnapshotReportTypes}
                                        name="businessAnalysisType"
                                        testID="businessAnalysisType"
                                        accessibilityLabel="businessAnalysisType"
                                        onChange={() => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {reportType === "sales-tax-report" && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Print By")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Print By",
                                          value: "",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={printByOptions}
                                        name="printBy"
                                        testID="printBy"
                                        accessibilityLabel="printBy"
                                      />
                                    </View>
                                  </View>
                                )}

                                {reportType === "sales-tax-report" && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Filter By")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Filter By",
                                          value: "",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={filterByOptions}
                                        name="filterBy"
                                        testID="filterBy"
                                        accessibilityLabel="filterBy"
                                        onChange={(val) => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");

                                          if (val === "orderDate") {
                                            setFieldValue(
                                              "maxDate",
                                              moment()
                                                .subtract(1, "days")
                                                .toDate()
                                            );
                                          } else if (val === "deliveryDate") {
                                            setFieldValue("maxDate", "");
                                          }
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {reportType === "house-account-report" && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(
                                          messages,
                                          "Payment History or Statements"
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label:
                                            "Select Payment History or Statements",
                                          value: "",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={paymentHistoryReportOrStatments}
                                        name="paymentHistoryReportOrStatments"
                                        testID="paymentHistoryReportOrStatments"
                                        accessibilityLabel="paymentHistoryReportOrStatments"
                                        onChange={() => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");
                                          setFieldValue("month", "");
                                          setFieldValue("year", "");
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {values.paymentHistoryReportOrStatments ===
                                  "HA_statements" && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Month") +
                                          " & " +
                                          Localise(messages, "Year")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Month",
                                        }}
                                        containerStyle={{
                                          width: "50%",
                                        }}
                                        data={eligibleMonths}
                                        name="month"
                                        testID="month"
                                        accessibilityLabel="month"
                                      />
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Year",
                                        }}
                                        containerStyle={{
                                          width: "50%",
                                        }}
                                        data={getYears(currentYear)}
                                        name="year"
                                        testID="year"
                                        accessibilityLabel="year"
                                      />
                                    </View>
                                  </View>
                                )}
                                {([
                                  "order-summary-report",
                                  "order-detail-report",
                                  "sales-tax-report",
                                  "occasion-reminder",
                                  "sales-by-promo-code",
                                  "product-sales-report",
                                  "sales-summary-report",
                                  "product-comparison-report",
                                  "advance-optimization-usage-report",
                                  "credit-card-settlement-report",
                                  "business-snapshot-report",
                                  "business-snapshot-report-by-type",
                                ].includes(reportType) ||
                                  values.paymentHistoryReportOrStatments ===
                                    "payment_HistoryReport") && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      zIndex:
                                        reportType ===
                                        "product-comparison-report"
                                          ? 2
                                          : -2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(
                                          messages,
                                          reportType ===
                                            "advance-optimization-usage-report"
                                            ? "Optimization Date Range"
                                            : dateRangeType ||
                                                filterRangeType ||
                                                "Date Range"
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...tw("flex-row"),
                                        width: "70%",
                                        zIndex: 30,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <FormFieldDatePicker
                                        dateFormat={
                                          Platform.OS === "web"
                                            ? "MM/dd/yyyy"
                                            : "MM/DD/YYYY"
                                        }
                                        dateValueFormat="YYYY-MM-DD"
                                        name="startDate"
                                        placeholder="Start Date"
                                        containerStyle={{
                                          paddingLeft: 0,
                                          marginLeft: 0,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          width: "49%",
                                          zIndex: 20,
                                        }}
                                        alignIconRight={true}
                                        iconName="calendar"
                                        iconType="font-awesome"
                                        minDate={values.minDate}
                                        maxDate={
                                          (values?.rangeType === "orderDate" ||
                                            values?.filterBy === "orderDate") &&
                                          [
                                            "order-detail-report",
                                            "sales-summary-report",
                                            "sales-tax-report",
                                            "business-snapshot-report",
                                          ].includes(reportType)
                                            ? moment().toDate() // Added this for updating max date to today's date
                                            : values.maxDate
                                        }
                                        testID="startDate"
                                        accessibilityLabel="startDate"
                                      />
                                      <FormFieldDatePicker
                                        dateFormat={
                                          Platform.OS === "web"
                                            ? "MM/dd/yyyy"
                                            : "MM/DD/YYYY"
                                        }
                                        dateValueFormat="YYYY-MM-DD"
                                        name="endDate"
                                        placeholder="End Date"
                                        containerStyle={{
                                          paddingLeft: 0,
                                          marginLeft: 0,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          width: "49%",
                                          zIndex: 10,
                                        }}
                                        alignIconRight={true}
                                        iconName="calendar"
                                        iconType="font-awesome"
                                        minDate={values.minDate}
                                        maxDate={
                                          (values?.rangeType === "orderDate" ||
                                            values?.filterBy === "orderDate") &&
                                          [
                                            "order-detail-report",
                                            "sales-summary-report",
                                            "sales-tax-report",
                                            "business-snapshot-report",
                                            "business-snapshot-report-by-type",
                                          ].includes(reportType)
                                            ? moment().toDate() // Added this for updating max date to today's date
                                            : values.maxDate
                                        }
                                        testID="endDate"
                                        accessibilityLabel="endDate"
                                      />
                                    </View>
                                  </View>
                                )}

                                {["product-comparison-report"].includes(
                                  reportType
                                ) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      zIndex: -2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(
                                          messages,
                                          "Comparison Date Range"
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...tw("flex-row"),
                                        width: "70%",
                                        zIndex: 30,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <FormFieldDatePicker
                                        dateFormat={
                                          Platform.OS === "web"
                                            ? "MM/dd/yyyy"
                                            : "MM/DD/YYYY"
                                        }
                                        dateValueFormat="YYYY-MM-DD"
                                        name="comparisonStartDate"
                                        placeholder="Start Date"
                                        containerStyle={{
                                          paddingLeft: 0,
                                          marginLeft: 0,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          width: "49%",
                                          zIndex: 20,
                                        }}
                                        alignIconRight={true}
                                        iconName="calendar"
                                        iconType="font-awesome"
                                        minDate={values.minDate}
                                        maxDate={values.maxDate}
                                        testID="comparisonStartDate"
                                        accessibilityLabel="comparisonStartDate"
                                      />
                                      <FormFieldDatePicker
                                        dateFormat={
                                          Platform.OS === "web"
                                            ? "MM/dd/yyyy"
                                            : "MM/DD/YYYY"
                                        }
                                        dateValueFormat="YYYY-MM-DD"
                                        name="comparisonEndDate"
                                        placeholder="End Date"
                                        containerStyle={{
                                          paddingLeft: 0,
                                          marginLeft: 0,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          width: "49%",
                                          zIndex: 10,
                                        }}
                                        alignIconRight={true}
                                        iconName="calendar"
                                        iconType="font-awesome"
                                        minDate={values.minDate}
                                        maxDate={values.maxDate}
                                        testID="comparisonEndDate"
                                        accessibilityLabel="comparisonEndDate"
                                      />
                                    </View>
                                  </View>
                                )}
                                {reportType === "occasion-reminder" && (
                                  <View
                                    style={{
                                      zIndex: -5,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        width: "30%",
                                      }}
                                    >
                                      {Localise(messages, "Occasion Type")}
                                    </Text>
                                    <View style={{ width: "70%" }}>
                                      <OccasionsField
                                        values={values}
                                        setValues={setValues}
                                        Localise={Localise}
                                        messages={messages}
                                        allLabel={allLabel}
                                      />
                                    </View>
                                  </View>
                                )}
                                {[
                                  "credit-card-settlement-report",
                                  "aged-analysis-report",
                                  "business-snapshot-report-by-type",
                                ].includes(reportType) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -3,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Report Format")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeHolderExists={false}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={supportedFileFormats}
                                        name="reportFormat"
                                        testID="reportFormat"
                                        accessibilityLabel="reportFormat"
                                      />
                                    </View>
                                  </View>
                                )}
                                <View
                                  style={[
                                    tw("flex flex-row mt-3"),
                                    {
                                      flex: 1,
                                      justifyContent: showDurationText
                                        ? "space-between"
                                        : "flex-end",
                                      paddingRight: 5,
                                      zIndex: -10,
                                    },
                                  ]}
                                >
                                  {showDurationText && (
                                    <UserAlert
                                      message={
                                        "This report updates every 90 minutes"
                                      }
                                    />
                                  )}
                                  <TouchableOpacity style={{ marginLeft: 0 }}>
                                    <SubmitButton
                                      containerStyle={{
                                        width: 150,
                                        margin: 2,
                                      }}
                                      title={"Download"}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </>
                            ) : null}
                          </View>
                        ) : (
                          <View style={[tw("flex flex-col p-2 mb-2")]}>
                            <View>
                              <Text
                                style={{ ...fonts.heading4, paddingLeft: 5 }}
                              >
                                {Localise(messages, "Report Type")}
                              </Text>
                              <FormFieldPicker
                                placeholder={{ label: "Select Report Type" }}
                                containerStyle={{
                                  width: "100%",
                                  marginTop: 5,
                                }}
                                data={reportTypes}
                                name="reportType"
                                testID="reportType"
                                accessibilityLabel="reportType"
                              />
                            </View>

                            {reportType?.length ? (
                              <>
                                {values.allShopCodes.length > 1 &&
                                  [
                                    "order-summary-report",
                                    "order-detail-report",
                                    "house-account-report",
                                    "sales-tax-report",
                                    "sales-by-promo-code",
                                    "product-sales-report",
                                    "sales-summary-report",
                                    "aged-analysis-report",
                                    "advance-optimization-usage-report",
                                    "credit-card-settlement-report",
                                    "business-snapshot-report",
                                    "business-snapshot-report-by-type",
                                  ].includes(reportType) && (
                                    <View style={{ zIndex: 50 }}>
                                      <Text
                                        style={{
                                          ...fonts.heading4,
                                          paddingLeft: 5,
                                        }}
                                      >
                                        {Localise(messages, "Select Shop")}
                                      </Text>
                                      <FormFieldAutoComplete
                                        name="selectedMemberCodes"
                                        data={values.allShopCodes.filter(
                                          (code) =>
                                            code.label
                                              .toLowerCase()
                                              .includes(
                                                memberQuery.toLowerCase()
                                              )
                                        )}
                                        initialDataLength={
                                          values.allShopCodes.length
                                        }
                                        showOnFocus={true}
                                        setFocusBack={true}
                                        placeholder={
                                          selectedCount
                                            ? `${selectedCount} ${Localise(
                                                messages,
                                                "Selected"
                                              )}`
                                            : "Select Shop Codes"
                                        }
                                        listDisplayValues={["label"]}
                                        outerContainerStyle={{
                                          zIndex: 40,
                                          width: "100%",
                                          marginTop: 5,
                                        }}
                                        isMultiSelect={true}
                                        onSelect={(selectedValue) => {
                                          const { selectedMemberCodes } =
                                            finalValues;
                                          if (
                                            selectedValue.label === allLabel
                                          ) {
                                            if (
                                              selectedMemberCodes.length ===
                                              selectedValue.value.length
                                            ) {
                                              set(
                                                finalValues,
                                                "selectedMemberCodes",
                                                []
                                              );
                                            } else {
                                              set(
                                                finalValues,
                                                "selectedMemberCodes",
                                                [...selectedValue.value]
                                              );
                                            }
                                          } else {
                                            const index =
                                              selectedMemberCodes.indexOf(
                                                selectedValue.value
                                              );
                                            const isSelected = index >= 0;
                                            isSelected
                                              ? selectedMemberCodes.splice(
                                                  index,
                                                  1
                                                )
                                              : selectedMemberCodes.push(
                                                  selectedValue.value
                                                );
                                          }
                                          setValues(finalValues);
                                        }}
                                        onChangeText={(text) => {
                                          const arr = text.split(",");
                                          setMemberQuery(arr[arr.length - 1]);
                                        }}
                                      />
                                    </View>
                                  )}

                                {values.allShopCodes.length > 1 &&
                                  ["product-comparison-report"].includes(
                                    reportType
                                  ) && (
                                    <View
                                      style={{
                                        ...tw("flex-col"),
                                        marginBottom: 4,
                                        width: "100%",
                                        zIndex: 3,
                                      }}
                                    >
                                      <Text
                                        style={[
                                          fonts.heading4,
                                          { paddingLeft: 5 },
                                        ]}
                                      >
                                        {Localise(messages, "Select Shop")}
                                      </Text>

                                      <FormFieldPicker
                                        name="selectedMemberCodes"
                                        data={values.allShopCodes}
                                        containerStyle={{
                                          width: "100%",
                                          marginTop: 5,
                                        }}
                                        placeholder={{
                                          label: "Select ShopCode",
                                        }}
                                      />
                                    </View>
                                  )}
                                {[
                                  "order-summary-report",
                                  "order-detail-report",
                                  "product-sales-report",
                                  "sales-summary-report",
                                  "product-comparison-report",
                                  "business-snapshot-report",
                                  "business-snapshot-report-by-type",
                                ].includes(reportType) && (
                                  <View style={{ zIndex: 40 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(messages, "Range Type")}
                                    </Text>
                                    <FormFieldPicker
                                      placeholder={{
                                        label: "Select Range Type",
                                      }}
                                      containerStyle={{
                                        width: "100%",
                                        marginTop: 5,
                                      }}
                                      data={rangeTypes}
                                      name="rangeType"
                                      testID="rangeType"
                                      accessibilityLabel="rangeType"
                                      disabled={
                                        reportType === "order-summary-report"
                                      }
                                      onChange={(val) => {
                                        setFieldValue("startDate", "");
                                        setFieldValue("endDate", "");

                                        if (val === "orderDate") {
                                          setFieldValue(
                                            "maxDate",
                                            moment()
                                              .subtract(1, "days")
                                              .toDate()
                                          );
                                        } else if (val === "deliveryDate") {
                                          setFieldValue("maxDate", "");
                                        }
                                      }}
                                    />
                                  </View>
                                )}
                                {[
                                  "sales-by-promo-code",
                                  "sales-tax-report",
                                  "credit-card-settlement-report",
                                  "aged-analysis-report",
                                ].includes(reportType) && (
                                  <View style={{ zIndex: 40 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(
                                        messages,
                                        "Detail or Summary Report"
                                      )}
                                    </Text>
                                    <FormFieldPicker
                                      placeholder={{
                                        label:
                                          "Select Detail or Summary Report",
                                      }}
                                      containerStyle={{
                                        width: "100%",
                                        marginTop: 5,
                                      }}
                                      data={salesByPromoReportTypes}
                                      name="detailOrSummaryReport"
                                      testID="detailOrSummaryReport"
                                      accessibilityLabel="detailOrSummaryReport"
                                      onChange={() => {
                                        setFieldValue("startDate", "");
                                        setFieldValue("endDate", "");
                                      }}
                                    />
                                  </View>
                                )}
                                {["business-snapshot-report-by-type"].includes(
                                  reportType
                                ) && (
                                  <View
                                    style={{
                                      ...tw("flex-row"),
                                      marginBottom: 4,
                                      zIndex: -1,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "30%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text style={[fonts.heading4]}>
                                        {Localise(messages, "Analysis Type")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "70%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Analysis Type",
                                        }}
                                        containerStyle={{
                                          width: "100%",
                                        }}
                                        data={businessSnapshotReportTypes}
                                        name="businessAnalysisType"
                                        testID="businessAnalysisType"
                                        accessibilityLabel="businessAnalysisType"
                                        onChange={() => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {reportType === "sales-tax-report" && (
                                  <View style={{ zIndex: 40 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(messages, "Print By")}
                                    </Text>
                                    <FormFieldPicker
                                      placeholder={{
                                        label: "Select Print By",
                                        value: "",
                                      }}
                                      containerStyle={{
                                        width: "100%",
                                        marginTop: 5,
                                      }}
                                      data={printByOptions}
                                      name="printBy"
                                      testID="printBy"
                                      accessibilityLabel="printBy"
                                    />
                                  </View>
                                )}
                                {reportType === "sales-tax-report" && (
                                  <View style={{ zIndex: 40 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(messages, "Filter By")}
                                    </Text>
                                    <FormFieldPicker
                                      placeholder={{
                                        label: "Select Filter By",
                                        value: "",
                                      }}
                                      containerStyle={{
                                        width: "100%",
                                        marginTop: 5,
                                      }}
                                      data={filterByOptions}
                                      name="filterBy"
                                      testID="filterBy"
                                      accessibilityLabel="filterBy"
                                      onChange={(val) => {
                                        setFieldValue("startDate", "");
                                        setFieldValue("endDate", "");

                                        if (val === "orderDate") {
                                          setFieldValue(
                                            "maxDate",
                                            moment()
                                              .subtract(1, "days")
                                              .toDate()
                                          );
                                        } else if (val === "deliveryDate") {
                                          setFieldValue("maxDate", "");
                                        }
                                      }}
                                    />
                                  </View>
                                )}
                                 {reportType === "house-account-report" && (
                                  <View
                                    style={{
                                      flexWrap: "wrap",
                                      zIndex: 30,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(
                                        messages,
                                        "Payment History or Statements"
                                      )}
                                    </Text>

                                    <View
                                      style={{
                                        alignItems: "flex-end",
                                        width: "100%",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label:
                                            "Select Payment History or Statements",
                                          value: "",
                                        }}
                                        containerStyle={{
                                          marginTop: 5,
                                          width: "100%",
                                          zIndex: 40,
                                        }}
                                        data={paymentHistoryReportOrStatments}
                                        name="paymentHistoryReportOrStatments"
                                        testID="paymentHistoryReportOrStatments"
                                        accessibilityLabel="paymentHistoryReportOrStatments"
                                        onChange={() => {
                                          setFieldValue("startDate", "");
                                          setFieldValue("endDate", "");
                                          setFieldValue("month", "");
                                          setFieldValue("year", "");
                                        }}
                                      />
                                    </View>
                                  </View>
                                )}
                                {values.paymentHistoryReportOrStatments ===
                                  "HA_statements" && (
                                  <>
                                    <View
                                      style={{
                                        ...tw("flex-row"),
                                        marginBottom: 4,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          ...fonts.heading4,
                                          paddingLeft: 5,
                                        }}
                                      >
                                        {Localise(messages, "Month") +
                                          " & " +
                                          Localise(messages, "Year")}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Month",
                                        }}
                                        containerStyle={{
                                          width: "50%",
                                        }}
                                        data={eligibleMonths}
                                        name="month"
                                        testID="month"
                                        accessibilityLabel="month"
                                      />
                                      <FormFieldPicker
                                        placeholder={{
                                          label: "Select Year",
                                        }}
                                        containerStyle={{
                                          width: "50%",
                                        }}
                                        data={getYears(currentYear)}
                                        name="year"
                                        testID="year"
                                        accessibilityLabel="year"
                                      />
                                    </View>
                                  </>
                                )}
                                {([
                                  "order-summary-report",
                                  "order-detail-report",
                                  "business-snapshot-report",
                                  "business-snapshot-report-by-type",
                                  "sales-tax-report",
                                  "occasion-reminder",
                                  "sales-by-promo-code",
                                  "product-sales-report",
                                  "sales-summary-report",
                                  "product-comparison-report",
                                  "advance-optimization-usage-report",
                                  "credit-card-settlement-report",
                                ].includes(reportType)||values.paymentHistoryReportOrStatments ===
                                "payment_HistoryReport") && (
                                  <View
                                    style={{
                                      flexWrap: "wrap",
                                      zIndex:
                                        reportType ===
                                        "product-comparison-report"
                                          ? 40
                                          : 30,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(
                                        messages,
                                        reportType ===
                                          "advance-optimization-usage-report"
                                          ? "Optimization Date Range"
                                          : dateRangeType ||
                                              filterRangeType ||
                                              "Date Range"
                                      )}
                                    </Text>

                                    <FormFieldDatePicker
                                      dateFormat={
                                        Platform.OS === "web"
                                          ? "MM/dd/yyyy"
                                          : "MM/DD/YYYY"
                                      }
                                      dateValueFormat="YYYY-MM-DD"
                                      name="startDate"
                                      placeholder="Start Date"
                                      containerStyle={{
                                        marginTop: 5,
                                        width: "100%",
                                        zIndex: 20,
                                      }}
                                      alignIconRight={true}
                                      iconName="calendar"
                                      iconType="font-awesome"
                                      minDate={values.minDate}
                                      maxDate={
                                        (values?.rangeType === "orderDate" ||
                                          values?.filterBy === "orderDate") &&
                                        [
                                          "order-detail-report",
                                          "sales-summary-report",
                                          "sales-tax-report",
                                          "business-snapshot-report",
                                          "business-snapshot-report-by-type",
                                        ].includes(reportType)
                                          ? moment().toDate() // Added this for updating max date to today's date
                                          : values.maxDate
                                      }
                                      initalSelectedDate={
                                        [
                                          "order-detail-report",
                                          "sales-summary-report",
                                          "sales-tax-report",
                                          "business-snapshot-report",
                                          "business-snapshot-report-by-type",
                                        ].includes(reportType)
                                          ? moment().toDate() // Added this for updating max date to today's date
                                          : values.maxDate
                                      }
                                      testID="startDate"
                                      accessibilityLabel="startDate"
                                    />

                                    <FormFieldDatePicker
                                      dateFormat={
                                        Platform.OS === "web"
                                          ? "MM/dd/yyyy"
                                          : "MM/DD/YYYY"
                                      }
                                      dateValueFormat="YYYY-MM-DD"
                                      name="endDate"
                                      placeholder="End Date"
                                      containerStyle={{
                                        width: "100%",
                                        zIndex: 10,
                                      }}
                                      alignIconRight={true}
                                      iconName="calendar"
                                      iconType="font-awesome"
                                      minDate={values.minDate}
                                      maxDate={
                                        (values?.rangeType === "orderDate" ||
                                          values?.filterBy === "orderDate") &&
                                        [
                                          "order-detail-report",
                                          "sales-summary-report",
                                          "sales-tax-report",
                                          "business-snapshot-report",
                                          "business-snapshot-report-by-type",
                                        ].includes(reportType)
                                          ? moment().toDate() // Added this for updating max date to today's date
                                          : values.maxDate
                                      }
                                      initalSelectedDate={
                                        [
                                          "order-detail-report",
                                          "sales-summary-report",
                                          "sales-tax-report",
                                          "business-snapshot-report",
                                          "business-snapshot-report-by-type",
                                        ].includes(reportType)
                                          ? moment().toDate() // Added this for updating max date to today's date
                                          : values.maxDate
                                      }
                                      testID="endDate"
                                      accessibilityLabel="endDate"
                                    />
                                  </View>
                                )}
                                {["product-comparison-report"].includes(
                                  reportType
                                ) && (
                                  <View
                                    style={{
                                      flexWrap: "wrap",
                                      zIndex: 30,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(
                                        messages,
                                        "Comparison Date Range"
                                      )}
                                    </Text>

                                    <FormFieldDatePicker
                                      dateFormat={
                                        Platform.OS === "web"
                                          ? "MM/dd/yyyy"
                                          : "MM/DD/YYYY"
                                      }
                                      dateValueFormat="YYYY-MM-DD"
                                      name="comparisonStartDate"
                                      placeholder="Start Date"
                                      containerStyle={{
                                        marginTop: 5,
                                        width: "100%",
                                        zIndex: 40,
                                      }}
                                      alignIconRight={true}
                                      iconName="calendar"
                                      iconType="font-awesome"
                                      minDate={values.minDate}
                                      maxDate={values.maxDate}
                                      initalSelectedDate={values.maxDate}
                                      testID="comparisonStartDate"
                                      accessibilityLabel="comparisonStartDate"
                                    />

                                    <FormFieldDatePicker
                                      dateFormat={
                                        Platform.OS === "web"
                                          ? "MM/dd/yyyy"
                                          : "MM/DD/YYYY"
                                      }
                                      dateValueFormat="YYYY-MM-DD"
                                      name="comparisonEndDate"
                                      placeholder="End Date"
                                      containerStyle={{
                                        width: "100%",
                                        zIndex: 40,
                                      }}
                                      alignIconRight={true}
                                      iconName="calendar"
                                      iconType="font-awesome"
                                      minDate={values.minDate}
                                      maxDate={values.maxDate}
                                      initalSelectedDate={values.maxDate}
                                      testID="comparisonEndDate"
                                      accessibilityLabel="comparisonEndDate"
                                    />
                                  </View>
                                )}
                                {reportType === "occasion-reminder" && (
                                  <View style={{ zIndex: -5 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(messages, "Occasion Type")}
                                    </Text>
                                    <OccasionsField
                                      values={values}
                                      setValues={setValues}
                                      Localise={Localise}
                                      messages={messages}
                                      allLabel={allLabel}
                                    />
                                  </View>
                                )}

                                {[
                                  "credit-card-settlement-report",
                                  "aged-analysis-report",
                                  "business-snapshot-report-by-type",
                                ].includes(reportType) && (
                                  <View style={{ zIndex: 20 }}>
                                    <Text
                                      style={{
                                        ...fonts.heading4,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {Localise(messages, "Report Format")}
                                    </Text>
                                    <FormFieldPicker
                                      placeHolderExists={false}
                                      containerStyle={{
                                        width: "100%",
                                        marginTop: 5,
                                      }}
                                      data={supportedFileFormats}
                                      name="reportFormat"
                                      testID="reportFormat"
                                      accessibilityLabel="reportFormat"
                                    />
                                  </View>
                                )}
                                <View
                                  style={[
                                    tw(
                                      `flex ${
                                        showDurationText
                                          ? "flex-col"
                                          : "flex-row justify-end"
                                      } mt-2`
                                    ),
                                    {
                                      flex: 1,
                                      justifyContent: "flex-end",
                                      paddingRight: 5,
                                    },
                                  ]}
                                >
                                  {showDurationText && (
                                    <UserAlert
                                      message={
                                        "This report updates every 90 minutes"
                                      }
                                    />
                                  )}
                                  <TouchableOpacity
                                    style={{
                                      marginLeft: 0,
                                      alignSelf: "flex-end",
                                    }}
                                  >
                                    <SubmitButton
                                      containerStyle={{
                                        width: 150,
                                        margin: 10,
                                      }}
                                      title={
                                        Platform.OS === "web"
                                          ? "Download"
                                          : "View Report"
                                      }
                                    />
                                  </TouchableOpacity>
                                </View>
                              </>
                            ) : null}
                          </View>
                        )}
                      </>
                    );
                  }}
                />
              </Accordion>
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
}
