const ORDERS = Object.freeze({
  FLORIST_DELIVERED: "FLORIST_DELIVERED",
  FLORIST_PARTNER: "FLORIST_PARTNER",
  STORE_PICKUP: "STORE_PICKUP",
  PHONE_OUT: "PHONE_OUT",
  WALK_IN: "WALK_IN",
  PARTNER_STORE_PICKUP: "PARTNER_STORE_PICKUP",
  MOL_FLORIST_DELIVERED: "MOL_FLORIST_DELIVERED",
  FOL_FLORIST_DELIVERED: "FOL_FLORIST_DELIVERED",
  MOL_CUSTOMER_PICKUP: "MOL_CUSTOMER_PICKUP",
  DELIVERY_SERVICE: "DELIVERY_SERVICE",
  DRAFT_ALREADY_DELETED_MESSAGE: "Draft order was already deleted.",
  DRAFT_CREATE_FAILURE_MESSAGE:
    "Failed to save the draft order. Please try again!",
  DRAFT_CREATE_SUCCESS_MESSAGE: "Draft order has been saved successfully",
  DRAFT_CREATING_MESSAGE: "Creating the draft order. Just a moment.",
  DRAFT_DELETE_FAILURE_MESSAGE:
    "Failed to delete the draft order. Please try again!",
  DRAFT_DELETE_SUCCESS_MESSAGE: "Draft order has been deleted successfully",
  DRAFT_EMPTY_DESCRIPTION:
    "It looks like you don't have any draft orders at the moment. Create a new order to save it as a draft.",
  DRAFT_EMPTY_TEXT: "No Draft Orders Available.",
  DRAFT_CONFIRM_MODAL_CONTENT:
    "We cannot save payment information, such as credit card details, in a draft order. Do you wish to continue saving the draft without the payment information, or cancel to continue working on the order?",
  DRAFT_INFO_TEXT:
    "Click on any draft order tile to open, modify, or complete your order.",
  DRAFT_MODIFY_FAILURE_MESSAGE:
    "Failed to modify the draft order. Please try again!",
  DRAFT_MODIFY_SUCCESS_MESSAGE: "Draft order has been modified successfully",
  DRAFT_MODIFYING_MESSAGE: "Modifying the draft order. Just a moment.",
  DRAFT_TITLE: "Draft Orders",
  EDIT_ORDER_TITLE: "Edit Order",
  CREATE_SUBSCRIPTION_TITLE: "Create Subscription",
  EDIT_SUBSCRIPTION_TITLE: "Edit Subscription",
  CREATE_ORDER_TITLE: "Create Order",
  AMOUNT_DUE_ERROR: "Amount due should be greater than 0",
  FUNERAL_LOG_ERR_1: "Failed to map or unmap orders with Funeral log",
  FUNERAL_LOG_ERR_2: "Failed to map order with Funeral log",
  PAYMENT_SUCCESS: "Payment Successful",
  ORDER_MODIFY_FAILED: "Order modify request failed, please try again.",
  NO_MODIFICATIONS_MADE: "No modifications were made to the order",
  PAYMENT_FAILED: "Payment failed to process, please try again.",
  ORDER_SUBMITTED: "Your order was submitted",
  OPEN_ORDER_LINK: "Open your order(s)",
  DD_CHANGE_SUBSCRIPTION_SUCCESS_MSG:
    "Your subscription was modified successfully. To avoid duplicates, consider canceling the created order for old delivery date.",
  MODIFY_SUBSCRIPTION_MSG: "Your subscription has been modified successfully",
  SUBSCRIPTION_SUBMITTED: "Your subscription was submitted",
  GC_PRODUCT_ERROR:
    "Cannot add Product and Gift Card in the same order. Please create a new order to proceed.",
  RECALC_TAX_FEES_CONFIRM: "Would you like to recalculate the fees & taxes?",
  ITEM_REMOVAL_CONFIRM_MSG:
    "Are you sure you want to remove this item from your order?",
  CANCEL_TRANSACTION: "Canceling transaction.",
  SAVING_EDITS: "We are saving your edits. Just a moment.",
  CREATING_SUBSCRIPTION: "Creating your subscription. Just a moment.",
  CREATING_ORDER: "Creating your order(s). Just a moment.",
  FIELD_ERROR: "Please complete required fields to submit this order",
  REFUND_INITIATED: "Refund initiated for successful transaction(s)",
  RETRY_MESSAGE: "Something went wrong. Please try again!",
  CANCEL: "Cancel",
  CONTINUE: "Continue",
  SAVE_DRAFT: "Save Draft",
  DELETE_DRAFT: "Delete Draft",
  FUNERAL_DS_INSTRUCTIONS:
    "This is a gift. Please ring the doorbell or knock. Photo proof required if left unattended. For Funeral Homes or Businesses, proceed inside for handoff.",
  ADDRESS_OR_DATE_CHANGE_WARNING_TEXT:
    "Notice: You have updated your order details. The order will stay in the current route ROUTE_NAME. Please adjust the route if necessary. ",
  CREDIT_CARD_PROCESS_FAILURE_ERROR_MESSAGE:
    "There was a credit card processing error. Contact us at (800) 788-9000 to resolve the issue.",
  CUSTOMER_ALREADY_EXISTS_ERROR_MESSAGE:
    "Customer already exists. Please select customer from the Customer Directory to proceed.",
  PAYMENT_FAILED_ERROR_MESSAGE: "Payment failed to process, please try again.",
  ADDRESS_NOT_REVERTED_MESSAGE: "Address is not reverted. Please try agian",
  ORIGINAL_ADDRESS: "Original Address ",
  ADDRESS_COORECTED: "Address Corrected",
  ADDRESS_VERIFIED: "Address Verified",
  ADDRESS_NOT_VERIFIED: "Address Not Verified",
  REVERT_ADDRESS: "Revert Address",
  ADDRESS_REVERTED_MESSAGE: "Address Reverted Successfully",
  ADDRESS_REVERTED_MEESAGE_WITH_OPERATOR: "Address updated by",
});

export default ORDERS;
