import React, { memo, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Button } from "react-native-elements";
import { View, TouchableOpacity, Image, Platform } from "react-native";

import { CustomModal, Tooltip } from "components/elements";
import { Form, FormField, SubmitButton } from "components/elements/forms";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import IMAGES from "static/assets/images";

import tw from "tailwind-rn";
import { fonts, backgroundColors, theme } from "styles/theme";

import { setOnDemandInvoiceFields } from "library/sagas/ongoing/current-orders/slice";
import { selectOnDemandInvoice } from "library/sagas/ongoing/current-orders/selector";

import { getOnDemandInvoiceFormValidationSchema } from "./yup";

const EmailInvoice = ({
  title = "",
  showModal = false,
  onSubmit = () => {},
  onCancel = () => {},
  customerInfo,
}) => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);

  const isSmallScreen = !isDesktop;

  const onDemandInvoiceResponse = useSelector(selectOnDemandInvoice);

  const { email = "" } = customerInfo;

  const {
    customerEmailFields = [email],
    validateOnDemandInvoiceFields = false,
  } = onDemandInvoiceResponse;

  // Initialize customerEmailFields if empty
  useEffect(() => {
    if (customerEmailFields?.length === 0) {
      dispatch(
        setOnDemandInvoiceFields({
          ...onDemandInvoiceResponse,
          customerEmailFields: [email],
        })
      );
    }
  }, [customerInfo]);

  const getInitValues = {
    recipientEmail: customerEmailFields,
  };

  return (
    <>
      <CustomModal
        modalVisible={showModal}
        modalContent={{
          content: (
            <View
              style={{
                maxWidth: isSmallScreen ? "100%" : 600,
                maxHeight: isSmallScreen ? "100%" : 400,
                margin: "auto",
                backgroundColor: backgroundColors.secondary,
                borderColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <View
                style={[
                  tw("p-3 flex flex-row items-center justify-between"),
                  { backgroundColor: backgroundColors.primary },
                ]}
              >
                <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
                  {Localise(messages, title)}
                </Text>
              </View>
              <Form
                initialValues={getInitValues}
                onSubmit={(values, formikBag) => {
                  onSubmit(values, formikBag);
                }}
                validationSchema={getOnDemandInvoiceFormValidationSchema(
                  Localise,
                  messages
                )}
                validateOnChange={validateOnDemandInvoiceFields}
                validateOnBlur={validateOnDemandInvoiceFields}
                render={(props) => {
                  const { values: onDemandFieldValues, submitCount } = props;

                  //eslint-disable-next-line
                  useEffect(() => {
                    if (submitCount > 0) {
                      dispatch(
                        setOnDemandInvoiceFields({
                          ...onDemandInvoiceResponse,
                          setValidationOnChange: true,
                        })
                      );
                    }
                  }, [submitCount]);

                  return (
                    <View>
                      <View
                        style={[
                          tw(`flex flex-row items-center justify-between`),
                        ]}
                      >
                        <Text
                          style={{
                            ...tw("my-4 ml-2"),
                            maxWidth: 360,
                            textAlign: "justify",
                          }}
                        >
                          {"Customer Email"}
                        </Text>

                        {customerEmailFields?.length < 5 && (
                          <TouchableOpacity
                            style={{ paddingHorizontal: 5 }}
                            onPress={() => {
                              dispatch(
                                setOnDemandInvoiceFields({
                                  ...onDemandInvoiceResponse,
                                  customerEmailFields: [
                                    ...onDemandFieldValues?.recipientEmail,
                                    "",
                                  ],
                                })
                              );
                            }}
                            testID="add-customer-email"
                            accessibilityLabel="add-customer-email"
                          >
                            <Tooltip
                              text={Localise(messages, "Add Customer Email")}
                              renderForWebOnly={true}
                            >
                              <Image
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                                resizeMode="contain"
                                source={IMAGES[`create-order`]}
                              />
                            </Tooltip>
                          </TouchableOpacity>
                        )}
                      </View>

                      {customerEmailFields?.map((field, index) => {
                        return (
                          <FormField
                            key={index}
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="email-address"
                            name={`recipientEmail[${index}]`}
                            placeholder={"Enter Customer Email"}
                            textContentType="emailAddress"
                          />
                        );
                      })}

                      <View
                        style={tw(
                          "flex flex-row justify-center items-center mx-16"
                        )}
                      >
                        <Button
                          testID={"close_ondemand_popup"}
                          accessibilityLabel="close_ondemand_popup"
                          title={Localise(messages, "Cancel")}
                          titleStyle={theme.Button.secondaryTitleStyle}
                          buttonStyle={theme.Button.secondaryButtonStyle}
                          onPress={() => {
                            onCancel();
                            dispatch(
                              setOnDemandInvoiceFields({
                                customerEmailFields: [email],
                                showOnDemandInvoiceForm: false,
                              })
                            );
                          }}
                        />
                        <SubmitButton
                          containerStyle={{ marginLeft: 24 }}
                          title={"Send"}
                          isSubmitOnEnter={true}
                          testID={"submit_email_content"}
                          accessibilityLabel={"submit_email_content"}
                        />
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          ),
        }}
        contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: backgroundColors.secondary,
              }
            : { padding: 0 }
        }
      />
    </>
  );
};

export default memo(EmailInvoice);
