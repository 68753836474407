import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator, View } from "react-native";
import SplitScreen from "components/containers/split-screen";
import SearchFilters from "components/containers/search-filters";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import get from "lodash/get";
import UserProfileContext from "library/contexts/userProfile";
import FloristSelection from "components/views/drawer/create-order/florist-selection";
import SubHeader from "components/containers/header/sub-header";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Entitlements } from "library/utils/entitlements";
import { getDatesDateRange } from "library/utils/dateRange";
import ScreenHeader from "components/containers/header/screen";
import {
  fetchDesignerSuggestions,
  setDesignersData,
  setIsOrderSearchApplied,
  setIsOrderSplitCallInProgress,
  setIsOrdersCallInProgress,
  setCurrentTimeMinus30Seconds,
  setCanCallRetryFetchOrders,
} from "library/sagas/ongoing/global-data/slice";
import { colors } from "styles/theme";
import { getMemberCodesWithFeature } from "library/utils/featureAvailability";
import {
  selectDesignerInfo,
  selectIsOrdersCallInProgress,
  selectIsOrderSearchApplied,
  selectIsOrderSplitCallInProgress,
  selectCurrentTimeMinus30Seconds,
  selectCanCallRetryFetchOrders,
} from "library/sagas/ongoing/global-data/selector";
import { setShowFetchingOrdersLoader } from "library/sagas/ongoing/current-orders/slice";
import Environment from "library/utils/environment";
import {
  checkDateRangeFilterApplied,
  retryFetchOrdersAfterDelay,
} from "../../views/drawer/dashboard/helper";

const ListingScreen = ({
  listingInfo,
  userActions,
  listingType,
  initConfig,
  initData,
  deliverySourceList = {},
  initViewMoreCounts = {},
  pollInterval,
  columnsList,
  subColumnsList,
  actionsList,
  subActionsList,
  statusList = {},
  sortOptions,
  filterOptions,
  params,
  title,
  placeholder,
  route,
  actionName,
  showPriceRange = false,
  List,
  Details,
  showaction = true,
  CreateUser,
  enableFilters = true,
  enableSort = true,
  enableShopSelection = true,
  showDivider = true,
  tabHeaderExists = false,
  showLoading = false,
  showDateType = true,
  customFiltersWidth = false,
  loading: DSLoading = false,
  hasStickyHeader = false,
  ListingHeader,
  showTitle = true,
  showScreenHeader = false,
  showExistingSubHeader = true,
  settingsRoute,
  createRoute,
  range = 21,
  shops,
  sortDefaultValue,
}) => {
  const dispatch = useDispatch();
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { userProfile: { authGroups, firstName: operator } = {}, proxyUser } =
    React.useContext(UserProfileContext);

  const { permissions } = React.useContext(AppSettingsContext);
  const [autoScroll, setAutoScroll] = useStateIfMounted(false);
  const [screenToScroll, setScreenToScroll] = useStateIfMounted(1);
  const [ScrollYCoordinate, setScrollYCoordinate] = useStateIfMounted(0);
  const designersInfo = useSelector(selectDesignerInfo);
  const isOrdersApiCalled = useSelector(selectIsOrdersCallInProgress);
  const updatedActions = useSelector(selectIsOrderSearchApplied);
  const orderSplitCallInProgress = useSelector(
    selectIsOrderSplitCallInProgress
  );
  const selectLastSyncTime = useSelector(selectCurrentTimeMinus30Seconds);
  const selectRetryFetchOrders = useSelector(selectCanCallRetryFetchOrders);
  const statusOrderFetchDelayMs = Environment.get(
    "SPLIT_ORDERS_CALL_INTERVAL",
    7000
  );

  const isEnabledOrderListCallSplit = Environment.get(
    "ENABLE_ORDERS_CALL_SPLIT",
    true
  );

  let entitlementKeyword = "";
  let designCenterMembers = [];
  if (listingType === "current") {
    entitlementKeyword = Entitlements.ORDERS;
    designCenterMembers = getMemberCodesWithFeature(
      permissions,
      Entitlements.ORDERS,
      Entitlements.ORDERS_PAGE.DESIGNER
    );
  } else if (listingType === "staff") {
    entitlementKeyword = Entitlements.STAFF_MANAGEMENT;
  } else if (listingType === "deliveryTasks") {
    entitlementKeyword = Entitlements.DELIVERY_TASKS;
  }

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (entitlementKeyword in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  const memberCode = get(authGroups, "0.memberCodes.0", "");
  const [menuAction, setMenuAction] = useStateIfMounted("");
  const {
    setRecordId,
    setDeliveryMethod,
    setSourceMemberCode,
    setShopCode,
    setActions,
    setRecordAction,
    fetchData,
    setLoading,
  } = userActions;
  const { set1 = {}, set2 = {} } = statusList;
  const { sources = [] } = deliverySourceList;

  const timerToClearSomewhere = useRef(null);
  const customStartDate = useRef("");
  const customEndDate = useRef("");
  const isCustomDateRangeApplied = useRef("");
  const callRetryFetchOrders = useRef(true);
  const retryLastsyncTime = useRef("");

  const {
    recordId,
    deliveryMethod,
    sourceMemberCode,
    shopCode,
    recordAction,
    actions = {},
    loading,
    data,
    originalData,
    timeStamp,
    hasMoreOrders,
    minDeliveryDate,
    maxDeliveryDate,
  } = listingInfo;

  const {
    appliedFilters = [],
    selectedShops = [],
    search,
    sort,
    orderIds,
  } = actions;

  var customRangeFilter = appliedFilters.find((obj) => obj["Date Range"]);

  const {
    startDate: filterStartDate = "",
    endDate: filterEndDate = "",
    dateType = undefined,
  } = get(customRangeFilter, `${"Date Range"}.value`, {});

  const selectedMemberCodes = [];
  selectedShops.map((shop) => {
    const memberCode = get(shop, `${"Shop Codes"}.value`, "");
    selectedMemberCodes.push(memberCode);
  });

  useEffect(() => {
    if (timeStamp && pollInterval && !orderSplitCallInProgress) {
      timerToClearSomewhere.current = setTimeout(() => {
        getData();
      }, pollInterval);
    }
    return () => {
      clearTimeout(timerToClearSomewhere.current);
    };
  }, [timeStamp, orderSplitCallInProgress]);

  useEffect(() => {
    const initActions = {
      appliedFilters: [],
      filters: [],
      selectedShops: [],
      shops: [],
      search: {},
      sort: {},
      orderIds: "", //Comma separated Orders
    };
    if (
      JSON.stringify(initActions) !== JSON.stringify(actions) &&
      updatedActions &&
      !orderSplitCallInProgress &&
      listingType === "current"
    ) {
      const isDateRangeApplied = checkDateRangeFilterApplied(actions);
      if (isDateRangeApplied) {
        if (filterStartDate) {
          setLoading(true);
          dispatch(setIsOrdersCallInProgress(true));
          fetchData({
            params: {
              startDate: filterStartDate,
              endDate: filterEndDate,
              fetchStatuses: set1.status.join(","),
              memberCodes: undefined,
              deltaOrders: false,
              lastSyncTime: "",
              dateType,
              set2,
            },
            actions,
            originalData: [],
            initData,
            listingType,
            resolve: () => {
              dispatch(setIsOrdersCallInProgress(false));
              customStartDate.current = filterStartDate;
              customEndDate.current = filterEndDate;
            },
          });
          dispatch(setIsOrderSearchApplied(false));
        }
      } else {
        dispatch(setCanCallRetryFetchOrders(false));
        getData();
        dispatch(setIsOrderSearchApplied(false));
      }
    }

    if (listingType === "current" && actions?.orderIds) {
      // Always split orderIds to handle single & multiple values
      const orderIdsArray = actions?.orderIds?.split(",");
      const noOfOrders = orderIdsArray?.length;

      const orderIdsFilteredData = originalData.filter((each) =>
        actions?.orderIds?.includes(each?.orderItemId)
      );
      dispatch(
        setShowFetchingOrdersLoader(orderIdsFilteredData?.length !== noOfOrders)
      );
    }
  }, [originalData, updatedActions, orderSplitCallInProgress]);

  useEffect(() => {
    callRetryFetchOrders.current = selectRetryFetchOrders;
    retryLastsyncTime.current = selectLastSyncTime;
  }, [selectLastSyncTime, selectRetryFetchOrders]);

  useEffect(() => {
    clearTimeout(timerToClearSomewhere.current);

    // fetching designers list on loading order-listing screen
    const dcMemberCodes =
      designCenterMembers.length === 1
        ? designCenterMembers
        : selectedShops.map((shop) => shop["Shop Codes"].value);

    if (!isEmpty(dcMemberCodes)) {
      dispatch(
        fetchDesignerSuggestions({
          members: dcMemberCodes.join(","),
        })
      );
    } else if (!isEmpty(designersInfo)) {
      dispatch(setDesignersData([]));
    }

    const isCustomDateRangeOrdersFetched =
      customStartDate.current === filterStartDate &&
      customEndDate.current === filterEndDate;

    const isDateRangeApplied =
      checkDateRangeFilterApplied(actions) && !isCustomDateRangeOrdersFetched;

    if (isDateRangeApplied && !updatedActions) {
      dispatch(setIsOrderSearchApplied(true));
    }

    isCustomDateRangeApplied.current = isDateRangeApplied;

    getData();
  }, [appliedFilters, selectedShops, search, sort, recordAction, orderIds]);

  const fetchOrderListingData = () => {
    const startDate = filterStartDate
      ? filterStartDate
      : set1.start === "1w"
      ? moment().subtract(1, "week").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    const endDate = filterEndDate
      ? filterEndDate
      : set1.end === "1d"
      ? moment().subtract(1, "days").format("YYYY-MM-DD")
      : undefined;

    const orderProcessIntervalTimeStamp = Environment.get(
      "ORDERS_PROCESSING_INTERVAL_TIME_STAMP",
      60
    );

    setLoading(true);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const callFetchDataWithDelay = async () => {
      const statuses = [set1?.initStatuses?.join(","), set1?.status?.join(",")];
      dispatch(setIsOrderSplitCallInProgress(true));

      for (let i = 0; i < statuses.length; i++) {
        const fetchStatuses = statuses[i]; // Get the current statuses to use in fetchData
        const fetchStartDate =
          i === 0 ? moment().format("YYYY-MM-DD") : startDate;
        const fetchEndDate =
          i === 0 ? moment().add(1, "days").format("YYYY-MM-DD") : "";
        if (i === 0) {
          const formatLastSyncTime = moment()
            .utc()
            .subtract(orderProcessIntervalTimeStamp, "seconds")
            .format();

          dispatch(setCurrentTimeMinus30Seconds(formatLastSyncTime));
        }

        // Call fetchData
        fetchData({
          params: {
            startDate: fetchStartDate,
            endDate: fetchEndDate,
            fetchStatuses,
            memberCodes: undefined,
            deltaOrders: false,
            lastSyncTime: "",
            dateType,
            set2,
          },
          actions,
          originalData,
          initData,
          listingType,
          showLoader: i === 1,
          resolve: async (response) => {
            if (i !== 0) {
              dispatch(setIsOrderSearchApplied(true));
              dispatch(setIsOrderSplitCallInProgress(false));
              customStartDate.current = "";
              customEndDate.current = "";

              const isCustomDateRangeOrdersFetched =
                customStartDate.current === filterStartDate &&
                customEndDate.current === filterEndDate;
              const isDateRangeApplied =
                checkDateRangeFilterApplied(actions) &&
                !isCustomDateRangeOrdersFetched;

              if (response && !isDateRangeApplied) {
                // Third fetchData call with deltaOrders set to true
                const delay = (ms) =>
                  new Promise((resolve) => setTimeout(resolve, ms));

                const statusOrderFetchDelayMs = Environment.get(
                  "ORDERS_PROCESSING_INTERVAL",
                  40000
                );

                dispatch(setIsOrdersCallInProgress(false));
                // Wait for 40 seconds before the next call
                await delay(statusOrderFetchDelayMs);
                if (callRetryFetchOrders.current) {
                  retryFetchOrdersAfterDelay({
                    response,
                    currentTimeMinus30Seconds: retryLastsyncTime.current,
                    fetchStatuses: set1?.status?.join(","),
                    fetchStartDate: startDate,
                    fetchEndDate: endDate,
                    set2,
                    actions,
                    InitOrdersData: initData,
                    isCustomDateRangeApplied,
                    fetchData,
                  });
                }
              }
            }
          },
        });
        // Wait for 7 seconds before the next call
        if (i < statuses.length - 1) {
          await delay(statusOrderFetchDelayMs); // Delay only between calls
        }
      }
    };

    const callFetchData = () => {
      fetchData({
        params: {
          startDate,
          endDate,
          fetchStatuses: set1.status.join(","),
          memberCodes: undefined,
          deltaOrders: !!timeStamp,
          lastSyncTime: timeStamp || "",
          dateType,
          set2,
        },
        actions,
        originalData,
        timeStamp,
        initData,
        listingType,
        showLoader: true,
        resolve: async () => {
          if (!callRetryFetchOrders.current) {
            const delay = (ms) =>
              new Promise((resolve) => setTimeout(resolve, ms));

            const statusOrderFetchDelayMs = Environment.get(
              "ORDERS_PROCESSING_INTERVAL",
              40000
            );

            dispatch(setIsOrdersCallInProgress(false));
            // Wait for 40 seconds before the next call
            await delay(statusOrderFetchDelayMs);
            retryFetchOrdersAfterDelay({
              response: originalData,
              currentTimeMinus30Seconds: retryLastsyncTime.current,
              fetchStatuses: set1?.status?.join(","),
              fetchStartDate: startDate,
              fetchEndDate: endDate,
              set2,
              actions,
              InitOrdersData: initData,
              isCustomDateRangeApplied,
              fetchData,
            });
            dispatch(setCanCallRetryFetchOrders(true));
          }
        },
      });
    };
    // Call the function
    if (isEnabledOrderListCallSplit) {
      if (!orderSplitCallInProgress) {
        timeStamp
          ? callFetchData()
          : !isOrdersApiCalled && !endDate
          ? callFetchDataWithDelay()
          : "";
      }
    } else {
      callFetchData();
    }
  };

  const getData = () => {
    if (listingType === "staff" || listingType === "ftd-user-accounts") {
      setLoading(true);

      fetchData({ actions, listingType, params: { selectedMemberCodes } });
    } else if (listingType === "deliveryTasks") {
      const deliveryStartDate = filterStartDate
        ? moment(filterStartDate).startOf("day").utc().toISOString()
        : set1.start === "1w"
        ? moment().subtract(1, "week").utc().startOf("day").toISOString()
        : moment().utc().startOf("day").toISOString();

      const deliveryEndDate = filterEndDate
        ? moment(filterEndDate).endOf("day").utc().toISOString()
        : moment().add(1, "week").utc().endOf("day").toISOString();

      setLoading && setLoading(true);

      fetchData({
        actions,
        listingType,
        initData,
        params: {
          deliveryStartDate,
          deliveryEndDate,
          fetchStates: set1.status.join(","),
          fetchMemberCodes: filteredShopCodes.join(","),
          sources: sources.join(","),
        },
        setLoading,
      });
    } else {
      fetchOrderListingData();
    }
  };

  const handleOnAction = (
    actionName,
    orderTileRecordId = "",
    refreshNeeded = recordId === orderTileRecordId
  ) => {
    setRecordAction(`${actionName}${orderTileRecordId}`);
    if (refreshNeeded && recordId && orderTileRecordId) {
      setRecordId("");
      setRecordId(orderTileRecordId);
    }
  };

  const customSecondScreenStyle = React.useMemo(() => {
    return { padding: isDesktop ? 15 : isTablet ? 0 : 10 };
  }, [isDesktop, isTablet]);

  const HeaderSiblings = () => {
    return loading ? (
      <ActivityIndicator
        color={colors.activityIndicator}
        style={{
          height: 25,
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 10,
        }}
      />
    ) : null;
  };
  return (
    <>
      {showScreenHeader && (
        <ScreenHeader
          title={title}
          settingsRoute={settingsRoute}
          createRoute={createRoute}
          sideCarIdentifier={recordId}
          setSideCarIdentifier={setRecordId}
          recordAction={recordAction}
          setRecordAction={setRecordAction}
          listingType={listingType}
          loader={HeaderSiblings}
        />
      )}
      <SplitScreen
        showTwoScreens={!!recordId}
        subHeaderExists={isSmallScreen}
        tabHeaderExists={tabHeaderExists}
        hasStickyHeader={hasStickyHeader}
        proxyHeaderExists={proxyUser ? true : false}
        screenHeaderExists={showScreenHeader}
        autoScreenScroll={autoScroll}
        screenToScroll={screenToScroll}
        ScrollYCoordinate={ScrollYCoordinate}
        customSecondScreenStyle={customSecondScreenStyle}
      >
        <View fsClass="fs-unmask">
          {isSmallScreen &&
          showExistingSubHeader &&
          listingType !== "deliveryTasks" ? (
            <SubHeader showTwoScreens={!!recordId} />
          ) : (
            <></>
          )}
          <SearchFilters
            loading={DSLoading || loading}
            actions={actions}
            setActions={setActions}
            sortOptions={sortOptions}
            filterOptions={filterOptions}
            params={params}
            title={title}
            placeholder={placeholder}
            route={route}
            actionName={actionName}
            showaction={showaction}
            showPriceRange={showPriceRange}
            setRecordId={setRecordId}
            memberCodes={filteredShopCodes}
            enableFilters={enableFilters}
            enableSort={enableSort}
            enableShopSelection={enableShopSelection}
            showLoading={showLoading}
            showDivider={showDivider}
            showDateType={showDateType}
            customFiltersWidth={customFiltersWidth}
            showTitle={showTitle}
            showBreadCrumb={listingType === "current"}
            showFiltersTitle={listingType !== "current"}
            range={range}
            {...(sortDefaultValue && {
              sortDefaultValue,
            })}
          />
          {ListingHeader && !(loading && DSLoading) ? (
            <ListingHeader
              data={data}
              minDeliveryDate={minDeliveryDate}
              maxDeliveryDate={maxDeliveryDate}
              deliveryStartDate={
                !isEmpty(customRangeFilter)
                  ? getDatesDateRange(appliedFilters).startDate
                  : minDeliveryDate
              }
              deliveryEndDate={
                !isEmpty(customRangeFilter)
                  ? getDatesDateRange(appliedFilters).endDate
                  : maxDeliveryDate ||
                    moment().add(1, "week").utc().endOf("day").toISOString()
              }
              isCustomRangeApplied={!isEmpty(customRangeFilter)}
            />
          ) : (
            <></>
          )}
          <List
            hasMoreOrders={hasMoreOrders}
            isCustomRangeApplied={!isEmpty(customRangeFilter)}
            data={data}
            listingInfo={listingInfo}
            statusList={statusList}
            initData={initData}
            fetchData={fetchData}
            setActions={setActions}
            config={initConfig}
            recordId={recordId}
            columnsList={columnsList}
            subColumnsList={subColumnsList}
            actionsList={actionsList}
            subActionsList={subActionsList}
            setRecordId={setRecordId}
            setDeliveryMethod={setDeliveryMethod}
            setSourceMemberCode={setSourceMemberCode}
            setShopCode={setShopCode}
            onAction={handleOnAction}
            isSmallScreen={isSmallScreen}
            isMobile={isMobile}
            filterActions={actions}
            listingType={listingType}
            memberCodes={filteredShopCodes}
            memberCode={memberCode}
            operator={operator}
            setMenuAction={setMenuAction}
            initViewMoreCounts={initViewMoreCounts}
            loading={DSLoading || loading}
            getData={getData}
            minDeliveryDate={minDeliveryDate}
            maxDeliveryDate={maxDeliveryDate}
            showaction={showaction}
            shops={shops}
          />
        </View>
        {recordAction &&
        recordAction.includes("selectNewFlorist") &&
        recordAction.split("_")[2] === recordId ? (
          <View>
            {isSmallScreen && showExistingSubHeader ? (
              <SubHeader
                title={`Order Detail`}
                onTrigger={() => {
                  setRecordAction(recordId);
                }}
              />
            ) : (
              <></>
            )}
            <FloristSelection
              onComplete={() => {
                setRecordAction(recordId);
              }}
              sideCarInfo={JSON.parse(recordAction.split("_")[1])}
              onSelect={(florist) => {
                setRecordAction(
                  `floristSelected_${JSON.stringify(florist)}_${recordId}`
                );
              }}
            />
          </View>
        ) : recordId && recordId.includes("createStaffProfile") ? (
          <>
            {isSmallScreen && (
              <SubHeader
                title={`Staff Management`}
                onTrigger={() => {
                  setRecordId();
                }}
              />
            )}
            <CreateUser
              onComplete={() => {
                setRecordId();
              }}
              onAction={setRecordAction}
            />
          </>
        ) : listingType === "deliveryTasks" ? (
          <View>
            <Details
              listingType={listingType}
              isSmallScreen={isSmallScreen}
              recordId={recordId}
              shopCode={shopCode}
              onComplete={setRecordId}
              onAction={setRecordAction}
              handleOnAction={handleOnAction}
              actionsList={actionsList}
              subActionsList={subActionsList}
              menuAction={menuAction}
              setMenuAction={setMenuAction}
              recordAction={recordAction}
              setAutoScroll={setAutoScroll}
              setScreenToScroll={setScreenToScroll}
              setScrollYCoordinate={setScrollYCoordinate}
            />
          </View>
        ) : (
          <View>
            {isSmallScreen && showExistingSubHeader ? (
              <SubHeader
                title={listingType === "staff" ? `Staff Management` : `Orders`}
                onTrigger={() => {
                  setRecordId();
                }}
              />
            ) : (
              <></>
            )}
            <Details
              listingType={listingType}
              isSmallScreen={isSmallScreen}
              recordId={recordId}
              deliveryMethod={deliveryMethod}
              sourceMemberCode={sourceMemberCode}
              onComplete={setRecordId}
              onAction={setRecordAction}
              actionsList={actionsList}
              subActionsList={subActionsList}
              menuAction={menuAction}
              setMenuAction={setMenuAction}
              recordAction={recordAction}
              listingInfo={listingInfo}
              statusList={statusList}
              initData={initData}
              fetchData={fetchData}
            />
          </View>
        )}
      </SplitScreen>
    </>
  );
};

export default React.memo(ListingScreen);
