const DELIVERY = Object.freeze({
  DELETE_ROUTE:
    "All orders have been removed. Would you like to delete this route?",
  SAVE_ROUTE_CONFIRM: "Would you like to save this route?",
  OPTIMISE_SUGGEST:
    "Would you like to optimize this route before leaving the page?",
  DS_OPERATOR: "DeliveryService",
});

export default DELIVERY;
