import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { DeviceContext } from "library/contexts/appSettings";
import styles from "components/containers/listing-new/styles";

import {
  PrintIframeAction,
  ToasterHandler,
  Tooltip,
} from "components/elements";

import { IMAGES } from "static/assets/images";
import EmailInvoice from "components/views/drawer/order-details/order-head/take-action/emailInvoice";

import {
  selectCustomerDetails,
  selectSelectedPaymentDetails,
} from "library/sagas/views/home/drawer/customer-directory/selector";

import { setOnDemandInvoiceFields } from "library/sagas/ongoing/current-orders/slice";
import {
  sendEmailReceipts,
  setSelectedPaymentDetails,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { CUSTOMER_DIRECTORY } from "library/constants";
import { getPrintReceiptFormattedHtml } from "components/views/drawer/customer-directory/upsert-screen/helper";
import common from "library/utils/common";

const Item = ({ index, data, columns, subColumnsList }) => {
  const dispatch = useDispatch();

  const { isMobile } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);

  const isElectronApp = common.checkIsElectronApp();

  const customerDetails = useSelector(selectCustomerDetails);
  const selectedPaymentDetails = useSelector(selectSelectedPaymentDetails);

  const [showOnDemandEmailReceiptForm, setShowOnDemandEmailReceiptForm] =
    useStateIfMounted(false);

  const { email = "", customerId = "" } = customerDetails;
  const { paymentId = "", shopCode = "" } = selectedPaymentDetails;

  const sendPaymentReceipt = (params) => {
    const {
      requestType,
      filteredEmails = [],
      paymentId,
      customerId,
      shopCode,
      formikBag,
    } = params;

    dispatch(
      sendEmailReceipts({
        payload: {
          requestPayload: {
            paymentId,
            requestType,
            customerId,
            ...(filteredEmails?.length > 0 && { recipients: filteredEmails }),
          },
          shopCode,
        },
        resolve: (response) => {
          if (requestType === "EMAIL") {
            ToasterHandler(
              "nice",
              Localise(messages, CUSTOMER_DIRECTORY.EMAIL_RECEIPT_SUCCESS_MSG)
            );
            formikBag.setSubmitting(false);
            dispatch(
              setOnDemandInvoiceFields({
                customerEmailFields: [email],
                showOnDemandInvoiceForm: false,
              })
            );
            setShowOnDemandEmailReceiptForm(false);
          } else {
            const content = response?.paymentReceiptHtml;

            const updatePrintStatus = () => {
              // show success toaster
              ToasterHandler(
                "hooray",
                Localise(messages, CUSTOMER_DIRECTORY.PRINT_RECEIPT_SUCCESS_MSG)
              );
            };

            if (isElectronApp) {
              const printContent = [content];
              const { ipcRenderer } = window.require("electron");
              ipcRenderer.send("printOrderInfo", 1, printContent, true);
              setTimeout(() => {
                updatePrintStatus();
              }, 100);
            } else {
              const inputhtml = getPrintReceiptFormattedHtml(content);
              PrintIframeAction(
                inputhtml,
                { printType: "receipt", preferences: {} },
                updatePrintStatus,
                "iframeId"
              );
            }
          }
        },
        reject: (error) => {
          if (requestType === "EMAIL") {
            formikBag.setSubmitting(false);
            setShowOnDemandEmailReceiptForm(false);
          }
          ToasterHandler("uh oh", Localise(messages, error));
        },
      })
    );
  };

  const sendOnDemandReceiptEmail = (values, formikBag) => {
    if (formikBag.isSubmitting) return;

    const filteredEmails = values?.recipientEmail.filter(
      (email) => email.trim() !== ""
    );

    sendPaymentReceipt({
      requestType: "EMAIL",
      filteredEmails,
      paymentId,
      customerId,
      shopCode,
      formikBag,
    });
  };

  const onCloseOnDemandReceiptEmailForm = () => {
    setShowOnDemandEmailReceiptForm(false);
  };

  const printOnDemandPaymentReceipt = (data) => {
    const { paymentId = "", shopCode = "" } = data;

    sendPaymentReceipt({
      requestType: "PRINT",
      paymentId,
      customerId,
      shopCode,
    });
  };

  const showIcons = ![
    "Debit Memo Issued",
    "Credit Memo Issued",
    "Credit Balance Added with Credit Memo",
  ].includes(data?.paymentType);

  return (
    <>
      <View
        style={{
          minWidth: 300,
        }}
      >
        <View style={styles.container} fsClass="fs-unmask">
          <View style={[styles.row]} fsClass="fs-unmask">
            {columns?.map(({ column, columnWidth }, index) => (
              <View key={index} style={{ width: columnWidth }}>
                <Text
                  style={[styles.column, !isMobile && { fontSize: 13 }]}
                  numberOfLines={2}
                >
                  {column === "amountPaid" ? `$${data[column]}` : data[column]}
                </Text>

                {column === "paymentType" &&
                data[[subColumnsList[column]]]?.length !== undefined ? (
                  <>
                    {data[[subColumnsList[column]]]?.map(
                      (subItem, subIndex) => (
                        <View key={subIndex}>
                          <Text
                            key={`${subItem?.orderID}_${subIndex}`}
                            style={{
                              fontWeight: "500",
                              fontSize: isMobile ? 9 : 10,
                              paddingLeft: 5,
                            }}
                            testID={`${subItem}_${subIndex}`}
                            accessibilityLabel={`${subItem}_${subIndex}`}
                            numberOfLines={isMobile ? 3 : 1}
                          >
                            {`${subItem?.orderID} - ${`$${parseFloat(
                              subItem?.paymentAmount
                            ).toFixed(2)}`}`}
                          </Text>
                        </View>
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
                {column === "icons" && showIcons ? (
                  <>
                    <TouchableOpacity
                      style={{ paddingHorizontal: 5 }}
                      onPress={() => {
                        setShowOnDemandEmailReceiptForm(true);
                        dispatch(setSelectedPaymentDetails(data));
                      }}
                      testID="send-email-receipt"
                      accessibilityLabel="send-email-receipt"
                    >
                      <Tooltip
                        text={Localise(
                          messages,
                          CUSTOMER_DIRECTORY.EMAIL_RECEIPT_TOOLTIP
                        )}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          resizeMode="contain"
                          source={IMAGES["email-receipt"]}
                        />
                      </Tooltip>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ paddingHorizontal: 5 }}
                      onPress={() => {
                        printOnDemandPaymentReceipt(data);
                      }}
                      testID="print-receipt"
                      accessibilityLabel="print-receipt"
                    >
                      <Tooltip
                        text={Localise(
                          messages,
                          CUSTOMER_DIRECTORY.PRINT_RECEIPT_TOOLTIP
                        )}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          resizeMode="contain"
                          source={IMAGES[`print`]}
                        />
                      </Tooltip>
                    </TouchableOpacity>
                  </>
                ) : (
                  <></>
                )}
              </View>
            ))}
          </View>
        </View>
      </View>
      {showOnDemandEmailReceiptForm && (
        <EmailInvoice
          title="Email Receipt"
          showModal={showOnDemandEmailReceiptForm}
          onSubmit={sendOnDemandReceiptEmail}
          onCancel={onCloseOnDemandReceiptEmailForm}
          customerInfo={customerDetails}
        />
      )}
    </>
  );
};

export default Item;
