/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { View } from "react-native";
import { Input } from "react-native-elements";
import tw from "tailwind-rn";
import { formatPrice } from "library/utils/formatter";
import { InfoLabel } from "components/elements";
import { colors } from "styles/theme";

const AdditionCalculator = ({ setFieldValue }) => {
  const [expression, setExpression] = useState("");
  const [error, setError] = useState("");

  const calculateTotal = () => {
    // Validate the input expression
    if (
      /[^0-9.+\-*/\s]/.test(expression) || // Check for invalid characters
      /[*+/\-]$/.test(expression) // Check if expression ends with an operator
    ) {
      setError("Invalid value entered"); // Set error message
      setFieldValue("ChecksValue", 0); // Reset total to 0
      return;
    } else {
      setError(""); // Clear error if validation passes
    }

    try {
      // Sanitize and evaluate the expression
      const sanitizedExpression = expression.replace(/[^0-9.+\-*/]/g, ""); // Allow numbers and operators only
      const sum = new Function(`return ${sanitizedExpression}`)(); // Evaluate the expression safely
      setFieldValue("ChecksValue", formatPrice(isNaN(sum) ? 0 : sum)); // Set total or 0 if NaN
    } catch (error) {
      setFieldValue("ChecksValue", 0); // In case of any error, reset total to 0
    }
  };

  return (
    <View style={tw("pt-1")}>
      <InfoLabel
        toolTipProps={{
          showToolTip: true,
          toolTipText:
            "Enter check values separated by a ‘+’ to calculate the total amount received via checks.",
        }}
        iconProps={{
          iconStyle: { marginHorizontal: 5 },
          size: 12,
          iconPosition: "right",
        }}
        labelProps={{
          showLabel: true,
          labelText: "Enter Check Values",
          labelStyle: {
            fontWeight: "700",
            fontSize: 12,
            color: colors.primary,
            marginLeft: 5,
          },
        }}
        containerStyle={{ marginBottom: 5 }}
      />
      <Input
        value={expression}
        onChangeText={setExpression}
        onBlur={calculateTotal}
        keyboardType="numeric"
        errorMessage={error}
      />
    </View>
  );
};

export default AdditionCalculator;
