import React, { useState } from "react";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { DeviceContext } from "library/contexts/appSettings";
import { SaveCancelButtons } from "../../helper";
import { InfoLabel, ToasterHandler } from "components/elements";
import { Form, FormFieldSwitch } from "components/elements/forms";
import { Picker } from "components/elements";
import { fonts, colors } from "styles/theme";
import get from "lodash/get";
import AppSettingsContext from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
// import UserProfileStorage from "library/storage/userProfile";

import {
  saveCustomerHouseAccount,
  setAccountingType,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  selectCustomerDetails,
  selectHouseAccountType,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectShopPreferences } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { CUSTOMER_DIRECTORY } from "library/constants";
import { getValidationSchema } from "./yup";
import { getSuccessMessage } from "./helper";

const HouseAccount = (props) => {
  const {
    UIConfig,
    preferences = {},
    enableHouseAccount = true,
    showAccountingType = true,
    disableOnDirty = true,
    hideSaveCancel,
    formRef,
    setFormSubmitting,
    onSuccess,
    onReject,
    onCreditLimitChange = () => {},
  } = props;
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectCustomerDetails);
  const shopPreferences = useSelector(selectShopPreferences);
  const {
    customerId,
    storeOrigin,
    phone,
    email,
    address: {
      addressLine1 = "",
      city = "",
      state = "",
      country = "",
      zipcode = "",
    } = {},
    houseAccountInfo = {
      houseAccountId: "",
      isActive: false,
    },
  } = customerDetails;
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    InputFields: { fields },
  } = UIConfig;
  const [loading, setLoading] = useState(false);

  const selectedShopPermissions = get(permissions, storeOrigin, {});
  const isOpenItemAndBalanceForwardEliglible =
    selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
      Entitlements.HOUSE_ACCOUNT_PERMISSIONS.OPEN_ITEM_ACCOUNTING
    ) &&
    selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
      Entitlements.HOUSE_ACCOUNT_PERMISSIONS.BALANCE_FORWARD_ACCOUNTING
    );

  function onSubmit(values, formikBag) {
    setFormSubmitting && setFormSubmitting(true);
    const isAutoEmailInvoicesChanged =
      values?.autoEmailInvoices !== houseAccountInfo?.autoEmailInvoices;
    const isAutoEmailStatementsChanged =
      values?.autoEmailStatements !== houseAccountInfo?.autoEmailStatements;
    dispatch(
      saveCustomerHouseAccount({
        params: {
          ...values,
          customerId,
          storeOrigin,
        },
        resolve: () => {
          onSuccess && onSuccess();
          const successMessage = Localise(
            messages,
            getSuccessMessage(
              isAutoEmailInvoicesChanged,
              isAutoEmailStatementsChanged,
              values,
              houseAccountInfo
            )
          );
          ToasterHandler("success", successMessage);
          formikBag.setSubmitting(false);
        },
        reject: (message) => {
          onReject && onReject();
          ToasterHandler(
            "error",
            Localise(
              messages,
              message || "Failed to update House Account. Please try again"
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  }

  const toolTipText = `${Localise(
    messages,
    "Add Customer name, phone, email, and address in Customer Info section before activating House Account"
  )}`;

  const isEligibleForHouseAccount =
    email &&
    phone &&
    //!isNaN(phone) &&
    addressLine1 &&
    city &&
    state &&
    zipcode &&
    country;

  const supportInvoice =
    (preferences["house_account_invoice"] ||
      shopPreferences["house_account_invoice"]) === "true";
  const { isActive, accountType, ...otherProps } = houseAccountInfo;
  const selectedAccountingType = useSelector(selectHouseAccountType);

  // const getStorePreferences =
  //   UserProfileStorage.getShopPreferences(storeOrigin);

  // const {
  //   email_ha_invoices_notification_settings = "",
  //   house_account_statement_notification_settings = "",
  // } = getStorePreferences;

  // const isEnabledGlobalAutoSendInvoices =
  //   !email_ha_invoices_notification_settings
  //     ? false
  //     : JSON.parse(email_ha_invoices_notification_settings)?.emailToggle;

  // const isEnabledGlobalAutoSendStatements =
  //   !house_account_statement_notification_settings
  //     ? true
  //     : JSON.parse(house_account_statement_notification_settings)?.emailToggle;

  return (
    <>
      {!isActive &&
        isOpenItemAndBalanceForwardEliglible &&
        houseAccountInfo?.houseAccountId?.length === 0 &&
        showAccountingType && (
          <View style={tw(`flex flex-row justify-start pb-2 items-center`)}>
            <Text
              style={{
                height: 40,
                width: isMobile ? "25%" : "30%",
                display: "flex",
                alignItems: "center",
                ...fonts.heading5,
              }}
              testID="selectAccountType"
            >
              {Localise(messages, "Accounting Type")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: isMobile ? "75%" : "70%",
              }}
            >
              <Picker
                containerStyle={{
                  width: "100%",
                  paddingTop: 7,
                }}
                items={[
                  {
                    label: Localise(messages, "Balance Forward"),
                    value: "Balance_Forward",
                  },
                  {
                    label: Localise(messages, "Open Item Accounting"),
                    value: "Open_Item",
                  },
                ]}
                placeholder={{
                  label: Localise(messages, "Select Accounting Type"),
                  value: "select_accounting_type",
                }}
                value={selectedAccountingType}
                onValueChange={(val) => {
                  dispatch(setAccountingType(val));
                }}
              ></Picker>
            </View>
          </View>
        )}
      {enableHouseAccount && (
        <View style={tw(`flex flex-row justify-start pb-2 items-center`)}>
          <Text
            style={{
              height: 40,
              width: isMobile ? "25%" : "30%",
              display: "flex",
              alignItems: "center",
              ...fonts.heading5,
            }}
            testID="status"
          >
            {Localise(messages, "Status")}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 5,
              width: isMobile ? "75%" : "70%",
            }}
          >
            <FormFieldSwitch
              value={isActive}
              onValueChange={() => {
                if (loading || !supportInvoice) return;
                if (!isEligibleForHouseAccount) {
                  ToasterHandler(
                    "uh oh",
                    Localise(
                      messages,
                      "Customer info missing. Please add in Customer Info section above."
                    )
                  );
                  return;
                }
                setLoading(true);
                dispatch(
                  saveCustomerHouseAccount({
                    params: {
                      customerId,
                      storeOrigin,
                      accountType: accountType || selectedAccountingType,
                      isActive: !isActive,
                      ...(houseAccountInfo?.houseAccountId?.length === 0 &&
                        selectedAccountingType === "Open_Item" && {
                          creditBalance: 0, // passing this field while creating house acc for the first time
                        }),
                      ...otherProps,
                    },
                    resolve: (isCreate) => {
                      setLoading(false);
                      let successMessage = isCreate
                        ? "House Account is created successfully"
                        : `House Account has been ${
                            isActive ? "disabled" : "enabled"
                          }`;
                      ToasterHandler(
                        "success",
                        Localise(messages, successMessage)
                      );
                    },
                    reject: (message) => {
                      setLoading(false);
                      ToasterHandler(
                        "error",
                        Localise(
                          messages,
                          message ||
                            "Failed to update House Account. Please try again"
                        )
                      );
                    },
                  })
                );
              }}
              testID="toggleStatus"
            />

            {!isEligibleForHouseAccount && (
              <InfoLabel
                toolTipProps={{
                  showToolTip: true,
                  toolTipText,
                }}
                iconProps={{
                  iconStyle: { marginLeft: 10 },
                  size: 15,
                  iconPosition: "right",
                }}
              />
            )}
            <Text style={{ marginLeft: 10 }} testID="statusOfToggle">
              {Localise(messages, `${isActive ? "Active" : "Inactive"}`)}
            </Text>
            {loading && (
              <ActivityIndicator
                style={{ marginLeft: 10 }}
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
        </View>
      )}
      {isActive && (
        <Form
          innerRef={formRef}
          initialValues={houseAccountInfo}
          validationSchema={getValidationSchema(Localise, messages)}
          onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
          validateOnBlur={true}
          render={({ values, setFieldValue }) => {
            return (
              <>
                {fields.map((fieldInfo, index) => {
                  const {
                    formFieldElement: Component,
                    title,
                    formFieldProps,
                  } = fieldInfo;

                  let toggleProps = {};
                  if (formFieldProps?.value === "isEnabled") {
                    toggleProps.onValueChange = () => {
                      setFieldValue(
                        formFieldProps?.name,
                        !values[formFieldProps?.name]
                      );
                      // if (
                      //   formFieldProps?.name === "autoEmailInvoices"
                      //     ? isEnabledGlobalAutoSendInvoices
                      //       ? true
                      //       : values[formFieldProps?.name]
                      //     : isEnabledGlobalAutoSendStatements
                      //     ? true
                      //     : values[formFieldProps?.name]
                      // ) {
                      //   setFieldValue(
                      //     formFieldProps?.name,
                      //     !values[formFieldProps?.name]
                      //   );
                      // } else {
                      //   ToasterHandler(
                      //     "uh oh",
                      //     Localise(
                      //       messages,
                      //       formFieldProps?.name === "autoEmailInvoices"
                      //         ? CUSTOMER_DIRECTORY.ERROR_INVOICE_PREFERENCE_ENABLED
                      //         : CUSTOMER_DIRECTORY.ERROR_STATEMENT_PREFERENCE_ENABLED
                      //     )
                      //   );
                      // }
                    };
                    toggleProps.value = values[formFieldProps?.name];
                  }

                  const isDisplayPrefText =
                    ["autoEmailInvoices", "autoEmailStatements"].includes(
                      formFieldProps?.name
                    ) && !isMobile;

                  const prefText =
                    formFieldProps?.name === "autoEmailInvoices"
                      ? Localise(
                          messages,
                          values[formFieldProps?.name]
                            ? CUSTOMER_DIRECTORY.INVOICE_PREFERENCE_ENABLED
                            : CUSTOMER_DIRECTORY.INVOICE_PREFERENCE_DISABLED
                        )
                      : formFieldProps?.name === "autoEmailStatements"
                      ? Localise(
                          messages,
                          values[formFieldProps?.name]
                            ? CUSTOMER_DIRECTORY.STATEMENT_PREFERENCE_ENABLED
                            : CUSTOMER_DIRECTORY.STATEMENT_PREFERENCE_DISABLED
                        )
                      : null;

                  return (
                    <View
                      key={index}
                      style={tw(
                        `flex flex-row justify-start pb-2 items-${
                          Platform.OS !== "web" ? "center" : "baseline"
                        }`
                      )}
                    >
                      <Text
                        style={{
                          height: 40,
                          width: isMobile ? "25%" : "30%",
                          ...fonts.heading5,
                        }}
                      >
                        {title}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingLeft: 5,
                          width: isMobile ? "75%" : "70%",
                        }}
                      >
                        <Component
                          {...formFieldProps}
                          {...toggleProps}
                          handleOnBlur={onCreditLimitChange}
                        />
                        {isDisplayPrefText ? (
                          <Text style={{ marginHorizontal: 20 }}>
                            <InfoLabel
                              toolTipProps={{
                                showToolTip: true,
                                toolTipText: prefText,
                              }}
                              iconProps={{
                                iconStyle: { marginLeft: 10 },
                                size: 16,
                                iconPosition: "right",
                              }}
                            />
                          </Text>
                        ) : (
                          <Text
                            style={{ ...fonts.default, marginHorizontal: 24 }}
                          >
                            {Localise(messages, prefText)}
                          </Text>
                        )}
                      </View>
                    </View>
                  );
                })}
                {
                  <View style={{ display: hideSaveCancel ? "none" : "flex" }}>
                    <SaveCancelButtons disableOnDirty={disableOnDirty} />
                  </View>
                }
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default HouseAccount;
