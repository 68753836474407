import React, { useContext, useEffect } from "react";
import { View, ActivityIndicator, Text, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Picker, TabBar } from "components/elements";
import ScreenHeader from "components/containers/split-screen-new/header";
import { SideCar } from "components/wrappers";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import {
  setShopCode,
  setActiveTab,
  setSideCar,
  setTaxAccordian,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  selectIsPageLoading,
  selectIsPageLoaded,
  selectTabNames,
  selectActiveTab,
  selectShopCode,
  selectSideCar,
  selectSideCarTitle,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { formatQuillEditorStyles, isValidJSON } from "library/utils/formatter";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import isArray from "lodash/isArray";

import PreferencesSearch from "./mercury-network/florist-preferences/preferences-search";
import MercuryNetwork from "./mercury-network";
import ShopDetails from "./shop-details";
import Orders from "./orders";
import DeliveryPickup from "./delivery-pickup";
import Payments from "./payments";
import Catalog from "./catalog";
import Notifications from "./notifications";
import Addons from "./addons";
import EnclosureCard from "./shop-details/enclosure-card";
import CityZipCodes from "./delivery-pickup/fees-coverage/extended/city-zipcodes";
import NewCoverageArea from "./delivery-pickup/fees-coverage/extended/new-coverage-area";
import { PriceMinimumProducts } from "../product-catalog/common/settings";
import Marketing from "./marketing";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

const HeaderSiblings = () => {
  const loading = useSelector(selectIsPageLoading);

  return loading ? (
    <ActivityIndicator
      color={colors.activityIndicator}
      style={{ height: 25 }}
    />
  ) : null;
};

const TabBarSiblings = ({ shopCodes = [] }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);
  const shopNames = UserProfileStorage.getAllShopNames();

  return shopCodes.length > 1 ? (
    <View
      style={[
        tw(`flex flex-row items-center justify-${isMobile ? "start" : "end"}`),
        { flexGrow: 1 },
      ]}
    >
      <Text
        style={{
          ...fonts.heading4,
          maxWidth: isMobile ? 100 : 150,
        }}
      >
        {Localise(messages, "Select Shop:")}
      </Text>
      <Picker
        containerStyle={{
          height: 40,
          ...(isMobile ? { width: "60%" } : {}),
        }}
        items={shopCodes.map((code) => ({
          label: `${code} ${shopNames[code]}`,
          value: code,
        }))}
        placeholder={{}}
        value={shopCode}
        onValueChange={(val) => dispatch(setShopCode(val))}
      ></Picker>
    </View>
  ) : null;
};

export const MainScreen = ({
  defaultTab,
  shopCodes,
  loadingKey,
  saveSettings,
  error,
  setCardSettingsFormikData,
  fileInfo,
  setFileInfo,
  setRefreshPreferences,
  refreshPreferences,
  screenOneRef,
}) => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(selectIsPageLoaded);
  const currentTab = useSelector(selectActiveTab);
  const { type: sideCar } = useSelector(selectSideCar);
  const { isDesktop, isTablet } = useContext(DeviceContext);

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
  };

  useEffect(() => {
    if (!currentTab) setCurrentTab(defaultTab);
  }, []);

  if (currentTab && defaultTab != currentTab) return null;

  const preRenderSetttingsTab = ["addon"].includes(currentTab);

  return isLoaded || preRenderSetttingsTab ? (
    <View
      fsClass="fs-unmask"
      style={{
        maxWidth: sideCar ? (isDesktop ? 996 : isTablet ? 676 : 334) : "100%",
        minHeight: 500,
      }}
    >
      {currentTab === "mercury-network" && (
        <MercuryNetwork
          setRefreshPreferences={setRefreshPreferences}
          refreshPreferences={refreshPreferences}
        />
      )}
      {currentTab === "shop-details" && (
        <ShopDetails
          shopCodes={shopCodes}
          error={error}
          setCardSettingsFormikData={setCardSettingsFormikData}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          loadingKey={loadingKey}
          saveSettings={saveSettings}
          screenOneRef={screenOneRef}
        />
      )}
      {currentTab === "order-settings" && (
        <Orders loadingKey={loadingKey} saveSettings={saveSettings} />
      )}
      {currentTab === "delivery-pickup" && (
        <DeliveryPickup
          loadingKey={loadingKey}
          saveSettings={saveSettings}
          screenOneRef={screenOneRef}
        />
      )}
      {currentTab === "payments" && (
        <Payments loadingKey={loadingKey} saveSettings={saveSettings} />
      )}
      {currentTab === "catalog-settings" && <Catalog />}
      {currentTab === "notifications" && (
        <Notifications saveSettings={saveSettings} />
      )}
      {currentTab === "addon" && (
        <Addons shopCodes={shopCodes} screenOneRef={screenOneRef} />
      )}
      {currentTab === "marketing" && <Marketing />}
    </View>
  ) : null;
};

export const SideScreen = ({
  defaultTab,
  cardSettingsFormikData,
  error,
  fileInfo,
  setError,
  setRefreshPreferences,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const currentTab = useSelector(selectActiveTab);
  const isLoaded = useSelector(selectIsPageLoaded);
  const { type: sideCar, orderInvoiceData } = useSelector(selectSideCar);
  if (!sideCar || (currentTab && defaultTab != currentTab)) return null;

  return isLoaded ? (
    <SideCar titleSelector={selectSideCarTitle} onCloseAction={setSideCar}>
      {["florist_partner_details", "search_florist_partners"].includes(
        sideCar
      ) && <PreferencesSearch setRefreshPreferences={setRefreshPreferences} />}
      {sideCar === "enclosure-card" && (
        <EnclosureCard
          previewInfo={cardSettingsFormikData}
          img={(fileInfo && fileInfo.uri) || ""}
          error={error}
          setError={(key) => setError({ keys: [...error.keys, key] })}
          removeError={(key) => {
            if (!error.keys.length) return;
            const index = error.keys.findIndex((x) => x === key);
            index >= 0 && error.keys.splice(index, 1);
            setError({ keys: [...error.keys] });
          }}
        />
      )}
      {sideCar === "order-invoice-card" && Platform.OS === "web" && (
        <View>
          <View style={tw("my-2")}>
            <Text style={[fonts.heading1, tw("mb-2")]}>
              {Localise(messages, "Card Layout")}
            </Text>
          </View>
          <View style={tw("flex w-full")}>
            <View style={[tw("flex flex-row w-full")]}>
              <Text
                style={[
                  tw(
                    "w-1/2 flex flex-wrap justify-center items-center text-center"
                  ),
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    fontSize: "9",
                    padding: 10,
                    flexShrink: 1,
                  },
                ]}
              >
                {Localise(messages, "Card Message ")}
              </Text>

              <Text
                style={[
                  tw(
                    "w-1/2 flex flex-wrap justify-center items-center text-center"
                  ),
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    fontSize: "9",
                    padding: 10,
                    flexShrink: 1,
                  },
                ]}
              >
                {Localise(messages, "Delivery Address")}
              </Text>
            </View>

            <View style={[tw("flex flex-row w-full")]}>
              <Text
                style={[
                  tw(
                    "w-1/2 flex flex-wrap justify-center items-center text-center"
                  ),
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    fontSize: "9",
                    padding: 10,
                    flexShrink: 1,
                  },
                ]}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatQuillEditorStyles(
                      orderInvoiceData["orderInvocieSection3Content"]
                    ),
                  }}
                />
              </Text>

              <Text
                style={[
                  tw(
                    "w-1/2 flex flex-wrap justify-center items-center text-center"
                  ),
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    fontSize: "9",
                    padding: 10,
                    flexShrink: 1,
                  },
                ]}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatQuillEditorStyles(
                      orderInvoiceData["orderInvocieSection4Content"]
                    ),
                  }}
                />
              </Text>
            </View>
          </View>
        </View>
      )}
      {sideCar === "city-zipcodes" && <CityZipCodes />}
      {sideCar === "new-coverage" && <NewCoverageArea />}
      {sideCar === "priceMinimumProducts" && (
        <PriceMinimumProducts shopSettings={true} />
      )}
    </SideCar>
  ) : null;
};

export const CustomHeader = ({ defaultTab, shopCodes = [] }) => {
  const dispatch = useDispatch();
  const tabNames = useSelector(selectTabNames);
  const currentTab = useSelector(selectActiveTab);
  const { messages, Localise } = useContext(I18NContext);

  const setCurrentTab = (name) => {
    dispatch(setActiveTab(name));
    dispatch(setTaxAccordian(false));
  };

  if (currentTab && defaultTab != currentTab) return null;

  return (
    <>
      <ScreenHeader
        title={Localise(messages, "Shop Settings")}
        style={{
          justifyContent: "flex-start",
          paddingHorizontal: currentTab === "notifications" ? 20 : 0,
        }}
        siblings={HeaderSiblings}
      />
      <TabBar
        containerStyle={tw("w-full")}
        tabNames={tabNames}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        style={{
          marginTop: -10,
          paddingHorizontal: currentTab === "notifications" ? 20 : 0,
        }}
        siblings={() => <TabBarSiblings shopCodes={shopCodes} />}
      />
    </>
  );
};

export const getSystemMacAddress = () => {
  let macAddress =
    Platform.OS === "web" && document.getElementById("macAddress").value;

  if (macAddress) {
    return macAddress;
  } else {
    const si = window.require("systeminformation");

    //Getting System MAC address as manufacturer_model_serial
    return si.system().then((data) => {
      const { manufacturer, model, serial } = data;
      macAddress = `${manufacturer}_${model}_${serial}`;
      return macAddress;
    });
  }
};

export const getCurrentPOSSettings = async (settings) => {
  const hasMultiplePreferences = isArray(settings);

  const macAddress = await getSystemMacAddress();

  let currentPOSSettings = {};
  let currentPOSSettingsIndex = -1;

  if (hasMultiplePreferences) {
    currentPOSSettingsIndex = settings.findIndex(
      (each) => each.mac_address === macAddress
    );

    if (currentPOSSettingsIndex > -1)
      currentPOSSettings = settings[currentPOSSettingsIndex];
  } else if (settings?.mac_address === macAddress) {
    currentPOSSettings = settings;
  }

  return {
    currentPOSSettings,
    currentPOSSettingsIndex,
  };
};

//Occasion preferenceKeys saved in member preferences.
const occasionSettingsKeys = [
  "birthday_occasion_notification_settings",
  "anniversary_occasion_notification_settings",
];

//Read settings string as Object
export const getShopSettingsByKey = (shopSettings, key) => {
  let settings = shopSettings[key];
  if (isValidJSON(settings)) return JSON.parse(settings);
  return {};
};

//Set EmailToggle to false for all occasionReminder settings.
export const resetOccasionReminderSettings = (shopSettings) => {
  const updatedSettings = [];

  occasionSettingsKeys.forEach((settingsKey) => {
    const shopSettingsByKey = getShopSettingsByKey(shopSettings, settingsKey);
    if (isEmpty(shopSettingsByKey)) return;

    const { emailToggle, ...other } = shopSettingsByKey;
    updatedSettings.push({
      id: settingsKey,
      values: [JSON.stringify({ emailToggle: false, ...other })],
    });
  });
  return updatedSettings;
};

//Check is occasionReminder toggle on for any occasion.
export const isOccasionReminderOn = (shopSettings) => {
  return occasionSettingsKeys.some((settingsKey) => {
    const shopSettingsByKey = getShopSettingsByKey(shopSettings, settingsKey);
    if (isEmpty(shopSettingsByKey)) return;

    const { emailToggle } = shopSettingsByKey;
    return emailToggle;
  });
};

export const maxFileSize = 1.5 * 1024 * 1024; // 1.5MB in bytes

//Read preferences matching the macAddress if the setting has multiple configs. Ex: Reading pref matching macAddress
//{"invoice_settings":[{macAddress:x, auto_print:on}, {macAddress:y, auto_print:on}]}
export const getPreferences = (shopSettings, macAddress, key) => {
  const preferences = JSON.parse(shopSettings[key] || "[]");
  const hasMultiplePreferences = isArray(preferences);
  let matchIndex = -1;
  let matchingPreferences = {};

  if (hasMultiplePreferences) {
    matchIndex = preferences?.findIndex(
      (each) => each.macAddress === macAddress
    );
    if (matchIndex > -1) matchingPreferences = preferences[matchIndex];
  } else {
    matchingPreferences = preferences; //sets if value is string.
  }
  return matchingPreferences;
};

//update member preference for selected props
export const updatePreferences = (
  shopSettings,
  macAddress,
  settingsKey,
  propKey,
  propValue
) => {
  const preferences = shopSettings[settingsKey] || [];
  const hasMultiplePreferences = isArray(preferences);
  let matchIndex = -1;

  let clonedPreferences = cloneDeep(preferences);
  if (hasMultiplePreferences) {
    matchIndex = clonedPreferences.findIndex(
      (each) => each.macAddress === macAddress
    );
    if (matchIndex > -1)
      clonedPreferences.splice(matchIndex, 1, {
        ...clonedPreferences[matchIndex],
        [propKey]: propValue,
      });
  } else {
    clonedPreferences = [{ macAddress, [propKey]: propValue }];
  }
  return clonedPreferences;
};
