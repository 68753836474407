import { createStyles, minWidth } from "react-native-media-queries";
import { fonts, colors, backgroundColors } from "styles/theme";

export default createStyles(
  {
    container: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
      justifyContent: "space-between",
      borderRadius: 3,
      minHeight: 50,
      marginBottom: 5,
      padding: 5,
    },
    row: {
      borderColor: "transparent",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flex: 1,
      borderLeftWidth: 1,
      paddingRight: 5,
      paddingLeft: 2,
    },
    activeRowContainer: {
      borderColor: backgroundColors.primary,
      backgroundColor: "#fafafa",
    },
    activeRow: {
      color: backgroundColors.primary,
      fontWeight: "600",
    },
    column: {
      padding: 5,
      ...fonts.default,
      fontSize: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    menuContainer: {
      width: "5%",
      maxWidth: 30,
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 3,
    },
  },
  minWidth(767, {
    row: {
      flexWrap: "nowrap",
      flex: 1,
    },
    menuContainer: {
      justifyContent: "center",
      marginTop: 0,
    },
  })
);
