import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { useDispatch } from "react-redux";

import { fonts } from "styles/theme";

import { useNavigation, useNavigationState } from "@react-navigation/native";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { getBreadCrumbRoutes } from "./helper";

export const Breadcrumb = ({
  variant = "",
  closeSideCar,
  onLinkClick = () => {},
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);

  let { routes = [], history = [] } = useNavigationState(
    (state) => state || {}
  );

  const historyExists = history.length > 1;
  const currentRoute = history[history.length - 1] || {};
  const currentRouteInfo = routes.filter(
    (route) => route.key === currentRoute.key
  )[0];

  const previousRoute = (historyExists && history[history.length - 2]) || {};
  const previousRouteInfo = routes.filter(
    (route) => route.key === previousRoute.key
  )[0];

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const links = getBreadCrumbRoutes(
    currentRouteInfo,
    previousRouteInfo,
    variant
  );

  const onPress = ({ linkTo = "" }) => {
    dispatch(setCurrentPage(linkTo));
    navigation.navigate(linkTo);
  };

  return links.length ? (
    <View
      style={[
        tw(`flex flex-row items-center`),
        {
          paddingTop: 1,
          width: isMobile ? "100%" : "auto",
          paddingLeft: isDesktop ? 15 : 0,
        },
      ]}
    >
      {links.map((link, index) => (
        <View
          style={tw("flex flex-row justify-center items-center mt-1")}
          key={index}
        >
          {link.linkTo ? (
            <TouchableOpacity
              onPress={() => {
                if (variant !== "" && index === links.length - 2) {
                  closeSideCar();
                } else {
                  onLinkClick(onPress, { linkTo: link?.linkTo });
                }
              }}
            >
              <Text style={{ ...fonts.link2, textDecorationLine: "none" }}>
                {Localise(messages, link.title)}
              </Text>
            </TouchableOpacity>
          ) : (
            <Text style={fonts.heading5}>{Localise(messages, link.title)}</Text>
          )}
          {index !== links.length - 1 && <Text> / </Text>}
        </View>
      ))}
    </View>
  ) : null;
};
