export const getSuccessMessage = (
  isAutoEmailInvoicesChanged,
  isAutoEmailStatementsChanged,
  values,
  houseAccountInfo
) => {
  const isOtherFieldChanged = Object.keys(values).some(
    (key) =>
      key !== "autoEmailInvoices" &&
      key !== "autoEmailStatements" &&
      values[key] !== houseAccountInfo[key]
  );
  if (
    isAutoEmailInvoicesChanged &&
    !isAutoEmailStatementsChanged &&
    !isOtherFieldChanged
  ) {
    return values?.autoEmailInvoices
      ? "Auto email invoices enabled"
      : "Auto email invoices disabled";
  }

  if (
    isAutoEmailStatementsChanged &&
    !isAutoEmailInvoicesChanged &&
    !isOtherFieldChanged
  ) {
    return values?.autoEmailStatements
      ? "Auto email statements enabled"
      : "Auto email statements disabled";
  }

  return "House Account updated successfully";
};
