/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, useRef } from "react";
import { Text, Icon } from "react-native-elements";
import {
  ActivityIndicator,
  View,
  TouchableOpacity,
  Platform,
  Image,
  ScrollView,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";

import moment from "moment";
import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";

import { useFormikContext } from "formik";

import IMAGES from "static/assets/images";
import { backgroundColors, colors, fonts } from "styles/theme";

import { CustomModal, ToasterHandler, TabBar } from "components/elements";
import { Form } from "components/elements/forms";
import { Breadcrumb } from "components/containers/header/bread-crumb";

import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import AppSettingsContext from "library/contexts/appSettings";

import { getPrice } from "library/utils/createOrder";
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

import { formatPrice } from "library/utils/formatter";
import {
  getCardType,
  checkForNA,
  sendCommandToPAXTerminalV1,
  TransactionTypes,
  initiateRefundThroughTerminal,
  getCancelTerminalTxnMessage,
} from "library/utils/payment-options";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { defaultDeliveryProvider } from "library/utils/deliveryService";
import { hasOrderNotesFeature } from "library/utils/featureAvailability";

import {
  setOrderLocked,
  setRecordData,
  setAddressVerificationStatus,
  setActiveTab,
  setOrderDetailTabs,
  setDSEligibilityFailureMessage,
  setDconStatus,
  setReOpenWarnDetails,
} from "library/sagas/ongoing/current-orders/slice";
import {
  selectRecordData,
  selectOrderDetailTabs,
  selectActiveTab,
  selectReopenWarnDetails,
} from "library/sagas/ongoing/current-orders/selector";
import {
  setCurrentPage,
  fetchDesignerSuggestions,
} from "library/sagas/ongoing/global-data/slice";
import {
  fetchOrderData,
  setOrderDetailsFromScan,
  setIsSidecarOpen,
  modifyOrder as modifyOrderSaga,
  fetchRouteDetails,
  setRouteDetails,
  saveOrderViewerInfo,
  updateOrderViewerInfo,
  fetchOrderViewersInfo,
  setOrderViewersInfo,
} from "library/sagas/ongoing/order-details/slice";
import {
  selectApiError,
  selectPageStatus,
  selectIsFullRefund,
  selectIsOrderCancelled,
  selectOrderDetailsFromScan,
  selectOrderHeadHeight,
  selectCurrentViewerInfo,
} from "library/sagas/ongoing/order-details/selector";

import OrderHead from "./order-head";
import BuyerInfo from "./buyer-info";
import ProductDetails from "./product-details";
import DeliveryInfo from "./delivery-info";
import DconInfo from "./dcon-info";
import FulfillmentInfo from "./fulfillment-info";
import OrderMessages from "./order-messages";
import PickupInfo from "./pickup-info";
import WalkInOrderInfo from "./walk-in-order-info";
import FloristPickupInfo from "./florist-pickup-info";
import PaymentDetails from "./payment-details";
import CustomerProfileInfo from "./customer-profile-info";
import RecipientDateInfo from "./delivery-info/recipient-date-info";
import OrderNotes from "./order-notes";
import {
  getActionObj,
  getSupportedOrderActions,
  getOrderDetailsInitialValues,
  getOrderPatchUpdates,
  getOrderDetailsTabs,
  isAdddressChangedFn,
} from "./helper";
import getOrderDetailsValidationSchema from "./yup";
import { getValidationSchema as getDSOrderValidationSchema } from "components/views/drawer/delivery/delivery-service-dashboard/upsert-screen/yup";
import { DistanceLimitMapping } from "components/views/drawer/delivery/delivery-service-dashboard/upsert-screen/config";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import UpsertDesigner from "./upsert-designer";
import ReopenModal from "components/views/drawer/order-details/order-head/take-action/reopenModal";
import { showPrintToaster } from "components/views/drawer/order-details/order-head/take-action/helper";
import UploadImageButton from "./upload-image-button";
import FloristSelection from "components/views/drawer/create-order/florist-selection";
import { getShopSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { isPartnerPickupOrderSource } from "components/views/drawer/order-details/helper";

import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";
import { ORDERS } from "library/constants";

const isValidPickupTime = (pickUpDateTime, shopDayTimings) => {
  let pickUpTimeWithDate = moment(pickUpDateTime);
  let openingTime = moment(shopDayTimings.open);
  let closingTime = moment(shopDayTimings.close);
  return (
    moment().isBefore(pickUpTimeWithDate) &&
    openingTime.isSameOrBefore(pickUpTimeWithDate) &&
    closingTime.isSameOrAfter(pickUpTimeWithDate)
  );
};

//eslint-disable-next-line
const OrderDetails = React.memo(
  ({
    formValues,
    isPreview = false,
    recordId,
    deliveryMethod,
    sourceMemberCode,
    listingType,
    onComplete,
    onAction,
    actionsList,
    subActionsList,
    menuAction,
    setMenuAction,
    recordAction,
    scrollViewRef,
    showDriverAction,
    taxes,
    subTotals,
    listingInfo,
    statusList,
    initData,
    fetchData,
  }) => {
    const dispatch = useDispatch();

    const currentOrderTileRecordIdRef = useRef("");
    if (currentOrderTileRecordIdRef.current !== recordId)
      currentOrderTileRecordIdRef.current = recordId;

    const orderDetailResponse = useSelector(selectRecordData);
    const status = useSelector(selectPageStatus);
    const apiError = useSelector(selectApiError);
    const isOrderDetailsFromScan = useSelector(selectOrderDetailsFromScan);

    const orderDetailsFormRef = useRef();
    const terminalTxnCancellingRef = useRef(false);
    const tabDetailScrollRef = useRef(null);
    const selectedActionRef = useRef("");

    const { messages: locMessages, Localise } = useContext(I18NContext);
    const { memberCodes, userProfile: { email } = {} } =
      useContext(UserProfileContext);

    const [loading, setLoading] = useStateIfMounted(true);
    const [fulfillmentPos, setFulfillmentPos] = useStateIfMounted(0);
    const { isTablet } = useContext(DeviceContext);
    const [orderRefundStatus, setOrderRefundStatus] = useStateIfMounted("");
    const [isActionProcessing, setActionProcessing] = useStateIfMounted(false);
    const [currentPaymentMethod, setCurrentPaymentMethod] =
      useStateIfMounted("");

    const isLocalOrder = [
      "FLORIST_DELIVERED",
      "STORE_PICKUP",
      "WALK_IN",
      "PHONE_OUT",
    ].includes(deliveryMethod);

    const isMHQNonCoreUser = isMHQNonCoreMember(sourceMemberCode);

    const getOrderLockStatus = () => {
      request("get-order-lock-status", {
        recordId,
        deliveryMethod,
      }).then((res) => {
        const { isOrderLocked: isLocked, lockedBy } = res;
        if (isLocked && lockedBy !== email) {
          dispatch(setOrderLocked({ isLocked, lockedBy }));
        } else {
          dispatch(setOrderLocked({ isLocked: false }));
        }
      });
    };

    useEffect(() => {
      status === "progress" && !loading && setLoading(true);
      status === "done" && loading && setLoading(false);
    }, [status]);

    const pullOrderDetails = (
      recordId,
      deliveryMethod,
      sourceMemberCode,
      refreshDetails
    ) => {
      refreshDetails && setLoading(true);
      // dispatch(setOrderModifying(false));
      dispatch(
        fetchOrderData({
          recordId,
          deliveryMethod,
          sourceMemberCode,
          refreshDetails,
        })
      );

      dispatch(
        fetchDesignerSuggestions({
          members: sourceMemberCode,
          reject: (error) => {
            ToasterHandler("error", Localise(locMessages, error));
          },
        })
      );
    };

    useEffect(() => {
      if (fulfillmentPos > 0) {
        // scroll the order details scroll
        scrollViewRef &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: fulfillmentPos,
            animated: true,
          });
        // scroll the tab details scroll
        tabDetailScrollRef &&
          tabDetailScrollRef?.current?.scrollTo({
            x: 0,
            y: fulfillmentPos,
            animated: true,
          });
      }
    }, [fulfillmentPos]);

    useEffect(() => {
      recordId &&
        pullOrderDetails(recordId, deliveryMethod, sourceMemberCode, true);
      recordId && isLocalOrder && getOrderLockStatus();
      recordId &&
        dispatch(
          fetchOrderViewersInfo({
            params: {
              referenceId: recordId,
              sourceMemberCode,
            },
          })
        );
      recordId &&
        dispatch(
          saveOrderViewerInfo({
            params: {
              referenceId: recordId,
              referenceType: "ORDER",
              status: "VIEWING",
              siteId: sourceMemberCode,
            },
          })
        );
      menuAction && !menuAction.includes(recordId) && setMenuAction("");
      // getting the latest printer-settings to support remote print
      recordId &&
        dispatch(
          getShopSettings({
            params: {
              memberCode: sourceMemberCode,
              ids: isMHQNonCoreUser
                ? PrinterSettingsKeys
                : nonStandardEligiblePrinterSettings,
              updateStorage: true,
            },
          })
        );
    }, [recordId]);

    useEffect(() => {
      if (formValues) {
        const {
          deliveryFee = 0,
          retailDeliveryFee = 0,
          relayFee = 0,
          serviceFee = 0,
          retransFee = 0,
        } = get(formValues, "orderItems.0", {});
        let orderTotalPrice =
          subTotals.reduce((a, b) => a + b) +
          taxes.reduce((a, b) => a.taxAmount + b.taxAmount, {
            taxAmount: 0,
          }) +
          parseFloat(getPrice(deliveryFee)) +
          parseFloat(getPrice(relayFee)) +
          parseFloat(getPrice(retailDeliveryFee)) +
          parseFloat(getPrice(serviceFee)) +
          parseFloat(getPrice(retransFee));
        const orderRes = { ...formValues, orderTotalPrice, taxes, subTotals };
        dispatch(setRecordData(orderRes));
        setLoading(false);
      }
    }, [isPreview, formValues, taxes, subTotals]);

    const actionCallback = React.useCallback(
      (action, recordId, refreshNeeded = true, refreshDetails = true) => {
        onAction(`${action}${recordId}`);

        if (refreshNeeded && recordId === currentOrderTileRecordIdRef.current) {
          pullOrderDetails(
            recordId,
            deliveryMethod,
            sourceMemberCode,
            refreshDetails
          );
        }
      }
    );

    /**
     * If we opened order details side car from orders listing screen and if recordId is not available then
     * we are returning null here in order to avoid all other actions and unnecessary state changes / calls.
     */
    if (!isPreview && !recordId) return null;

    if (loading) {
      const isOrderNotFound =
        status === "fail" && apiError.includes("No Records");

      const isOrderDetailsReqFailed = status === "fail";

      // If order not found / order request failed for any reason then we are resetting the orderDetailsFromScan flag to prevent it to show the actions popup.
      if (
        isOrderDetailsFromScan &&
        (isOrderNotFound || isOrderDetailsReqFailed)
      )
        dispatch(setOrderDetailsFromScan(false));

      return (
        <View>
          <View
            style={{
              ...tw("px-3 py-3 flex flex-row items-center justify-between"),
              ...{ backgroundColor: backgroundColors.primary },
              ...(isTablet && { margin: 10 }),
            }}
          >
            <View style={tw("flex flex-row items-center justify-start")}>
              {isOrderNotFound ? (
                <Text
                  style={{
                    ...fonts.heading2,
                    ...tw("text-white"),
                  }}
                >
                  {Localise(locMessages, "Order Not Found")}
                </Text>
              ) : isOrderDetailsReqFailed ? (
                <>
                  <Text
                    style={{
                      ...fonts.heading2,
                      ...tw("text-white"),
                    }}
                  >
                    {Localise(
                      locMessages,
                      "Something went wrong, Please try again."
                    )}
                  </Text>
                  <Icon
                    name={"refresh"}
                    size={20}
                    type="material-community"
                    iconStyle={{ color: colors.secondary, marginRight: 5 }}
                    onPress={() =>
                      pullOrderDetails(
                        recordId,
                        deliveryMethod,
                        sourceMemberCode,
                        true
                      )
                    }
                    testID="calendar_left_arrow"
                    accessibilityLabel="calendar_left_arrow"
                  />
                </>
              ) : (
                <>
                  <Text
                    style={{
                      ...fonts.heading2,
                      ...tw("text-white"),
                    }}
                  >
                    {Localise(locMessages, "Loading Order")}
                  </Text>
                  <ActivityIndicator
                    style={{ marginLeft: 10 }}
                    color={colors.activityIndicator}
                  />
                </>
              )}
            </View>
            {!isTablet && (
              <View>
                <TouchableOpacity
                  onPress={() => onComplete()}
                  testID="close"
                  accessibilityLabel="close"
                >
                  <Image
                    style={{ width: 20, height: 20 }}
                    resizeMode="cover"
                    source={IMAGES["close"]}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      );
    } else if (isEmpty(orderDetailResponse)) {
      return null;
    }

    const feeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "feeRefundedAmount"
      )?.value || 0;
    const relayFeeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "relayFeeRefundedAmount"
      )?.value || 0;
    const taxRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "taxRefundedAmount"
      )?.value || 0;

    const serviceFeeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "serviceFeeRefundedAmount"
      )?.value || 0;

    const retransFeeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "retransFeeRefundedAmount"
      )?.value || 0;

    const rushFeeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "rushFeeRefundedAmount"
      )?.value || 0;

    const retailFeeRefundedAmount =
      orderDetailResponse?.orderAmounts?.find(
        (obj) => obj.name === "retailDeliveryFeeRefundedAmount"
      )?.value || 0;

    let newRefundedAmount = 0;

    newRefundedAmount =
      Number(feeRefundedAmount) +
      Number(relayFeeRefundedAmount) +
      Number(taxRefundedAmount) +
      Number(retransFeeRefundedAmount) +
      Number(serviceFeeRefundedAmount) +
      Number(rushFeeRefundedAmount) +
      Number(retailFeeRefundedAmount);

    {
      orderDetailResponse?.orderItems[0]?.lineItems?.map((lineItem, index) => {
        const lineItemRefundedAmount =
          lineItem?.lineItemAmounts?.find(
            (obj) => obj.name === "refundedAmount"
          )?.value || ``;

        newRefundedAmount += Number(lineItemRefundedAmount);
      });
    }

    const cancelTerminalTxn = () => {
      if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = true;

      setCurrentPaymentMethod("");

      sendCommandToPAXTerminalV1({
        transactionType: TransactionTypes.CANCEL,
        sendingMember: sourceMemberCode,
        callback: ({ terminalResponse }) => {
          console.log(
            "Cancel Txn Response from order details sidecar :>> ",
            terminalResponse
          );

          setActionProcessing(false);
          if (orderDetailsFormRef)
            orderDetailsFormRef.current.setSubmitting(false);

          ToasterHandler(
            "uh oh",
            Localise(
              locMessages,
              getCancelTerminalTxnMessage(terminalResponse?.ResponseText)
            )
          );

          if (terminalTxnCancellingRef)
            terminalTxnCancellingRef.current = false;
        },
      });
    };

    return (
      <Form
        initialValues={
          isPreview
            ? orderDetailResponse
            : getOrderDetailsInitialValues(orderDetailResponse)
        }
        onSubmit={() => {}}
        {...(!isPreview && {
          validationSchema: getOrderDetailsValidationSchema(
            Localise,
            locMessages,
            orderDetailsFormRef.current?.values ?? {}
          ),
        })}
        innerRef={orderDetailsFormRef}
        onReset={() => {}}
        render={() => (
          <View
            style={[
              {
                backgroundColor: backgroundColors.sideCar,
                ...(isTablet && { padding: 10 }),
              },
            ]}
          >
            {recordAction &&
            recordAction.includes("selectNewFlorist") &&
            recordAction.split("_")[2] === recordId ? (
              <FloristSelection
                onComplete={() => {
                  onAction(recordId);
                }}
                sideCarInfo={JSON.parse(recordAction.split("_")[1])}
                onSelect={(florist) => {
                  onAction(
                    `floristSelected_${JSON.stringify(florist)}_${recordId}`
                  );
                }}
              />
            ) : (
              <>
                {currentPaymentMethod.length > 0 && (
                  <CustomModal
                    modalVisible={currentPaymentMethod.length > 0}
                    modalContent={{
                      content: (
                        <View style={[tw("items-center")]}>
                          <View style={tw("flex flex-row")}>
                            <ActivityIndicator
                              style={{ marginLeft: 5, marginTop: -5 }}
                              color={colors.activityIndicator}
                            />
                            <Text style={{ marginLeft: 10 }}>
                              {Localise(locMessages, "Processing Payment")}
                            </Text>
                          </View>
                          {currentPaymentMethod === "PAYMENT_TERMINAL" && (
                            <View style={{ marginTop: 16 }}>
                              <Text>
                                {Localise(
                                  locMessages,
                                  "Cancel transaction from Terminal using [X] key or click Cancel button below."
                                )}
                              </Text>
                            </View>
                          )}
                        </View>
                      ),
                      ...(currentPaymentMethod === "PAYMENT_TERMINAL" && {
                        buttons: [
                          {
                            type: "primary",
                            title: Localise(locMessages, "Cancel"),
                          },
                        ],
                      }),
                    }}
                    primaryhandler={cancelTerminalTxn}
                    contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
                    modalStyle={
                      Platform.OS !== "web" && {
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                        backgroundColor: "#00000070",
                        color: "#FFFFFF",
                      }
                    }
                  />
                )}

                {isActionProcessing &&
                  terminalTxnCancellingRef.current === true &&
                  currentPaymentMethod.length === 0 && (
                    <CustomModal
                      modalVisible={
                        isActionProcessing &&
                        terminalTxnCancellingRef.current === true
                      }
                      modalContent={{
                        content: (
                          <View style={[tw("items-center")]}>
                            <View style={tw("flex flex-row")}>
                              <ActivityIndicator
                                style={{ marginLeft: 5, marginTop: -5 }}
                                color={colors.activityIndicator}
                              />
                              <Text style={{ marginLeft: 10 }}>
                                {`${Localise(
                                  locMessages,
                                  "Canceling transaction."
                                )}`}
                              </Text>
                            </View>
                          </View>
                        ),
                      }}
                      contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
                      modalStyle={
                        Platform.OS !== "web" && {
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          backgroundColor: "#00000070",
                          color: "#FFFFFF",
                        }
                      }
                    />
                  )}

                {!isNil(orderDetailResponse.orderItems) &&
                  orderDetailResponse.orderItems.map((item, index) => (
                    <OrderInfo
                      key={index}
                      index={index}
                      memberCodes={memberCodes}
                      listingType={listingType}
                      actionCallback={actionCallback}
                      actionsList={actionsList}
                      subActionsList={subActionsList}
                      isPreview={isPreview}
                      pullOrderDetails={pullOrderDetails}
                      menuAction={menuAction}
                      setMenuAction={setMenuAction}
                      recordAction={recordAction}
                      setLoading={setLoading}
                      setFulfillmentPos={setFulfillmentPos}
                      scrollViewRef={scrollViewRef}
                      recordId={recordId}
                      showDriverAction={showDriverAction}
                      taxes={taxes}
                      orderRefundStatus={orderRefundStatus}
                      setOrderRefundStatus={setOrderRefundStatus}
                      onComplete={onComplete}
                      isLocalOrder={isLocalOrder}
                      listingInfo={listingInfo}
                      statusList={statusList}
                      initData={initData}
                      fetchData={fetchData}
                      isActionProcessing={isActionProcessing}
                      setActionProcessing={setActionProcessing}
                      setCurrentPaymentMethod={setCurrentPaymentMethod}
                      tabDetailScrollRef={tabDetailScrollRef}
                      selectedActionRef={selectedActionRef}
                    />
                  ))}

                {/* Need to add similar structure for order total & payment method for create-order preview-screen */}

                <>
                  <View
                    style={{
                      ...tw("flex flex-row justify-between p-3 mt-4"),
                      backgroundColor: backgroundColors.primary2,
                      minHeight: 40,
                      marginBottom: 55,
                      zIndex: -1,
                    }}
                  >
                    <Text style={[fonts.heading3, tw("text-white")]}>
                      {Localise(locMessages, "Order Total")}
                    </Text>
                    <Text
                      style={[fonts.heading3, tw("pl-5 text-white")]}
                    >{`$${formatPrice(
                      parseFloat(
                        orderDetailResponse.orderTotalPrice -
                          formatPrice(newRefundedAmount)
                      )
                    )}`}</Text>
                  </View>
                </>
              </>
            )}
          </View>
        )}
      />
    );
  }
);

//eslint-disable-next-line
const OrderInfo = React.memo(
  ({
    memberCodes,
    listingType,
    isPreview,
    actionCallback,
    actionsList,
    subActionsList,
    index,
    pullOrderDetails,
    menuAction,
    setMenuAction,
    recordAction,
    setLoading,
    setFulfillmentPos,
    scrollViewRef,
    recordId,
    showDriverAction,
    taxes,
    orderRefundStatus,
    setOrderRefundStatus,
    onComplete,
    isLocalOrder,
    listingInfo,
    statusList,
    initData,
    fetchData,
    isActionProcessing,
    setActionProcessing,
    setCurrentPaymentMethod,
    tabDetailScrollRef,
    selectedActionRef,
  }) => {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const orderDetailResponse = useSelector(selectRecordData);
    const orderDetailsTabs = useSelector(selectOrderDetailTabs);
    const currentTab = useSelector(selectActiveTab);
    const showCancelSection = useSelector(selectIsFullRefund);
    const showRefundSection = useSelector(selectIsOrderCancelled);
    const orderHeadHeight = useSelector(selectOrderHeadHeight);
    const reopenWarnDetails = useSelector(selectReopenWarnDetails);
    const currentViewerInfo = useSelector(selectCurrentViewerInfo);
    const { isDesktop, isTablet } = React.useContext(DeviceContext);
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const { permissions } = React.useContext(AppSettingsContext);

    const { userProfile: { firstName: operator } = {} } =
      useContext(UserProfileContext);
    const {
      validateForm,
      isValid,
      values,
      setSubmitting,
      setErrors,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      initialValues,
    } = useFormikContext();

    const orderDetails = cloneDeep(orderDetailResponse.orderItems[index] || {});
    const orderTotalPrice = orderDetailResponse.orderTotalPrice || 0;
    const cardInfo = orderDetailResponse.cardInfo || {};
    const customerInfo = orderDetailResponse.customerInfo || {};
    const paymentDetails = orderDetailResponse.paymentDetails || {};
    const isSmallScreen = !isDesktop;
    const showPayment = !!orderDetailResponse.paymentDetails;

    // convert from utc to moment timezone
    orderDetails.createdOn = moment(orderDetails.createdOn).format();

    const {
      orderItemId,
      status: orderStatus = "",
      dsEligible = false,
      deliveryInfo: { deliveryMethod: actualDeliveryMethod },
      messages = [],
      buyerInfo = {},
      direction,
      receivingMember,
      sendingMember = {},
      hasRefundError = false,
      hasSettlementError = false,
      hasFulfillmentError = false,
      orderSource = "",
      pickupInfo,
      hasDSFulfillmentError = false,
      fees = {},
      distanceInMiles = "",
    } = orderDetails;

    const existingRetailDeliveryFee =
      get(fees, "applicableCharges.surCharges", []).find(
        (obj) => obj.name === "retailDeliveryFee"
      )?.value || 0;

    const selectedShopPreferences = UserProfileStorage.getShopPreferences(
      sendingMember.memberCode
    );

    const {
      deliveryDate,
      deliveryMethod,
      pickUpDateTime,
      pickUpBy = "",
      isDSSubmitted: isDSSubmitSuccess,
      recipientInfo: {
        addressLine1 = "",
        addressLine2 = "",
        city = "",
        state = "",
        zip = "",
        country = "",
      } = {},
      routeId,
    } = get(values, "orderItems.0.deliveryInfo", {});

    const { storePickupDateTime = "" } = pickupInfo || {};
    const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";
    const isPhoneOutOrder = deliveryMethod === "PHONE_OUT";
    const isFloristDelivered = actualDeliveryMethod === "FLORIST_DELIVERED";

    const supportedOrderActions = getSupportedOrderActions(
      listingType,
      isLocalOrder
    );
    const isPartnerPickupOrder = isPartnerPickupOrderSource(orderSource);
    const isPickUpOrder =
      (isPartnerPickupOrder &&
        !isEmpty(storePickupDateTime)) ||
      isStorePickupOrder ||
      isWalkInOrder ||
      (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP");

    let isFloristPickup = false;

    if (
      orderSource === "MOL" &&
      (deliveryMethod === "MOL_CUSTOMER_PICKUP" ||
        !isEmpty(storePickupDateTime))
    )
      isFloristPickup = true;

    const fillerMemberCode =
      direction === "INBOUND" ? receivingMember?.memberCode : "";

    const [actionTriggered, setActionTriggered] = useStateIfMounted({});
    const [requestTriggered, setRequestTriggered] = useStateIfMounted({});
    const [isRecipientInfoSubmitted, setRecipientInfoSubmitted] =
      useStateIfMounted("");

    const isInactiveOrder =
      direction === "INBOUND" &&
      ["CANCELLED", "REJECTED", "FORFEITED", "FORWARDED"].includes(orderStatus);

    const doNotFill =
      isInactiveOrder || !!isRecipientInfoSubmitted || hasRefundError;

    const sourceMemberCode =
      direction === "INBOUND"
        ? receivingMember?.memberCode
        : sendingMember?.memberCode;

    const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));

    const [showDconForm, setShowDconForm] = useStateIfMounted(
      listingType === "delivery" ? true : false
    );

    const isTransferEligible =
      direction === "INBOUND" &&
      !doNotFill &&
      !["DELIVERED"].includes(orderStatus) &&
      !isPickUpOrder &&
      !isPastDate &&
      orderSource !== "MOL" &&
      actualDeliveryMethod !== "FLORIST_DELIVERED";

    const actionInfo = recordAction.split("_");
    const showConfirmFlorist =
      actionInfo.length > 0
        ? actionInfo[0] === "floristSelected" && actionInfo[2] === orderItemId
        : false;

    const initiateRefund = (recordId, orderTotalPrice) => {
      if (!isEmpty(paymentDetails)) {
        request("initiate-refund", {
          isPartial: false,
          orderId: recordId,
          refundInitiatedAmount: orderTotalPrice,
          reason: "Other",
        })
          .then(() => {
            console.log("Refund initiated");
          })
          .catch(() => {
            "Error: Failed to refund";
          });
      }
    };

    useEffect(() => {
      showConfirmFlorist && dispatch(setActiveTab("order-summary"));
    }, [showConfirmFlorist]);

    useEffect(() => {
      isSubmitting && submitOrderDetailsForm();
    }, [isSubmitting]);

    const submitOrderDetailsForm = () => {
      if (isStorePickupOrder) {
        const { pickUpDateTime: newPickUpDateTime, shopDayTimings } = get(
          values,
          "orderItems.0.deliveryInfo",
          {}
        );
        const actualPickupDateTime = moment(
          storePickupDateTime || pickUpDateTime
        ).format("YYYY-MM-DDTHH:mm:ss");
        if (
          actualPickupDateTime !== newPickUpDateTime &&
          !isValidPickupTime(newPickUpDateTime, shopDayTimings)
        ) {
          const newErrors = {};
          set(
            newErrors,
            "orderItems.0.deliveryInfo.pickUpDateTime",
            Localise(locMessages, "Please enter a valid Pickup Time")
          );
          setSubmitting(false);
          setErrors(newErrors);
          return;
        }
      }
      setActionProcessing(true);
      saveOrderModifications();
    };

    const triggerOrderActionsRequest = ({ reqParams, actionTriggered }) => {
      setActionProcessing(true);

      const {
        action,
        transferTo = "",
        formikBag,
        remotePrint = false,
        remotePrinterName,
      } = actionTriggered;

      request("order-actions", reqParams)
        .then(() => {
          actionCallback(action, recordId, true, transferTo ? true : false);
          if (transferTo) {
            formikBag.setSubmitting(false);
            setActionProcessing(false);
            ToasterHandler(
              "thanks",
              Localise(
                locMessages,
                "Your order has been submitted for transfer"
              )
            );
          } else if (remotePrint && remotePrinterName) {
            showPrintToaster({
              remotePrint,
              printerName: remotePrinterName,
              artifactName: "Order Details",
              Localise,
              messages,
            });
            setActionProcessing(false);
          } else setActionProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          if (error === "INVALID_ACTION") {
            setActionProcessing(false);
            ToasterHandler(
              "uh oh",
              Localise(
                locMessages,
                "This order has expired, please refresh your page to remove."
              )
            );
          } else if (transferTo) {
            formikBag.setSubmitting(false);
            setActionProcessing(false);
            ToasterHandler(
              "uh oh",
              Localise(locMessages, "Please try to transfer your order again")
            );
          } else if (
            [
              "price-approval",
              "price-deny",
              "delivery-date-approval",
              "delivery-date-deny",
            ].includes(action) &&
            error === "There are new updates on this order"
          ) {
            setActionProcessing(false);
            formikBag?.setSubmitting(false);
            formikBag?.setFieldValue("isDenyClicked", false);
            ToasterHandler(
              "uh oh",
              Localise(
                locMessages,
                "There are new updates on this order, refresh page and try again"
              )
            );
          } else setActionProcessing(false);
        });
    };

    useEffect(() => {
      const {
        action,
        price = "",
        askId = "",
        isMessage = false,
        refreshNeeded = false,
        transferTo = "",
        formikBag,
        preferredDeliveryType = "",
        remotePrint = false,
        reject = () => {},
        resolve = () => {},
        params = {},
      } = actionTriggered;
      const { requestType } = requestTriggered;
      if (
        [
          "accept",
          "transfer",
          "assign",
          "price-approval",
          "price-deny",
          "print",
          "accept-print",
          "delivery-date-approval",
          "delivery-date-deny",
          "preferred-delivery-type",
          "cancel-approval",
          "cancel-deny",
          "design",
          "designed",
          "out-for-delivery",
        ].includes(action) ||
        (isPickUpOrder && ["delivery-confirmation"].includes(action))
      ) {
        let shopTimeZone =
          UserProfileStorage.getZFormatShopTimeZone(sourceMemberCode);
        const actionObj = getActionObj(
          deliveryDate,
          action,
          price,
          preferredDeliveryType,
          shopTimeZone
        );
        transferTo ? formikBag.setSubmitting(true) : setActionProcessing(true);
        if (showDconForm) {
          setShowDconForm(false);
          //reset delivery status prefill for form
          dispatch(setDconStatus(""));
        }
        const reqParams = {
          recordId,
          deliveryMethod: actualDeliveryMethod,
          action,
          askId,
          ...actionObj,
          operator,
          isMessage,
          sourceMemberCode: sourceMemberCode,
          transferMemberCode: transferTo,
          ...(remotePrint && { remotePrintRequest: true }),
        };
        if (
          ["DELIVERED"].includes(orderStatus) &&
          ["accept", "design", "designed", "out-for-delivery"].includes(action)
        ) {
          setActionProcessing(false);
          // order status update from Completed(Reopen Order) - show warning popup
          setTimeout(() => {
            dispatch(
              setReOpenWarnDetails({
                showModal: true,
                source: "details",
                action,
                resolve: () =>
                  triggerOrderActionsRequest({ reqParams, actionTriggered }),
              })
            );
          }, 100);
        } else {
          triggerOrderActionsRequest({ reqParams, actionTriggered });
        }
      } else if (
        ["markAsRead", "markAsUnread", "markAsReadAndRefund"].includes(action)
      ) {
        setActionProcessing(true);
        const isMarkRead =
          action === "markAsRead" || action === "markAsReadAndRefund";
        request("order-message-actions", {
          recordId,
          deliveryMethod: actualDeliveryMethod,
          markAsRead: isMarkRead,
          isStructured: true,
          isUnstructured: true,
          sourceMemberCode: sourceMemberCode,
          isCheckResponse: params?.isCheckResponse || false,
        })
          .then(() => {
            if (
              action === "markAsReadAndRefund" &&
              !hasFulfillmentError &&
              requestType === "cancel"
            ) {
              initiateRefund(recordId, orderTotalPrice);
            }
            actionCallback(action, recordId, true, false);
            setActionProcessing(false);
          })
          .catch(() => {
            setActionProcessing(false);
          });
      } else if (["markOrderAsRead"].includes(action)) {
        setActionProcessing(true);
        request("order-read-action", {
          recordId,
          deliveryMethod: actualDeliveryMethod,
          memberCode: sourceMemberCode,
          markAsRead: true,
        })
          .then(() => {
            if (!hasFulfillmentError && requestType === "cancel") {
              initiateRefund(recordId, orderTotalPrice);
              actionCallback(action, recordId, refreshNeeded);
            }
            setActionProcessing(false);
          })
          .catch(() => {
            setActionProcessing(false);
          });
      } else if (action === "OrderJourneyCancelDeny") {
        // Scrolling the user to top of the screen as per PAR-1052
        scrollViewRef &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: 0,
            animated: true,
          });
      } else if (
        !isPickUpOrder &&
        ["delivery-confirmation", "delivery-exception"].includes(action)
      ) {
        dispatch(setDconStatus(action));
        setShowDconForm(false);
        setTimeout(() => {
          setShowDconForm(true);
        }, 0);
      } else if (action === "save-order-changes") {
        submitOrderDetailsForm();
      } else if (action === "unlock-order") {
        request("unlock-order", {
          recordId,
          deliveryMethod: actualDeliveryMethod,
        });
      } else if (["copy-order", "edit-and-lock-order"].includes(action)) {
        if (action === "edit-and-lock-order") {
          (isLocalOrder ||
            ["MOL_FLORIST_DELIVERED"].includes(actualDeliveryMethod)) &&
            request("lock-order", {
              recordId,
              deliveryMethod: actualDeliveryMethod,
            });
        }
        navigation.dispatch(
          CommonActions.navigate({
            name: "create-order",
            params: {
              id: recordId,
              action: action.substring(0, action.indexOf("-")),
              smc: sourceMemberCode,
              dm: actualDeliveryMethod,
            },
          })
        );
        dispatch(setCurrentPage("create-order"));
      } else if (
        [
          "add-follow-up",
          "remove-follow-up",
          "update-order-notes",
          "update-delivery-instructions",
          "auto-flag-attempted-delivery",
          "delivery-date-change",
        ].includes(action)
      ) {
        const showProcessingLoader = [
          "add-follow-up",
          "remove-follow-up",
        ].includes(action);
        showProcessingLoader && setActionProcessing(true);
        dispatch(
          modifyOrderSaga({
            params,
            resolve: () => {
              showProcessingLoader && setActionProcessing(false);
              action === "delivery-date-change" &&
                setActionTriggered({
                  action: "markAsRead",
                  params: { isCheckResponse: true },
                });
              actionCallback(action, recordId, true, false);
              resolve && resolve();
            },
            reject: () => {
              showProcessingLoader && setActionProcessing(false);
              reject && reject();
            },
          })
        );
      } else {
        // keeping selected action in the ref for scrolling purpose after reloading the order details.
        if (["assign-to-designer", "upload-designed-image"].includes(action)) {
          selectedActionRef.current = action;
        }
        action &&
          actionCallback(
            action,
            recordId,
            !action.includes("selectNewFlorist"),
            [
              "triCardPrint",
              "invoice",
              "designerWorksheetPrint",
              "orderMessages",
              "receipt",
            ].includes(action)
              ? false
              : true
          );
      }
    }, [actionTriggered]);

    const getTokenizePaymentPayload = (
      values,
      merchantReferenceId = "",
      sendingMember,
      paymentType
    ) => {
      const {
        actualCardNumber = "",
        cvv: cvvNumber,
        expDate,
        billingInfo: { addressLine1, city, state, country },
        zip: zipCode,
      } = get(values, "paymentInfo.CREDIT_CARD", {});

      const {
        firstPaymentInfo: {
          tokenId = "",
          cardType = "",
          lastFourDigits = "",
        } = {},
      } = get(values, "customerInfo", {});

      const [expirationMonth, expirationYear] = expDate.split("/");

      const tokenizeReqPayload = {
        currency: "usd",
        isCalyxRoute: true,
        merchantReferenceId,
        processorAccount: "mhq",
        partnerCode: sendingMember,
        paymentMethod: {
          // ToDo: Need to clear based on billing API chages to make the fields optional.
          billTo: {
            address1: checkForNA(addressLine1),
            city: checkForNA(city),
            state: checkForNA(state),
            country: checkForNA(country),
            zipCode: checkForNA(zipCode),
          },
        },
        storePaymentMethod: false,
      };

      if (paymentType === "SAVED_CARD" && tokenId) {
        set(tokenizeReqPayload, "paymentMethod.tokenId", tokenId);
        set(tokenizeReqPayload, "paymentMethod.paymentType", cardType);
        set(tokenizeReqPayload, "paymentMethod.creditCard", {
          cardNumber: lastFourDigits,
          cardType,
          expirationMonth,
          expirationYear,
        });
      } else {
        const cardType = getCardType(actualCardNumber);
        set(tokenizeReqPayload, "paymentMethod.paymentType", cardType);
        set(tokenizeReqPayload, "paymentMethod.creditCard", {
          cardNumber: actualCardNumber.split(" ").join(""),
          cardType,
          cvvNumber,
          expirationMonth,
          expirationYear: expirationYear,
        });
      }
      return tokenizeReqPayload;
    };

    const TokenizeAndAuthorizePaymentOrderModification = (
      paymentType,
      retryCount = 0
    ) => {
      const getTokenApiRetryCount = Environment.get(
        "GET_TOKEN_API_RETRY_COUNT",
        3
      );

      const merchantReferenceId = get(
        orderDetailResponse,
        "paymentDetails.paymentMethod.0.merchantReferenceId",
        ""
      );
      const tokenizePayload = getTokenizePaymentPayload(
        values,
        merchantReferenceId,
        sendingMember?.memberCode,
        paymentType
      );
      request("tokenize-credit-card", {
        requestPayload: tokenizePayload,
      })
        .then((tokenizeResp = {}) => {
          if (!tokenizeResp.tokenId) {
            if (tokenizeResp.status === "declined") {
              const newErrors = {};
              set(
                newErrors,
                "paymentInfo.CREDIT_CARD.cardNumber",
                tokenizeResp.errorMessages[0]
              );
              setErrors(newErrors);
              setActionProcessing(false);
              setSubmitting(false);
            } else {
              if (retryCount < getTokenApiRetryCount - 1)
                TokenizeAndAuthorizePaymentOrderModification(
                  paymentType,
                  retryCount + 1
                );
            }
          } else {
            updateOrder(tokenizeResp?.tokenId);
          }
        })
        .catch((error) => {
          let errorMessage =
            error === "CREDIT_CARD_PROCESS_FAILURE"
              ? Localise(
                  messages,
                  ORDERS.CREDIT_CARD_PROCESS_FAILURE_ERROR_MESSAGE
                )
              : error === "CUSTOMER_ALREADY_EXISTS"
              ? Localise(messages, ORDERS.CUSTOMER_ALREADY_EXISTS_ERROR_MESSAGE)
              : error ||
                Localise(messages, ORDERS.PAYMENT_FAILED_ERROR_MESSAGE);

          setActionProcessing(false);
          setSubmitting(false);
          ToasterHandler("uh oh", Localise(messages, errorMessage));
        });
    };
    const modifyOrderErrorCallback = (values) => {
      const paymentMethodInfo = get(values, "paymentInfo.PAYMENT_TERMINAL", {});

      // We are initiating refunds always (if it is terminal payment) as we are in a catch block that indicates order modification is not successful
      if (paymentMethodInfo?.isHardwareTerminal) {
        const authorizationDetails = get(
          paymentMethodInfo,
          "authorizationDetails",
          []
        );

        const merchantReferenceId =
          authorizationDetails.find((obj) => obj.name === "merchantReferenceId")
            ?.value || "";

        const transactionId =
          authorizationDetails.find(
            (obj) => obj.name === "transactionDetailsId"
          )?.value || "";

        initiateRefundThroughTerminal({
          sendingMember: sourceMemberCode,
          merchantReferenceId,
          transactionId,
          resolve: ({ message }) => {
            setActionProcessing(false);
            setSubmitting(false);
            ToasterHandler("uh oh", Localise(messages, message));
          },
        });
      } else {
        setActionProcessing(false);
        setSubmitting(false);
        ToasterHandler(
          "uh oh",
          Localise(
            locMessages,
            "Order modify request failed, please try again."
          )
        );
      }
    };

    const modifyOrder = (orderUpdates, values) => {
      request("modify-order", {
        recordId,
        deliveryMethod: actualDeliveryMethod,
        orderUpdates,
        sourceMemberCode,
      })
        .then(() => {
          actionCallback("save-order-changes", recordId);
          setActionProcessing(false);
          setSubmitting(false);
          ToasterHandler(
            "hooray",
            Localise(locMessages, "Order modified successfully")
          );
        })
        .catch(() => {
          modifyOrderErrorCallback(values);
        });
    };

    const updateOrder = (tokenId = "") => {
      setActionProcessing(true);
      // Execute save order modifications API
      const orderUpdatesReqObj = getOrderPatchUpdates(
        orderDetailResponse,
        values,
        tokenId
      );

      if (hasSettlementError) {
        request("lock-order", {
          recordId,
          deliveryMethod: actualDeliveryMethod,
        })
          .then(() => {
            modifyOrder(orderUpdatesReqObj, values);
          })
          .catch(() => {
            modifyOrderErrorCallback(values);
          });
      } else {
        modifyOrder(orderUpdatesReqObj, values);
      }
    };

    const saveOrderModifications = () => {
      dispatch(setAddressVerificationStatus(""));
      validateForm().then(() => {
        if (isValid) {
          if (
            ["FLORIST_PARTNER", "FOL_FLORIST_DELIVERED"].includes(
              actualDeliveryMethod
            )
          ) {
            setActionProcessing(true);
            setRequestTriggered({
              requestType: "recipient-info",
              orderReq: {},
            });
            setSubmitting(false);
          } else {
            const {
              paymentType = "",
              newTotalAmount = 0,
              saveBillingInfo = false,
            } = values?.paymentInfo;

            const merchantReferenceId = get(
              orderDetailResponse,
              "paymentDetails.paymentMethod.0.merchantReferenceId",
              ""
            );

            if (["CREDIT_CARD", "SAVED_CARD"].includes(paymentType)) {
              TokenizeAndAuthorizePaymentOrderModification(paymentType);
            } else if (
              paymentType === "PAYMENT_TERMINAL" &&
              newTotalAmount > 0
            ) {
              setCurrentPaymentMethod(paymentType);

              sendCommandToPAXTerminalV1({
                transactionType: TransactionTypes.AUTHORIZATION,
                sendingMember: sourceMemberCode,
                merchantReferenceId,
                amount: newTotalAmount,
                requestMultiUseToken: saveBillingInfo,
                callback: ({ terminalResponse, paymentMethodDetails = {} }) => {
                  setCurrentPaymentMethod("");

                  if (terminalResponse.ResponseCode === "00") {
                    paymentMethodDetails = {
                      ...paymentMethodDetails,
                      authPerformed: true,
                      isHardwareTerminal: true,
                    };

                    // Setting paymentMethodDetails in values also in order to access them while initiating void when order modification failed
                    set(
                      values,
                      "paymentInfo.PAYMENT_TERMINAL",
                      paymentMethodDetails
                    );

                    updateOrder(paymentMethodDetails?.tokenId || "");
                  } else {
                    setActionProcessing(false);
                    setSubmitting(false);
                    ToasterHandler(
                      "uh oh",
                      Localise(locMessages, terminalResponse.ResponseText)
                    );
                  }
                },
                cancelBtnHandler: ({ txnStatus }) => {
                  setCurrentPaymentMethod(txnStatus);
                },
              });
            } else {
              updateOrder();
            }
          }
        } else {
          setActionProcessing(false);
        }
      });
    };

    const setNewDeliveryFee = (newDeliveryFee) => {
      const requestParams = {
        zip,
        city: encodeURIComponent(city || ""),
        state,
        country,
        deliveryDate,
        selectedMemberCode: sourceMemberCode,
        streetAddress: addressLine1 + " " + addressLine2,
      };
      request("get-delivery-fee", requestParams)
        .then((resp) => {
          setFieldValue(
            "paymentInfo.newDeliveryFee",
            (resp?.deliveryFee?.localFee ?? 0).toString()
          );
        })
        .catch((error) => {
          setFieldValue("paymentInfo.newDeliveryFee", newDeliveryFee);
          console.log("Failed to get delivery fee. Try again. ", error);
          ToasterHandler(
            "error",
            Localise(messages, "Something went wrong, please try again.")
          );
        });
    };

    const validateOrderForDSEligibility = (
      shopDeliveryProvider,
      isDSEnabled = false
    ) => {
      const eligibleProvidersList = Environment.get(
        "ELIGIBLE_DELIVERY_PROVIDERS",
        "roadie,doordash"
      ).split(",");

      if (eligibleProvidersList.includes(shopDeliveryProvider) && isDSEnabled) {
        const {
          firstName: recipientFirstName,
          lastName: recipientLastName,
          phone: recipientPhone,
          addressLine1,
          city,
          state,
          zip: zipcode,
          country: recipientCountry,
          locationType,
          locationName,
        } = get(orderDetailResponse, "orderItems.0.recipientInfo");
        const {
          city: fillerCity,
          state: fillerState,
          zip: fillerZipcode,
          country: fillerCountry,
          phone: fillerPhone,
        } = get(orderDetailResponse, "orderItems.0.receivingMember");

        const orderDSObject = {
          shopField: receivingMember?.memberCode,
          referenceNumber: orderItemId,
          deliveryDetails: {
            deliveryDate,
          },
          recipientDetails: {
            firstName: recipientFirstName,
            lastName: recipientLastName,
            addressLine1,
            phone: recipientPhone,
            city,
            state,
            zipcode,
            country: recipientCountry,
            locationType: locationType?.toLowerCase(),
            locationName,
          },
          fillerDetails: {
            phone: fillerPhone,
            city: fillerCity,
            state: fillerState,
            zipcode: fillerZipcode,
            country: fillerCountry,
          },
          floristTimeZone:
            UserProfileStorage.getShopTimeZone(receivingMember?.memberCode) ||
            "America/Chicago",
        };
        const customErrorMessages = {
          locationName: "Recipient Location Name is Required",
          addressLine1: "Recipient Street address is required",
          city: "Recipient City is required",
          recipientState: "Recipient State is required",
          recipientZipCode: "Recipient Postal Code is required",
          recipientCountry: "Recipient Country is required",
          nonUSRecipientCountry: "Recipient Country must be United States",
          fillerCity: "Fulfillment Member City is required",
          fillerState: "Fulfillment Member State is required",
          fillerZip: "Fulfillment Member Postal Code is required",
          fillerCountry: "Fulfillment Member Country is required",
          nonUSFillerCountry:
            "Fulfillment Member Country must be United States",
          fillerPhone: "Fulfillment Member Phone is required",
          invalidFillerPhone: "Fulfillment Member Phone is not valid",
        };

        const dsDistanceLimit =
          DistanceLimitMapping[shopDeliveryProvider] ||
          DistanceLimitMapping["default"];
        const distanceLimitMessage = `Delivery is beyond ${dsDistanceLimit} miles`;
        const hasDSDistanceLimitError =
          distanceInMiles && distanceInMiles > dsDistanceLimit;
        const getQuotations = () => {
          request("get-quotations", {
            memberCode: fillerMemberCode,
            referenceId: orderItemId,
            latestQuoteOnly: true,
          })
            .then((quotationDetails) => {
              const {
                eligible = false,
                vendor: { responseMessage = "" } = {},
              } = get(quotationDetails, "0", {});

              if (!eligible) {
                dispatch(
                  setDSEligibilityFailureMessage({
                    eligibility: responseMessage,
                  })
                );
              } else {
                dispatch(setDSEligibilityFailureMessage({ eligibility: "" }));
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch(setDSEligibilityFailureMessage({ eligibility: err }));
            });
        };
        //validate Order details
        getDSOrderValidationSchema(
          Localise,
          locMessages,
          [
            "undeliverableAction",
            "pickUpDateTime",
            "phone",
            "addressLine2",
            "recipientLocationType",
          ],
          ["fillerDetails", "pastDeliveryDate"],
          customErrorMessages
        )
          .validate(orderDSObject, { abortEarly: false })
          .then(() => {
            dispatch(
              setDSEligibilityFailureMessage({
                detailsValidation: hasDSDistanceLimitError
                  ? distanceLimitMessage
                  : "",
              })
            );
            if (!hasDSDistanceLimitError) {
              getQuotations();
            }
          })
          .catch((validationError) => {
            const { errors = [] } = validationError || {};
            let validationErrorMessage = errors.join("\n") || "";
            if (hasDSDistanceLimitError)
              validationErrorMessage += "\n" + distanceLimitMessage;
            if (hasDSDistanceLimitError || errors?.length) {
              dispatch(
                setDSEligibilityFailureMessage({
                  detailsValidation: validationErrorMessage,
                })
              );
            } else {
              getQuotations();
            }
          });
      }
    };

    useEffect(() => {
      const isOutgoingOrder =
        direction === "OUTBOUND" &&
        sendingMember &&
        sendingMember.memberCode !== receivingMember.memberCode;
      dispatch(
        setDSEligibilityFailureMessage({
          detailsValidation: "",
          eligibility: "",
        })
      );
      if (
        !isDSSubmitSuccess &&
        !dsEligible &&
        !isOutgoingOrder &&
        !isWalkInOrder &&
        !isPhoneOutOrder &&
        !isFloristPickup &&
        !isPickUpOrder
      ) {
        request("get-shop-settings", {
          ids: ["delivery_provider", "delivery_service"],
          memberCode: fillerMemberCode,
        })
          .then((res) => {
            if (res && res.preferences && res.preferences.length) {
              const result = processShopSettingsResponse(res);
              const {
                delivery_provider: deliveryProvider,
                delivery_service: deliveryServiceEnabled,
              } = result;
              const shopDeliveryProvider =
                deliveryProvider || defaultDeliveryProvider;
              const isDSEnabled = deliveryServiceEnabled === "true";

              validateOrderForDSEligibility(shopDeliveryProvider, isDSEnabled);
            }
          })
          .catch((err) => {
            console.log("error", err);
            const {
              delivery_provider: deliveryProvider,
              delivery_service: deliveryServiceEnabled,
            } = UserProfileStorage.getShopPreferences(fillerMemberCode);
            const shopDeliveryProvider =
              deliveryProvider || defaultDeliveryProvider;
            const isDSEnabled = deliveryServiceEnabled === "true";

            validateOrderForDSEligibility(shopDeliveryProvider, isDSEnabled);
          });
      }
    }, [dsEligible, isDSSubmitSuccess]);

    useEffect(() => {
      let newDeliveryFee = "";
      let newRetailDeliveryFee = "";

      // Initial order has florist delivered & converted to pickup/walk-in
      if (
        (isFloristDelivered || actualDeliveryMethod == "PHONE_OUT") &&
        (isStorePickupOrder || isWalkInOrder)
      ) {
        newDeliveryFee = "0.00";
        newRetailDeliveryFee = "0.00";
        // Below else if the order has been converted from pickup/walk-in to florist delivered
      } else if (
        ["STORE_PICKUP", "WALK_IN"].includes(actualDeliveryMethod) &&
        (deliveryMethod === "FLORIST_DELIVERED" || isPhoneOutOrder)
      ) {
        newRetailDeliveryFee = get(
          selectedShopPreferences,
          "retail_delivery_fee",
          ""
        );
      }
      actualDeliveryMethod !== deliveryMethod &&
      (deliveryMethod === "FLORIST_DELIVERED" || isPhoneOutOrder)
        ? setNewDeliveryFee("")
        : setFieldValue("paymentInfo.newDeliveryFee", newDeliveryFee);
      setFieldValue("paymentInfo.newRetailDeliveryFee", newRetailDeliveryFee);
      if (isStorePickupOrder) {
        setFieldTouched("orderItems.0.deliveryInfo.pickUpBy");
        setFieldTouched("orderItems.0.deliveryInfo.pickUpDateTime");
        if (!pickUpBy) {
          const {
            isBusinessProfile,
            customerType = "",
            businessName = "",
            firstName = "",
            lastName = "",
          } = values.customerInfo || {};
          const isBusinessAccount =
            isBusinessProfile || customerType === "Business";
          if (isBusinessAccount ? businessName : firstName && lastName) {
            setFieldValue(
              "orderItems.0.deliveryInfo.pickUpBy",
              isBusinessAccount
                ? businessName.trim()
                : `${firstName.trim()} ${lastName.trim()}`.trim()
            );
          }
        }
      }
    }, [deliveryMethod]);

    useEffect(() => {
      let shopCurrentRetailDeliveryFee = "0.00";
      if (
        !(isStorePickupOrder || isWalkInOrder) &&
        state === sendingMember.state
      ) {
        shopCurrentRetailDeliveryFee = get(
          selectedShopPreferences,
          "retail_delivery_fee",
          ""
        );

        if (
          !existingRetailDeliveryFee &&
          parseFloat(shopCurrentRetailDeliveryFee) > 0
        )
          setFieldValue(
            "paymentInfo.newRetailDeliveryFee",
            shopCurrentRetailDeliveryFee
          );
      } else {
        if (parseFloat(existingRetailDeliveryFee) > 0)
          setFieldValue("paymentInfo.newRetailDeliveryFee", "0.00");
      }
    }, [state]);

    useEffect(() => {
      if (
        !isAdddressChangedFn(
          values,
          initialValues,
          "orderItems.0.deliveryInfo.recipientInfo"
        )
      )
        return;
      dispatch(setAddressVerificationStatus("pending"));
      if (
        actualDeliveryMethod !== deliveryMethod &&
        (deliveryMethod === "FLORIST_DELIVERED" || isPhoneOutOrder)
      )
        setNewDeliveryFee("");
    }, [addressLine1, city, state, zip, country]);

    useEffect(() => {
      const { requestType, orderReq, requestCallBack } = requestTriggered;
      if (
        [
          "order-communication",
          "cancel",
          "delivery-date",
          "price",
          "adjustment",
          "recipient-info",
        ].includes(requestType)
      ) {
        const {
          deliveryInfo: { recipientInfo },
        } = get(values, "orderItems.0");

        const isAddressChanged = isAdddressChangedFn(
          values,
          initialValues,
          "orderItems.0.deliveryInfo.recipientInfo"
        );

        const payload =
          requestType === "recipient-info" ? recipientInfo : orderReq;

        if (!isAddressChanged) {
          const { latitude, longitude } = get(
            orderDetailResponse,
            "orderItems.0.recipientInfo"
          );

          payload.latitude = latitude;
          payload.longitude = longitude;
          payload.addressVerificationInfo = get(
            orderDetailResponse,
            "orderItems.0.addressVerificationInfo"
          );
        }

        !orderReq.ignoreCancel &&
          request("order-requests", {
            ...payload,
            recordId,
            deliveryMethod: actualDeliveryMethod,
            requestType,
            operator: operator,
            sourceMemberCode: sourceMemberCode,
          })
            .then((response) => {
              if (response) {
                if (
                  requestType === "order-communication" &&
                  !!requestCallBack
                ) {
                  requestCallBack();
                } else if (requestType === "recipient-info") {
                  ToasterHandler(
                    "hooray",
                    Localise(locMessages, "Order modified successfully")
                  );
                  actionCallback(new Date().toString(), recordId);
                } else {
                  actionCallback(requestType, recordId);
                }

                /**
                 * Setting menu action as empty in order to hide Cancel form after submission of it.
                 */
                if (menuAction === `cancel${orderItemId}`) setMenuAction("");
              }
              requestType === "adjustment" &&
                actionCallback(requestType, recordId);
            })
            .catch(() => {
              requestType === "recipient-info" && setActionProcessing(false);
              ToasterHandler(
                "uh oh",
                Localise(locMessages, "Order request failed, please try again.")
              );
            });
      }
    }, [requestTriggered]);

    useEffect(() => {
      const { requestType } = requestTriggered;

      if (!orderItemId || !requestType) return;

      const isFullfillmentWithCancelRequest =
        hasFulfillmentError && requestType === "cancel";

      const isDSFulfillmentwithRequest =
        (requestType === "reject" || requestType === "deliveryTypeChange") &&
        hasDSFulfillmentError;

      // Order should be marked as read only with florist request. Nothing should be marked as read simply by opening it.
      if (isFullfillmentWithCancelRequest || isDSFulfillmentwithRequest) {
        // All the orders with fullfillment error (past & future delivery dates), orders with viewChats & viewResponses
        setActionTriggered({
          recordId: orderItemId,
          action:
            orderStatus === "ERROR"
              ? "markOrderAsRead"
              : isFullfillmentWithCancelRequest
              ? "markAsReadAndRefund"
              : "markAsRead",
          refreshNeeded: true,
        });
      }
    }, [requestTriggered]);

    const createNewOrder = (isErrorOrder = false) => {
      setLoading(true);

      const selectedFlorist = JSON.parse(recordAction.split("_")[1]);

      const { name, memberCode, rating, minPrice, distance, phone } =
        selectedFlorist;

      const {
        messageNumber,
        messages,
        orderItemId,
        status,
        updatedOn,
        createdOn,
        erosOrderNumber,
        isPrinted,
        recipientInfo,
        deliveryInfo: { deliveryMethod },
        ...others
      } = orderDetails;

      const { name: fullName, ...actualRecipientInfo } = recipientInfo;

      const orderTotal =
        orderDetails.price?.find((obj) => obj.name === "actualPrice")?.value ||
        0;

      const createOrderReqObj = {
        orderItems: [
          {
            ...others,
            price: [
              {
                value: parseFloat(orderTotal).toFixed(2),
                name: "orderTotal",
              },
            ],
            recipientInfo: actualRecipientInfo,
            receivingMember: {
              memberCode: memberCode,
            },
            deliveryInfo: {
              ...orderDetails.deliveryInfo,
              floristInfo: {
                name,
                memberCode,
                rating,
                minPrice,
                distance,
                phone,
              },
            },
            previousOrderId: orderItemId,
          },
        ],
        orderChannel:
          Platform.OS === "web" ? (isTablet ? "Tablet" : "Web") : "Mobile",
        orderType: [
          "FLORIST_DELIVERED",
          "STORE_PICKUP",
          "WALK_IN",
          "PHONE_OUT",
        ].includes(deliveryMethod)
          ? "Local"
          : "Wired",
        orderOrigin: "MHQ",
        customerInfo: isEmpty(customerInfo) ? null : customerInfo,
        paymentDetails: isEmpty(paymentDetails) ? null : paymentDetails,
        createdOn: new Date().toISOString(),
        paymentType: "",
        savePayment: "",
        createHouseAccount: "",
        nameOnCard: "",
        cardNumber: "",
        expDate: "",
        securityCode: "",
        billingZip: "",
        coupon: "",
        isBillingAndCutomerAdddressSame: "",
      };

      request("create-order", {
        createOrderReqObj,
      })
        .then((response) => {
          request(
            isErrorOrder ? "order-read-action" : "order-message-actions",
            {
              recordId,
              deliveryMethod,
              markAsRead: true,
              isStructured: true,
              isUnstructured: true,
              sourceMemberCode: sourceMemberCode,
            }
          ).then((res) => {
            setLoading(false);
            actionCallback("", orderItemId);
            if (response) {
              ToasterHandler(
                "excellent",
                Localise(
                  locMessages,
                  "This order has been assigned to a new florist"
                )
              );
            } else {
              ToasterHandler(
                "uh oh",
                Localise(
                  locMessages,
                  "Please check your connection & select the florist again"
                )
              );
            }
          });
        })
        .catch(() => {
          ToasterHandler(
            "uh oh",
            Localise(
              locMessages,
              "Please check your connection & select the florist again"
            )
          );
        });
    };

    const hasCustomerInfo =
      Object.values(customerInfo).filter((val) => !!val).length > 0;
    const showCustomerInfo =
      hasCustomerInfo &&
      !(deliveryMethod === "FLORIST_PARTNER" && direction === "INBOUND");

    const setCurrentTab = (name) => {
      setFieldValue("actionItem", "");
      dispatch(setActiveTab(name));
      setTimeout(() => {
        // scrolling the main scroll to top
        scrollViewRef &&
          scrollViewRef?.current?.scrollTo({
            x: 0,
            y: 0,
            animated: true,
          });
        // scrolling the order details tab scroll to top
        tabDetailScrollRef &&
          tabDetailScrollRef?.current?.scrollTo({
            x: 0,
            y: 0,
            animated: true,
          });
      }, 50);
    };

    useEffect(() => {
      const showNotes = hasOrderNotesFeature(permissions, sourceMemberCode);

      const orderDetailTabs = getOrderDetailsTabs(
        orderDetailResponse,
        isFloristPickup,
        isWalkInOrder,
        isPickUpOrder,
        showPayment,
        showNotes
      );
      dispatch(setOrderDetailTabs(orderDetailTabs));

      // opening order-journey if there are any unread messages
      if (
        (orderDetails?.numOfUnreadChatMessages > 0 ||
          orderDetails?.numOfUnreadStructuredResponses > 0) &&
        !(hasDSFulfillmentError || hasFulfillmentError || hasSettlementError)
      ) {
        dispatch(setActiveTab("order-journey"));
      }

      // On component unmounting, resetting the orderRefundStatus
      return () => {
        setOrderRefundStatus("");
        dispatch(setRouteDetails({}));
        dispatch(setOrderViewersInfo([]));
        currentViewerInfo?.userActionId &&
          dispatch(
            updateOrderViewerInfo({
              params: {
                userActionId: currentViewerInfo?.userActionId,
                sourceMemberCode: currentViewerInfo?.siteId,
              },
            })
          );
      };
    }, []);

    useEffect(() => {
      if (
        recordAction &&
        recordAction.includes("selectNewFlorist") &&
        recordAction.split("_")[2] === recordId
      ) {
        setMenuAction("");
        dispatch(setIsSidecarOpen(false));
      }
    }, [recordAction]);

    useEffect(() => {
      if (showCancelSection === "Yes") {
        setFieldValue("actionItem", "cancel");
      }
      if (showRefundSection === "Yes") {
        dispatch(setActiveTab("payment"));
        setFieldValue("actionItem", "refund");
      }
    }, [orderDetailResponse]);

    useEffect(() => {
      routeId
        ? dispatch(
            fetchRouteDetails({
              params: { routeId, shopCode: receivingMember?.memberCode },
            })
          )
        : dispatch(setRouteDetails({}));
    }, [routeId, receivingMember?.memberCode, dispatch]);

    return (
      <View
        style={{
          ...(isSmallScreen &&
            isPreview && {
              flex: 1,
            }),
          width: "100%",
        }}
      >
        {isSmallScreen && (
          <View style={tw("pb-2")}>
            <Text style={fonts.heading3}>
              {Localise(locMessages, "Order Details")}
            </Text>
            <Breadcrumb
              variant="order-details"
              closeSideCar={() => onComplete()}
            />
          </View>
        )}

        {/* Order Head Implementation Component Start */}
        <OrderHead
          index={index}
          doNotFill={doNotFill}
          recordId={recordId}
          deliveryMethod={deliveryMethod}
          scrollViewRef={scrollViewRef}
          actionTriggered={actionTriggered}
          setActionTriggered={setActionTriggered}
          setRequestTriggered={setRequestTriggered}
          isActionProcessing={isActionProcessing}
          listingType={listingType}
          actionCallback={actionCallback}
          actionsList={actionsList}
          subActionsList={subActionsList}
          menuAction={menuAction}
          setMenuAction={setMenuAction}
          showDriverAction={showDriverAction}
          onComplete={onComplete}
          orderRefundStatus={orderRefundStatus}
          setOrderRefundStatus={setOrderRefundStatus}
          isInactiveOrder={isInactiveOrder}
          showPayment={showPayment}
          setShowDconForm={setShowDconForm}
          tabDetailScrollRef={tabDetailScrollRef}
        />
        {/* Order Head Implementation Component End */}

        <View style={[tw("pt-2"), { zIndex: 1 }]}>
          <DconInfo
            isPreview={isPreview}
            index={index}
            listingType={listingType}
            showDconForm={showDconForm}
            setShowDconForm={setShowDconForm}
            isSmallScreen={isSmallScreen}
            sourceMemberCode={sourceMemberCode}
            doNotFill={doNotFill}
            recordId={recordId}
            actionCallback={actionCallback}
            setActionProcessing={setActionProcessing}
            isActionProcessing={isActionProcessing}
            menuAction={menuAction}
            setMenuAction={setMenuAction}
            isPickUpOrder={isPickUpOrder}
            triggerAction={setActionTriggered}
          />
          {buyerInfo.name && (
            <BuyerInfo
              buyerInfo={buyerInfo}
              isSmallScreen={isSmallScreen}
              doNotFill={doNotFill}
            />
          )}
        </View>

        {/* --- New Tabbed Structure Layout Start --- */}
        <View
          style={{
            ...tw(isSmallScreen ? "mt-1" : ""),
            padding: 1,
          }}
        >
          <View
            style={{
              position: Platform.OS === "web" ? "sticky" : "relative",
              ...(Platform.OS === "web" && { top: orderHeadHeight }),
              zIndex: 99,
              backgroundColor: colors.secondary,
              borderWidth: 1,
              padding: 1,
              borderColor: colors.grayScaleLight,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <TabBar
              tabNames={orderDetailsTabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              scrollTabBar={true}
              showScrollBar={false}
              showsScrollIndicators={true}
              showBorder={false}
              style={{
                paddingHorizontal: 10,
              }}
            />
          </View>
          <ScrollView
            style={{
              ...(Platform.OS === "web" && { maxHeight: 500 }),
              backgroundColor: colors.secondary,
              borderWidth: 1,
              padding: 1,
              borderColor: colors.grayScaleLight,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderTopColor: "transparent",
            }}
            ref={tabDetailScrollRef}
          >
            {currentTab === "order-summary" && (
              <View style={{ padding: 15 }}>
                <RecipientDateInfo
                  {...{
                    index,
                    recordId,
                    isSmallScreen,
                    orderDetails,
                    triggerRequest: setRequestTriggered,
                    triggerAction: setActionTriggered,
                    supportDateChange: supportedOrderActions.dateChange,
                    actionCallback: actionCallback,
                  }}
                />
                <ProductDetails
                  isSmallScreen={isSmallScreen}
                  triggerRequest={setRequestTriggered}
                  triggerAction={setActionTriggered}
                  isPreview={isPreview}
                  cardInfo={cardInfo}
                  doNotFill={doNotFill}
                  index={index}
                  isPickUpOrder={isPickUpOrder}
                  supportPriceChange={supportedOrderActions.priceChange}
                  showProductInfo={currentTab === "order-summary"}
                  listingInfo={listingInfo}
                  statusList={statusList}
                  initData={initData}
                  fetchData={fetchData}
                  scrollViewRef={scrollViewRef}
                  tabDetailScrollRef={tabDetailScrollRef}
                />
                {showCustomerInfo && (
                  <CustomerProfileInfo doNotFill={doNotFill} />
                )}
                <FulfillmentInfo
                  index={index}
                  triggerAction={setActionTriggered}
                  recordAction={recordAction}
                  onConfirm={createNewOrder}
                  isSmallScreen={isSmallScreen}
                  setFulfillmentPos={setFulfillmentPos}
                  doNotFill={doNotFill}
                  isTransferEligible={isTransferEligible}
                  memberCodes={memberCodes}
                  isPickUpOrder={isPickUpOrder}
                />
                <UpsertDesigner
                  triggerAction={setActionTriggered}
                  doNotFill={doNotFill}
                  index={index}
                  listingInfo={listingInfo}
                  statusList={statusList}
                  initData={initData}
                  fetchData={fetchData}
                  scrollViewRef={scrollViewRef}
                  selectedActionRef={selectedActionRef}
                  tabDetailScrollRef={tabDetailScrollRef}
                />
                <UploadImageButton
                  index={index}
                  recordId={recordId}
                  triggerAction={setActionTriggered}
                  scrollViewRef={scrollViewRef}
                  selectedActionRef={selectedActionRef}
                  tabDetailScrollRef={tabDetailScrollRef}
                />
              </View>
            )}

            {currentTab === "delivery" && (
              <View style={{ padding: 15 }}>
                {isFloristPickup ? (
                  <FloristPickupInfo
                    index={index}
                    isSmallScreen={isSmallScreen}
                    doNotFill={doNotFill}
                  />
                ) : isWalkInOrder ? (
                  <WalkInOrderInfo
                    index={index}
                    isSmallScreen={isSmallScreen}
                    triggerAction={setActionTriggered}
                    doNotFill={doNotFill}
                  />
                ) : isPickUpOrder ? (
                  <PickupInfo
                    index={index}
                    isSmallScreen={isSmallScreen}
                    doNotFill={doNotFill}
                    isPreview={isPreview}
                  />
                ) : (
                  <DeliveryInfo
                    triggerRequest={setRequestTriggered}
                    triggerAction={setActionTriggered}
                    isPreview={isPreview}
                    onDSSubmit={pullOrderDetails}
                    sourceMemberCode={sourceMemberCode}
                    doNotFill={doNotFill}
                    index={index}
                    recordId={recordId}
                    actionCallback={actionCallback}
                    supportDateChange={supportedOrderActions.dateChange}
                    isRecipientInfoSubmitted={isRecipientInfoSubmitted}
                    setRecipientInfoSubmitted={setRecipientInfoSubmitted}
                    setActionProcessing={setActionProcessing}
                  />
                )}
              </View>
            )}
            {currentTab === "order-journey" && (
              <OrderMessages
                index={index}
                isSmallScreen={isSmallScreen}
                operator={operator}
                triggerRequest={setRequestTriggered}
                triggerAction={setActionTriggered}
                menuAction={menuAction}
                recordAction={recordAction}
                hideSendInput={
                  doNotFill ||
                  isPickUpOrder ||
                  supportedOrderActions.hideChatSend
                }
                isPickUpOrder={isPickUpOrder}
              />
            )}
            {currentTab === "payment" && (
              <View style={{ padding: 15 }}>
                <PaymentDetails
                  isPreview={isPreview}
                  taxes={taxes}
                  orderRefundStatus={orderRefundStatus}
                  setOrderRefundStatus={setOrderRefundStatus}
                  setActionProcessing={setActionProcessing}
                  triggerAction={setActionTriggered}
                />
              </View>
            )}
            {currentTab === "order-notes" && (
              <View style={{ padding: 15 }}>
                <OrderNotes index={index} triggerAction={setActionTriggered} />
              </View>
            )}
          </ScrollView>
        </View>
        {/* --- New Tabbed Structure Layout End --- */}

        {reopenWarnDetails?.showModal &&
        reopenWarnDetails?.source === "details" ? (
          <ReopenModal {...{ reopenWarnDetails, setFieldValue }} />
        ) : null}
      </View>
    );
  }
);

export default OrderDetails;
