import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";

import CustomerInput from "./input";
import { getValidationSchema } from "./yup";
import { SaveCancelButtons, Section } from "../../helper";
import { getInitialValues } from "./ui-config";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { Spinner, ToasterHandler } from "components/elements";
import { Form } from "components/elements/forms";
import { fonts } from "styles/theme";

import tw from "tailwind-rn";
import get from "lodash/get";

import I18NContext from "library/contexts/i18N";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import {
  saveCustomerInfo,
  saveCustomerHouseAccount,
} from "library/sagas/views/home/drawer/customer-directory/slice";

const CustomerInfo = ({
  UIConfig,
  isCreate,
  redirectToCustomerDirectoryOnSave = true,
  createHouseAccount = false,
  orderTotal = "",
  formRef,
  onSuccess,
  onReject,
  hideSaveCancel,
  setFormSubmitting,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectCustomerDetails);
  const initialValues = getInitialValues(customerDetails);
  const { messages, Localise } = React.useContext(I18NContext);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);

  const { InputFields, CustomerHistory = [] } = UIConfig;

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  function onSubmit(values, formikBag) {
    setFormSubmitting && setFormSubmitting(true);
    dispatch(
      saveCustomerInfo({
        params: { ...values, isCreate },
        resolve: () => {
          if (!createHouseAccount) {
            ToasterHandler(
              "success",
              Localise(
                messages,
                isCreate
                  ? "Customer created successfully"
                  : "Customer Info saved successfully"
              )
            );
            formikBag.resetForm(initialValues);
            redirectToCustomerDirectoryOnSave &&
              navigation.dispatch(CommonActions.navigate("customer-directory"));
          } else {
            dispatch(
              saveCustomerHouseAccount({
                params: {
                  customerId: values.customerId,
                  storeOrigin: values.storeOrigin,
                  houseAccountId: "",
                  accountType: "Balance_Forward",
                  creditLimit: orderTotal,
                  isActive: true,
                },
                resolve: () => {
                  ToasterHandler(
                    "success",
                    Localise(messages, "House Account created successfully")
                  );
                  formikBag.setSubmitting(false);
                  onSuccess && onSuccess();
                },
                reject: (message) => {
                  ToasterHandler(
                    "error",
                    Localise(
                      messages,
                      message ||
                        "Failed to update House Account. Please try again"
                    )
                  );
                  formikBag.setSubmitting(false);
                  onReject && onReject();
                },
              })
            );
          }
        },
        reject: (message = "") => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              message !== ""
                ? message
                : "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (formRef) {
      formRef.current.resetForm();
    }
  }, []);
  return (
    <Form
      innerRef={formRef}
      initialValues={{ ...initialValues, createHouseAccount }}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      validationSchema={getValidationSchema(Localise, messages)}
      validateOnBlur={validationOnChange}
      validateOnChange={validationOnChange}
      render={(props) => {
        const { submitCount } = props;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);

        return (
          <>
            {InputFields.map((data, index) => {
              return data.type === "group" ? (
                <View key={index} style={tw("pb-4")}>
                  <Text style={[fonts.heading5, tw("pb-4")]}>
                    {Localise(messages, data.name)}
                  </Text>
                  <View
                    style={[tw("flex flex-row"), { paddingHorizontal: 20 }]}
                  >
                    {data?.fields?.map((data, index) => (
                      <CustomerInput
                        data={data}
                        key={index}
                        isCreate={isCreate}
                        initialAddress={initialValues?.address || {}}
                      />
                    ))}
                  </View>
                </View>
              ) : (
                <CustomerInput
                  data={data}
                  key={index}
                  isCreate={isCreate}
                  initialAddress={initialValues?.address || {}}
                />
              );
            })}
            {get(initialValues, "customerId", "") !== "" &&
              CustomerHistory.length > 0 && (
                <Section title={Localise(messages, "Customer History")}>
                  {CustomerHistory.map((data, index) => (
                    <View style={tw("flex-row p-2")} key={index}>
                      <Text style={{ width: "40%" }}>{data.label}</Text>
                      <Text>{initialValues[data.key]}</Text>
                    </View>
                  ))}
                </Section>
              )}
            {
              <View style={{ display: hideSaveCancel ? "none" : "flex" }}>
                <SaveCancelButtons />
              </View>
            }
          </>
        );
      }}
    />
  );
};

export default CustomerInfo;
