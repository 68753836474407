import React, { useContext, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import {
  selectActiveTab,
  selectDSEligibilityFailureMessage,
} from "library/sagas/ongoing/current-orders/selector";
import { selectRouteDetails } from "library/sagas/ongoing/order-details/selector";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { hasDeliveryZone } from "library/utils/featureAvailability";
import { Maps, DeliveryZone } from "components/elements";
import { generateDeliveryInfoContent } from "../helper";

import RecipientInfo from "./recipient-info";
import RequestDateChange from "./request-date-change";
import {
  isFloristDelivered,
  isIncomingWiredOrder,
  hasEditRouteAccess,
} from "./helper";
import { getRouteDisplayName } from "components/views/drawer/delivery/routes-dashboard/helper";
import AddressRevert from "./address-revert";

const RecipientDateInfo = ({
  index,
  recordId,
  isSmallScreen,
  orderDetails,
  triggerRequest,
  triggerAction,
  supportDateChange,
  actionCallback,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const currentTab = useSelector(selectActiveTab);
  const dsEligibilityFailureDetails = useSelector(
    selectDSEligibilityFailureMessage
  );
  const routeDetails = useSelector(selectRouteDetails);
  const dsEligibilityFailureMessage = Object.values(dsEligibilityFailureDetails)
    ?.filter(Boolean)
    ?.join("\n");
  const [mapAccordionStatus, setMapAccordionStatus] = useStateIfMounted(false);
  const { messages: locMessages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);

  const {
    orderItemId = "",
    direction = "",
    messages = [],
    receivingMember: {
      latitude: fillerLatitude = "",
      longitude: fillerLongitude = "",
      memberCode: receivingMemberCode = "",
    } = {},
    recipientInfo: {
      addressLine1,
      latitude: recipientLatitude = "",
      longitude: recipientLongitude = "",
      originalRecipientAddress,
    } = {},
    deliveryInfo: { deliveryMethod, routeId },
  } = orderDetails;

  const hasDSSubmittedMsg = !!messages.find(
    (e) =>
      e.messageType === "OLC" &&
      ["Delivery Service Requested", "Rejected by Delivery Service"].some(
        (msg) => e.comments?.includes(msg)
      )
  );
  const showDSRequestsLink = direction === "INBOUND" && hasDSSubmittedMsg;

  const fillerLocation = {
    locations: [
      {
        latitude: parseFloat(fillerLatitude || 0),
        longitude: parseFloat(fillerLongitude || 0),
        isFillingFlorist: true,
      },
    ],
  };

  const viewDSRequests = () => {
    dispatch(setSelectScreenKey("DS"));
    UserProfileStorage.setCurrentTab("DS");
    dispatch(setCurrentPage("DeliveryDashboard"));
    navigation.dispatch(
      CommonActions.navigate({
        name: "lmds-delivery",
        params: {
          referenceNumber: orderItemId,
          shopCode: receivingMemberCode,
          applyParamFilters: true,
          search: {
            title: orderItemId,
            value: orderItemId,
          },
        },
      })
    );
  };

  const showDeliveryZone = useMemo(
    () =>
      hasDeliveryZone({
        shopCode: receivingMemberCode,
        deliveryMethod,
        direction,
        addressLine1,
      }),
    [receivingMemberCode, deliveryMethod, direction, addressLine1]
  );

  const showRouteName =
    isFloristDelivered(deliveryMethod) ||
    isIncomingWiredOrder(deliveryMethod ?? "", direction);

  const redirectToEditRoute = () => {
    const {
      route: { memberCodes: routeMemberCodes, routeId, autoRouteId } = {},
    } = routeDetails;

    if (!hasEditRouteAccess(permissions, routeDetails)) return;

    dispatch(setCurrentPage("edit-route"));
    if (autoRouteId) {
      UserProfileStorage.setAutoRouteId(autoRouteId);
    } else {
      UserProfileStorage.setAutoRouteId(undefined);
    }
    UserProfileStorage.setRouteShopList([...routeMemberCodes]);
    navigation.dispatch(
      CommonActions.navigate({
        name: "edit-route",
        params: {
          routeId,
          memberCodes: routeMemberCodes.join(","),
          accessByDriver: "false",
        },
      })
    );
  };

  return (
    <View style={[tw("flex flex-col"), { zIndex: 6 }]}>
      <View style={tw("flex flex-row w-full")}>
        <View style={tw("w-1/2 flex items-start")}>
          <RecipientInfo
            {...{
              index,
              recordId,
              isSmallScreen,
              orderDetails,
              mapAccordionStatus,
              setMapAccordionStatus,
              direction,
            }}
          />
          {originalRecipientAddress ? (
            <AddressRevert
              index={index}
              receivingMemberCode={receivingMemberCode}
              actionCallback={actionCallback}
            />
          ) : null}
        </View>
        <View style={tw("w-1/2 flex items-end")}>
          <RequestDateChange
            {...{
              index,
              triggerRequest,
              triggerAction,
              supportDateChange,
              isSmallScreen,
            }}
          />
          {/* Delivery Zone Name */}
          {showDeliveryZone ? (
            <DeliveryZone
              {...{
                memberCode: receivingMemberCode,
                index,
                orderDetailsPage: true,
                customStyle: [
                  { fontSize: 14, paddingLeft: 0, paddingVertical: 2 },
                ],
                isVertical: true,
                zIndex: -2,
              }}
            />
          ) : null}
          {/* Route Name */}
          {showRouteName &&
            generateDeliveryInfoContent({
              label: "Route",
              name:
                routeId === routeDetails?.route?.routeId
                  ? getRouteDisplayName(
                      routeDetails?.route?.routeFriendlyName,
                      routeDetails?.route?.routeName
                    ) || ""
                  : "",
              tooltipText: routeDetails?.route?.routeName,
              zIndex: -3,
              onPress: () => {
                redirectToEditRoute();
              },
              textStyle: hasEditRouteAccess(permissions, routeDetails)
                ? [fonts.link1, { fontWeight: "400" }]
                : [{ fontSize: 12 }],
            })}
        </View>
      </View>

      {/* View Delivery Service Requests */}
      {currentTab === "delivery" && showDSRequestsLink ? (
        <Text
          testID={"view_linked_DS_requests"}
          accessibilityLabel={"view_linked_DS_requests"}
          style={[
            fonts.link1,
            tw("my-2"),
            { textDecorationLine: "underline", width: 300 },
          ]}
          onPress={viewDSRequests}
        >
          {Localise(locMessages, "View Delivery Service Requests")}
        </Text>
      ) : null}

      {currentTab === "delivery" && dsEligibilityFailureMessage ? (
        <View style={[tw("flex items-start mt-2 pr-4"), { zIndex: -1 }]}>
          <Text
            style={{
              fontSize: 14,
            }}
          >
            {Localise(
              locMessages,
              "This order is not eligible for Delivery Service due to"
            ) + ":"}
          </Text>
          <Text
            style={{
              ...tw("text-left"),
              padding: 5,
              fontSize: 14,
            }}
          >
            {dsEligibilityFailureMessage}
          </Text>
        </View>
      ) : null}

      {/* Map View */}
      {mapAccordionStatus && (
        <View style={tw("py-3")}>
          <Maps
            mapMarkers={{
              ...fillerLocation,
              ...{
                recipientLatLong: {
                  recipientLatitude: parseFloat(recipientLatitude),
                  recipientLongitude: parseFloat(recipientLongitude),
                },
              },
            }}
            zoomLevel={8}
          />
        </View>
      )}
    </View>
  );
};

export default RecipientDateInfo;
