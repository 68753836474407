const CUSTOMER_DIRECTORY = Object.freeze({
  CONTACT_SUBSCRIPTION:
    "There’s an active subscription linked to this contact. Please cancel the subscription before deleting the contact.",
  CONTACT_DELETED: "Contact has been deleted",
  FAILURE_ERROR: "We were unable to process your request, please try again.",
  CONTACT_UPDATED: "Contact has been updated",
  CONTACT_CREATED: "Contact has been created",
  CONFIRM_DELETE_CONTACT: "Are you sure you want to delete this contact?",
  CONFIRM_MERGE_START: "Merge started and will be completed in a few minutes.",
  ADD_CONTACT: "Add Contact",
  MERGE_CONTACT: "Merge Contact",
  MERGE_RECORDS: "Merge Records",
  MERGE_SELECTED: "Merge Selected",
  SELECT_RECORD: "Search and select record to merge",
  CONFIRM_MERGE:
    "Would you like to merge these profiles? This action cannot be reversed",
  INVOICE_PREFERENCE_ENABLED:
    "Invoices for this customer will be generated automatically, overriding the default global settings configured in Shop Settings.",
  INVOICE_PREFERENCE_DISABLED:
    "Invoices for this customer will not be generated automatically, overriding the default global settings configured in Shop Settings.",
  STATEMENT_PREFERENCE_ENABLED:
    "House Account Statements for this customer will be sent automatically via email, overriding the default global settings configured in Shop Settings.",
  STATEMENT_PREFERENCE_DISABLED:
    "House Account Statements for this customer will not be sent automatically via email, overriding the default global settings configured in Shop Settings.",
  ERROR_INVOICE_PREFERENCE_ENABLED:
    "It looks like your global setting are currently disabled in shop setting for sending invoices automatically. Please enable to update for this customer",
  ERROR_STATEMENT_PREFERENCE_ENABLED:
    "It looks like your global setting are currently disabled in shop setting for sending statements automatically. Please enable to update for this customer",
  PAYMENT_RECEIVED: "Payment received successfully!",
  AMT_ADDED_TO_CREDIT_BAL: "Amount added to Credit Balance",
  AMT_ADDED_TO_BAL_DUE: "Amount added to Balance due",
  BAL_FWD_TOOLTIP_INFO:
    "In Balance Forward accounting, payments are automatically applied to the oldest outstanding invoices and cannot be manually adjusted.",
  PRINT_RECEIPT_SUCCESS_MSG: "Receipt has been sent for printing",
  EMAIL_RECEIPT_TOOLTIP: "Click to Email Receipt",
  PRINT_RECEIPT_TOOLTIP: "Click to Print Receipt",
  EMAIL_RECEIPT_SUCCESS_MSG: "Your Receipt emailed successfully",
  CURRENT_BALANCE_INFO_TEXT:
    "To make payment adjustments to the account, submit a Credit/Debit Memo above.",
  RECEIVE_PAYMENT_INFO_TEXT:
    "To view House Account balance, previous payments and print/email receipts, first select a Shop above.",
  INVOICES_PAID_IN_FULL: "Invoices paid in full",
  NO_RESULTS_FOUND: "No results found",
});

export default CUSTOMER_DIRECTORY;
